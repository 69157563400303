import EditIcon from '@jibin/common/icons/Edit/Edit';
import LocationOnIcon from '@jibin/common/icons/LocationOn/LocationOn';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { FC, useCallback, useEffect, useState } from 'react';
import { ListTableProps } from './Models/ListTableProps';
import { shallowEqual, useSelector } from 'react-redux';
import { TechinicalInformation } from '../TechinicalInformation.Api';
import AddWirelessAssessmentModel from './AddWirelessAssessmentModel';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import ConformationModel from '@jibin/common/components/ConformationModel';
import { Wireless } from './Models/wireless';
import { NoDataAvailable } from '../../Common/NoDataAvailable';
import { Button, Skeleton } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import TablePagination from '../../Common/TablePagination';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: 4,
    padding: 0
  }
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const Row: FC<{ row: any; index: any; refreshTable(): void }> = (prop) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [isRefresh, setIsRefresh] = useState(true);
  const [rowdata, setRowdata] = useState({} as any);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any, data: any) => {
    data['company_facility_uuid'] = data.facility.company_facility_uuid;
    setRowdata(data);
    setAnchorEl(event.currentTarget);
  };
  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
    handleClose();
    prop.refreshTable();
  };
  const colosepill = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setRowdata(null);
    setAnchorEl(null);
  };
  const handleConfirm = (data: any) => {
    TechinicalInformation.PatchWirelessAssessment(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      data.tech_info_wireless_uuid,
      {
        status: data.status == 'Active' ? 'Inactive' : 'Active'
      }
    ).then(
      (q: any) => {
        handleRefresh();
        handleClose();
      },
      (err: any) => {}
    );
  };

  return (
    <>
      <Box sx={Style.Common.Divider}></Box>
      <TableRow
        id={prop.row.tech_info_wireless_uuid}
        key={'a' + prop.index}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {prop.row.ssid}
        </TableCell>
        <TableCell sx={{ width: 0.4 }}>
          <Box sx={{ width: '300px' }}>
            <Typography> {prop.row.description}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          <LightTooltip
            title={
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: '600',
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                    p: 1
                  }}>
                  <LocationOnIcon sx={{ mr: 1 }} />
                  {prop.row.facility.name}
                </Typography>
                <Typography variant="caption" sx={{ p: 1, display: 'flex' }}>
                  {prop.row.facility.address}
                </Typography>
              </Box>
            }>
            <Box component="span" sx={{ textDecoration: 'underline' }}>
              {prop.row.facility.name}
            </Box>
          </LightTooltip>
        </TableCell>
        <TableCell>
          <Box>
            {prop.row.status.toUpperCase() == 'ACTIVE' && (
              <Box sx={{ ...Style.SiteListTable.StatusActive, bgcolor: 'common.green' }}></Box>
            )}
            {prop.row.status.toUpperCase() == 'PENDING' && (
              <Box
                sx={{
                  ...Style.SiteListTable.StatusActive,
                  bgcolor: 'common.orange'
                }}></Box>
            )}
            {prop.row.status.toUpperCase() == 'INACTIVE' && (
              <Box
                sx={{
                  ...Style.SiteListTable.StatusActive,
                  bgcolor: '#EF4444'
                }}></Box>
            )}
            {prop.row.status}
          </Box>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="Example"
            onClick={(e) => {
              handleClick(e, prop.row);
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <MoreHorizIcon color="primary" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id={'account-menu'}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <AddWirelessAssessmentModel
              colosepill={colosepill}
              IsEdit={true}
              Wireless={rowdata}
              handleSubmit={handleRefresh}
            />
            {/* <Divider /> */}
            <ConformationModel
              handleConfirm={() => {
                handleConfirm(rowdata);
              }}
              title="Confirm Action"
              handleClose={() => handleClose()}
              data={rowdata}
              label={{ ...label }}
              type={DeleteTypesEnum.SwitchAndLabel}
              asking={
                rowdata?.status == 'Active'
                  ? 'Are you sure you want to deactivate this Wireless?'
                  : 'Are you sure you want to activate this Wireless?'
              }
              description={rowdata?.ssid}
              cancelButtonText="Cancel"
              conformButtonText="Confirm"
            />
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
};

export const WirelessAssessmentTable: FC<ListTableProps> = (props: any) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [wirelessList, setWirelessList] = useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };
  const location = useLocation();
  const GetWirelessAssessments = useCallback(() => {
    TechinicalInformation.GetWirelessAssessmentList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams(pagination).toString()
    ).then(
      (q: any) => {
        setWirelessList(q.data);
        setTotalCount(q?.data?.pagination?.total_records);
        setIsSkeleton(false);
        if (searchParams.get('tech_info_wireless_uuid')) {
          const filterdData = q.data.data.filter(
            (el, index) => el.tech_info_wireless_uuid == searchParams.get('tech_info_wireless_uuid')
          );

          setTimeout(() => {
            const element = document.getElementById(filterdData[0].tech_info_wireless_uuid);
            if (element) {
              var headerOffset = 90;
              var elementPosition = element.getBoundingClientRect().top;
              var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
              element.classList.add('flash');
              setTimeout(() => {
                element.classList.remove('flash');
              }, 1000);
            }
          }, 1);
          searchParams.delete('tech_info_wireless_uuid');
          setSearchParams(searchParams);
        }
      },
      (err: any) => {}
    );
  }, [commonDetails, location.search, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    GetWirelessAssessments();
  }, [GetWirelessAssessments, isRefresh, props]);
  const colosepill = () => {};
  if (isSkeleton) {
    return (
      <Box>
        <Skeleton sx={{ p: 5 }} />
      </Box>
    );
  } else if (wirelessList?.data?.length == 0) {
    return (
      <NoDataAvailable
        title="No Data Available"
        message="Please provide your company's technical information to enable your security services.">
        <Box sx={{ ...Style.CompanySettingPage.AddButton, textAlign: 'center', mt: 2 }}>
          <AddWirelessAssessmentModel
            colosepill={colosepill}
            IsEdit={false}
            Wireless={new Wireless()}
            handleSubmit={handleRefresh}
          />
        </Box>
      </NoDataAvailable>
    );
  }
  return (
    <Box sx={{ ...Style.SiteListTable.SiteListTableWrapper, overflowX: 'initial' }}>
      <Box sx={Style.Applications.AddButton}>
        <AddWirelessAssessmentModel
          colosepill={colosepill}
          IsEdit={false}
          Wireless={new Wireless()}
          handleSubmit={handleRefresh}
        />
      </Box>
      <Table sx={{ minWidth: 650, marginBottom: '12px' }} aria-label="site list table">
        <TableHead
          sx={{ position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' }}>
          <TableRow>
            <TableCell>SSID</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wirelessList?.data?.map((row, index) => (
            <Row index={index} row={row} refreshTable={() => handleRefresh()} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};

export default WirelessAssessmentTable;
