import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CrossCancle from '../../icons/CrossCancle/CrossCancle';
import List from '@mui/material/List';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Autocomplete,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  ListSubheader,
  ListSubheaderProps,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import { shallowEqual, useSelector } from 'react-redux';
import Style from '@jibin/common/style/Styles';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { RemediationApi } from '@jibin/app/src/Pages/Remediation/Remediation.Api';
import { RiskManagementApi } from '@jibin/app/src/Pages/RiskManagement/RiskManagement.Api';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import CopyUrl from '../ConformationModel/CopyUrl';
import { useFormik } from 'formik';
import { GetAssignImage } from '@jibin/common/utils/Common';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import LinkIcon from '@jibin/common/icons/LinkIcon';
import CalenderIconFilter, {
  CalenderIconSmall
} from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import { NoHistoryFound } from '@jibin/app/src/Pages/Common/NoDataAvailable';
import SearchIcon from '@jibin/common/icons/Search/Search';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import CyberSecurityDomainDropdown from 'src/Pages/Common/CyberSecurityDropdown';

export interface RemidiationPopUpProp {
  risk_uuid: any;
  el: any;
  isCreateRemediation?: boolean;
  isEditDD?: boolean;
  setIsEditDD?: any;
  closePopup(event: React.KeyboardEvent | React.MouseEvent): void;
  isEngineer: boolean;
  setIsRefresh: any;
  isRefresh: any;
  isGap: any;
  servicesOption: any;
  selectedRiskList?: any;
  risksList?: any;
  isMultiCreateRemediation?: any;
  bulkSelectedRisks?: any;
  setBulkSelectedRisks?: any;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const RiskPopUp: FC<RemidiationPopUpProp> = (prop) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const { isCreateRemediation, isEditDD, setIsEditDD } = prop;
  const [remediationEditData, setRemediationEditData] = useState({
    name: prop?.el?.name,
    description: prop?.el?.description,
    recommendation: prop?.el?.recommendation,
    criticality: prop?.el?.criticality,
    status: prop?.el?.status,
    assets:
      prop?.el?.assets?.custom ||
      prop?.el?.assets?.ip ||
      prop?.el?.assets?.mac ||
      prop?.el?.assets?.machine ||
      prop?.el?.assets?.hostnames?.join(', '),
    services_uuid: prop?.el?.service?.services_uuid,
    integration_tools_uuid: prop?.el?.tool?.tool_uuid
  });
  const [files, setFiles] = useState([]);
  const [assetWithValue, setAssetWithValue] = useState({});
  const [assetsTagList, setAssetTagList] = useState([]);

  const [fileData, setFileData] = useState([]);
  const validURL = (str) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  };

  const [value, setValue] = React.useState(0);
  const [resolve, setResolve] = useState(false);
  const [assign, setAssign] = useState(null);
  const [filelist, setFilesList] = useState([]);
  const [actionTaken, setActionTaken] = useState('');
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [skelton, setSkeleton] = useState(false);
  const [dataPosted, setDataPosted] = useState(false);
  const [isAssignButtonLoading, setIsAssignButtonLoading] = useState(false);
  const [isEditButtonLoading, setIsEditButtonLoading] = useState(false);
  const [currentAssign, setCurrentAssign] = useState({});
  const [tagOptions, setTagOptions] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [isComplete, setIsComplete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteOption, setDeleteOption] = useState('');
  const [tagErrorMessage, setTagErrorMessage] = useState({ maxLimit: '', tagLimit: '' });
  const [compalate, setComplate] = useState(true);
  const [riskHistory, setRiskHistory] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [searchAssets, setSearchAssets] = useState([]);
  const [selectedUUID, setSelectedUUID] = useState([]);
  const [associatedAssets, setAssociatedAssets] = useState([]);
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [servicesOption, setServicesOption] = useState([]);

  const GetServiceFilterOption = async () => {
    try {
      const res = await RiskManagementApi.GetServicesList();
      const ProviderListForFilter = res.data.data.map((el) => {
        return el;
      });
      setServicesOption(ProviderListForFilter);
    } catch (error) {}
  };

  const handleToggleIndex = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (
      box1Ref.current &&
      !box1Ref.current.contains(event.target) &&
      box2Ref.current &&
      !box2Ref.current.contains(event.target) &&
      box3Ref.current &&
      !box3Ref.current.contains(event.target)
    ) {
      setIsBoxVisible(false);
    }
  };
  const isSelected = (name: string) => selectedUUID?.indexOf(name) !== -1;
  const handleClickCheck = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selectedUUID.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUUID, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUUID.slice(1));
    } else if (selectedIndex === selectedUUID?.length - 1) {
      newSelected = newSelected.concat(selectedUUID.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUUID.slice(0, selectedIndex),
        selectedUUID.slice(selectedIndex + 1)
      );
    }
    setSelectedUUID(newSelected);
  };
  const assetColorBackGround = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#97e9d2';
    } else if (status === 'Not Started') {
      color = '#FFE6E2';
    } else if (status === 'Risk Accepted') {
      color = '#e5e5e7';
    } else if (status === 'In Remediation') {
      color = '#FEF3C7';
    }
    return color;
  };
  const assetColorIcon = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#10B981';
    } else if (status === 'Not Started') {
      color = '#F73939';
    } else if (status === 'Risk Accepted') {
      color = '#D9E1FD';
    } else if (status === 'In Remediation') {
      color = '#FBBF24';
    }
    return color;
  };
  const bgStatusColor = (status: string) => {
    let color = '';
    if (status === 'Resolved') {
      color = '#10B981';
    } else if (status === 'Not Started') {
      color = '#F87171';
    } else if (status === 'Risk Accepted') {
      color = '#D9E1FD';
    } else if (status === 'In Remediation') {
      color = '#FBBF24';
    }
    return color;
  };
  const addRisks = () => {
    const filteredData = searchAssets
      ?.filter(
        (item) =>
          selectedUUID?.includes(item?.risk_uuid) &&
          item?.remediation_uuid === '' &&
          item?.status !== 'Resolved'
      )
      ?.slice(0, 10);
    setAssociatedAssets([...associatedAssets, ...filteredData]);
    GetSearchRisk(selectedUUID);
  };
  const removeRisk = (assetUUIDToRemove) => {
    const updatedAssetsList = associatedAssets?.filter(
      (asset) => asset?.risk_uuid !== assetUUIDToRemove
    );
    const updatedAssetUUIDs = selectedUUID?.filter((uuid) => uuid !== assetUUIDToRemove);
    setSelectedUUID(updatedAssetUUIDs);
    setAssociatedAssets(updatedAssetsList);
    GetSearchRisk(updatedAssetUUIDs);
  };
  const handleTagChange = (event, value) => {
    var newArray = value?.filter(function (elem, pos) {
      return value?.indexOf(elem) == pos;
    });
    if (tagErrorMessage?.maxLimit) {
      setTagErrorMessage({
        ...tagErrorMessage,
        maxLimit: ''
      });
      return;
    }
    formik.setFieldValue('tags', newArray);
    maketags(newArray);
  };
  const setRemediationInitialData = () => {
    const assets: any = [];
    const assetsTag: any = [];

    if (rowData?.assets?.custom) {
      assets.push(rowData?.assets?.custom);
    }
    if (rowData?.assets?.ip) assets.push(rowData?.assets?.ip);
    if (rowData?.assets?.mac) assets.push(rowData?.assets?.mac);
    if (rowData?.assets?.machine) assets.push(rowData?.assets?.machine);
    if (rowData?.assets?.hostnames?.length > 0) assets.push([...rowData?.assets?.hostnames]);

    if (rowData?.assets?.hostnames?.length > 0) assets.push([...rowData?.assets?.hostnames]);
    for (const key in rowData?.assets) {
      assetsTag.push(key);
    }
    setAssetWithValue(rowData?.assets);
    setAssetTagList(assetsTag);

    const updatedData = {
      name: rowData?.name,
      description: rowData?.description,
      recommendation: rowData?.recommendation,
      criticality: rowData?.criticality,
      status: rowData?.status,
      assets: assets.join(', '),
      services_uuid: rowData?.service?.services_uuid,
      integration_tools_uuid: rowData?.tool?.tool_uuid
    };

    setRemediationEditData(updatedData);
  };
  function validateString(inputString) {
    if (!/^[a-zA-Z_\s]+$/.test(inputString)) {
      return false;
    }

    if (/^\d+$/.test(inputString)) {
      return false;
    }

    return true;
  }
  const GetTags = useCallback(async () => {
    const query: any = {
      include_non_associated_tags: true
    };
    try {
      const res = isCreateRemediation
        ? await RemediationApi.TagsList(
            commonDetails.data.user_uuid,
            commonDetails.data.company.company_uuid,
            new URLSearchParams(query).toString()
          )
        : await RiskManagementApi.GetTagsList(
            commonDetails.data.company.company_uuid,
            commonDetails.data.user_uuid,
            new URLSearchParams(query).toString()
          );
      if (res?.data?.data) {
        const TagList = res.data.data.map((el) => el);
        setTagOptions(TagList);
      }
    } catch (error) {}
  }, [commonDetails, isCreateRemediation]);

  function MyListSubheader(props: ListSubheaderProps) {
    return <ListSubheader {...props} />;
  }

  MyListSubheader.muiSkipListHighlight = true;

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const [rowData, setRowData] = useState<any>({});
  const [assetValue, setAssetValue] = useState([]);

  const GetRemidiationDetails = useCallback(async () => {
    if (!assign) {
      setSkeleton(true);
    }

    try {
      let res = await RiskManagementApi.GetRisk(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        ref.current
      );
      if (res?.data?.data && res?.data?.data?.length > 0) {
        res.data.data = res?.data?.data[0];
      }
      setFacilities(isCreateRemediation ? [] : res?.data?.data?.tags);
      const assets: any = [];
      const assetsLabel: any = [];
      const assetsTag: any = [];
      if (res?.data?.data?.assets?.custom) {
        assets.push(res?.data?.data?.assets?.custom);
      }
      if (res?.data?.data?.assets?.ip) {
        assets.push(res?.data?.data?.assets?.ip);
      }
      if (res?.data?.data?.assets?.mac) assets.push(res?.data?.data?.assets?.mac);
      if (res?.data?.data?.assets?.machine) assets.push(res?.data?.data?.assets?.machine);
      if (res?.data?.data?.assets?.hostnames?.length > 0)
        assets.push([...res?.data?.data?.assets?.hostnames]);
      for (const key in res?.data?.data?.assets) {
        assetsTag.push(key);
      }
      if (res?.data?.data?.assets?.['ip']) {
        formik.setFieldValue('ip', res?.data?.data?.assets?.ip);
        assetsLabel.push('ip');
      } else {
        formik.setFieldValue('ip', '');
      }

      if (res?.data?.data?.assets?.['mac']) {
        formik.setFieldValue('mac', res?.data?.data?.assets?.mac);
        assetsLabel.push('mac');
      } else {
        formik.setFieldValue('mac', '');
      }
      if (res?.data?.data?.assets?.['custom']) {
        formik.setFieldValue('custom', res?.data?.data?.assets?.custom);
        assetsLabel.push('custom');
      } else {
        formik.setFieldValue('custom', '');
      }
      if (res?.data?.data?.assets?.['machine']) {
        formik.setFieldValue('machine', res?.data?.data?.assets?.machine);
        assetsLabel.push('machine');
      } else {
        formik.setFieldValue('machine', '');
      }
      if (res?.data?.data?.assets?.['hostnames']) {
        formik.setFieldValue('hostnames', res?.data?.data?.assets?.hostnames?.join(','));
        assetsLabel.push('hostnames');
      } else {
        formik.setFieldValue('hostnames', '');
      }

      setAssetWithValue(res?.data?.data?.assets);
      setAssetTagList(assetsTag);
      handleChangeAsset({ target: { value: assetsTag } }, false);
      setRemediationEditData({
        name: res?.data?.data?.name,
        description: res?.data?.data?.description,
        recommendation: res?.data?.data?.recommendation,
        criticality: res?.data?.data?.criticality,
        status: res?.data?.data?.status,
        assets: assets.join(', '),
        services_uuid: res?.data?.data?.service?.services_uuid,
        integration_tools_uuid: res?.data?.data?.tool?.tool_uuid
      });
      const tagsList = res?.data?.data?.tags?.map((tag) => tag?.tag_name);
      formik.setFieldValue('tags', isCreateRemediation ? [] : tagsList);
      formik.setFieldValue('name', res?.data?.data?.name);
      formik.setFieldValue('description', res?.data?.data?.description);
      formik.setFieldValue('criticality', res?.data?.data?.criticality);
      formik.setFieldValue('recommendation', res?.data?.data?.recommendation);
      formik.setFieldValue('services_uuid', res?.data?.data?.service?.services_uuid);
      formik.setFieldValue('integration_tools_uuid', res?.data?.data?.tool?.tool_uuid);
      formik.setFieldValue('Assets', assetsLabel?.length > 0 ? assetsLabel : ['']);
      formik.setFieldValue(
        'discovered_at',
        res?.data?.data?.discovered_at ? new Date(res?.data?.data?.discovered_at) : ''
      );

      if (assetsLabel?.length > 0 && res?.data?.data?.name && res?.data?.data?.description)
        setIsComplete(false);

      if (res?.data?.data?.owner?.user_uuid) {
        setCurrentAssign({
          company_uuid: res?.data?.data?.owner?.company?.company_uuid,
          first_name: res?.data?.data?.owner?.first_name,
          last_name: res?.data?.data?.owner?.last_name,
          user_uuid: res?.data?.data?.owner?.user_uuid,
          ico: res?.data?.data?.owner?.ico
        });
        setAssign({
          company_uuid: res?.data?.data?.owner?.company?.company_uuid,
          first_name: res?.data?.data?.owner?.first_name,
          last_name: res?.data?.data?.owner?.last_name,
          user_uuid: res?.data?.data?.owner?.user_uuid,
          ico: res?.data?.data?.owner?.ico
        });
        if (res?.data?.data?.actions_taken?.length > 0) {
          setActionTaken(res?.data?.data?.actions_taken[0]?.description || '');
        }
      }
      setSkeleton(false);

      if (res?.data?.data?.actions_taken) {
        const allFiles = res?.data?.data?.actions_taken?.map((item) => item?.files).flat();
        setFiles(allFiles);
      }
      setRowData(res?.data?.data);

      setAssetValue(assets);
    } catch (err) {
      console.log(err);
      setSkeleton(false);
    }
  }, [commonDetails, prop?.isRefresh, isCreateRemediation]);

  const ref = useRef('');
  const GetAssignees = useCallback(async () => {
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      renderOption(res?.data);
    } catch (err) {
      console.log(err);
    }
  }, [commonDetails]);
  const [option, setOption] = useState([]);
  useEffect(() => {
    setActionTaken('');
    if (isCreateRemediation) {
      setEdit(true);
    }
    setResolve(false);
    ref.current = prop?.el.risk_uuid;

    if (ref.current) {
      setValue(0);

      GetRemidiationDetails();
      GetAssignees();
      GetTags();
      formik.resetForm();
    }

    const assets: any = [];

    if (prop?.el?.assets?.custom) assets.push(prop?.el?.assets?.custom);
    if (prop?.el?.assets?.ip) assets.push(prop?.el?.assets?.ip);
    if (prop?.el?.assets?.mac) assets.push(prop?.el?.assets?.mac);
    if (prop?.el?.assets?.machine) assets.push(prop?.el?.assets?.machine);
    if (prop?.el?.assets?.hostnames?.length > 0) assets.push([...prop?.el?.assets?.hostnames]);
    if (prop?.el?.assets?.['ip']) {
      formik.setFieldValue('ip', prop?.el?.assets?.ip);
    } else {
      formik.setFieldValue('ip', '');
    }

    if (prop?.el?.assets?.['mac']) {
      formik.setFieldValue('mac', prop?.el?.assets?.mac);
    } else {
      formik.setFieldValue('mac', '');
    }
    if (prop?.el?.assets?.['custom']) {
      formik.setFieldValue('custom', prop?.el?.assets?.custom);
    } else {
      formik.setFieldValue('custom', '');
    }
    if (prop?.el?.assets?.['machine']) {
      formik.setFieldValue('machine', prop?.el?.assets?.machine);
    } else {
      formik.setFieldValue('machine', '');
    }
    if (prop?.el?.assets?.['hostnames']) {
      formik.setFieldValue('hostnames', prop?.el?.assets?.hostnames.join(','));
    } else {
      formik.setFieldValue('hostnames', '');
    }
    const tagsArray = prop?.el?.tags?.map((tag) => tag?.tag_name);
    formik.setFieldValue('tags', prop?.isCreateRemediation ? [] : tagsArray);
    formik.setFieldValue('name', prop?.el?.name);
    formik.setFieldValue('description', prop?.el?.description);
    formik.setFieldValue('criticality', prop?.el?.criticality);
    formik.setFieldValue('recommendation', prop?.el?.recommendation);
    formik.setFieldValue('services_uuid', prop?.el?.service?.services_uuid);
    formik.setFieldValue('integration_tools_uuid', prop?.el?.tool?.tool_uuid);
    formik.setFieldValue('assign', null);
    if (isCreateRemediation) {
      const source: any = servicesOption.filter(
        (item: any) => item?.remediation_source === prop?.el?.service?.remediation_source
      );
      formik.setFieldValue(
        'source',
        source[0]?.remediation_source ? [`${source[0]?.name} (${source[0]?.code})`] : []
      );
    }
    return () => {
      setAssign(null);
      setCurrentAssign({});
      setFileData([]);
      setEdit(false);
    };
  }, [GetRemidiationDetails, GetAssignees, GetTags, prop, servicesOption]);

  const renderColor = (criticality: any) => {
    var color = '#F87171';
    if (criticality == 'Critical') {
      color = '#F87171';
    } else if (criticality == 'High') {
      color = '#F59E0B';
    } else if (criticality == 'Medium') {
      color = '#FCD34D';
    } else if (criticality == 'Low') {
      color = '#436AF3';
    }
    return color;
  };
  const maketags = (data: any) => {
    var facilities = [];

    data?.forEach((element) => {
      let facilitytype = tagOptions?.filter((q) => q?.tag_name == element);
      if (facilitytype?.length > 0) {
        facilities.push({ tag_name: element, tag_uuid: facilitytype[0]?.tag_uuid });
      } else {
        facilities.push({ tag_name: element });
      }
    });

    setFacilities(facilities);
  };
  const handleClickOpenDelete = (option, value) => {
    setDeleteOption(option);

    formik.setFieldValue('tags', value);
    setOpenDelete(true);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue == 1) {
      GetRistHistory();
    }
  };
  const GetRistHistory = async () => {
    try {
      const res = await RiskManagementApi.GetRistHistory(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        rowData?.risk_uuid || prop?.el.risk_uuid
      );
      setRiskHistory(res?.data?.data);
    } catch (error) {}
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const validate = (value: any) => {
    const errors: any = {};
    const isEmpty = value?.Assets?.every((item) => item?.trim() === '');
    const requiredFields = ['hostnames', 'ip', 'mac', 'machine', 'custom'];

    for (const field of requiredFields) {
      if (value?.Assets?.includes(field) && !value[field]) {
        setIsComplete(true);
        return;
      }
    }

    if (!value?.description || !value?.name || !value?.services_uuid || isEmpty) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }

    return errors;
  };
  const setFormikValue = (val: any) => {
    if (val?.includes('ip')) {
      formik.setFieldValue('ip', formik.values?.ip);
    } else {
      formik.setFieldValue('ip', '');
    }

    if (val?.includes('mac')) {
      formik.setFieldValue('mac', formik.values?.mac);
    } else {
      formik.setFieldValue('mac', '');
    }
    if (val?.includes('custom')) {
      formik.setFieldValue('custom', formik.values?.custom);
    } else {
      formik.setFieldValue('custom', '');
    }
    if (val?.includes('machine')) {
      formik.setFieldValue('machine', formik.values?.machine);
    } else {
      formik.setFieldValue('machine', '');
    }
    if (val?.includes('hostnames')) {
      formik.setFieldValue('hostnames', formik.values?.hostnames);
    } else {
      formik.setFieldValue('hostnames', '');
    }
    validate(formik.values);
  };

  const validateAsset = (val: any) => {
    if (
      formik?.values?.Assets?.[0] == '' ||
      (formik?.values?.Assets?.includes('ip') && !val?.ip) ||
      (formik?.values?.Assets?.includes('hostnames') && !val?.hostnames) ||
      (formik?.values?.Assets?.includes('machine') && !val?.machine) ||
      (formik?.values?.Assets?.includes('mac') && !val?.mac) ||
      (formik?.values?.Assets?.includes('custom') && !val?.custom)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const formik = useFormik({
    initialValues: {
      asset: null,
      source: [],
      tags: []
      // Assets: ['']
    },
    validate,
    onSubmit: async (e: any) => {
      await handleSubmitEditData(e);
    }
  });
  const handleConfirmDelete = (deleteFacility) => {
    const updatedFacility = formik?.values?.tags?.filter((el, index) => el !== deleteFacility);
    formik.setFieldValue('tags', updatedFacility);

    maketags(updatedFacility);
    setOpenDelete(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangeAsset = (event: any, isChange: any) => {
    let {
      target: { value, name }
    } = event;

    const filterValue = value?.filter((el: any) => el != '');
    if (isChange) {
      setFormikValue(filterValue);
    }
    if (filterValue?.length == 0) {
      const item = typeof value === 'string' ? filterValue.split(',') : [''];
      formik.setFieldValue('Assets', item);
      setIsComplete(true);
    } else {
      const item = typeof value === 'string' ? filterValue.split(',') : filterValue;
      formik.setFieldValue('Assets', item);
    }

    if (value?.length > formik?.values?.Assets?.length) {
      setIsComplete(true);
    }
  };
  const renderOption = (assignees) => {
    const options = assignees?.data?.map((option) => {
      const firstLetter = option?.company_name.toUpperCase();
      const company_uuid = option?.company_uuid;

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, company_uuid: company_uuid, ...el };
      });
      return data;
    });

    const optionarray = options?.concat?.apply([], options).flat();
    setOption(optionarray);
  };
  const handleSubmitEditData = async (event: any) => {
    if (edit || isEditDD) {
      try {
        setIsEditButtonLoading(true);
        let formattedDate = '';
        if (formik?.values?.discovered_at) {
          const currentDate = new Date(formik?.values?.discovered_at);
          formattedDate = currentDate.toISOString();
        }

        const updatedData = {
          ...remediationEditData,
          name: formik.values.name,
          description: formik.values.description,
          services_uuid: formik.values?.services_uuid,

          criticality: formik.values.criticality,
          recommendation: formik.values.recommendation,
          assets: {
            ip: formik.values?.ip,
            mac: formik.values?.mac,
            custom: formik.values?.custom,
            machine: formik.values?.machine,
            hostnames: formik.values?.hostnames ? formik.values?.hostnames.split(',') : []
          },
          tags: facilities,
          discovered_at: formattedDate
        };
        if (!formattedDate) {
          delete updatedData['discovered_at'];
        }
        if (formik.values.integration_tools_uuid) {
          updatedData.integration_tools_uuid = formik.values.integration_tools_uuid;
        } else {
          delete updatedData.integration_tools_uuid;
        }
        const keys = formik.values?.source
          ?.map((value) => {
            const found = servicesOption?.find((item) => `${item.name} (${item.code})` === value);
            return found ? found?.remediation_source : null;
          })
          .filter((key) => key !== null);

        if (isCreateRemediation) {
          const associatedAssetUUIDs = associatedAssets.map((risk) => risk?.risk_uuid);
          const filteredIds = prop?.risksList
            .filter((asset) => associatedAssetUUIDs.includes(asset?.risk_uuid))
            .map((asset) => asset?.risk_uuid);
          const data = {
            description: formik?.values?.description,
            // asset: {
            //   ip: formik?.values?.ip,
            //   mac: formik?.values?.mac,
            //   custom: formik?.values?.custom,
            //   machine: formik?.values?.machine,
            //   hostnames: formik?.values?.hostnames ? formik?.values?.hostnames.split(',') : []
            // },
            sources: keys,
            issue_name: formik?.values?.name,
            severity: formik?.values?.criticality
              ? formik?.values?.criticality?.toLowerCase()
              : 'medium',
            recommendation: formik?.values?.recommendation,
            status: 'not_started',
            tags: facilities,
            owner_uuid: formik?.values?.assign?.user_uuid
          };
          const uploadData = {
            remediation: data,
            risk_uuids: associatedAssetUUIDs
          };
          const res = await RiskManagementApi.CreateRemediationRisk(
            commonDetails.data.user_uuid,
            commonDetails.data.company.company_uuid,
            uploadData
          );
          prop?.closePopup(event);
          setRowData({});
          prop?.setBulkSelectedRisks([]);
        } else {
          const res = await RiskManagementApi.PutRisk(
            commonDetails.data.user_uuid,
            commonDetails.data.company.company_uuid,
            prop?.el.risk_uuid || '',
            updatedData
          );
          GetRemidiationDetails();
        }
        setEdit(false);
        setIsEditDD(false);
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          setIsEditButtonLoading(false);
        }, 1000);
      }
    } else {
      setResolve(true);
    }
  };
  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels?.join(', ');
  };
  const renderColorBackGround = (criticality: any = 'Medium') => {
    var color = '#FFE6E2';
    if (criticality == 'Critical') {
      color = '#FFE6E2';
    } else if (criticality == 'High') {
      color = '#FCD34D';
    } else if (criticality == 'Medium') {
      color = '#FEF3C7';
    } else if (criticality == 'Low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const AssetList = [
    { key: 'Select Asset', value: '', placeholder: '' },
    { key: 'Domain/URL', value: 'hostnames', placeholder: 'Eg: www.redesign-group.com' },
    { key: 'IP Address', value: 'ip', placeholder: 'Eg: 192.158.1.38' },
    { key: 'MAC Address', value: 'mac', placeholder: 'Eg: 00-B0-D0-63-C2-26' },
    {
      key: 'Machine/Server Name',
      value: 'machine',
      placeholder: 'Eg: Teletraan'
    },
    { key: 'Other', value: 'custom', placeholder: 'Type here' }
  ];

  const RenderAssetList = (assest: any) => {
    let data = [];
    if (assest?.length > 0) {
      data = AssetList.filter((el: any) => {
        return el?.value && assest?.includes(el.value) && el;
      });
    } else {
      data = [];
    }

    return data;
  };

  const renderstatusColor = (status) => {
    var color = '#F87171';

    if (status == 'In Remediation') {
      color = '#FEF3C7';
    } else if (status == 'Not Started') {
      color = '#FFE6E2';
    } else if (status == 'Risk Accepted') {
      color = '#D9E1FD';
    } else if (status == 'False Positive') {
      color = '#F9A866';
    } else {
      color = '#A7F3D0';
    }
    return color;
  };

  const handleConfirmDeleteSource = (option, value) => {
    const updatedFacility = value.filter((el, index) => el !== option);
    formik.setFieldValue('source', updatedFacility);
  };

  const GetSearchRisk = useCallback(
    async (selectedUUID?: any) => {
      try {
        const queryParams = new URLSearchParams({
          keyword: searchText
        }).toString();
        const res = await RiskManagementApi.RiskList(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          queryParams
        );
        const filteredData = res?.data?.data?.filter(
          (item) =>
            item?.remediation_uuid === '' &&
            item?.status !== 'Resolved' &&
            !selectedUUID?.includes(item?.risk_uuid)
        );
        setSearchAssets(filteredData);
      } catch (error) {}
    },
    [commonDetails, searchText]
  );

  useEffect(() => {
    if (searchText) {
      GetSearchRisk(selectedUUID);
    } else {
      setSearchAssets([]);
    }
  }, [GetSearchRisk, searchText]);

  useEffect(() => {
    if (Object.keys(prop?.el)?.length) {
      setAssociatedAssets([prop?.el]);
      setSelectedUUID([prop?.el?.risk_uuid]);
    } else if (prop?.bulkSelectedRisks?.length) {
      const filteredData = prop?.bulkSelectedRisks?.filter(
        (item) => item?.remediation_uuid === '' && item?.status !== 'Resolved'
      );

      const filteredIds = filteredData?.map((risk) => risk?.risk_uuid);
      setSelectedUUID(filteredIds);
      setAssociatedAssets(filteredData);
    }
  }, [prop?.el, prop?.bulkSelectedRisks]);

  useEffect(() => {
    if (prop?.isMultiCreateRemediation) {
      formik.setFieldValue('source', []);
      formik.setFieldValue('name', '');
      formik.setFieldValue('description', '');
      formik.setFieldValue('recommendation', '');
      formik.setFieldValue('Assets', ['']);
      formik.setFieldValue('tags', []);
      GetTags();
    }
  }, [prop?.isMultiCreateRemediation]);

  useEffect(() => {
    GetServiceFilterOption();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (skelton) {
    return <Skeleton height={100} width={520} />;
  }

  const capitalizeFirstLetter = (text) => {
    return text?.charAt(0)?.toUpperCase() + text?.slice(1);
  };

  return (
    <>
      {/* {rowData?.status && ( */}
      <Box
        sx={{
          width: { md: 520, xs: 300 },
          height: '100vh' || value == 1 || value == 2 ? '100vh' : 'auto',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              justifyContent: 'space-between',
              backgroundColor: '#003260',
              px: 2.5,
              alignItems: 'center',
              py: 1
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',

                width: 1,
                alignItems: 'center'
              }}>
              <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', my: 1.25 }}>
                {!isCreateRemediation
                  ? edit || isEditDD
                    ? 'Edit a Risk'
                    : rowData?.name
                  : 'Create Remediation'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {!isCreateRemediation && !edit && !isEditDD && (
                  <CopyUrl
                    title="Copy and share this page"
                    handleClose={() => {}}
                    icon={<Shareicon />}
                    mainBtnTitle={''}
                    url={`${window.location.origin}${PageRouteConstants.RiskManagement}${window.location.search}`}
                    handleConfirm={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}${PageRouteConstants.RiskManagement}${window.location.search}`
                      );
                      // window.location.href

                      notifySuccess('Copied');
                    }}
                  />
                )}

                <Box
                  onClick={(event) => {
                    prop?.closePopup(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
            {!isCreateRemediation && !edit && !isEditDD && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}>
                <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                  Discovered:{' '}
                  {rowData?.discovered_at
                    ? moment(rowData?.discovered_at).format('DD MMM YYYY').toUpperCase()
                    : 'Unavailable'}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: renderstatusColor(rowData?.status),
                    py: '6px',
                    px: '30px',
                    fontSize: '12px',
                    borderRadius: '100px'
                  }}>
                  <Typography variant="caption">
                    {' '}
                    {rowData?.status == 'In Remediation' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="caption">In Remediation</Typography>
                      </Box>
                    ) : rowData?.status == 'Not Started' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="caption">Not Started</Typography>
                      </Box>
                    ) : rowData?.status == 'Resolved' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="caption">Resolved</Typography>
                      </Box>
                    ) : rowData?.status == 'Risk Accepted' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="caption">Risk Accepted</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="caption">
                          {rowData?.status?.charAt(0).toUpperCase() +
                            '' +
                            rowData?.status?.slice(1)}
                        </Typography>
                      </Box>
                    )}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </List>

        <Box sx={{ flex: 1 }}>
          <Box sx={{ p: 3, pt: 0 }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderColor: 'divider' }}>
                {!isCreateRemediation && (
                  <Tabs
                    value={value}
                    sx={{ '.Mui-selected': { textDecoration: 'underline' }, minHeight: '0px' }}
                    TabIndicatorProps={{ sx: { display: 'none' } }}
                    onChange={handleChange}
                    aria-label="basic tabs example">
                    <Tab
                      sx={{
                        justifyContent: 'flex-end',
                        padding: '0px',
                        textTransform: 'none',
                        minWidth: 50
                      }}
                      label="Risk"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="History"
                      sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                )}
              </Box>
              <TabPanel value={value} index={0}>
                {edit || isEditDD ? (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                        Criticality Level:
                      </Typography>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={formik?.values?.criticality || 'Medium'}
                          name="criticality"
                          defaultValue={formik?.values?.criticality}
                          onChange={formik.handleChange}
                          displayEmpty
                          sx={{
                            '.MuiSelect-select': {
                              py: 1,
                              display: 'flex',
                              alignItems: 'center'
                            },
                            '&.MuiInputBase-root': {
                              backgroundColor: renderColorBackGround(formik.values.criticality)
                            },
                            borderRadius: '100px'
                          }}
                          inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="Critical">
                            <Box>
                              <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                            </Box>
                            <Typography variant="body2">Critical</Typography>
                          </MenuItem>
                          <MenuItem sx={{ backgroundColor: '#FCD34D' }} value="High">
                            <Box>
                              <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F59E0B' }} />
                            </Box>
                            <Typography variant="body2">High</Typography>
                          </MenuItem>
                          <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'Medium'}>
                            <Box>
                              <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                            </Box>
                            <Typography variant="body2">Medium</Typography>
                          </MenuItem>

                          <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'Low'}>
                            {' '}
                            <Box>
                              <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                            </Box>
                            <Typography variant="body2">Low</Typography>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ my: 1 }}>
                      <Box>
                        {isCreateRemediation && (
                          <>
                            <List sx={{ py: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                                  Remediation Name
                                </Typography>
                                <Box sx={{ width: 1 }}>
                                  <TextField
                                    size="small"
                                    placeholder="Remediation Name"
                                    onChange={formik.handleChange}
                                    fullWidth
                                    name="name"
                                    inputProps={{ sx: { fontSize: '14px' } }}
                                    variant="outlined"
                                    sx={{ fontSize: '14px' }}
                                    value={formik.values?.name}
                                  />
                                </Box>
                              </Box>
                            </List>

                            <List sx={{ py: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                                  Add Associated Risks
                                </Typography>
                                <Box ref={box1Ref} sx={{ width: 1 }}>
                                  <TextField
                                    placeholder="Search by Risk Name"
                                    hiddenLabel
                                    fullWidth
                                    size="small"
                                    name="searchText"
                                    value={searchText}
                                    onClick={() => setIsBoxVisible(true)}
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                      setIsBoxVisible(true);
                                    }}
                                    sx={{
                                      input: { backgroundColor: '#FFFFFF' },
                                      '.MuiOutlinedInput-root': {
                                        bgcolor: '#FFFFFF'
                                      }
                                    }}
                                    inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                                          <SearchIcon />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </Box>
                                {isBoxVisible && searchAssets?.length ? (
                                  <Box sx={{ width: 1 }}>
                                    <Box
                                      ref={box2Ref}
                                      sx={{
                                        border: '1px solid #CCCCCC',
                                        padding: '8px',
                                        borderRadius: '4px',
                                        maxHeight: '170px',
                                        overflowX: 'auto'
                                      }}>
                                      {searchAssets?.map((asset, index) => {
                                        const isItemSelected = isSelected(asset?.risk_uuid);
                                        const isLastItem = index === searchAssets?.length - 1;
                                        return (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              overflow: 'hidden',
                                              mb: isLastItem ? 0 : 1
                                            }}>
                                            <Checkbox
                                              sx={{
                                                padding: 0,
                                                width: '16px',
                                                height: '16px',
                                                mr: 0.5
                                              }}
                                              onClick={(event) => {
                                                handleClickCheck(event, asset?.risk_uuid);
                                                event.stopPropagation();
                                                event.preventDefault();
                                              }}
                                              checked={isItemSelected}
                                              checkedIcon={<CheckBoxLightBlue />}
                                              inputProps={
                                                {
                                                  // 'aria-labelledby': labelId
                                                }
                                              }
                                              icon={
                                                <CheckBoxOutLine
                                                  sx={{
                                                    fill: 'none',
                                                    width: '16px',
                                                    height: '16px'
                                                  }}
                                                />
                                              }
                                            />
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: assetColorBackGround(asset?.status),
                                                borderRadius: '50%',
                                                width: '24px',
                                                height: '24px',
                                                mr: '6px',
                                                flex: ' 0 0 24px'
                                              }}>
                                              <FlagIcon
                                                sx={{
                                                  fontSize: '12px',
                                                  color: assetColorIcon(asset?.status)
                                                }}
                                              />
                                            </Box>
                                            <Typography
                                              variant="subtitle2"
                                              sx={{
                                                fontSize: '14px',
                                                color: '#464646',
                                                fontWeight: 400,
                                                whiteSpace: 'nowrap'
                                              }}>
                                              <span>{asset?.name}</span>
                                            </Typography>
                                          </Box>
                                        );
                                      })}
                                    </Box>
                                  </Box>
                                ) : null}
                                <Box ref={box3Ref} sx={{ textAlign: 'end', width: 1 }}>
                                  <Button variant="outlined" onClick={addRisks}>
                                    Add Risks
                                  </Button>
                                </Box>
                              </Box>
                            </List>

                            <List sx={{ py: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                                  Associated Risks
                                </Typography>
                                {associatedAssets?.map((asset) => {
                                  return (
                                    <Box
                                      className="as-asset-wrapper"
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: 1,
                                        border: '1px solid #CCCCCC',
                                        borderRadius: '4px',
                                        overflow: 'hidden',
                                        padding: '8px',
                                        position: 'relative',
                                        cursor: 'pointer'
                                      }}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          background: assetColorBackGround(asset?.status),
                                          borderRadius: '50%',
                                          width: '24px',
                                          flex: ' 0 0 24px',
                                          height: '24px',
                                          mr: '6px'
                                        }}>
                                        <FlagIcon
                                          sx={{
                                            fontSize: '12px',
                                            color: assetColorIcon(asset?.status)
                                          }}
                                        />
                                      </Box>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{
                                          fontSize: '14px',
                                          color: '#464646',
                                          fontWeight: 400,
                                          whiteSpace: 'nowrap'
                                        }}>
                                        <span>{asset?.name}</span>
                                      </Typography>
                                      <Box
                                        className="hover-status"
                                        sx={{ display: 'none', alignItems: 'center', gap: 1 }}>
                                        <Box
                                          sx={{
                                            minWidth: '14px',
                                            height: '14px',
                                            width: '14px',
                                            backgroundColor: bgStatusColor(asset?.status),
                                            borderRadius: '50%'
                                          }}></Box>
                                        <Typography variant="caption">{asset?.status}</Typography>
                                        <CrossCancle
                                          onClick={() => removeRisk(asset?.risk_uuid)}
                                          sx={{
                                            color: '#000',
                                            height: '8px',
                                            width: '8px',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  );
                                })}
                                {/* {limitMsg && <Box sx={{ background: '#FECACA4D', p: 1.5, borderRadius: 1, width: 1 }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '14px', color: '#464646', fontWeight: 400 }}>
                                    You have achieve the limit of Assets for this Remediation.
                                  </Typography>
                                </Box>} */}
                              </Box>
                            </List>

                            <List sx={{ py: 1.5 }}>
                              <CyberSecurityDomainDropdown
                                label={'Cybersecurity Domain'}
                                disabled={false}
                                value={formik.values.source}
                                onChange={(event, value) => {
                                  // setValue(value);
                                  console.log('the value is :', value);

                                  formik.setFieldValue('source', value);
                                  // maketagsSource(value);
                                }}
                                useUUID={true}
                                showChips={true}
                                deleteChip={handleConfirmDeleteSource}
                                name=""
                              />
                            </List>
                          </>
                        )}
                        {!isCreateRemediation && (
                          <List sx={{ py: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                                Risk Name
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  onChange={formik.handleChange}
                                  fullWidth
                                  name="name"
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  sx={{ fontSize: '14px' }}
                                  value={formik.values?.name}
                                  defaultValue={rowData?.name}
                                />
                              </Box>
                            </Box>
                          </List>
                        )}

                        {!isCreateRemediation && (
                          <List sx={{ py: 1.5 }}>
                            <CyberSecurityDomainDropdown
                              label={'Cybersecurity Domain'}
                              disabled={false}
                              value={formik.values.services_uuid}
                              onChange={(e: any) => {
                                formik.handleChange(e);
                                formik.setFieldValue('integration_tools_uuid', '');
                              }}
                              useUUID={true}
                              showChips={false}
                              deleteChip={null}
                              name="services_uuid"
                            />
                          </List>
                        )}

                        {!isCreateRemediation &&
                          prop?.servicesOption?.find(
                            (service) => service?.value === formik?.values?.services_uuid
                          )?.tools?.length > 0 && (
                            <List sx={{ py: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                                  Tool Name
                                </Typography>
                                <Box sx={{ width: 1 }}>
                                  <Select
                                    size="small"
                                    displayEmpty
                                    fullWidth
                                    id="type"
                                    sx={{
                                      fontSize: '14px',
                                      color: '#696969',
                                      minWidth: { xxs: 0, md: '188px' },
                                      backgroundColor: '#FFFFFF'
                                    }}
                                    defaultValue={rowData?.tool?.tool_uuid}
                                    name="integration_tools_uuid"
                                    value={formik?.values?.integration_tools_uuid}
                                    onChange={formik.handleChange}
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    <MenuItem value="" sx={{ display: 'none' }}>
                                      Select Tool
                                    </MenuItem>
                                    {prop?.servicesOption
                                      ?.find(
                                        (service) =>
                                          service?.value === formik?.values?.services_uuid
                                      )
                                      ?.tools?.map((tool: any, index) => (
                                        <MenuItem
                                          sx={{ fontSize: '14px' }}
                                          value={tool?.integration_tools_uuid}
                                          key={index}>
                                          <Box sx={{ display: 'flex', gap: 0.5 }}>
                                            <Box>
                                              {tool?.vendor?.name ? `${tool?.vendor?.name} -` : ''}
                                            </Box>
                                            <Box>
                                              <b>{tool?.name}</b>
                                            </Box>
                                          </Box>
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </Box>
                              </Box>
                            </List>
                          )}

                        {/* <List sx={{ py: 1.5 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 2
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                              Asset Type
                            </Typography>
                            <Box sx={{ width: 1 }}>
                              <FormControl sx={{ width: 1 }}>
                                <Select
                                  size="small"
                                  displayEmpty
                                  multiple
                                  fullWidth
                                  placeholder="Select Asset"
                                  name="Assets"
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  value={formik?.values?.Assets || []}
                                  onChange={(e: any) => handleChangeAsset(e, true)}
                                  input={
                                    <OutlinedInput
                                      sx={{
                                        py: '5.5px'
                                      }}
                                    />
                                  }
                                  renderValue={(selected) => renderValue(selected, AssetList)}
                                  sx={{
                                    py: 0.35,
                                    width: 1,
                                    fontSize: '14px',
                                    color: '#696969',
                                    '.MuiSelect-select': {
                                      py: '5.5px'
                                    },
                                    minWidth: { xxs: 0, md: '150px' },
                                    backgroundColor: '#FFFFFF'
                                  }}
                                  inputProps={{ 'aria-label': 'Without label' }}>
                                  {AssetList?.map((name, index) => (
                                    <MenuItem
                                      sx={{
                                        fontSize: '14px',
                                        display: !name.value ? 'none' : 'flex',

                                        py: 0,
                                        '.MuiMenuItem-root': {
                                          py: '0px'
                                        }
                                      }}
                                      key={name?.key}
                                      autoFocus={index === 1}
                                      value={name?.value}>
                                      <Checkbox
                                        checked={formik?.values?.Assets?.indexOf(name?.value) > -1}
                                      />
                                      <ListItemText
                                        sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                                        primary={name.key}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        </List> */}

                        {/* <List sx={{ py: 1.5 }}>
                          {RenderAssetList(formik?.values?.Assets)?.map((el) => {
                            return (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',

                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                                  {el?.key}
                                </Typography>
                                <Box sx={{ width: 1, pb: 1.5 }}>
                                  <TextField
                                    size="small"
                                    name="assets"
                                    fullWidth
                                    onChange={(e: any) => {
                                      formik.setFieldValue(el?.value, e.target.value);
                                    }}
                                    value={formik.values[el.value]}
                                    inputProps={{ sx: { fontSize: '14px' } }}
                                    variant="outlined"
                                    placeholder={el?.placeholder}
                                    sx={{ fontSize: '14px' }}
                                    defaultValue={assetWithValue?.[el.value]}
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                        </List> */}

                        <List sx={{ pt: 1.5, pb: 1.5 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 2
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                              Risk Description
                            </Typography>
                            <Box sx={{ width: 1 }}>
                              <TextField
                                size="small"
                                name="description"
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                inputProps={{ sx: { fontSize: '14px' } }}
                                variant="outlined"
                                rows={7}
                                multiline
                                sx={{ fontSize: '14px' }}
                                defaultValue={rowData?.description}
                              />
                            </Box>
                          </Box>
                        </List>

                        <List sx={{ pt: 1.5, pb: 0 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 2
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '14px', color: '#696969', fontWeight: 600 }}>
                              {!isCreateRemediation && 'Risk'} Recommendation
                            </Typography>
                            <Box sx={{ width: 1 }}>
                              <TextField
                                size="small"
                                name="recommendation"
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.recommendation}
                                inputProps={{ sx: { fontSize: '14px' } }}
                                variant="outlined"
                                rows={7}
                                multiline
                                sx={{ fontSize: '14px' }}
                                defaultValue={formik.values?.recommendation}
                              />
                            </Box>
                          </Box>
                        </List>

                        {isCreateRemediation && (
                          <FormControl fullWidth>
                            <List sx={{ py: 1.5 }}>
                              <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: '#696969',
                                      fontWeight: 600,
                                      mb: 2
                                    }}>
                                    Assignee
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    onClick={() => {
                                      formik.setFieldValue('assign', {
                                        first_name: commonDetails.data.first_name,
                                        last_name: commonDetails.data.last_name,
                                        user_uuid: commonDetails.data.user_uuid,
                                        firstLetter: commonDetails.data.company.name,
                                        ico: commonDetails.data.ico,
                                        company_uuid: commonDetails.data.company.company_uuid
                                      });
                                      // formik.setFieldValue('assign', value);
                                    }}
                                    sx={{
                                      fontWeight: 600,
                                      color: '#436AF3',
                                      mb: 2,
                                      cursor: 'pointer'
                                    }}>
                                    {commonDetails?.data?.group !=
                                      UserRoles.SERVICE_DELIVERY_COORDINATOR && 'Assign to me'}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1.5 }}>
                                  <FormControl sx={{ width: '100%' }}>
                                    <Autocomplete
                                      id="grouped-demo"
                                      size="small"
                                      fullWidth={true}
                                      options={option}
                                      value={formik.values.assign}
                                      onChange={(e, value) => {
                                        formik.setFieldValue('assign', value);
                                      }}
                                      groupBy={(option: any) => option.firstLetter}
                                      getOptionLabel={(option: any) => {
                                        return `${formik?.values?.assign?.first_name} ${formik?.values?.assign?.last_name}`;
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          sx={{ fontSize: '14px' }}
                                          {...params}
                                          placeholder="Assign to"
                                          InputProps={{
                                            ...params.InputProps,
                                            startAdornment: formik.values.assign ? (
                                              <InputAdornment position="start">
                                                <Box>
                                                  {formik.values.assign.ico ? (
                                                    <Box
                                                      sx={{
                                                        ...Style.versionsCard.infoRound,
                                                        width: '24px',
                                                        height: '24px',
                                                        fontSize: '14px'
                                                      }}>
                                                      <img
                                                        width={'100%'}
                                                        height="100%"
                                                        style={{
                                                          objectFit: 'cover',
                                                          borderRadius: '50%'
                                                        }}
                                                        src={GetAssignImage(
                                                          formik.values.assign.firstLetter,
                                                          formik.values.assign.ico
                                                        )}
                                                        alt=""
                                                      />
                                                    </Box>
                                                  ) : (
                                                    <Typography
                                                      sx={{
                                                        ...Style.versionsCard.infoRound,
                                                        width: '24px',
                                                        height: '24px',
                                                        fontSize: '14px'
                                                      }}>
                                                      {formik.values.assign?.first_name?.charAt(0) +
                                                        '' +
                                                        formik.values.assign?.last_name?.charAt(0)}
                                                    </Typography>
                                                  )}
                                                </Box>
                                              </InputAdornment>
                                            ) : null
                                          }}
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                          {...props}
                                          key={option.user_uuid}>
                                          {option.ico ? (
                                            <Box
                                              sx={{
                                                ...Style.versionsCard.infoRound,
                                                width: '24px',
                                                height: '24px',
                                                fontSize: '14px'
                                              }}>
                                              <img
                                                width={'100%'}
                                                height="100%"
                                                style={{
                                                  objectFit: 'cover',
                                                  borderRadius: '50%'
                                                }}
                                                src={GetAssignImage(option.firstLetter, option.ico)}
                                                alt=""
                                              />
                                            </Box>
                                          ) : (
                                            <Typography
                                              sx={{
                                                ...Style.versionsCard.infoRound,
                                                width: '24px',
                                                height: '24px',
                                                fontSize: '14px'
                                              }}>
                                              {option?.first_name?.charAt(0) +
                                                '' +
                                                option?.last_name?.charAt(0)}
                                            </Typography>
                                          )}

                                          <Typography variant="body2">
                                            {' '}
                                            {option.first_name} {option.last_name}
                                          </Typography>
                                        </Box>
                                      )}
                                      renderGroup={(params) => (
                                        <Box key={params.key}>
                                          <Typography
                                            sx={{
                                              fontSize: '14px',
                                              fontWeight: 'bold',
                                              padding: '10px 10px'
                                            }}>
                                            {params.group}
                                          </Typography>
                                          {params.children}
                                        </Box>
                                      )}
                                    />
                                  </FormControl>
                                </Box>
                              </Box>
                            </List>
                          </FormControl>
                        )}
                        {/* {isCreateRemediation && ( */}
                        <List sx={{ py: 2 }}>
                          <FormControl variant="outlined" fullWidth>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                              Tag
                            </Typography>
                            <Autocomplete
                              multiple
                              id="tags-filled"
                              freeSolo
                              options={tagOptions?.map((el) => el?.tag_name)}
                              value={formik?.values?.tags || []}
                              renderTags={(value, getTagProps) => {
                                return value?.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                    onDelete={() => handleClickOpenDelete(option, value)}
                                  />
                                ));
                              }}
                              selectOnFocus
                              onChange={(event: any, newValue, reason) => {
                                if (
                                  reason == 'selectOption' ||
                                  reason == 'removeOption' ||
                                  reason == 'clear'
                                ) {
                                  handleTagChange(event, newValue);
                                } else {
                                  const optionToSelect = tagOptions?.find((option) => {
                                    if (
                                      option?.tag_name?.toLowerCase() ===
                                      event?.target?.value?.toLowerCase()
                                    ) {
                                      return option;
                                    }
                                  });
                                  if (optionToSelect) {
                                    handleTagChange(event, [
                                      ...formik?.values?.tags,
                                      optionToSelect?.tag_name
                                    ]);
                                  } else {
                                    handleTagChange(event, newValue);
                                  }
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  error={
                                    tagErrorMessage.maxLimit || tagErrorMessage.tagLimit
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    tagErrorMessage.maxLimit
                                      ? tagErrorMessage.maxLimit
                                      : tagErrorMessage.tagLimit
                                  }
                                  {...params}
                                  onChange={(e: any) => {
                                    if (e.target.value) {
                                      if (e.target.value.startsWith('_')) {
                                        setTagErrorMessage({
                                          ...tagErrorMessage,
                                          maxLimit: `Please Don't Start the tag with (_)`
                                        });
                                      } else if (!validateString(e.target.value)) {
                                        setTagErrorMessage({
                                          ...tagErrorMessage,
                                          maxLimit: `Please don't add specific characters or numbers just Alphapetical Letters ONLY`
                                        });
                                      } else if (e.target.value?.length > 50) {
                                        setTagErrorMessage({
                                          ...tagErrorMessage,
                                          maxLimit:
                                            'You have reached the maximum limit of characters per Tag'
                                        });
                                      } else {
                                        setTagErrorMessage({
                                          ...tagErrorMessage,
                                          maxLimit: ''
                                        });
                                      }
                                    } else {
                                      setTagErrorMessage({
                                        ...tagErrorMessage,
                                        maxLimit: ''
                                      });
                                    }
                                  }}
                                  placeholder={formik?.values?.tags?.length > 0 ? '' : 'Add Tags'}
                                  // InputProps={{
                                  //   startAdornment: (
                                  //     <InputAdornment position="start">
                                  //       <SearchIcon sx={{ color: "grey.500" }} />
                                  //     </InputAdornment>
                                  //   ),
                                  // }}
                                />
                              )}
                              sx={{
                                '&.MuiAutocomplete-root': {
                                  '.MuiAutocomplete-input': {
                                    minWidth: '70px'
                                  },
                                  '.MuiOutlinedInput-root': {
                                    p: 0,
                                    pl: 1,
                                    pr: 1,
                                    '.MuiAutocomplete-clearIndicator': {
                                      margin: '0px -6px 0px 0px'
                                    }
                                  },
                                  '.MuiAutocomplete-tag': {
                                    m: '7px',
                                    bgcolor: 'common.lightBlue',
                                    border: 'none'
                                  },
                                  '.MuiAutocomplete-endAdornment': {
                                    right: '5px'
                                  }
                                }
                              }}
                            />
                            <Dialog
                              fullWidth
                              maxWidth="sm"
                              open={openDelete}
                              onClose={handleCloseDelete}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description">
                              <DialogTitle
                                id="alert-dialog-title"
                                sx={{
                                  px: { xs: 1, md: 2 },
                                  py: { xs: 0.5, md: 1 },
                                  bgcolor: 'error.red100',
                                  mb: { xs: 2, md: 3 }
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}>
                                  <Typography
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: 'error.main',
                                      fontWeight: '600',
                                      maxWidth: 'calc(100% - 40px)',
                                      p: 1
                                    }}>
                                    <WarningIcon sx={{ mr: 1 }} />
                                    Confirm Deletion
                                  </Typography>
                                  <IconButton
                                    sx={{ color: 'text.primary' }}
                                    aria-label="close"
                                    onClick={handleCloseDelete}>
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              </DialogTitle>
                              <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                                <DialogContentText
                                  id="alert-dialog-description"
                                  sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
                                  Are you sure do you want to delete this Tag?
                                </DialogContentText>

                                <DialogContentText
                                  id="alert-dialog-description"
                                  sx={{ color: 'text.primary' }}>
                                  {deleteOption}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions
                                sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                                <Button
                                  variant="outlined"
                                  sx={{ minWidth: 100 }}
                                  onClick={handleCloseDelete}>
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{ minWidth: 100 }}
                                  color="error"
                                  onClick={() => handleConfirmDelete(deleteOption)}>
                                  {' '}
                                  Confirm
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </FormControl>
                        </List>
                        {/* )} */}

                        {!isCreateRemediation && (
                          <List sx={{ py: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                mt: 1,
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600, color: '#696969' }}>
                                Date Discovered
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <DatePicker
                                  showIcon
                                  maxDate={moment().toDate()}
                                  className="calendar-form"
                                  icon={<CalenderIconFilter />}
                                  dateFormat="dd MMM yyyy"
                                  placeholderText="DD MMM YYYY"
                                  selected={formik.values.discovered_at}
                                  onChange={(date: any, event: any) => {
                                    formik.setFieldValue('discovered_at', date);
                                  }}
                                />
                              </Box>
                            </Box>
                          </List>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                        Criticality Level:
                      </Typography>
                      <FormControl sx={{ m: 1 }}>
                        <Box
                          sx={{
                            py: 1,
                            px: 2,
                            backgroundColor: renderColorBackGround(rowData?.criticality),
                            borderRadius: '100px'
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FlagIcon
                              sx={{
                                fontSize: '12px',
                                mr: 1,
                                color: renderColor(rowData?.criticality)
                              }}
                            />

                            <Typography variant="body2">
                              {' '}
                              {capitalizeFirstLetter(rowData?.criticality)}
                            </Typography>
                          </Box>
                        </Box>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: '#F2F9FF',
                        padding: 2.5,
                        borderRadius: '8px',
                        my: 1
                      }}>
                      <Box>
                        <List>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 1
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '19px', color: '#696969', fontWeight: 600 }}>
                              {rowData?.name}
                            </Typography>
                          </Box>
                        </List>

                        <List>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 1
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '16px', color: '#696969', fontWeight: 600 }}>
                              Service Name
                            </Typography>
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: ' #696969',
                                  wordBreak: 'break-word',
                                  wordWrap: 'break-word'
                                }}>
                                {rowData?.service?.name} {`(${rowData?.service?.code})`}
                              </Typography>
                            </Box>
                          </Box>
                        </List>
                        {!isCreateRemediation && rowData?.tool?.name && (
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px', color: '#696969', fontWeight: 600 }}>
                                Tool Name
                              </Typography>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: ' #696969',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}>
                                  {rowData?.tool?.name}
                                </Typography>
                              </Box>
                            </Box>
                          </List>
                        )}

                        <List>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 1
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '16px', color: '#696969', fontWeight: 600 }}>
                              Asset
                            </Typography>
                            <Box>
                              {RenderAssetList(assetsTagList)?.map((el: any) =>
                                Array.isArray(assetWithValue[el?.value]) ? (
                                  assetWithValue[el?.value].map((item) => (
                                    <Box sx={{ display: 'flex' }}>
                                      <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                        {el?.key}:
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: ' #696969',
                                          wordBreak: 'break-word',
                                          wordWrap: 'break-word'
                                        }}>
                                        &nbsp;{item}
                                      </Typography>
                                    </Box>
                                  ))
                                ) : assetWithValue[el?.value].includes(',') ? (
                                  assetWithValue[el?.value].split(',').map((item) => {
                                    return (
                                      <Box sx={{ display: 'flex' }}>
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                          {el?.key}:
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: ' #696969',
                                            wordBreak: 'break-word',
                                            wordWrap: 'break-word'
                                          }}>
                                          &nbsp;{item}
                                        </Typography>
                                      </Box>
                                    );
                                  })
                                ) : (
                                  <Box sx={{ display: 'flex' }}>
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                      {el?.key}:
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: ' #696969',
                                        wordBreak: 'break-word',
                                        wordWrap: 'break-word'
                                      }}>
                                      &nbsp;{assetWithValue[el?.value]}
                                    </Typography>
                                  </Box>
                                )
                              )}
                            </Box>
                          </Box>
                        </List>

                        <List>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 1
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '16px', color: '#696969', fontWeight: 600 }}>
                              Risk Description
                            </Typography>
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: ' #696969',
                                  wordBreak: 'break-word',
                                  wordWrap: 'break-word'
                                }}>
                                {rowData?.description}
                              </Typography>
                            </Box>
                          </Box>
                        </List>

                        <List>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              gap: 1
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: '16px', color: '#696969', fontWeight: 600 }}>
                              Risk Recommendation
                            </Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: ' #696969',
                                  wordBreak: 'break-word',
                                  wordWrap: 'break-word'
                                }}>
                                {rowData?.recommendation}
                              </Typography>
                            </Box>
                          </Box>
                        </List>
                      </Box>
                    </Box>
                    {rowData?.risk_acceptance_justification && (
                      <Box sx={{ mt: 3 }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                          Risk Acceptance Business Justification
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: '#F2F9FF',
                            padding: 2.5,
                            borderRadius: '8px',
                            my: 1
                          }}>
                          {rowData?.risk_acceptance_justification}
                        </Box>
                      </Box>
                    )}
                    {rowData?.tags && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          mt: 3
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                          Tags{' '}
                        </Typography>
                        <Box>
                          {rowData?.tags?.map((tag) => {
                            return (
                              <Chip
                                variant="outlined"
                                label={tag?.tag_name}
                                sx={{
                                  fontSize: '12px',
                                  backgroundColor: '#D9EDFF',
                                  mr: '6px',
                                  border: 0
                                }}
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                    {rowData?.remediation_uuid && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          mt: 3
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                          Track Remediation{' '}
                        </Typography>
                        <Box
                          onClick={() =>
                            navigate(
                              `${PageRouteConstants.Remediation}?remediation_uuids=${rowData?.remediation_uuid}&status=not_started,in_progress,reopened,resolved`
                            )
                          }>
                          <Typography
                            variant="body2"
                            sx={{
                              gap: 0.5,
                              fontWeight: 600,
                              color: '#436AF3',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center'
                            }}>
                            {rowData?.remediation_uuid} <LinkIcon />
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {rowData?.risk_accepted_by?.user_uuid && (
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5
                          }}>
                          <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                            Risk Accepted By
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box>
                                {rowData?.risk_accepted_by?.ico ? (
                                  <Box
                                    sx={{
                                      ...Style.versionsCard.infoRound,
                                      width: '24px',
                                      height: '24px',
                                      fontSize: '14px'
                                    }}>
                                    <img
                                      width={'100%'}
                                      height="100%"
                                      style={{
                                        objectFit: 'cover',
                                        borderRadius: '50%'
                                      }}
                                      src={GetAssignImage(
                                        rowData?.risk_accepted_by?.first_name,
                                        rowData?.risk_accepted_by?.ico
                                      )}
                                      alt=""
                                    />
                                  </Box>
                                ) : (
                                  <Typography
                                    sx={{
                                      ...Style.versionsCard.infoRound,
                                      width: '24px',
                                      height: '24px',
                                      fontSize: '14px'
                                    }}>
                                    {' '}
                                    {rowData?.risk_accepted_by?.first_name.charAt(0) +
                                      '' +
                                      rowData?.risk_accepted_by?.last_name.charAt(0)}
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between'
                                }}>
                                <Typography variant="body2" sx={{ fontWeight: 400 }}>
                                  {rowData?.risk_accepted_by?.first_name}{' '}
                                  {rowData?.risk_accepted_by?.last_name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: '#475467',
                                  fontSize: '12px',
                                  display: 'flex',

                                  gap: 0.5,
                                  alignItems: 'center'
                                }}>
                                <CalenderIconSmall />{' '}
                                {moment(rowData?.risk_accepted_at).format('DD MMMM YYYY')}
                                <AccessTimeIcon
                                  sx={{
                                    color: '#696969',
                                    fontSize: 16,
                                    fontWeight: 600
                                  }}
                                />
                                {moment(rowData?.risk_accepted_at)
                                  .tz(moment.tz.guess())
                                  .format('hh:mm A z')}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}

                      {rowData?.risk_acceptance_expire_at && (
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5
                          }}>
                          <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                            Risk Expires On
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between'
                                }}>
                                <Typography
                                  variant="caption"
                                  sx={{ fontWeight: 400, color: '#475467' }}>
                                  {moment(rowData?.risk_acceptance_expire_at).format(
                                    'DD MMMM YYYY'
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                variant="caption"
                                sx={{ fontSize: '12px', color: '#EF4444' }}>
                                Expires{' '}
                                {moment(rowData?.risk_acceptance_expire_at).endOf('day').fromNow()}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Divider />
                <Box sx={{ display: 'inline-block', width: 1, mt: 2 }}>
                  {!!riskHistory?.length ? (
                    riskHistory?.map((element: any, index) => (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                        <IconButton
                          sx={{
                            width: '32px',
                            height: '32px',
                            backgroundColor: '#D9D9D9',
                            p: 0,
                            mr: '12px',
                            pointerEvents: 'none'
                          }}
                          color="primary"
                          aria-label="profile picture"
                          component="label">
                          {element?.owner?.ico ? (
                            <img
                              width={'100%'}
                              height="100%"
                              style={{ objectFit: 'cover', borderRadius: '50%' }}
                              src={`${process.env.REACT_APP_API_HOST}/${element?.owner?.ico}`}
                              alt=""
                            />
                          ) : (
                            <Typography
                              variant="caption"
                              sx={{ ...Style.UserNameRound, color: '#696969' }}>
                              {element?.owner?.first_name?.charAt(0) +
                                element?.owner?.last_name?.charAt(0)}
                            </Typography>
                          )}
                        </IconButton>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ color: '#696969', fontWeight: 600, mb: '6px' }}>
                            {element?.owner?.first_name} {element?.owner?.last_name}{' '}
                            <span style={{ fontWeight: 500 }}>from</span>{' '}
                            {element?.owner?.company?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: '#696969', fontWeight: 600, mb: '6px' }}>
                            {element?.title}
                          </Typography>
                          <Typography
                            sx={{ fontSize: '12px', color: '#475467', mb: '6px', fontWeight: 400 }}>
                            {expandedIndex === index ? (
                              <>
                                <span
                                  style={{ wordBreak: 'break-word' }}
                                  dangerouslySetInnerHTML={{
                                    __html: element.action
                                  }}></span>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: '#436AF3',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                    mt: '6px'
                                  }}
                                  onClick={() => handleToggleIndex(index)}>
                                  Close
                                </Typography>
                              </>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: '#436AF3',
                                  fontWeight: 600,
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleToggleIndex(index)}>
                                See More
                              </Typography>
                            )}
                          </Typography>
                          <Typography sx={{ fontSize: '12px', color: '#475467', fontWeight: 400 }}>
                            On {moment(element.created_at).format('DD MMM YYYY').toUpperCase()} at{' '}
                            {moment(element.created_at).format('hh:mm A zz').toUpperCase()}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Stack
                      sx={{
                        justifyContent: 'center',
                        width: 1,
                        alignItems: 'center',
                        height: 1
                      }}>
                      <NoHistoryFound title="No history available" />
                    </Stack>
                  )}
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>

        {dataPosted && !prop?.isEngineer ? (
          <Box></Box>
        ) : (
          value === 0 && (
            <Box
              sx={{
                px: 3,
                pb: 2,
                textAlign: 'end',
                bottom: 0,
                right: 0,
                position: Number(value) === 1 || Number(value) === 2 ? 'absolute' : 'relative'
              }}>
              <Button
                onClick={(e: any) => {
                  setRowData({});
                  setRemediationInitialData();
                  setEdit(!edit);
                  (edit || isEditDD) && prop?.closePopup(e);
                }}
                variant={edit || isEditDD ? 'outlined' : 'contained'}
                sx={{
                  position: 'relative',
                  mr: 2,
                  fontWeight: 700,
                  justifySelf: 'flex-end',
                  minWidth: 150,
                  border: '1px solid #436AF3',

                  '&.Mui-disabled': {
                    backgroundColor: '#EBEBEB',
                    color: '#9D9D9D'
                  }
                }}>
                {edit || isEditDD ? 'Cancel' : 'Edit'}
              </Button>
              {(edit || isEditDD) && (
                <LoadingButton
                  loading={isEditButtonLoading}
                  type="submit"
                  onClick={(e: any) => {
                    handleSubmitEditData(e);
                  }}
                  sx={{
                    position: 'relative',
                    fontWeight: 700,
                    justifySelf: 'flex-end',
                    minWidth: 150,
                    backgroundColor: '#436AF3',
                    border: '1px solid #436AF3',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#436AF3'
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#EBEBEB',
                      border: '1px solid #EBEBEB',
                      color: isEditButtonLoading ? '#EBEBEB' : '#9D9D9D'
                    }
                  }}>
                  {'Save'}
                </LoadingButton>
              )}
            </Box>
          )
        )}
      </Box>
      {/* )} */}
    </>
  );
};

export default RiskPopUp;
