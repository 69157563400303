import ConformationModel from '@jibin/common/components/ConformationModel';
import LockIcon from '@jibin/common/icons/LockIcon';
import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanySettingsService } from '../CompanySettings.Api';
import { ListTableProps } from './Models/ListTableProps';
import { ApiResponse, Users } from './Models/UserList.model';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import InviteUserModel from './InviteUserModel';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { UserModel } from './Models/User.Model';
import { UserProp } from './Models/UserProp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { NoDataAvailable, NoSearchDataAvailable } from './../../Common/NoDataAvailable';
import {
  Divider,
  IconButton,
  Menu,
  Skeleton,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  MenuItem,
  Button
} from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import moment from 'moment';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CrossCancle, { CrossCancleRounded } from '@jibin/common/icons/CrossCancle/CrossCancle';
import { CorrectIconRounder } from '@jibin/common/icons/CorrectIcon/CorrectIcon';
import { rolesArray, UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import SearchIcon from '@jibin/common/icons/Search/Search';
import TablePagination from '../../Common/TablePagination';
import MfaConfirmation from '@jibin/common/components/ConformationModel/MfaConfirmation';
import UnAssigned from '../../Common/UnAssigned';
import { Lock } from '@mui/icons-material';
import { Amplify, Auth } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
import SuccessConfirmation from '@jibin/common/components/ConformationModel/SuccessConfirmation';
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const cognito = new AWS.CognitoIdentityServiceProvider();
const label = { inputProps: { 'aria-label': 'Switch demo' } };
var debounce = require('lodash.debounce');
Amplify.configure({
  Auth: {
    userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
    userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
  }
});
const RowUser = ({ row, index, handleRefresh, handleConfirm, handleMFAUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any, row: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handeSendLink = async (row) => {
    try {
      const email = row.email;
      const encodedEmail = encodeURIComponent(email);
      const timestamp = Date.now();
      const url = `${window.location.origin}/forgot-password?email=${encodedEmail}&timestamp=${timestamp}`;
      await Auth.forgotPassword(email, {
        is_reset_password: 'true',
        link: url
      });
      toast.success('Password reset e-mail sent successfully');
    } catch (error) {
      console.log(error);
    }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleResendInvite = async (values) => {
    try {
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        role: values.role
      };

      const res = await CompanySettingsService.userInvite(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        payload
      );
      handleClose();
    } catch (error) {}
  };
  return (
    <TableRow
      id={row.user_uuid}
      key={row?.first_name + index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            {row.ico ? (
              <Box
                sx={{
                  ...Style.versionsCard.infoRound,
                  width: '32px',
                  height: '32px'
                }}>
                <img
                  width={'100%'}
                  height="100%"
                  style={{ objectFit: 'cover', borderRadius: '50%' }}
                  src={`${process.env.REACT_APP_API_HOST}/${row.ico}`}
                  alt=""
                />
              </Box>
            ) : row?.first_name ? (
              <Typography
                sx={{
                  ...Style.versionsCard.infoRound,
                  width: '32px',
                  height: '32px'
                }}>
                {' '}
                {`${row?.first_name?.charAt(0)}${row?.last_name?.charAt(0) ?? ''}`}
              </Typography>
            ) : (
              <UnAssigned />
            )}
          </Box>
          {row?.first_name && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 0.5,
                justifyContent: 'space-between'
              }}>
              <Typography variant="caption">{row?.first_name + ' '}</Typography>
              {row?.last_name && <Typography variant="caption">{row?.last_name}</Typography>}
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{row.email}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption" sx={{ width: 'fit-content' }}>
          {' '}
          {rolesArray?.find((el: any) => el?.role == row.role)?.role ? (
            <Tooltip
              arrow
              title={rolesArray?.find((el: any) => el?.role == row.role)?.tooltip}
              placement="top">
              <Box sx={{ width: 'fit-content' }}>
                {rolesArray?.find((el: any) => el?.role == row.role)?.tooltip
                  ? rolesArray?.find((el: any) => el?.role == row.role)?.role.toUpperCase()
                  : `${row.role.charAt(0).toUpperCase()}${row.role.substring(1)}`}
              </Box>
            </Tooltip>
          ) : (
            `${row.role.charAt(0).toUpperCase()}${row.role.substring(1)}`
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Box>
          {row.status === 'ACTIVE' ? (
            <Box
              sx={{
                ...Style.SiteListTable.StatusActive,
                bgcolor: 'common.green'
              }}></Box>
          ) : row.status === 'PENDING' ? (
            <Box
              sx={{
                ...Style.SiteListTable.StatusActive,
                bgcolor: '#FBBF24'
              }}></Box>
          ) : (
            <Box
              sx={{
                ...Style.SiteListTable.StatusActive,
                bgcolor: '#EF4444'
              }}></Box>
          )}
          <Typography variant="caption">
            {row.status.charAt(0).toUpperCase() + row.status.substr(1).toLowerCase()}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {row?.last_login_time
            ? moment(row?.last_login_time).format('DD MMM YYYY [at] hh:mm:ss')
            : ''}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          {row?.mfa?.length ? (
            <>
              <CorrectIconRounder />{' '}
              <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                {row?.mfa[0] == 'app' ? 'Authenticator' : row?.mfa[0].toUpperCase()}
              </Typography>
            </>
          ) : (
            <>
              <CrossCancleRounded />{' '}
              <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                {'disabled'}
              </Typography>{' '}
            </>
          )}
        </Box>
      </TableCell>
      {commonDetails?.data?.company?.user_role?.toLowerCase() == 'user' ? (
        <></>
      ) : (
        row?.user_uuid != commonDetails?.data?.user_uuid && (
          <TableCell align="right">
            <IconButton
              aria-label="Example"
              disabled={
                commonDetails.data.group == UserRoles.SERVICE_DELIVERY_MANAGER &&
                row.role.toLowerCase() == UserRoles.SUPER_ADMIN.toLowerCase()
              }
              onClick={(e) => {
                handleClick(e, row);
              }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}>
              <MoreHorizIcon color="primary" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id={'account-menu'}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <InviteUserModel
                key={row.user_uuid + row.role}
                UserModel={{ ...row }}
                IsEdit={true}
                handleSubmit={() => {
                  handleRefresh();
                  handleClose();
                }}
              />
              {/* <Divider /> */}
              {(commonDetails?.data?.group.toLowerCase() == UserRoles.SUPER_ADMIN ||
                commonDetails?.data?.group.toLowerCase() == UserRoles.CUSTOMER_ADMIN) &&
                row?.status?.toLowerCase() !== 'pending' && (
                  <SuccessConfirmation
                    checked={false}
                    handleClose={() => handleClose()}
                    handleConfirm={(e) => {
                      handeSendLink(row);
                      // handleSubmit('Archived');
                    }}
                    mainBtnType="error"
                    title="Reset Password"
                    type={'icon'}
                    iconComponent={<LockIcon />}
                    description={
                      <>
                        Are you sure, do you want to reset the password for this account{' '}
                        <strong>
                          {row?.first_name} {row?.last_name} :{' '}
                        </strong>
                        <strong>{row.email}</strong> ?
                      </>
                    }
                    asking={``}
                    returnData={'Published'}
                    mainBtnTitle={
                      <Typography variant="caption" sx={{ color: '#464646', ml: 2.5 }}>
                        Reset Password
                      </Typography>
                    }
                    cancelButtonText="Cancel"
                    conformButtonText="Reset"
                  />
                )}
              {row?.status?.toLowerCase() === 'pending' && (
                <SuccessConfirmation
                  checked={false}
                  handleClose={() => handleClose()}
                  handleConfirm={(e) => {
                    handleResendInvite(row);
                    // handleSubmit('Archived');
                  }}
                  mainBtnType="primary"
                  title="Confirm Action"
                  type={'icon'}
                  iconComponent={<EmailOutlinedIcon sx={{ fontSize: 20 }} />}
                  description={
                    <>
                      Are you sure you want to send a reminder email to{' '}
                      <strong>
                        {row?.first_name} {row?.last_name} :{' '}
                      </strong>{' '}
                      <strong>{row.email}</strong> ?
                    </>
                  }
                  asking={``}
                  returnData={'Published'}
                  mainBtnTitle={
                    <Typography variant="caption" sx={{ color: '#464646', ml: 2.5 }}>
                      Send Reminder
                    </Typography>
                  }
                  cancelButtonText="Cancel"
                  conformButtonText="Confirm"
                />
              )}
              <ConformationModel
                handleConfirm={() => {
                  handleConfirm(row);
                  handleClose();
                }}
                title="Confirm Action"
                data={row}
                handleClose={() => {}}
                label={{ ...label }}
                type={DeleteTypesEnum.SwitchAndLabel}
                description={
                  row?.status.toUpperCase() === 'ACTIVE'
                    ? `Are you sure you want to deactivate the user "${
                        row.first_name ? `${row.first_name} ${row.last_name}` : row.email
                      }"?`
                    : row?.status.toUpperCase() === 'INACTIVE' && row?.first_name
                    ? `Are you sure you want to activate the user "${row.first_name} ${row.last_name}"?`
                    : row?.status.toUpperCase() === 'PENDING'
                    ? `Are you sure you want to deactivate the user "${
                        row.first_name ? `${row.first_name} ${row.last_name}` : row.email
                      }"?`
                    : `Are you sure you want to activate the user "${
                        row.first_name ? `${row.first_name} ${row.last_name}` : row.email
                      }"?`
                }
                cancelButtonText="Cancel"
                conformButtonText="Confirm"
              />
              {commonDetails?.data?.group != UserRoles.CUSTOMER_ADMIN && (
                <MfaConfirmation
                  handleConfirm={() => {
                    handleMFAUpdate(row);
                    handleClose();
                  }}
                  title={
                    row?.mfa_status.toUpperCase() === 'ENABLED' ? 'Disable MFA' : 'Confirm Action'
                  }
                  data={row}
                  handleClose={() => {}}
                  label={{ ...label }}
                  styling={row?.mfa_status.toUpperCase() === 'ENABLED' ? 'disable' : 'enable'}
                  type={DeleteTypesEnum.SwitchAndLabel}
                  description={
                    row?.mfa_status.toUpperCase() === 'ENABLED'
                      ? `Are you sure, do you want to disable MFA for this account:`
                      : row?.mfa_status.toUpperCase() === 'DISABLED'
                      ? `Are you sure, do you want to enable MFA for this account:`
                      : null
                  }
                  tergetUser={row?.email}
                  cancelButtonText="Cancel"
                  conformButtonText={
                    row?.mfa_status.toUpperCase() === 'ENABLED' ? 'Disable' : 'Confirm'
                  }
                />
              )}
            </Menu>
          </TableCell>
        )
      )}
    </TableRow>
  );
};
export const UsersListTable: FC<ListTableProps> = (props) => {
  const [UserListData, setUserListData] = useState(new ApiResponse());
  const [userInitData, setUserInitData] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [userFilterform, setUserFilterform] = useState({
    keyword: '',
    mfa_status: '',
    status: 'ACTIVE,PENDING',
    roles: ''
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });

  const handleClearFuntion = () => {
    setUserFilterform({
      keyword: '',
      mfa_status: '',
      status: '',
      roles: ''
    });
  };

  const data: UserProp = {
    UserModel: new UserModel(),
    IsEdit: false,
    handleSubmit() {}
  };
  const location = useLocation();
  const handleSearch = (e) => {
    let updatedValue = e.target.value;
    if (Array.isArray(e.target.value)) {
      updatedValue = e.target.value.filter(Boolean).join(',');
    }
    setUserFilterform({ ...userFilterform, [e.target.name]: updatedValue });
  };
  useEffect(() => {
    setPage(0);
  }, [userFilterform]);
  const getUsers = useCallback(() => {
    CompanySettingsService.GetUserList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams({ ...userFilterform, ...pagination }).toString()
    ).then(
      (q: any) => {
        setUserListData(q.data);
        setTotalCount(q?.data?.pagination?.total_records);
        if (q?.data?.data?.length && isSkeleton) {
          setUserInitData(true);
        }
        setIsSkeleton(false);
        if (searchParams.get('user_uuid')) {
          const filterdData = q.data.data?.filter(
            (el, index) => el.user_uuid == searchParams.get('user_uuid')
          );

          setTimeout(() => {
            const element = document.getElementById(filterdData[0].user_uuid);
            if (element) {
              var headerOffset = 90;
              var elementPosition = element.getBoundingClientRect().top;
              var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
              element.classList.add('flash');
              setTimeout(() => {
                element.classList.remove('flash');
              }, 1000);
            }
          }, 1);
          searchParams.delete('user_uuid');
          setSearchParams(searchParams);
        }
      },
      (err: any) => {
        setIsSkeleton(false);
        setUserListData(new ApiResponse());
      }
    );
  }, [commonDetails, userFilterform, location.search, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    getUsers();
  }, [getUsers, isRefresh]);
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180
      }
    }
  };

  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };

  const handleConfirm = useCallback(
    debounce(
      (row: Users) => {
        let status;

        row.status.toUpperCase() == 'ACTIVE'
          ? (status = 'INACTIVE')
          : row?.status.toUpperCase() == 'INACTIVE' && row?.first_name
          ? (status = 'ACTIVE')
          : row?.status.toUpperCase() == 'PENDING'
          ? (status = 'INACTIVE')
          : (status = 'PENDING');
        row = { ...row, status: status };
        CompanySettingsService.putUser(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          row.user_uuid,
          row
        ).then(
          (q: any) => {
            setIsRefresh(!isRefresh);
            if (q.data.data.status.toUpperCase() == 'INACTIVE') {
              notifySuccess('User is deactivated successfully.');
            } else if (q.data.data.status.toUpperCase() == 'ACTIVE') {
              notifySuccess('User is activated successfully.');
            } else if (q.data.data.status.toUpperCase() == 'PENDING') {
              notifySuccess('Status changed successfully');
            }
          },
          (err: any) => {}
        );
      },
      500,
      true
    ),
    [UserListData]
  );
  const disableMFA = async (username) => {
    const params = {
      UserPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID), // Replace with your user pool ID
      Username: username,
      SMSMfaSettings: {
        Enabled: false,
        PreferredMfa: false
      },
      SoftwareTokenMfaSettings: {
        Enabled: false,
        PreferredMfa: false
      }
    };

    try {
      const result = await cognito.adminSetUserMFAPreference(params).promise();
      console.log('MFA Disabled:', result);
    } catch (err) {
      console.error('Error disabling MFA:', err);
    }
  };
  const handleMFAUpdate = useCallback(
    debounce(
      (row: any) => {
        let mfaStatus;
        if (row?.mfa_status.toUpperCase() === 'ENABLED') {
          mfaStatus = 'disable';
          disableMFA(row?.email);
        } else if (row?.mfa_status.toUpperCase() === 'DISABLED') {
          mfaStatus = 'enable';
        }

        CompanySettingsService.updateUserMFAStatus(
          commonDetails.data.company.company_uuid,
          commonDetails.data.user_uuid,
          row.user_uuid,
          mfaStatus,
          {}
        ).then(
          (q: any) => {
            setIsRefresh(!isRefresh);
            notifySuccess(`MFA ${mfaStatus}d successfully!`);
          },
          (err: any) => {}
        );
      },
      500,
      true
    ),
    [UserListData]
  );

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };

  const userStatusItems = [
    { key: 'Active', value: 'ACTIVE' },
    { key: 'Pending', value: 'PENDING' },
    { key: 'Inactive', value: 'INACTIVE' },
    { key: 'All Statuses', value: '' }
  ];

  const userMfaStatusItems = [
    { key: 'Enabled', value: 'enabled' },
    { key: 'Disabled', value: 'disabled' },
    { key: 'MFA Statuses', value: '' }
  ];

  let userRoleItems = [
    { key: 'Admin', value: 'admin' },
    { key: 'Auditor', value: 'auditor' },
    { key: 'User', value: 'user' },
    { key: 'Super Admin', value: 'superadmin' },
    { key: 'CSE', value: 'cse' },
    { key: 'SDC', value: 'sdc' },
    { key: 'SDM', value: 'sdm' },
    { key: 'All Roles', value: '' }
  ];

  if (commonDetails?.data?.company?.type === 'engineering') {
    userRoleItems = userRoleItems.filter(
      (item) => item.value !== 'admin' && item.value !== 'user' && item.value !== 'auditor'
    );
  } else {
    userRoleItems = userRoleItems.filter(
      (item) =>
        item.value === 'admin' ||
        item.value === 'user' ||
        item.value === 'auditor' ||
        item.value === ''
    );
  }

  if (isSkeleton) {
    return (
      <Box>
        <Skeleton sx={{ p: 5 }} />
      </Box>
    );
  } else if ((!UserListData?.data || UserListData?.data?.length == 0) && !userInitData) {
    return (
      <NoDataAvailable
        title=" No Data Available"
        message="Please click the button below to invite other users to the Trust Portal.">
        <Box sx={{ ...Style.CompanySettingPage.AddButton, textAlign: 'center', mt: 2 }}>
          <InviteUserModel
            {...data}
            handleSubmit={() => {
              handleRefresh();
            }}
          />
        </Box>
      </NoDataAvailable>
    );
  }

  return (
    <>
      <Box sx={{ ...Style.SiteListTable.SiteListTableWrapper, overflowX: 'initial' }}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'nowrap', alignItems: 'center', mb: '32px' }}>
          <TextField
            placeholder="Search by name or e-mail"
            hiddenLabel
            size="small"
            name="keyword"
            value={userFilterform.keyword}
            onChange={handleSearch}
            sx={{
              input: { backgroundColor: '#FFFFFF' },
              '.MuiOutlinedInput-root': {
                bgcolor: '#FFFFFF'
              },
              width: 250
            }}
            inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <FormControl sx={{ width: 150 }}>
            <Select
              size="small"
              displayEmpty
              multiple
              name="status"
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={userFilterform.status.split(',')}
              onChange={handleSearch}
              input={
                <OutlinedInput
                  sx={{
                    py: '5.5px'
                  }}
                />
              }
              renderValue={(selected) => renderValue(selected, userStatusItems)}
              MenuProps={MenuProps}
              sx={{
                py: 0.35,
                fontSize: '14px',
                color: '#696969',
                '.MuiSelect-select': {
                  py: '5.5px'
                },
                minWidth: { xxs: 0, md: '150px' },
                backgroundColor: '#FFFFFF'
              }}
              inputProps={{ 'aria-label': 'Without label' }}>
              {userStatusItems.map((name, index) => (
                <MenuItem
                  sx={{
                    fontSize: '14px',
                    display: !name.value ? 'none' : 'flex',

                    py: 0,
                    '.MuiMenuItem-root': {
                      py: '0px'
                    }
                  }}
                  key={name.key}
                  value={name.value}
                  autoFocus={index === 0}>
                  <Checkbox checked={userFilterform.status.split(',').includes(name.value)} />
                  <ListItemText
                    sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                    primary={name.key}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 150 }}>
            <Select
              size="small"
              displayEmpty
              multiple
              name="mfa_status"
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={userFilterform.mfa_status.split(',')}
              onChange={handleSearch}
              input={
                <OutlinedInput
                  sx={{
                    py: '5.5px'
                  }}
                />
              }
              renderValue={(selected) => renderValue(selected, userMfaStatusItems)}
              MenuProps={MenuProps}
              sx={{
                py: 0.35,
                fontSize: '14px',
                color: '#696969',
                '.MuiSelect-select': {
                  py: '5.5px'
                },
                minWidth: { xxs: 0, md: '150px' },
                backgroundColor: '#FFFFFF'
              }}
              inputProps={{ 'aria-label': 'Without label' }}>
              {userMfaStatusItems.map((name, index) => (
                <MenuItem
                  sx={{
                    fontSize: '14px',
                    display: !name.value ? 'none' : 'flex',

                    py: 0,
                    '.MuiMenuItem-root': {
                      py: '0px'
                    }
                  }}
                  key={name.key}
                  value={name.value}
                  autoFocus={index === 0}>
                  <Checkbox checked={userFilterform.mfa_status.indexOf(name.value) > -1} />
                  <ListItemText
                    sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                    primary={name.key}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 150 }}>
            <Select
              size="small"
              displayEmpty
              multiple
              name="roles"
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={userFilterform.roles.split(',')}
              onChange={handleSearch}
              input={
                <OutlinedInput
                  sx={{
                    py: '5.5px'
                  }}
                />
              }
              renderValue={(selected) => renderValue(selected, userRoleItems)}
              MenuProps={MenuProps}
              sx={{
                py: 0.35,
                fontSize: '14px',
                color: '#696969',
                '.MuiSelect-select': {
                  py: '5.5px'
                },
                minWidth: { xxs: 0, md: '150px' },
                backgroundColor: '#FFFFFF'
              }}
              inputProps={{ 'aria-label': 'Without label' }}>
              {userRoleItems.map((name, index) => (
                <MenuItem
                  sx={{
                    fontSize: '14px',
                    display: !name.value ? 'none' : 'flex',

                    py: 0,
                    '.MuiMenuItem-root': {
                      py: '0px'
                    }
                  }}
                  key={name.key}
                  value={name.value}
                  autoFocus={index === 0}>
                  <Checkbox checked={userFilterform.roles.split(',').includes(name.value)} />
                  <ListItemText
                    sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                    primary={name.key}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={handleClearFuntion}
            sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
            variant="text">
            {' '}
            <FilterCancelIcon /> Clear filter
          </Button>
          {commonDetails?.data?.company?.user_role?.toLowerCase() == 'user' ? (
            <></>
          ) : (
            <Box sx={{ ml: 'auto' }}>
              <InviteUserModel
                {...data}
                handleSubmit={() => {
                  handleRefresh();
                }}
              />
            </Box>
          )}
        </Box>
        {!UserListData?.data || UserListData?.data?.length == 0 ? (
          <NoSearchDataAvailable />
        ) : (
          <>
            {' '}
            <Table sx={{ minWidth: 650, marginBottom: '12px' }} aria-label="site list table">
              <TableHead sx={{ position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Account Status</TableCell>
                  <TableCell>Last Login</TableCell>
                  <TableCell>MFA</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {UserListData?.data?.map((row, index) => (
                  <>
                    {/* {row.user_uuid != commonDetails.data.user_uuid && ( */}
                    <>
                      <Box key={row?.first_name + index + index} sx={Style.Common.Divider}></Box>
                      <RowUser
                        row={row}
                        index={index}
                        handleConfirm={(row) => handleConfirm(row)}
                        handleMFAUpdate={(row) => handleMFAUpdate(row)}
                        handleRefresh={() => handleRefresh()}
                      />
                    </>
                    {/* )} */}
                  </>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              totalCount={totalCount}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default UsersListTable;
