import SearchIcon from '@jibin/common/icons/Search/Search';
import Style from '@jibin/common/style/Styles';
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import TablePagination from '../../Common/TablePagination';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanySettingsService } from '../CompanySettings.Api';
import useDebounce from '@jibin/common/hooks/useDebounce';
import moment from 'moment';
import { NoDataAvailable, NoSearchDataAvailable } from 'src/Pages/Common/NoDataAvailable';

const AuditLogRow = ({ data, index }) => {
  return (
    <TableRow id={index} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        <>
          {moment(data?.created_at).format('DD MMM YYYY').toUpperCase()}
          <br />
          {moment(data?.created_at).format('hh:mm:ss A').toUpperCase()}
        </>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {data?.user?.ico ? (
            <Box sx={Style.versionsCard.infoRound}>
              <img
                width={'100%'}
                height="100%"
                style={{ objectFit: 'cover', borderRadius: '50%' }}
                src={`${process.env.REACT_APP_API_HOST}/${data?.user?.ico}`}
                alt=""
              />
            </Box>
          ) : (
            <Box>
              <Typography
                sx={{
                  ...Style.versionsCard.infoRound,
                  width: '32px',
                  height: '32px'
                }}>
                {' '}
                {`${data?.user?.first_name?.charAt(0)}${data?.user?.last_name?.charAt(0)}`}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 0.5,
              justifyContent: 'space-between'
            }}>
            <Typography variant="caption">{data?.user?.name}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{data?.ip_address}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{data?.browser}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{data?.os}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{data?.section}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{data?.action}</Typography>
      </TableCell>
    </TableRow>
  );
};
const AuditLogsTable = () => {
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180
      }
    }
  };
  const [usernameFilter, setUsernameFilter] = useState('');
  const username = useDebounce(usernameFilter, 1000);
  const [auditLogFilter, setAuditLogFilter] = useState({
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: moment().toDate(),
    ipOrAction: '',
    users: ''
  });
  const ipOrAction = useDebounce(auditLogFilter.ipOrAction, 1000);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });

  const [users, setUsers] = useState([]);

  const [auditLogs, setAuditLogs] = useState(null);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [initialLength, setInitialLength] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const GetUserList = () => {
    CompanySettingsService.GetUserList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams({
        keyword: usernameFilter,
        page_no: '1',
        page_size: '500',
        status: 'ACTIVE,PENDING',
        mfa_status: '',
        roles: ''
      }).toString()
    ).then(
      (q: any) => {
        // setUsers(q.data?.data);
        const userlist = q?.data?.data?.map((user, index) => {
          return { key: `${user?.first_name} ${user?.last_name}`, value: user.user_uuid };
        });
        setUsers(userlist);
      },
      (err: any) => {}
    );
  };

  const GetAuditLogs = useCallback(() => {
    CompanySettingsService.getAuditLogs(
      commonDetails.data.company.company_uuid,
      commonDetails.data.user_uuid,
      new URLSearchParams({
        ...pagination,
        start_date: auditLogFilter?.startDate
          ? moment(auditLogFilter.startDate).format('YYYY-MM-DD')
          : '',
        end_date: auditLogFilter?.endDate
          ? moment(auditLogFilter.endDate).format('YYYY-MM-DD')
          : '',
        keyword: ipOrAction,
        user_uuids: auditLogFilter.users
      }).toString()
    ).then(
      (q: any) => {
        if (q?.data?.data?.length) {
          setInitialLength(true);
        }
        setAuditLogs(q.data?.data);
        setTotalCount(q?.data?.pagination?.total_records);
        setIsSkeleton(false);
      },
      (err: any) => {
        setIsSkeleton(false);
      }
    );
  }, [
    commonDetails,
    auditLogFilter.endDate,
    auditLogFilter.startDate,
    ipOrAction,
    auditLogFilter.users,
    pagination
  ]);

  const ExportAuditLogs = async () => {
    setIsLoading(true);

    try {
      const response = await CompanySettingsService.exportAuditLogs(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        new URLSearchParams({
          ...pagination,
          start_date: auditLogFilter?.startDate
            ? moment(auditLogFilter.startDate).format('YYYY-MM-DD')
            : '',
          end_date: auditLogFilter?.endDate
            ? moment(auditLogFilter.endDate).format('YYYY-MM-DD')
            : '',
          keyword: ipOrAction,
          user_uuids: auditLogFilter.users
        }).toString()
      );
      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `AuditLogs_Page_${pagination.page_no}.xlsx`;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading audit logs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    let updatedValue = e.target.value;
    if (Array.isArray(e.target.value)) {
      updatedValue = e.target.value.filter(Boolean).join(',');
      if (updatedValue === usernameFilter) {
        return;
      }
    }
    setPage(0);
    setAuditLogFilter({ ...auditLogFilter, [e.target.name]: updatedValue });
  };

  const renderValue = (value: any) => {
    if (!value || !value?.length || !value[0].length) {
      return 'Select a user';
    }
    const newValue = [];
    users.forEach((user) => {
      if (value.includes(user.value)) {
        newValue.push(user.key);
      }
    });
    console.log(newValue);
    return newValue.join(', ');
  };

  const handleClearFuntion = () => {
    setAuditLogFilter({
      startDate: null,
      endDate: null,
      ipOrAction: '',
      users: ''
    });
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    GetAuditLogs();
  }, [
    commonDetails,
    auditLogFilter.endDate,
    auditLogFilter.startDate,
    ipOrAction,
    auditLogFilter.users,
    pagination
  ]);

  useEffect(() => {
    GetUserList();
  }, [username]);

  if (isSkeleton) {
    return (
      <Box>
        <Skeleton sx={{ p: 5 }} />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ ...Style.SiteListTable.SiteListTableWrapper, overflowX: 'initial' }}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'nowrap', alignItems: 'center', mb: '32px' }}>
          <DatePicker
            className="calendar-filter"
            showIcon
            icon={<CalenderIconFilter />}
            dateFormat="dd MMM yyyy"
            selectsStart
            start_date={auditLogFilter.startDate}
            end_date={auditLogFilter.endDate}
            selected={auditLogFilter.startDate}
            onChange={(date) => {
              setPage(0);
              setAuditLogFilter((prev) => ({
                ...prev,
                startDate: date
              }));
              const event = {
                target: {
                  value: date,
                  name: 'startDate'
                }
              };
              handleSearch(event);
            }}
            name="startDate"
            placeholderText="Start Date"
            style={{
              input: {
                margin: '7px 10px 6px 25px !important'
              }
            }}
          />
          <DatePicker
            className="calendar-filter"
            showIcon
            icon={<CalenderIconFilter />}
            dateFormat="dd MMM yyyy"
            selectsEnd
            start_date={auditLogFilter.startDate}
            end_date={auditLogFilter.endDate}
            minDate={auditLogFilter.startDate}
            selected={auditLogFilter.endDate}
            onChange={(date) => {
              setPage(0);
              setAuditLogFilter((prev) => ({
                ...prev,
                endDate: date
              }));
              const event = {
                target: {
                  value: date,
                  name: 'endDate'
                }
              };
              handleSearch(event);
            }}
            name="endDate"
            placeholderText="End Date"
            style={{
              input: {
                margin: '7px 10px 6px 25px !important'
              }
            }}
          />
          <TextField
            placeholder="Search by IP or Action"
            hiddenLabel
            size="small"
            name="ipOrAction"
            value={auditLogFilter.ipOrAction}
            onChange={handleSearch}
            sx={{
              input: { backgroundColor: '#FFFFFF' },
              '.MuiOutlinedInput-root': {
                bgcolor: '#FFFFFF'
              },
              width: 250
            }}
            inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Select
            size="small"
            // displayEmpty
            label="Select a user"
            multiple
            name="users"
            labelId="multi-user-checkbox"
            id="multi-user-checkbox"
            value={auditLogFilter.users?.split(',')}
            onChange={handleSearch}
            input={
              <OutlinedInput
                sx={{
                  py: '5.5px'
                }}
              />
            }
            renderValue={(selected) => renderValue(selected)}
            MenuProps={MenuProps}
            sx={{
              py: 0.35,
              fontSize: '14px',
              color: '#696969',
              '.MuiSelect-select': {
                py: '5.5px'
              },
              minWidth: { xxs: 0, md: '150px' },
              maxWidth: '150px',
              backgroundColor: '#FFFFFF'
            }}
            inputProps={{ 'aria-label': 'Without label' }}>
            <TextField
              placeholder="Search by User Name"
              hiddenLabel
              autoComplete="off"
              size="small"
              name="userNameInput"
              value={usernameFilter}
              onKeyDown={(e) => e.stopPropagation()}
              onKeyUp={(e) => e.stopPropagation()}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setUsernameFilter(e.target.value);
              }}
              sx={{
                input: { backgroundColor: '#FFFFFF' },
                '.MuiOutlinedInput-root': {
                  bgcolor: '#FFFFFF'
                },
                width: 'auto',
                margin: '8px'
              }}
              inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            {users?.map((name) => {
              return (
                <MenuItem
                  sx={{
                    fontSize: '14px',
                    display: !name.value ? 'none' : 'flex',

                    py: 0,
                    '.MuiMenuItem-root': {
                      py: '0px'
                    }
                  }}
                  key={name.value}
                  value={name.value}>
                  <Checkbox checked={auditLogFilter.users.indexOf(name.value) > -1} />
                  <ListItemText
                    sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                    primary={name.key}
                  />
                </MenuItem>
              );
            })}
          </Select>
          <Button
            onClick={handleClearFuntion}
            sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
            variant="text">
            {' '}
            <FilterCancelIcon /> Clear filter
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={ExportAuditLogs}
            sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: 'auto' }}
            variant="outlined">
            <DownloadIcon /> Download all
          </LoadingButton>
        </Box>
        {!auditLogs || auditLogs?.length == 0 ? (
          initialLength ? (
            <NoSearchDataAvailable />
          ) : (
            <NoDataAvailable message="" />
          )
        ) : (
          <>
            <Table sx={{ minWidth: 650, marginBottom: '12px' }} aria-label="site list table">
              <TableHead
                sx={{ position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' }}>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>Browser</TableCell>
                  <TableCell>OS</TableCell>
                  <TableCell>Section</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditLogs &&
                  Boolean(auditLogs.length) &&
                  auditLogs.map((row, i) => (
                    <>
                      <Box key={123} sx={Style.Common.Divider}></Box>
                      <AuditLogRow key={`${i}-${row.audit_log_uuid}`} data={row} index={i + 1} />
                    </>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              totalCount={totalCount}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default AuditLogsTable;
