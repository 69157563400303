import { FC, useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Skeleton,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  Menu,
  Typography,
  SwipeableDrawer,
  Grid
} from '@mui/material';
import HeadingHeader from '../Common/HeadingHeader';
import { shallowEqual, useSelector } from 'react-redux';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import FilterDropdown from './FilterDropdown';
import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import { AssetManagementApi } from './AssetManagement.Api';
import { NoDataAvailable, NoSearchDataAvailable } from '../Common/NoDataAvailable';
import AssetListTable from './AssetListTable';
import AssetPopUp from './PopUp/AssetPopUp';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { RemediationApi } from '../Remediation/Remediation.Api';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const AssetManagement: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails?.commonDetails?.data,
    shallowEqual
  );

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100
      }
    }
  };

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [filterData, setFilterData] = useState({
    keyword: '',
    ip_address: '',
    model: '',
    status: '',
    os: '',
    manufacturer: '',
    asset_type: ''
  });
  const [ipAddressList, setIpAddressList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [osList, setOsList] = useState([]);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const assetStatusItems = [
    { key: 'Healthy', value: 'Healthy' },
    { key: 'Unhealthy', value: 'Unhealthy' },
    { key: 'Unknown', value: 'Unknown' },
    { key: 'Alert', value: 'Alert' },
    { key: 'All Statuses', value: '' }
  ];
  const [assignees, setAssignees] = useState({} as any);
  const [searchIp, setSearchIp] = useState('');
  const [searchModel, setSearchModel] = useState('');
  const [assetsList, setAssetsList] = useState([]);
  const [initialLength, setInitialLength] = useState(false);
  const [rowData, setRowData] = useState(null as any);
  const [isViewAsset, setIsViewAsset] = useState(false);
  const [isCreateRemediation, setIsCreateRemediation] = useState(false);
  const [useParams, setUseParam] = useSearchParams();
  const [state, setState] = useState({
    right: false
  });
  const [anchorElSelectAction, setAnchorElSelectAction] = useState<null | HTMLElement>(null);
  const openNew = Boolean(anchorElSelectAction);
  const handleClockOpenSelectAction = (event: any) => {
    setAnchorElSelectAction(event.currentTarget);
  };
  const handleClickCloseSelectAction = () => {
    setAnchorElSelectAction(null);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const [selected, setSelected] = useState<any[]>([]);
  const [isMultipleDelete, setIsMultipleDelete] = useState(false);
  const [associatedAssets, setAssociatedAssets] = useState([]);
  const assetsSummaryBgcolor = (status) => {
    switch (status) {
      case 'Healthy':
        return '#10B981';
      case 'Unhealthy':
        return '#F87171';
      case 'Unknown':
        return '#B7B7B7';
      case 'Alert':
        return '#FCD34D';
      default:
        return '#436AF3'; // Default color if no match
    }
  };
  const handleChange = (e: any) => {
    let updatedValue = e.target.value;
    if (Array.isArray(e.target.value)) {
      updatedValue = e.target.value.filter(Boolean).join(',');
    }
    setFilterData({ ...filterData, [e.target.name]: updatedValue });
  };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });
    return selectedLabels.join(', ');
  };
  const renderValueArray = (selected: any, placeholder: string) => {
    if (!selected || selected[0] === '') {
      return placeholder;
    }
    return selected.join(', ');
  };
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleSearchIp = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchIp(e.target.value);
  };
  const handleSearchModel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchModel(e.target.value);
  };

  const handleClearFuntion = () => {
    setFilterData({
      keyword: '',
      ip_address: '',
      model: '',
      status: '',
      os: '',
      manufacturer: '',
      asset_type: ''
    });
  };
  const [assetsSummary, setAssetsSummary] = useState([] as any);
  const [assetsSummaryCount, setAssetsSummaryCount] = useState(0);

  const GetRiskSummary = useCallback(async () => {
    try {
      setIsSkeleton(true);
      const res = await AssetManagementApi.GetAssetsSummary(
        commonDetails?.data?.company.company_uuid,
        commonDetails?.data?.user_uuid
      );
      setAssetsSummary(res?.data?.data);
      const totalCountReduce = res?.data?.data.reduce((acc, issue) => acc + issue.count, 0);
      setAssetsSummaryCount(totalCountReduce);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails]);

  const GetAssignees = useCallback(async () => {
    setIsSkeleton(true);
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      setAssignees(res.data);
    } catch (err) {
      setIsSkeleton(false);
      console.log(err);
    }
  }, [commonDetails]);
  const GetFiltersData = useCallback(async () => {
    try {
      const res = await AssetManagementApi.GetAssetFiltersData(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid
      );
      const uniqueIps = Array.from(new Set(res?.data?.data?.ip_address || []));
      // const uniqueModels = Array.from(new Set(res?.data?.data?.model || []));
      const uniqueModels = Array.from(
        new Set((res?.data?.data?.model || []).map((model) => model.replace(/,/g, '|')))
      );
      const uniqueOs = Array.from(new Set(res?.data?.data?.os || []));
      const uniqueManufacturers = Array.from(new Set(res?.data?.data?.manufacturer || []));
      const uniqueTypes = Array.from(new Set(res?.data?.data?.type || []));

      setIpAddressList(uniqueIps);
      setModelList(uniqueModels);
      setOsList(uniqueOs);
      setManufacturerList(uniqueManufacturers);
      setTypeList(uniqueTypes);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails]);

  useEffect(() => {
    GetFiltersData();
    GetAssignees();
    GetRiskSummary();
  }, [GetFiltersData, GetAssignees, GetRiskSummary]);

  const GetAssets = useCallback(async () => {
    try {
      let fData = { ...filterData };
      fData.model = fData.model.replace(/\|/g, ',');
      const queryParams = new URLSearchParams({
        ...fData,
        ...pagination
      }).toString();
      const res = await AssetManagementApi.AssetList(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        queryParams
      );
      setTotalCount(res?.data?.data?.pagination?.total_records);
      if (isSkeleton && res?.data?.data?.data) {
        setInitialLength(true);
      }

      setAssetsList(res?.data?.data?.data);
      setIsSkeleton(false);
      setSelected([]);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails, filterData, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    GetAssets();
  }, [GetAssets, filterData]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [filterData]);

  const toggleDrawer = (anchor: Anchor, open: boolean, row: any, isSetParams?: boolean) => {
    if (!open) {
      useParams.delete('asset_uuid');

      setUseParam(useParams);
      setIsViewAsset(false);
      setIsCreateRemediation(false);
      setAssociatedAssets([]);
      // setIsEditDD(false);
    } else {
      if (isSetParams) {
        useParams.set('asset_uuid', row?.asset_uuid);
        setUseParam(useParams);
      }
    }
    setRowData(row);
    setState({ ...state, [anchor]: open });
  };

  const handleRowClick = (e, row) => {
    const trElement = (e.target as HTMLElement).closest('tr');
    if (trElement) {
      setIsViewAsset(true);
      toggleDrawer('right', true, row, true);
    }
  };

  const isSelected = (name: string) => selected?.indexOf(name) !== -1;
  const handleClickCheck = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = assetsList?.map((n) => n.asset_uuid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <Box>
      <HeadingHeader subtitle1={'Asset Management'} />
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={{ px: 4, backgroundColor: '#FBFBFF', width: 1 }}>
          <Box sx={{ py: 3 }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Summary
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={4} md={3} lg={2.4}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: assetsSummaryBgcolor('Total Assets'),
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Total Assets'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {assetsSummaryCount}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {assetsSummary?.map((event) => {
                  return (
                    <Grid item xs={4} md={3} lg={2.4}>
                      <Box
                        sx={{
                          border: '0.8px solid #CCCCCC',
                          bgcolor: '#ffffff',
                          borderRadius: 2,
                          p: 2
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: '24px',
                              height: '24px',
                              backgroundColor: assetsSummaryBgcolor(event?.title),
                              borderRadius: '50%'
                            }}></Box>
                          <Typography
                            variant="body2"
                            sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                            {' '}
                            {event?.title}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ color: '#464646' }} variant="h1">
                            {event?.count?.toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              py: 3,
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'nowrap', alignItems: 'center' }}>
              <TextField
                placeholder="Search by Asset Name"
                hiddenLabel
                size="small"
                name="keyword"
                value={filterData.keyword}
                onChange={handleChange}
                sx={{
                  input: { backgroundColor: '#FFFFFF' },
                  '.MuiOutlinedInput-root': {
                    bgcolor: '#FFFFFF'
                  }
                }}
                inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />

              <FormControl sx={{ width: 245 }}>
                <Select
                  size="small"
                  displayEmpty
                  multiple
                  name="ip_address"
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={filterData?.ip_address?.split(',')}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => renderValueArray(selected, `IP Address`)}
                  MenuProps={MenuProps}
                  sx={{
                    py: 0.35,
                    '.MuiSelect-select': {
                      py: '5.5px'
                    },
                    fontSize: '14px',
                    color: '#696969',
                    minWidth: { xxs: 0, md: '150px' },
                    backgroundColor: '#FFFFFF'
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <Box
                    sx={{
                      fontSize: '14px',
                      py: 0,
                      px: 2,
                      '.MuiMenuItem-root': {
                        py: '0px'
                      }
                    }}>
                    <TextField
                      onClickCapture={stopImmediatePropagation}
                      onKeyDown={(e) => e.stopPropagation()}
                      placeholder="Search"
                      hiddenLabel
                      size="small"
                      name="keyword"
                      value={searchIp}
                      onChange={handleSearchIp}
                      sx={{ backgroundColor: '#FFFFFF' }}
                      inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                  {ipAddressList
                    ?.filter((el) => {
                      if (searchIp) {
                        return el?.toLowerCase()?.includes(searchIp?.toLowerCase());
                      } else {
                        return el;
                      }
                    })
                    ?.map(
                      (name, index) =>
                        index <= 50 && (
                          <MenuItem
                            sx={{
                              fontSize: '14px',
                              display: !name ? 'none' : 'flex',
                              py: 0,
                              '.MuiMenuItem-root': {
                                py: '0px'
                              }
                            }}
                            key={name}
                            value={name}>
                            <Checkbox
                              checked={filterData?.ip_address?.split(',')?.indexOf(name) > -1}
                            />
                            <EllipsisContent
                              maxTextWidth={150}
                              text={name}
                              sx={{ fontSize: '14px' }}
                            />
                          </MenuItem>
                        )
                    )}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 245 }}>
                <Select
                  size="small"
                  displayEmpty
                  multiple
                  name="model"
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={filterData?.model?.split(',')}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => renderValueArray(selected, `Model`)}
                  MenuProps={MenuProps}
                  sx={{
                    py: 0.35,
                    '.MuiSelect-select': {
                      py: '5.5px'
                    },
                    fontSize: '14px',
                    color: '#696969',
                    minWidth: { xxs: 0, md: '150px' },
                    backgroundColor: '#FFFFFF'
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <Box
                    sx={{
                      fontSize: '14px',
                      py: 0,
                      px: 2,
                      '.MuiMenuItem-root': {
                        py: '0px'
                      }
                    }}>
                    <TextField
                      onClickCapture={stopImmediatePropagation}
                      onKeyDown={(e) => e.stopPropagation()}
                      placeholder="Search"
                      hiddenLabel
                      size="small"
                      name="keyword"
                      value={searchModel}
                      onChange={handleSearchModel}
                      sx={{ backgroundColor: '#FFFFFF' }}
                      inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                  {modelList
                    ?.filter((el) => {
                      if (searchModel) {
                        return el?.toLowerCase()?.includes(searchModel?.toLowerCase());
                      } else {
                        return el;
                      }
                    })
                    ?.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          display: !name ? 'none' : 'flex',
                          py: 0,
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name}
                        value={name}>
                        <Checkbox checked={filterData?.model?.split(',')?.indexOf(name) > -1} />
                        <EllipsisContent maxTextWidth={150} text={name} sx={{ fontSize: '14px' }} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 150 }}>
                <Select
                  size="small"
                  displayEmpty
                  multiple
                  name="status"
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={filterData.status.split(',')}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => renderValue(selected, assetStatusItems)}
                  MenuProps={MenuProps}
                  sx={{
                    py: 0.35,
                    fontSize: '14px',
                    '.MuiSelect-select': {
                      py: '5.5px'
                    },
                    color: '#696969',
                    minWidth: { xxs: 0, md: '150px' },
                    backgroundColor: '#FFFFFF'
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  {assetStatusItems?.map((name, index) => (
                    <MenuItem
                      sx={{
                        fontSize: '14px',
                        display: !name.value ? 'none' : 'flex',

                        py: 0,
                        '.MuiMenuItem-root': {
                          py: '0px'
                        }
                      }}
                      key={name.key}
                      value={name.value}
                      autoFocus={index === 0}>
                      <Checkbox checked={filterData.status.split(',').indexOf(name.value) > -1} />
                      <ListItemText
                        sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                        primary={name.key}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FilterDropdown
                setFilterData={setFilterData}
                filterData={filterData}
                osList={osList}
                manufacturerList={manufacturerList}
                typeList={typeList}
              />

              <Box>
                <Button
                  onClick={handleClearFuntion}
                  sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
                  variant="text">
                  {' '}
                  <FilterCancelIcon /> Clear filter
                </Button>
              </Box>
            </Box>

            {selected.length ? (
              <Box>
                {' '}
                <Button
                  id="basic-button"
                  aria-controls={openNew ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
                  variant="contained"
                  aria-expanded={openNew ? 'true' : undefined}
                  onClick={handleClockOpenSelectAction}>
                  Select Action
                  <Box sx={{ display: 'flex' }}>
                    <DropDown />
                  </Box>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElSelectAction}
                  open={openNew}
                  onClose={handleClickCloseSelectAction}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}>
                  <MenuItem
                    onClick={() => {
                      setIsCreateRemediation(true);
                      toggleDrawer('right', true, {});
                      setAnchorElSelectAction(null);
                    }}>
                    <Typography sx={{ ml: 1 }} variant="caption">
                      {' '}
                      Create Remediation
                    </Typography>
                  </MenuItem>
                  {commonDetails?.data?.group == UserRoles.SUPER_ADMIN && (
                    <MenuItem
                      onClick={() => {
                        setIsMultipleDelete(true);
                        setAnchorElSelectAction(null);
                      }}>
                      <Typography sx={{ ml: 1 }} variant="caption">
                        {' '}
                        Delete Records
                      </Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            ) : null}
          </Box>

          <Box>
            {assetsList?.length == 0 || !assetsList ? (
              initialLength ? (
                <NoSearchDataAvailable
                  title="No Search Results"
                  message="Try adjusting your search or filter to find what you are looking for"
                />
              ) : (
                <NoDataAvailable
                  title="No Data Available"
                  message="The Assets page will be populated once the first risk is discovered."
                />
              )
            ) : (
              <AssetListTable
                assetsList={assetsList}
                totalCount={totalCount}
                page={page}
                handleSelectAllClick={handleSelectAllClick}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleRowClick={handleRowClick}
                stopImmediatePropagation={stopImmediatePropagation}
                toggleDrawer={toggleDrawer}
                setRowData={setRowData}
                rowData={rowData}
                setIsCreateRemediation={setIsCreateRemediation}
                handleClickCheck={handleClickCheck}
                isSelected={isSelected}
                selectedAssetList={selected}
                isMultipleDelete={isMultipleDelete}
                setIsMultipleDelete={setIsMultipleDelete}
                GetAssets={GetAssets}
                commonDetails={commonDetails}
              />
            )}
          </Box>

          <SwipeableDrawer
            BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
            sx={{
              '.MuiDrawer-paper': {
                boxShadow: 'none'
              }
            }}
            anchor={'right'}
            open={state['right']}
            onClose={() => toggleDrawer('right', false, {})}
            onOpen={() => toggleDrawer('right', true, rowData)}>
            {!isSkeleton && (
              <AssetPopUp
                rowData={rowData}
                assignees={assignees}
                isViewAsset={isViewAsset}
                isCreateRemediation={isCreateRemediation}
                commonDetails={commonDetails}
                assetsList={assetsList}
                selectedAssetList={selected}
                setAssociatedAssets={setAssociatedAssets}
                associatedAssets={associatedAssets}
                setIsCreateRemediation={setIsCreateRemediation}
                GetAssets={GetAssets}
                // isEditDD={isEditDD}
                // setIsEditDD={setIsEditDD}
                // isGap={false}
                // risk_uuid={rowData.risk_uuid}
                closePopup={() => toggleDrawer('right', false, {})}
              // isEngineer={engineer}
              // setIsRefresh={setIsRefresh}
              // isRefresh={isRefresh}
              // servicesOption={servicesOption}
              />
            )}
          </SwipeableDrawer>
        </Box>
      )}
    </Box>
  );
};
