import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';

import {
  Box,
  List,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useFormik } from 'formik';
import { ServiceReportsApi } from '../ServiceReports.Api';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { LoadingButton } from '@mui/lab';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import { ServiceReviewApi } from 'src/Pages/CustomerSuccess/ServicesReview/ServiceReview.Api';
import moment from 'moment';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { toast } from 'react-toastify';
var debounce = require('lodash.debounce');

const ServiceReportsSideBar: FC<{
  rowData: any;
  assignees: any;
  servicesListOption: any;
  isCreateRemediation: boolean;
  onClose(event?: React.KeyboardEvent | React.MouseEvent): void;
  engineer: boolean;
}> = (prop) => {
  const [create, setCreate] = useState(false);
  const [compalate, setComplate] = useState(false);
  const [reportsData, setReportsData] = useState<any>({});
  const [skeleton, setSkeleton] = useState(false);
  const [isRemediationButtonLoading, setIsRemediationButtonLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploade, setIsUpload] = useState(false);
  const [fileData, setFileData] = useState([] as any);
  const [isEdit, setIsEdit] = useState(false);

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const validate = (value: any) => {
    const errors: any = {};

    if (!value.name || !value.source || !value.startDate || !value.endDate) {
      setComplate(false);
    } else {
      setComplate(true);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      source: '',
      name: '',
      startDate: null,
      endDate: null
    },
    validate,
    onSubmit: useCallback(
      debounce(
        async () => {
          try {
            setIsRemediationButtonLoading(true);
            setIsUpload(true);

            const data = {
              name: formik.values.name,
              start_date: moment.utc(formik.values.startDate).format('YYYY-MM-DD'),
              end_date: moment.utc(formik.values.endDate).format('YYYY-MM-DD'),
              files: []
            };

            for (let i = 0; i < fileData.length; i++) {
              data.files.push({
                file_name: fileData[i].name,
                file_size: fileData[i].size,
                file_type: fileData[i].type
              });
            }

            let res = null;
            if (reportsData?.reports_uuid) {
              data.files = [...data.files, ...uploadedFiles];
              if (data.files.length === 0) {
                return toast.error('Please upload the report');
              }
              res = await ServiceReportsApi.EditReportData(
                formik.values.source,
                commonDetails.data.company.company_uuid,
                reportsData?.reports_uuid,
                data
              );
            } else {
              if (data.files.length === 0) {
                return toast.error('Please upload the report');
              }
              res = await ServiceReportsApi.PostReportData(
                formik.values.source,
                commonDetails.data.company.company_uuid,
                data
              );
            }
            setIsEdit(false);

            await ServiceReviewApi.UploadDocuments(res, fileData);

            const Ids = res?.data?.data[0]?.callback_url?.split('/');
            if (Ids.length > 0) {
              GetReportDataByID({
                service: { services_uuid: Ids[3] },
                uploaded_by: {
                  company: {
                    company_uuid: Ids[1]
                  }
                },
                reports_uuid: Ids[5]
              });
              setCreate(false);
            } else {
              prop.onClose();
            }
            setIsEdit(false);
          } catch (error) {
            console.log('ERROR ::', error);
          } finally {
            setTimeout(() => {
              setIsRemediationButtonLoading(false);
              setIsUpload(false);
              // setIsEdit(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [compalate, commonDetails, validate, fileData, reportsData]
    )
  });

  const GetReportDataByID = async (rowData) => {
    try {
      const res = await ServiceReportsApi.GetReportDataById(
        rowData.service.services_uuid,
        rowData.uploaded_by.company.company_uuid,
        rowData.reports_uuid
      );
      if (res?.data?.data) {
        setReportsData(res?.data?.data);
        SetupEditData(res?.data?.data);
        setComplate(true);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (prop.rowData?.reports_uuid) {
      GetReportDataByID(prop.rowData);
      setCreate(false);
    } else {
      setCreate(true);
      SetupEditData({});
      setReportsData(null);
    }
  }, [prop]);

  const SetupEditData = (reportsData) => {
    const updatedData = reportsData?.reports_uuid ? reportsData : prop.rowData;
    formik.setFieldValue('source', updatedData?.service?.services_uuid || '');
    formik.setFieldValue('name', updatedData?.name || '');
    formik.setFieldValue(
      'startDate',
      updatedData?.start_date
        ? new Date(moment.utc(updatedData?.start_date).format('DD MMM yyyy'))
        : null
    );
    formik.setFieldValue(
      'endDate',
      updatedData?.end_date
        ? new Date(moment.utc(updatedData?.end_date).format('DD MMM yyyy'))
        : null
    );
    setUploadedFiles(updatedData?.files);
    setFileData([]);
  };

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    const selectedFiles = Array.from(e.target.files);
    const newFileCount = selectedFiles.length + fileData.length;

    if (newFileCount > 10) {
      toast.error("Maximum 10 files can be uploaded");
      return;
    }

    setFileData([...fileData, ...selectedFiles]);
  };

  const handleImageUpload = (e, files) => {
    stopReload(e);

    if (!e) return;
    const selectedFiles = Array.from(files);
    const newFileCount = selectedFiles.length + fileData.length;

    if (newFileCount > 10) {
      toast.error("Maximum 10 files can be uploaded");
      return;
    }

    setFileData([...fileData, ...selectedFiles]);
  };

  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  const handleRemoveFile = (i) => {
    const upadatedFileData = fileData?.filter((el, index) => index != i);
    setFileData(upadatedFileData);
  };

  const DeleteActionTakenFile = async (element) => {
    try {
      const upadatedFileData = uploadedFiles?.filter((el) => el.id !== element.id);
      setUploadedFiles(upadatedFileData);
    } catch (error) { }
  };

  const getFile = async (el: any) => {
    try {
      const res = await ServiceReportsApi.GetReportFile(
        reportsData.service.services_uuid,
        reportsData.uploaded_by.company.company_uuid,
        reportsData.reports_uuid,
        el.file_name
      );

      const urlName = res?.data?.data;
      const a = document.createElement('a');
      a.href = urlName;
      a.target = '_blank';
      //a.download = `downloadfile.${res.headers['content-type'].split('/')[1]}`;
      a.download = `${el.file_name}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.log('ERROR ::', error);
    }
  };

  if (skeleton) {
    return <Box></Box>;
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: { md: 520, xs: 300 },
          position: 'relative',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              justifyContent: 'space-between',
              backgroundColor: '#003260',
              px: 2.5,
              alignItems: 'center',
              py: 1
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',

                width: 1,
                alignItems: 'center'
              }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700, color: 'white', my: 1.25 }}>
                Upload Report
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  onClick={(event) => {
                    prop.onClose(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </List>
        <Box sx={{ p: 3, flex: 1 }}>
          <List sx={{ py: 0 }}>
            <List sx={{ py: 1.5 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 2
                }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: '#696969', fontSize: '14px', fontWeight: 600 }}>
                  Report Service
                </Typography>
                <Box sx={{ width: 1 }}>
                  <Select
                    size="small"
                    displayEmpty
                    fullWidth
                    id="type"
                    sx={{
                      fontSize: '14px',
                      color: '#696969',
                      minWidth: { xxs: 0, md: '188px' },
                      backgroundColor: '#FFFFFF'
                    }}
                    defaultValue=""
                    disabled={!isEdit && !create}
                    name="source"
                    value={formik.values.source}
                    onChange={formik.handleChange}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" sx={{ display: 'none' }}>
                      Select Service
                    </MenuItem>

                    {prop?.servicesListOption?.map(
                      (el: any) =>
                        el?.value && (
                          <MenuItem sx={{ fontSize: '14px' }} value={el?.value}>
                            {el?.key}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </Box>
              </Box>
            </List>
            <Box sx={{ my: 1 }}>
              <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, pb: 2 }}>
                Report Name
              </Typography>
              <TextField
                autoComplete={'none'}
                size={'small'}
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
                disabled={!isEdit && !create}
                rows={7}
                sx={{
                  fontSize: '14px',
                  border: 'none',
                  textAlign: 'justify',
                  width: 1,
                  backgroundColor: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#CCCCCC'
                  },
                  input: {
                    '::placeholder': {
                      fontSize: '14px',
                    },
                  },
                }}
                placeholder="Type here"
              />
            </Box>

            <Box>
              {(isEdit || create) && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ color: '#696969', fontWeight: 600, pt: 3, pb: 2 }}>
                    Upload Report
                  </Typography>
                  <Box
                    onDragOver={(e) => stopReload(e)}
                    onDrop={(e) => handleDragEvent(e)}
                    sx={{
                      width: '100%',
                      backgroundColor: '#FBFBFF',
                      py: 4,
                      display: 'flex',
                      border: '1px dashed #CCCCCC',
                      borderRadius: '8px',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 2.5
                    }}>
                    <UploadIcon />
                    <Typography variant="body1" sx={{ fontWeight: 600, color: '#464646' }}>
                      Drag & Drop or{' '}
                      <label style={{ color: '#436AF3', cursor: 'pointer' }}>
                        <input
                          multiple
                          hidden
                          onChange={(e) => handleBrowseEvent(e)}
                          style={{
                            backgroundColor: 'transparent',
                            color: 'transparent'
                          }}
                          type={'file'}
                        />{' '}
                        Choose File{' '}
                      </label>
                      to upload
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1.5,
                        width: '100%',
                        alignItem: 'center',
                        m: 'auto'
                      }}>
                      {fileData.length == 0 || isUploade ? (
                        <Typography textAlign={'center'} variant="body2" sx={{ color: '#787878' }}>
                          Document, Image, or Video
                        </Typography>
                      ) : (
                        fileData?.map((el, index) => (
                          <Box
                            key={index + 1}
                            sx={{
                              display: 'flex',
                              width: '80%',
                              m: 'auto',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              p: 1.5,
                              borderRadius: '8px',
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #EBEBEB'
                            }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {el.type.split('/')[0] == 'image' ? (
                                <GallaryIcon />
                              ) : el.type.split('/')[0] == 'video' ? (
                                <VideoPlay />
                              ) : (
                                <FileIcon />
                              )}

                              <EllipsisContent
                                sx={{
                                  fontSize: 14,
                                  color: '#2D2D2D',
                                  width: 'max-content',
                                  maxWidth: {
                                    xxs: 150,
                                    md: 150
                                  }
                                }}
                                text={el.name}
                              />

                              <Typography variant="body2" sx={{ color: '#787878' }}>
                                {niceBytes(el.size)}
                              </Typography>
                            </Box>
                            <Box onClick={() => handleRemoveFile(index)}>
                              <CrossCancle
                                sx={{
                                  background: 'rgba(120, 120, 120,0.1)',
                                  width: '20px',
                                  height: '20px',
                                  color: '#787878',
                                  p: '6px',
                                  borderRadius: '50%',
                                  cursor: 'pointer'
                                }}
                              />
                            </Box>
                          </Box>
                        ))
                      )}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Box>
              <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, pt: 3, pb: 2 }}>
                Report Period
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <DatePicker
                  className="calendar-filter"
                  showIcon
                  icon={<CalenderIconFilter />}
                  dateFormat="dd MMM yyyy"
                  disabled={!isEdit && !create}
                  selectsStart
                  startDate={formik.values.startDate}
                  endDate={formik.values.endDate}
                  selected={formik.values.startDate}
                  onChange={(date) => {
                    formik.handleChange({
                      target: {
                        value: date,
                        name: 'startDate'
                      }
                    });
                    formik.handleChange({
                      target: {
                        value: null,
                        name: 'endDate'
                      }
                    });
                  }}
                  name="startDate"
                  placeholderText="Start Date"
                  style={{
                    input: {
                      margin: '7px 10px 6px 25px !important'
                    }
                  }}
                />
                <DatePicker
                  className="calendar-filter"
                  showIcon
                  icon={<CalenderIconFilter />}
                  dateFormat="dd MMM yyyy"
                  placeholderText="End Date"
                  selected={formik.values.endDate}
                  disabled={(!isEdit && !create) || !formik.values.startDate}
                  onChange={(date) =>
                    formik.handleChange({
                      target: {
                        value: date,
                        name: 'endDate'
                      }
                    })
                  }
                  name="endDate"
                  selectsEnd
                  startDate={formik.values.startDate}
                  endDate={formik.values.endDate}
                  minDate={formik.values.startDate}
                />
              </Box>
            </Box>
            <Box>
              {uploadedFiles?.length > 0 && (
                <Box sx={{}}>
                  <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, pt: 3 }}>
                    Files
                  </Typography>

                  <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1.5 }}>
                    {uploadedFiles.map((element, index) => (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          mt: 2,
                          alignItems: 'center',
                          backgroundColor: '#D9EDFF',
                          py: '5px',
                          px: 1.5,
                          borderRadius: '100px',
                          cursor: 'pointer',
                          width: 'fit-content'
                        }}>
                        <a
                          href={element?.file_url}
                          style={{ textDecoration: 'none' }}
                          target="_blank"
                          onClick={() => getFile(element)}>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              alignItems: 'center'
                            }}>
                            <PinIcon />
                            <EllipsisContent text={element.file_name} maxWidth={377} />
                          </Box>
                        </a>
                        {isEdit && (
                          <IconButton
                            sx={{ color: 'text.primary', width: '24px', height: '24px' }}
                            aria-label="close"
                            onClick={() => DeleteActionTakenFile(element)}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </List>
        </Box>
        <Box
          sx={{
            px: 2,
            py: 2,
            textAlign: 'end',
            borderTop: '1px solid #0000000D',
            width: 1
          }}>
          {reportsData?.reports_uuid ? (
            isEdit ? (
              <Box sx={{ display: 'flex', gap: 3, justifyContent: 'end' }}>
                <Button
                  variant="outlined"
                  sx={{ minWidth: 160 }}
                  onClick={() => {
                    setIsEdit(false);
                    SetupEditData(reportsData);
                  }}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isRemediationButtonLoading}
                  disabled={!compalate}
                  variant="contained"
                  sx={{ minWidth: 160 }}
                  type="submit">
                  Save
                </LoadingButton>
              </Box>
            ) : (
              (commonDetails.data.user_uuid === reportsData.uploaded_by?.user_uuid ||
                (commonDetails?.data?.group.toLowerCase() == UserRoles.SUPER_ADMIN &&
                  prop.engineer) ||
                commonDetails?.data?.group.toLowerCase() == UserRoles.CUSTOMER_ADMIN ||
                commonDetails?.data?.group.toLowerCase() == UserRoles.CYBER_SECURITY_ENGINEER ||
                commonDetails?.data?.group.toLowerCase() ==
                UserRoles.SERVICE_DELIVERY_COORDINATOR ||
                commonDetails?.data?.group.toLowerCase() == UserRoles.SERVICE_DELIVERY_MANAGER) && (
                <Button variant="outlined" sx={{ minWidth: 160 }} onClick={() => setIsEdit(true)}>
                  {'Edit'}
                </Button>
              )
            )
          ) : (
            <LoadingButton
              loading={isRemediationButtonLoading}
              disabled={!compalate}
              variant="contained"
              sx={{ minWidth: 160 }}
              type="submit">
              Submit
            </LoadingButton>
          )}
        </Box>
      </Box>
    </form>
  );
};
export default ServiceReportsSideBar;
