import { FC, useEffect, useRef, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import ColorDot from '@jibin/common/components/ColorDot';
import Box from '@mui/material/Box';

import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import Style from '@jibin/common/style/Styles';
import ActivityBoxes from './ActivityBoxes';
import { SpaceDashboardIconBlue } from '@jibin/common/icons/SpaceDashboard/SpaceDashboard';
import { PowerIconBlue } from '@jibin/common/icons/Power/Power';
import { PortalActivityApi } from './PortalActivity.Api';
import { GapAnalysisIconBlue } from '@jibin/common/icons/GapAnalysisIcon/GapAnalysisIcon';
import { SiemIconBlue } from '@jibin/common/icons/SiemIcon';
import Power from '@jibin/common/icons/Power/Power';
import { VerifiedUserIconBlue } from '@jibin/common/icons/VerifiedUser/VerifiedUser';
import { SignalCellularIconBlue } from '@jibin/common/icons/SignalCellular/SignalCellular';
import { ManageUserIconBlue } from '@jibin/common/icons/ManageUserIcon';
import { CompanyManagementIconBlue } from '@jibin/common/icons/CompanyManagementIcon';
import { PictureAsPdfIconBlue } from '@jibin/common/icons/PictureAsPdf/PictureAsPdf';
import { EndpointProtectionBlue } from '@jibin/common/icons/EndpointProtection/EndpointProtection';
import { TickMarkBlue } from '@jibin/common/icons/Tickmark/Tickmark';

import { PortalActivityIconBlue } from '@jibin/common/icons/PortalActivityIcon/PortalActivityIcon';

import { AttckSurafaceIconBlue } from '@jibin/common/icons/AttckSurafaceIcon/AttckSurafaceIcon';
import { NoIcon } from '@jibin/common/icons/NoIcon';
import { HumanHeadBlue } from '@jibin/common/icons/HumanHead/HumanHead';
import { NetworkDetectionIconBlue } from '@jibin/common/icons/NetworkDetectionIcon';
import { OnboardingIconBlue } from '@jibin/common/icons/OnboardingIcon';

import { CustomerSuccessBlue } from '@jibin/common/icons/CustomerSuccess/CustomerSuccess';
import { EmailProtectionBlue } from '@jibin/common/icons/EmailProtection/EmailProtection';

import TablePagination from '../Common/TablePagination';
import HeadingHeader from '../Common/HeadingHeader';
// import { commonDetailSlice } from '../../store/reducers/commonReducer';

export const ServiceForActivity = [
  {
    name: 'Remediation Tracker',
    showing_name: 'Remediation Tracker',
    tooltip_name: '',
    id: 1,
    route: PageRouteConstants.Remediation,
    supported_route: 'remediation-tracker',
    icon: TickMarkBlue
  },
  {
    name: 'Policies',
    showing_name: 'Policies',
    tooltip_name: '',
    id: 12,
    route: PageRouteConstants.PoliciesAndProcedures,
    supported_route: 'policies-procedures',
    icon: PictureAsPdfIconBlue
  },
  {
    name: 'Framework',
    showing_name: 'Framework',
    tooltip_name: '',
    id: 2,
    isStatic: true,
    route: PageRouteConstants.Frameworks,
    supported_route: 'frameworks',
    icon: SpaceDashboardIconBlue
  },

  {
    name: 'Gap Analysis',
    showing_name: 'Gap Analysis',
    tooltip_name: '',
    id: 3,
    route: PageRouteConstants.GapAnalysis,
    supported_route: 'gap-analysis',
    icon: GapAnalysisIconBlue
  },
  {
    name: 'Compliance',
    showing_name: 'Compliance',
    tooltip_name: '',
    id: 13,
    route: PageRouteConstants.GapAnalysisV2,
    supported_route: 'gap-analysis-v2',
    icon: GapAnalysisIconBlue
  },

  {
    name: 'Security Awareness Results',
    showing_name: 'Security Awareness Results',
    tooltip_name: '',
    id: 4,
    route: PageRouteConstants.SecurityAwarenessResult,
    supported_route: 'security-awareness',
    icon: HumanHeadBlue
  },
  {
    name: 'Security Awareness Participant',
    showing_name: 'Security Awareness Participant',
    tooltip_name: '',
    id: 4,
    route: PageRouteConstants.SecurityAwarenessParticupant,
    supported_route: 'security-awareness',
    icon: HumanHeadBlue
  },
  {
    name: 'Vulnerability Management',
    showing_name: 'Vulnerability Management',
    tooltip_name: '',
    id: 5,
    route: PageRouteConstants.Vulnerability,
    supported_route: 'vulnerability-management',
    icon: VerifiedUserIconBlue
  },
  {
    name: 'Attack Surface Management',
    showing_name: 'Attack Surface Management',
    tooltip_name: '',
    id: 6,
    route: PageRouteConstants.UpGuard,
    supported_route: 'attack-surface-management',
    icon: AttckSurafaceIconBlue
  },
  {
    name: 'Penetration Testing',
    showing_name: 'Penetration Testing',
    tooltip_name: '',
    id: 7,
    route: PageRouteConstants.Penetration,
    supported_route: 'penetration-testing',
    icon: SignalCellularIconBlue
  },
  {
    name: 'Email Protection',
    showing_name: 'Email Protection',
    tooltip_name: '',
    id: 8,
    route: PageRouteConstants.EmailProtection,
    supported_route: 'email-protection',
    icon: EmailProtectionBlue
  },
  {
    name: 'Tool Management',
    showing_name: 'Tools',
    tooltip_name: '',
    id: 9,
    route: PageRouteConstants.EndpointProtection,
    supported_route: 'endpoint-protection',
    icon: EndpointProtectionBlue
  },

  {
    name: 'Network Detection',
    showing_name: 'Network Detection',
    tooltip_name: '',
    id: 10,
    route: PageRouteConstants.NetwrokDetection,
    supported_route: 'network-detection',
    icon: NetworkDetectionIconBlue
  },
  {
    name: 'Event Management',
    showing_name: 'Events',
    tooltip_name: '',
    id: 11,
    route: PageRouteConstants.SIEM,
    supported_route: 'events',
    icon: SiemIconBlue
  },
  {
    name: 'On Boarding',
    showing_name: 'On Boarding',
    tooltip_name: 'On Boarding',
    id: 14,
    route: PageRouteConstants.OnBoarding,
    supported_route: 'On Boarding',
    icon: OnboardingIconBlue
  },
  {
    name: 'Portal Activity',
    showing_name: 'Portal Activity',
    tooltip_name: 'Portal Activity',
    id: 14,
    route: PageRouteConstants.OnBoarding,
    supported_route: 'Portal Activity',
    icon: PortalActivityIconBlue
  },
  {
    name: 'Dashboard',
    showing_name: 'Dashboard',
    tooltip_name: 'Dashboard',
    id: 15,
    route: PageRouteConstants.DashBoard,
    supported_route: 'Dashboard',
    icon: PowerIconBlue
  },
  {
    name: 'Reports',
    showing_name: 'Reports',
    tooltip_name: 'Reports',
    id: 15,
    route: PageRouteConstants.ServicesReview,
    supported_route: 'Reports',
    icon: CustomerSuccessBlue
  },
  {
    name: 'Consulting Reports',
    showing_name: 'Consulting Reports',
    tooltip_name: 'Consulting Reports',
    id: 15,
    route: PageRouteConstants.EngineeringConsulting,
    supported_route: 'Consulting Reports',
    icon: CustomerSuccessBlue
  },
  {
    name: 'User Management',
    showing_name: 'Manage Users',
    tooltip_name: 'Manage Users',
    id: 16,
    route: PageRouteConstants.ManageUsers,
    supported_route: 'Manage Users',
    icon: ManageUserIconBlue
  },
  {
    name: 'Meetings',
    showing_name: 'Meetings',
    tooltip_name: 'Meetings',
    id: 17,
    route: PageRouteConstants.Meetings,
    supported_route: 'Meetings',
    icon: NoIcon
  },

  {
    name: 'Company Profile',
    showing_name: 'Company Profile',
    tooltip_name: 'Company Profile',
    id: 17,
    route: PageRouteConstants.CompanyProfile,
    supported_route: 'Company Profile',
    icon: NoIcon
  },
  {
    name: 'Authorizations',
    showing_name: 'Authorizations',
    tooltip_name: 'Authorizations',
    id: 17,
    route: PageRouteConstants.Authorizations,
    supported_route: 'Authorizations',
    icon: NoIcon
  },
  {
    name: 'Risk Management',
    showing_name: 'Risks',
    tooltip_name: 'Risks',
    id: 17,
    route: PageRouteConstants.RiskManagement,
    supported_route: 'Risks',
    icon: NoIcon
  },
  {
    name: 'Asset Management',
    showing_name: 'Assets',
    tooltip_name: 'Assets',
    id: 17,
    route: PageRouteConstants.RiskManagement,
    supported_route: 'Assets',
    icon: NoIcon
  },
  {
    name: 'Licenses',
    showing_name: 'Licenses',
    tooltip_name: 'Licenses',
    id: 17,
    route: PageRouteConstants.Licenses,
    supported_route: 'Licenses',
    icon: NoIcon
  },
  {
    name: 'Plan And Billing',
    showing_name: 'Plan And Billing',
    tooltip_name: 'Plan And Billing',
    id: 17,
    route: PageRouteConstants.PlanAndBilling,
    supported_route: 'Plan And Billing',
    icon: NoIcon
  },
  {
    name: 'Applications',
    showing_name: 'Applications',
    tooltip_name: 'Applications',
    id: 17,
    route: PageRouteConstants.Applications,
    supported_route: 'Applications',
    icon: NoIcon
  },
  {
    name: 'IP Ranges',
    showing_name: 'IP Ranges',
    tooltip_name: 'IP Ranges',
    id: 17,
    route: PageRouteConstants.ExternalInfrastructureAssessment,
    supported_route: 'IP Ranges',
    icon: NoIcon
  },
  {
    name: 'Wireless Networks',
    showing_name: 'Wireless Networks',
    tooltip_name: 'Wireless Networks',
    id: 17,
    route: PageRouteConstants.WirelessAssessment,
    supported_route: 'Wireless Networks',
    icon: NoIcon
  },
  {
    name: 'Location',
    showing_name: 'Location',
    tooltip_name: 'Location',
    id: 17,
    route: PageRouteConstants.CompanyProfile,

    supported_route: 'Location',
    icon: NoIcon
  },
  {
    name: 'Account',
    showing_name: 'Account',
    tooltip_name: 'Account',
    id: 17,
    route: PageRouteConstants.MyAccount,

    supported_route: 'Account',
    icon: NoIcon
  },
  {
    name: 'Company Account',
    showing_name: 'Company Account',
    tooltip_name: 'Company Account',
    id: 17,
    route: PageRouteConstants.General,

    supported_route: 'Company Account',
    icon: NoIcon
  },
  {
    name: 'Company Management',
    showing_name: 'Company Management',
    tooltip_name: 'Company Management',
    id: 17,
    route: PageRouteConstants.CompanyManagement,

    supported_route: 'Company Management',
    icon: CompanyManagementIconBlue
  },
  {
    name: 'Company Account',
    showing_name: 'Company Account',
    tooltip_name: 'Company Account',
    id: 17,
    route: PageRouteConstants.CompanyManagementAccount,

    supported_route: 'Company Account',
    icon: NoIcon
  },

  {
    name: 'Contacts',
    showing_name: 'Contacts',
    tooltip_name: 'Contacts',
    id: 17,
    route: PageRouteConstants.CompanyManagementContact,

    supported_route: 'Contacts',
    icon: NoIcon
  },
  {
    name: 'Integrations',
    showing_name: 'Integrations',
    tooltip_name: 'Integrations',
    id: 17,
    route: PageRouteConstants.CompanyManagementIntegrations,

    supported_route: 'Integrations',
    icon: NoIcon
  }
];

export const PortalActivity: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const navigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [data, setData] = useState({ critical: [], high: [], medium: [] });
  const [portalActivity, setPortalActivity] = useState({} as any);
  const [portalPageActivity, setPortalPageActivity] = useState([] as any);
  const [waitingToReconnect, setWaitingToReconnect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const clientRef = useRef(null);
  const [portalUserActivity, setPortalUserActivity] = useState([] as any);
  const [penetrationTests, setPenetrationTests] = useState({} as any);
  const [penetrationStats, setPenetrationStats] = useState({} as any);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FDE68A';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const renderIcon = (serviceName) => {
    const maped = ServiceForActivity?.filter((el) => el.name == serviceName);
    const Icon = maped[0]?.icon;
    if (!Icon) {
      return <></>;
    }
    return <Icon />;
  };
  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  // const GetPenetrationTests = useCallback(() => {
  //   PanetrationApi.PenetrationTests(
  //     commonDetails.data.user_uuid,
  //     commonDetails.data.company.company_uuid
  //   ).then(
  //     (q: any) => {
  //       setPenetrationTests(q.data);
  //     },
  //     (err: any) => { }
  //   );
  // }, [commonDetails]);
  useEffect(() => {
    if (commonDetails) {
      // GetNotifications();
      if (waitingToReconnect) {
        return;
      }

      try {
        // Only set up the websocket once
        if (!clientRef.current) {
          const client = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_HOST}ws/channel/users_activity/subscribe`
          );
          clientRef.current = client;
          //window.client = client;

          client.onerror = (e) => console.error(e);

          client.onopen = () => {
            setIsOpen(true);
            // console.log('ws opened');
            //client.send('ping');
          };

          client.onmessage = (event) => {
            GetPortalActivitiy();
            GetPortalUserActivitiy();
          };

          client.onclose = () => {
            if (clientRef.current) {
              // Connection failed
              console.log('ws closed by server');
            } else {
              // Cleanup initiated from app side, can return here, to not attempt a reconnect
              // console.log('ws closed by app component unmount');
              return;
            }

            if (waitingToReconnect) {
              return;
            }

            // Parse event code and log
            setIsOpen(false);
            // console.log('ws closed');

            // Setting this will trigger a re-run of the effect,
            // cleaning up the current websocket, but not setting
            // up a new one right away
            setWaitingToReconnect(true);

            // This will trigger another re-run, and because it is false,
            // the socket will be set up again
            setTimeout(() => setWaitingToReconnect(null), 5000);
          };

          return () => {
            // console.log('Cleanup');
            // Dereference, so it will set up next time
            clientRef.current = null;

            client.close();
          };
        }
      } catch (error) {}
    }
  }, [waitingToReconnect]);
  const GetPortalActivitiy = async () => {
    try {
      const res = await PortalActivityApi.GetPortalActivitiy(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      const dataArray = [];
      for (const key in res?.data?.data?.pages) {
        const obje = {
          name: key,
          sessions: res?.data?.data?.pages[key]?.sessions,
          users: res?.data?.data?.pages[key]?.users
        };
        dataArray.push(obje);
      }
      const commonObjects = ServiceForActivity.map((obj1) => {
        const obj2 = dataArray.find((obj2) => obj2.name === obj1.name);
        if (obj2) {
          // Combine properties from both objects
          return { ...obj1, ...obj2 };
        }
        return null; // If no match is found
      }).filter(Boolean);

      setPortalActivity(res?.data?.data);
      setPortalPageActivity(commonObjects);
    } catch (error) {}
  };
  const GetPortalUserActivitiy = async () => {
    try {
      const res = await PortalActivityApi.GetPortalUserActivitiy(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        new URLSearchParams(pagination).toString()
      );
      setTotalCount(res?.data?.pagination?.total_records);
      setPortalUserActivity(res?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);
  const momentTimezone = require('moment-timezone');
  useEffect(() => {
    if (commonDetails?.data?.company?.type != 'engineering') {
      navigate(PageRouteConstants.DashBoard);
    }
    GetPortalActivitiy();
    GetPortalUserActivitiy();
  }, [commonDetails, pagination.page_no, pagination.page_size]);
  return (
    <Box>
      <HeadingHeader subtitle1={'User Activity'} />

      <Box sx={{ px: 4, py: 3, width: 1 }}>
        <Box
          sx={{
            bgcolor: '#FFFFFF',
            py: 1.5,
            px: 2.5,
            border: '1px solid #EBEBEB',
            borderRadius: '4px'
          }}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Box
                sx={{
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                <Box>
                  <Typography variant="h1" sx={{ fontSize: '48px' }}>
                    {portalActivity?.users}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ColorDot color={'#27AE60'} />
                  <Typography
                    variant="body1"
                    sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#696969' }}>
                    {' '}
                    Total Online Users
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box
                sx={{
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                <Box>
                  <Typography variant="h1" sx={{ fontSize: '48px' }}>
                    {portalActivity?.sessions}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ColorDot color={'#FDE68A'} />
                  <Typography
                    variant="body1"
                    sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#696969' }}>
                    {' '}
                    Total Opened Sessions
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            py: 4,

            borderRadius: '4px'
          }}>
          <Grid container spacing={3}>
            {portalPageActivity?.map((el) => (
              <ActivityBoxes
                heading={el?.name}
                icon={renderIcon(el?.name)}
                users={el?.users}
                sessions={el?.sessions}
              />
            ))}
          </Grid>
        </Box>
        <Box>
          <Box sx={{ ...Style.SiteListTable.SiteListTableWrapper, overflowX: 'initial' }}>
            <Table sx={{ minWidth: 650, marginBottom: '12px' }} aria-label="site list table">
              <TableHead
                sx={{ position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 400 }}>User Name</TableCell>
                  <TableCell sx={{ fontWeight: 400 }}>Company</TableCell>
                  <TableCell sx={{ fontWeight: 400 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 400 }}>Page</TableCell>
                  <TableCell sx={{ fontWeight: 400 }} align="right">
                    Session
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {portalUserActivity?.map((el: any) => (
                    <>
                      <Box sx={Style.Common.Divider}></Box>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {el?.user?.first_name} {el?.user?.last_name}
                        </TableCell>
                        <TableCell> {el?.company?.name}</TableCell>
                        <TableCell> {el?.user?.email} </TableCell>
                        <TableCell>{el?.page}</TableCell>
                        <TableCell align="right">{el?.sessions}</TableCell>
                      </TableRow>
                    </>
                  ))}
                </>
              </TableBody>
            </Table>
            <TablePagination
              totalCount={totalCount}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
