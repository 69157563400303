import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import { Box, Skeleton, Stack, Typography, linearProgressClasses } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { DashboardApi } from 'src/Pages/DashBoard/Dashboard.Api';
import FrameWorkPopup from 'src/Pages/DashBoard/DashboardFrameWorkPopup/FrameWorkPopup';
import DropdownMenu from 'src/Pages/DashBoard/DropdownMenu';

const ComplianceSkeleton = () => {
  return (
    <Box
      sx={{
        mb: 1.5,
        border: '1px solid #EAECF0',
        p: 2,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center'
      }}>
      <Box>
        <Box
          sx={{
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            overflow: 'hidden'
          }}>
          <Skeleton
            style={{
              width: '100%',
              height: '100%',
              transform: 'none'
            }}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', ml: 1 }}>
        <Stack direction={'row'} justifyContent={'space-between'} gap={1} alignItems={'center'}>
          <Typography sx={{ fontWeight: 600, mb: 1 }}>
            <Skeleton width={100} />
          </Typography>
          <Typography variant="caption" sx={{ mb: 1 }}>
            <Skeleton width={20} />
          </Typography>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            justifyContent: 'space-between'
          }}>
          <Box
            sx={{
              width: 0.85,
              border: '1px solid auto',
              borderRadius: '10px',
              p: 0.5
            }}>
            <Skeleton width={'100%'} height={10} variant="rounded" />
          </Box>

          <Typography variant="body2" sx={{ color: '#696969', whiteSpace: 'nowrap' }}>
            <Skeleton width={20} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const GapFrameWorkWidget = ({ isDragable, currentGrid, setAnchorEl, anchorEl, setWidgetSize }) => {
  const [loading, setLoading] = useState(true);
  const [frameworkCompliance, setFrameworkCompliance] = useState([] as any);
  const [openFramework, setOpenFramework] = useState(false);
  const handleOpenFramework = () => {
    if (!isDragable) {
      setOpenFramework(!openFramework);
    }
  };
  const handleCloseFramework = () => {
    setOpenFramework(false);
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const GetCategories = useCallback(async () => {
    setLoading(true);
    try {
      const res = await DashboardApi.GetGapStats(
        commonDetails.data.user_uuid,

        commonDetails.data.company.company_uuid
      );
      const dataArray = [];
      for (const key in res?.data?.data?.frameworks) {
        const obje = {
          name: key,
          completed: res?.data?.data?.frameworks[key]?.compliance_controls,
          total: res?.data?.data?.frameworks[key]?.total_controls,
          image: res?.data?.data?.frameworks[key]?.framework_logo_url,
          version: res?.data?.data?.frameworks[key]?.version
        };
        dataArray.push(obje);
      }
      setFrameworkCompliance(dataArray);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [commonDetails]);
  useEffect(() => {
    GetCategories();
  }, [GetCategories]);
  return (
    <Box
      onClick={handleOpenFramework}
      sx={{
        border: '1px solid #CCCCCC',
        width: 1,
        height: 1,
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        cursor: 'default'
      }}>
      <Box sx={{ py: 2, px: 3 }}>
        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center'
              }}>
              <Typography sx={{ fontWeight: 500, lineHeight: '24px' }}>
                Framework Compliance
              </Typography>
            </Box>
            {isDragable && (
              <>
                <EditIcon className="grid-edit-icon" fontSize="small" />
                {currentGrid?.i === 'dashboard-gap-analysis-v-2-0-1' ? (
                  <DropdownMenu
                    setAnchorEl={setAnchorEl}
                    anchorEl={anchorEl}
                    setWidgetSize={setWidgetSize}
                  />
                ) : null}
              </>
            )}
            <FrameWorkPopup
              data={frameworkCompliance}
              isGap={false}
              setOpenFramework={setOpenFramework}
              openFramework={openFramework}
              handleCloseFramework={handleCloseFramework}
            />
          </Box>
        </Box>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 1 }}>
            {loading && <ComplianceSkeleton />}
            {!loading &&
              frameworkCompliance
                ?.filter((el: any, index: any) => index < 3)
                ?.map((el: any, index: any) => (
                  <Box
                    key={index + 1}
                    sx={{
                      mb: 1.5,
                      border: '1px solid #EAECF0',
                      p: 2,
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <Box>
                      <Box
                        sx={{
                          border: '2px solid #B7B7B7',
                          borderRadius: '50%',
                          width: '50px',
                          height: '50px',
                          overflow: 'hidden'
                        }}>
                        <img src={el?.image} style={{ width: '100%', height: '100%' }} />
                      </Box>
                    </Box>
                    <Box sx={{ width: '100%', ml: 1 }}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        gap={1}
                        alignItems={'center'}>
                        <Typography sx={{ fontWeight: 600, mb: 1 }}>{el.name}</Typography>
                        <Typography variant="caption" sx={{ mb: 1 }}>
                          {el?.version}
                        </Typography>
                      </Stack>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                          justifyContent: 'space-between'
                        }}>
                        <Box
                          sx={{
                            width: 0.85,
                            border: '1px solid #B3DAFF',
                            borderRadius: '10px',
                            p: 0.5,
                            backgroundColor: '#F2F9FF'
                          }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={(el.completed / el?.total) * 100}
                            sx={{
                              [`.${linearProgressClasses.bar}`]: {
                                borderRadius: 5,
                                backgroundColor:
                                  (el.completed / el?.total) * 100 <= 20
                                    ? '#F87171'
                                    : (el.completed / el?.total) * 100 >= 21 &&
                                      (el.completed / el?.total) * 100 <= 79
                                    ? '#FBBF24'
                                    : '#34D399'
                              }
                            }}
                          />
                        </Box>

                        <Typography variant="body2" sx={{ color: '#696969', whiteSpace: 'nowrap' }}>
                          {Math.round((el.completed / el?.total) * 100)}% <span> </span>
                          {`(${el.completed?.toLocaleString()}/${el?.total?.toLocaleString()})`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default GapFrameWorkWidget;
