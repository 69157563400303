export enum SourceTypeEnums {
  gap = 'gap',
  vuln = 'vuln',
  pen = 'pen',
  endpoint = 'endpoint',
  attack_surface = 'attack_surface',
  network_protection = 'network_protection',
  siem = 'siem',
  audit = 'audit',
  email = 'email',
  user = 'user_management'
}

export const SOURCE = [
  { key: 'Asset Management (AM)', value: 'asset_management' },
  { key: 'Attack Surface Management (ASM)', value: 'attack_surface' },
  {
    key: 'Audit/Assessment (A/A)',
    value: 'audit'
  },
  { key: 'Compliance (CE)', value: 'gap' },
  { key: 'Email Protection (EP)', value: 'email' },
  { key: 'Endpoint Detection & Response (EDR)', value: 'endpoint' },
  { key: 'Identity & Access Management (IAM)', value: 'identity_and_access_management' },
  { key: 'Network Detection & Response (NDR)', value: 'network_protection' },
  { key: 'Penetration Testing (PT)', value: 'pen' },
  { key: 'Professional Services (PS)', value: 'professional_services' },
  { key: 'Project Management (PM)', value: 'project_management' },
  { key: 'Security Awareness Training (SAT)', value: 'security_awareness_training' },
  { key: 'Security Information & Event Management (SIEM)', value: 'siem' },
  { key: 'User Management (UM)', value: 'user_management' },
  { key: 'Vendor Risk Management (VRM)', value: 'vendor_risk_management' },
  { key: 'Vulnerability Management (VM)', value: 'vuln' }
];

export const SourceNameFilter = [
  { value: 'attack_surface', key: 'ASM', tooltip: 'Attack Surface Management' },
  {
    value: 'audit',
    key: 'A/A',
    tooltip: 'Audit/Assessment'
  },
  { value: 'email', key: 'EP', tooltip: 'Email Protection' },

  { value: 'endpoint', key: 'EDR', tooltip: 'Endpoint Detection & Response' },
  { value: 'gap', key: 'CE', tooltip: 'Compliance' },
  { value: 'network_protection', key: 'NDR', tooltip: 'Network Detection & Response' },
  { value: 'pen', key: 'PT', tooltip: 'Penetration Testing' },
  { value: 'siem', key: 'SIEM', tooltip: 'Security Information & Event Management' },
  { value: 'vuln', key: 'VM', tooltip: 'Vulnerability Management' },
  { value: '', key: 'All Sources', tooltip: '' }
];
