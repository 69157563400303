import { CustomIcon } from '@jibin/common/icons/AssetsIcons/CustomIcon';
import DomainIcon from '@jibin/common/icons/AssetsIcons/Domain';
import IpIcon from '@jibin/common/icons/AssetsIcons/IpIcon';
import MachineIcon from '@jibin/common/icons/AssetsIcons/MachineIcon';
import MacAddressIcon from '@jibin/common/icons/AssetsIcons/macAddressIcon';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import { TickMarkBlue } from '@jibin/common/icons/Tickmark/Tickmark';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import momentTimezone from 'moment-timezone';
import { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const provider = {
  cs: 'Falcon',
  cb: 'CarbonBlack',
  so: 'SentinelOne'
};
const EndpointProtectionRow = ({ el, assignees, engineer, onClick }) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [rowData, setRowData] = useState({} as any);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setRowData(null);
    setAnchorEl(null);
  };
  const findLetestRemediation = (remediations: any) => {
    const sortRemediation = remediations.sort((a: any, b: any) => {
      return a?.created_at < b?.created_at ? 1 : -1;
    });
    return sortRemediation[0];
  };
  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#10B981';
        break;
      case 'inactive':
        color = '#B7B7B7';
        break;
      case 'errors':
        color = '#EF4444';
        break;
      case 'bypass':
      case 'pending':
      case 'pending install':
      case 'pending update':
      case 'deregistered':

      case 'sensor out of date':
        color = '#FDE68A';
        break;

      case 'quarantine':
        color = '#F59E0B';
        break;
      case 'all':
        color = '#436AF3';
        break;
      case 'other':
        color = '#FDE68A';
        break;
    }

    return color;
  };
  const navigate = useNavigate();
  return (
    <TableRow
      id={el.device_id}
      sx={{
        cursor: 'default',
        '.MuiTableCell-root': {
          //backgroundColor: '#FFFFFF ',
          px: '20px',

          borderBottom: '1px solid  #CCCCCC',
          borderTopRadius: '4px',
          borderTop: '1px solid  #CCCCCC',
          '&:last-child': {
            borderRight: '1px solid  #CCCCCC',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px'
          },
          '&:first-child': {
            borderLeft: '1px solid  #CCCCCC',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px'
          }
        }
      }}>
      <TableCell align="left">
        <Typography variant="caption"> {el?.vendor}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="caption">{el?.tool_name}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="caption">{el.sensor}</Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: 250 }} align="left">
        <Box>
          <Box sx={{ display: 'flex' }}>
            {el?.asset?.external_ip && (
              <Tooltip arrow placement={'top'} title={el?.asset?.external_ip}>
                <IconButton>
                  <IpIcon />
                </IconButton>
              </Tooltip>
            )}
            {el?.asset?.internal_ip && (
              <Tooltip arrow placement={'top'} title={el?.asset?.internal_ip}>
                <IconButton>
                  <IpIcon />
                </IconButton>
              </Tooltip>
            )}
            {el?.asset?.mac_address && (
              <Tooltip arrow placement={'top'} title={el?.asset?.mac_address}>
                <IconButton>
                  <MacAddressIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }} align="left">
        <Typography variant="caption">{el.os} </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="caption">
          {momentTimezone(el?.last_check_in).tz('utc').format('DD MMM YYYY hh:mm A').toUpperCase()}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="caption">{el.user_name} </Typography>
      </TableCell>
      <TableCell align="left">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: '14px',
              height: '14px',
              backgroundColor: renderColorBackGround(el.status),
              borderRadius: '50%'
            }}></Box>
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            {' '}
            {el.status}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default EndpointProtectionRow;
