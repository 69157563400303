import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import CrossCancle from '../../icons/CrossCancle/CrossCancle';
import List from '@mui/material/List';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  Autocomplete,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  ListSubheader,
  ListSubheaderProps,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import { shallowEqual, useSelector } from 'react-redux';
import Style from '@jibin/common/style/Styles';
import { fontSize } from '@mui/system';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import JiraIcon from '@jibin/common/icons/JiraIcon';
import { EllipsisContent } from '../EllipsisContent';
import { SuccessConfirmationUpadated } from '@jibin/common/components/ConformationModel/SuccessConfirmationUpadated';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { RemediationApi } from 'src/Pages/Remediation/Remediation.Api';
import moment from 'moment';
import {
  SOURCE,
  SourceNameFilter,
  SourceTypeEnums
} from '@jibin/common/utils/enums/SourceTypeEnums';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { LoadingButton } from '@mui/lab';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { GetAssignImage } from '@jibin/common/utils/Common';
import LinkIcon from '@jibin/common/icons/LinkIcon';
import { useNavigate } from 'react-router-dom';
import CopyUrl from '../ConformationModel/CopyUrl';
import { useFormik } from 'formik';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { NoSearchDataAvailable } from './../../../app/src/Pages/Common/NoDataAvailable';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
var debounce = require('lodash.debounce');

export interface RemidiationPopUpProp {
  remediation_uuid: any;
  el: any;
  closePopup(event: React.KeyboardEvent | React.MouseEvent): void;
  isEngineer: boolean;
  setIsRefresh: any;
  isRefresh: any;
  isGap: any;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const RemidiationPopUpList: FC<RemidiationPopUpProp> = (prop) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [remediationEditData, setRemediationEditData] = useState({
    issue_name: prop?.el?.issue_name,
    description: prop?.el?.description,
    recommendation: prop?.el?.recommendation,
    severity: prop?.el?.severity,
    status: prop?.el?.status,
    asset:
      prop?.el?.asset?.custom ||
      prop?.el?.asset?.ip ||
      prop?.el?.asset?.mac ||
      prop?.el?.asset?.machine ||
      prop?.el?.asset?.hostname?.join(', '),
    source: prop?.el?.source,
    tags: prop?.el?.tags?.map((el) => el.tag_name) || [],
    note: prop?.el?.note
  });
  const [files, setFiles] = useState([]);
  const [assetWithValue, setAssetWithValue] = useState<any>({});
  const [assetsTagList, setAssetTagList] = useState([]);

  const [fileData, setFileData] = useState([] as any);
  const validURL = (str) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  };

  const [value, setValue] = React.useState(0);
  const [resolve, setResolve] = useState(false);
  const [assign, setAssign] = useState(null);
  const [remediationHistory, setRemediationHistory] = useState([]);
  const [severty, setSeverty] = useState('medium');
  const [filelist, setFilesList] = useState([]);
  const [actionTaken, setActionTaken] = useState('');
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [skelton, setSkeleton] = useState(false);
  const [dataPosted, setDataPosted] = useState(false);
  const [isAssignButtonLoading, setIsAssignButtonLoading] = useState(false);
  const [isEditButtonLoading, setIsEditButtonLoading] = useState(false);
  const [isEvidenceButtonLoading, setIsEvidenceButtonLoading] = useState(false);
  const [currentAssign, setCurrentAssign] = useState<any>({});
  const [tagOptions, setTagOptions] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [deleteOption, setDeleteOption] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [tagErrorMessage, setTagErrorMessage] = useState({ maxLimit: '', tagLimit: '' });
  const [isComplete, setIsComplete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const setRemediationInitialData = () => {
    const assets: any = [];
    const assetsTag: any = [];

    if (rowData?.asset?.custom) {
      assets.push(rowData?.asset?.custom);
    }
    if (rowData?.asset?.ip) assets.push(rowData?.asset?.ip);
    if (rowData?.asset?.mac) assets.push(rowData?.asset?.mac);
    if (rowData?.asset?.machine) assets.push(rowData?.asset?.machine);
    if (rowData?.asset?.hostname?.length > 0) assets.push([...rowData?.asset?.hostname]);

    if (rowData?.asset?.hostname?.length > 0) assets.push([...rowData?.asset?.hostname]);
    for (const key in rowData?.asset) {
      assetsTag.push(key);
    }
    setAssetWithValue(rowData?.asset);
    setAssetTagList(assetsTag);

    const updatedData = {
      issue_name: rowData?.issue_name,
      description: rowData?.description,
      recommendation: rowData?.recommendation,
      severity: rowData?.severity,
      status: rowData?.status,
      asset: assets.join(', '),
      source: rowData?.source,
      tags: rowData?.tags?.map((el) => el.tag_name) || [],
      note: rowData?.note
    };

    setRemediationEditData(updatedData);
  };

  const reopenRemediation = async (e: any) => {
    try {
      const res = await RemediationApi.ReopenRemediation(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        prop?.el.remediation_uuid,
        {
          status: 'Reopened'
        }
      );
      prop?.setIsRefresh(!prop?.isRefresh);
    } catch (error) {}
  };
  const handlePostData = useCallback(
    debounce(
      async (e: any) => {
        const files = [];
        for (let i = 0; i < fileData.length; i++) {
          files.push({
            file_name: fileData[i].name,
            file_size: fileData[i].size,
            file_type: fileData[i].type
          });
        }
        let data = { action: { description: actionTaken }, files };

        try {
          setIsEvidenceButtonLoading(true);
          const res = await RemediationApi.PostActionData(
            commonDetails.data.user_uuid,
            commonDetails.data.company.company_uuid,
            prop?.el.remediation_uuid,
            data
          );

          await RemediationApi.UploadDocuments(res, fileData);
          setResolve(false);
          prop?.closePopup(e);
          prop?.setIsRefresh(!prop?.isRefresh);
          setFileData([]);
        } catch (err) {
          console.log(err);
        } finally {
          setTimeout(() => {
            setIsEvidenceButtonLoading(false);
          }, 1500);
        }
      },
      500,
      true
    ),
    [resolve, actionTaken, fileData, open]
  );

  const GetTags = useCallback(async () => {
    const query: any = {
      include_non_associated_tags: true
    };
    try {
      const res = await RemediationApi.TagsList(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        new URLSearchParams(query).toString()
      );
      if (res.data.data) {
        const TagList = res.data.data.map((el) => el);
        setTagOptions(TagList);
      }
    } catch (error) {}
  }, [commonDetails]);

  function MyListSubheader(props: ListSubheaderProps) {
    return <ListSubheader {...props} />;
  }

  MyListSubheader.muiSkipListHighlight = true;
  function validateString(inputString) {
    if (!/^[a-zA-Z_\s]+$/.test(inputString)) {
      return false;
    }

    if (/^\d+$/.test(inputString)) {
      return false;
    }

    return true;
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue == 2) {
      GetRemediationHistory();
    }
  };
  const handleTagChange = (event, value) => {
    var newArray = value.filter(function (elem, pos) {
      return value.indexOf(elem) == pos;
    });
    if (tagErrorMessage.maxLimit) {
      setTagErrorMessage({
        ...tagErrorMessage,
        maxLimit: ''
      });
    } else {
      setRemediationEditData({
        ...remediationEditData,
        tags: newArray
      });
      maketags(newArray);
    }
  };
  function containsWhitespaceString(arr) {
    for (const str of arr) {
      if (/^\s*$/.test(str)) {
        return true;
      }
    }
    return false;
  }
  const areTagsEqual = (tag1, tag2) => tag1.trim().toLowerCase() === tag2.trim().toLowerCase();
  const handleChangeSeverty = (e: any) => {
    setSeverty(e.target.value);
  };
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  const handleImageUpload = (e, files) => {
    stopReload(e);

    if (!e) return;

    setFileData([...fileData, ...files]);
  };
  const handleAssige = (event: any, value: any) => {
    setAssign(value);
  };

  const [rowData, setRowData] = useState({} as any);
  const [assetValue, setAssetValue] = useState([]);

  const GetRemidiationDetails = useCallback(async () => {
    if (!assign) {
      setSkeleton(true);
    }

    try {
      const res = await RemediationApi.GetRemediatios(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        ref.current
      );
      setFacilities(res?.data?.data?.tags);
      const assets: any = [];
      const assetsLabel: any = [];
      const assetsTag: any = [];
      if (res.data?.data?.asset?.custom) {
        assets.push(res.data?.data?.asset?.custom);
      }
      if (res.data?.data?.asset?.ip) {
        assets.push(res.data?.data?.asset?.ip);
      }
      if (res.data?.data?.asset?.mac) assets.push(res.data?.data?.asset?.mac);
      if (res.data?.data?.asset?.machine) assets.push(res.data?.data?.asset?.machine);
      if (res.data?.data?.asset?.hostname?.length > 0)
        assets.push([...res.data?.data?.asset?.hostname]);
      for (const key in res.data?.data?.asset) {
        assetsTag.push(key);
      }
      if (res.data?.data?.asset?.['ip']) {
        formik.setFieldValue('ip', res.data?.data?.asset?.ip);
        assetsLabel.push('ip');
      } else {
        formik.setFieldValue('ip', '');
      }

      if (res.data?.data?.asset?.['mac']) {
        formik.setFieldValue('mac', res.data?.data?.asset?.mac);
        assetsLabel.push('mac');
      } else {
        formik.setFieldValue('mac', '');
      }
      if (res.data?.data?.asset?.['custom']) {
        formik.setFieldValue('custom', res.data?.data?.asset?.custom);
        assetsLabel.push('custom');
      } else {
        formik.setFieldValue('custom', '');
      }
      if (res.data?.data?.asset?.['machine']) {
        formik.setFieldValue('machine', res.data?.data?.asset?.machine);
        assetsLabel.push('machine');
      } else {
        formik.setFieldValue('machine', '');
      }
      if (res.data?.data?.asset?.['hostname']) {
        formik.setFieldValue('hostname', res.data?.data?.asset?.hostname?.join(','));
        assetsLabel.push('hostname');
      } else {
        formik.setFieldValue('hostname', '');
      }
      setAssetWithValue(res.data?.data?.asset);
      setAssetTagList(assetsTag);
      handleChangeAsset({ target: { value: assetsTag } }, false);
      setRemediationEditData({
        issue_name: res.data?.data?.issue_name,
        description: res.data?.data?.description,
        recommendation: res.data?.data?.recommendation,
        severity: res.data?.data?.severity,
        status: res.data?.data?.status,
        asset: assets.join(', '),
        source: res.data?.data?.source,
        tags: res.data?.data?.tags?.map((el) => el.tag_name) || [],
        note: res.data?.data?.note
      });

      formik.setFieldValue('tags', res.data?.data?.tags);
      formik.setFieldValue('issue_name', res.data?.data?.issue_name);
      formik.setFieldValue('description', res.data?.data?.description);
      formik.setFieldValue('severity', res.data?.data?.severity);
      formik.setFieldValue('recommendation', res.data?.data?.recommendation);
      formik.setFieldValue('source', res.data?.data?.source);
      formik.setFieldValue('Assets', assetsLabel.length > 0 ? assetsLabel : ['']);
      formik.setFieldValue('note', res?.data?.data?.note);

      if (assetsLabel.length > 0 && res.data?.data?.issue_name && res.data?.data?.description)
        setIsComplete(false);

      if (res.data?.data?.owner.user_uuid) {
        setCurrentAssign({
          company_uuid: res.data?.data?.owner?.company?.company_uuid,
          first_name: res.data?.data?.owner.first_name,
          last_name: res.data?.data?.owner.last_name,
          user_uuid: res.data?.data?.owner.user_uuid,
          ico: res.data?.data?.owner.ico
        });
        setAssign({
          company_uuid: res.data?.data?.owner?.company?.company_uuid,
          first_name: res.data?.data?.owner.first_name,
          last_name: res.data?.data?.owner.last_name,
          user_uuid: res.data?.data?.owner.user_uuid,
          ico: res.data?.data?.owner.ico
        });
        if (res?.data?.data?.actions_taken?.length > 0) {
          setActionTaken(res?.data?.data?.actions_taken[0]?.description || '');
        }
      }

      setSkeleton(false);

      if (res.data?.data?.actions_taken) {
        const allFiles = res.data?.data?.actions_taken.map((item) => item.files).flat();
        setFiles(allFiles);
      }
      setRowData(res.data?.data);

      setAssetValue(assets);
    } catch (err) {
      console.log(err);
      setSkeleton(false);
    }
  }, [commonDetails, prop?.isRefresh]);

  const ref = useRef('');
  const [assignees, setAssignees] = useState({} as any);
  const GetAssignees = useCallback(async () => {
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      renderOption(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [commonDetails]);
  const [option, setOption] = useState([]);
  useEffect(() => {
    setActionTaken('');
    setEdit(false);
    setResolve(false);
    ref.current = prop?.el.remediation_uuid;

    if (ref.current) {
      setValue(0);

      GetRemidiationDetails();
      GetAssignees();
      GetTags();
      formik.resetForm();
    }

    const assets: any = [];

    if (prop?.el?.asset?.custom) assets.push(prop?.el?.asset?.custom);
    if (prop?.el?.asset?.ip) assets.push(prop?.el?.asset?.ip);
    if (prop?.el?.asset?.mac) assets.push(prop?.el?.asset?.mac);
    if (prop?.el?.asset?.machine) assets.push(prop?.el?.asset?.machine);
    if (prop?.el?.asset?.hostname?.length > 0) assets.push([...prop?.el?.asset?.hostname]);
    if (prop?.el?.asset?.['ip']) {
      formik.setFieldValue('ip', prop?.el?.asset?.ip);
    } else {
      formik.setFieldValue('ip', '');
    }

    if (prop?.el?.asset?.['mac']) {
      formik.setFieldValue('mac', prop?.el?.asset?.mac);
    } else {
      formik.setFieldValue('mac', '');
    }
    if (prop?.el?.asset?.['custom']) {
      formik.setFieldValue('custom', prop?.el?.asset?.custom);
    } else {
      formik.setFieldValue('custom', '');
    }
    if (prop?.el?.asset?.['machine']) {
      formik.setFieldValue('machine', prop?.el?.asset?.machine);
    } else {
      formik.setFieldValue('machine', '');
    }
    if (prop?.el?.asset?.['hostname']) {
      formik.setFieldValue('hostname', prop?.el?.asset?.hostname.join(','));
    } else {
      formik.setFieldValue('hostname', '');
    }
    formik.setFieldValue('tags', prop?.el?.tags);
    formik.setFieldValue('issue_name', prop?.el?.issue_name);
    formik.setFieldValue('description', prop?.el?.description);
    formik.setFieldValue('severity', prop?.el?.severity);
    formik.setFieldValue('recommendation', prop?.el?.recommendation);
    formik.setFieldValue('note', prop?.el?.note);

    return () => {
      setAssign(null);
      setCurrentAssign({});
      setFileData([]);
    };
  }, [GetRemidiationDetails, GetAssignees, prop]);

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    setFileData([...fileData, ...e.target.files]);
  };

  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };

  const handleRemoveFile = (i) => {
    const upadatedFileData = fileData.filter((el, index) => index != i);
    setFileData(upadatedFileData);
  };

  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FCD34D';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const handleChangeAcctionTaken = (e: any) => {
    setActionTaken(e.target.value);
  };
  const renderOption = (assignees) => {
    const options = assignees.data.map((option) => {
      const firstLetter = option.company_name.toUpperCase();
      const company_uuid = option.company_uuid;

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, company_uuid: company_uuid, ...el };
      });
      return data;
    });

    const optionarray = options.concat.apply([], options).flat();
    setOption(optionarray);
  };
  const GetRemediationHistory = async () => {
    try {
      const res = await RemediationApi.GetRemediatiosHistory(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        prop?.el.remediation_uuid || ''
      );

      setRemediationHistory(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getFile = async (element: any) => {
    const res = await RemediationApi.GetFiles(element.file_url.slice(1));

    const urlName = res?.data?.data;
    const a = document.createElement('a');
    a.href = urlName;
    a.target = '_blank';
    //a.download = `downloadfile.${res.headers['content-type'].split('/')[1]}`;
    a.download = `${element.file_name}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const DeleteActionTakenFile = async (element: any, action_taken_id: string) => {
    try {
      const res = await RemediationApi.deleteRemediationActionFile(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        prop?.el.remediation_uuid || '',
        action_taken_id,
        element.file_uuid
      );
      GetRemidiationDetails();
    } catch (error) {}
  };

  const validate = (value: any) => {
    if (!value.issue_name || !value.description || !value.source || !validateAsset(value)) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  };
  const setFormikValue = (val: any) => {
    if (val.includes('ip')) {
      formik.setFieldValue('ip', formik.values?.ip);
    } else {
      formik.setFieldValue('ip', '');
    }

    if (val.includes('mac')) {
      formik.setFieldValue('mac', formik.values?.mac);
    } else {
      formik.setFieldValue('mac', '');
    }
    if (val.includes('custom')) {
      formik.setFieldValue('custom', formik.values?.custom);
    } else {
      formik.setFieldValue('custom', '');
    }
    if (val.includes('machine')) {
      formik.setFieldValue('machine', formik.values?.machine);
    } else {
      formik.setFieldValue('machine', '');
    }
    if (val.includes('hostname')) {
      formik.setFieldValue('hostname', formik.values?.hostname);
    } else {
      formik.setFieldValue('hostname', '');
    }
    validate(formik.values);
  };

  const validateAsset = (val: any) => {
    if (
      formik?.values?.Assets?.[0] == '' ||
      (formik?.values?.Assets?.includes('ip') && !val?.ip) ||
      (formik?.values?.Assets?.includes('hostname') && !val?.hostname) ||
      (formik?.values?.Assets?.includes('machine') && !val?.machine) ||
      (formik?.values?.Assets?.includes('mac') && !val?.mac) ||
      (formik?.values?.Assets?.includes('custom') && !val?.custom)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const formik = useFormik({
    initialValues: {
      // Assets: ['']
    },
    validate,
    onSubmit: async () => {
      await handleSubmitEditData();
    }
  });

  const handleChangeAsset = (event: any, isChange: any) => {
    let {
      target: { value, name }
    } = event;

    const filterValue = value.filter((el: any) => el != '');
    if (isChange) {
      setFormikValue(filterValue);
    }
    if (filterValue.length == 0) {
      const item = typeof value === 'string' ? filterValue.split(',') : [''];
      formik.setFieldValue('Assets', item);
      setIsComplete(true);
    } else {
      const item = typeof value === 'string' ? filterValue.split(',') : filterValue;
      formik.setFieldValue('Assets', item);
    }

    if (value.length > formik?.values?.Assets?.length) {
      setIsComplete(true);
    }
  };

  const handleSubmitEditData = async () => {
    if (edit) {
      try {
        setIsEditButtonLoading(true);

        const updatedData = {
          ...remediationEditData,
          issue_name: formik.values.issue_name,
          description: formik.values.description,
          source: formik.values.source,
          severity: formik.values.severity,
          recommendation: formik.values.recommendation,
          tags: facilities,
          asset: {
            ip: formik.values?.ip,
            mac: formik.values?.mac,
            custom: formik.values?.custom,
            machine: formik.values?.machine,
            hostname: formik.values?.hostname ? formik.values?.hostname.split(',') : []
          },
          note: formik.values.note
        };

        const res = await RemediationApi.PutRemediationData(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          prop?.el.remediation_uuid || '',
          updatedData
        );
        setEdit(false);
        prop?.setIsRefresh(!prop?.isRefresh);
        GetRemidiationDetails();
        GetTags();
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          setIsEditButtonLoading(false);
        }, 1000);
      }
    } else {
      setResolve(true);
    }
  };
  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels?.join(', ');
  };
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FCD34D';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const AssetList = [
    { key: 'Select Asset', value: '', placeholder: '' },
    { key: 'Domain/URL', value: 'hostname', placeholder: 'Eg: www.redesign-group.com' },
    { key: 'IP Address', value: 'ip', placeholder: 'Eg: 192.158.1.38' },
    { key: 'MAC Address', value: 'mac', placeholder: 'Eg: 00-B0-D0-63-C2-26' },
    {
      key: 'Machine/Server Name',
      value: 'machine',
      placeholder: 'Eg: Teletraan'
    },
    { key: 'Other', value: 'custom', placeholder: 'Type here' }
  ];
  const renderSelectGroup = (company) => {
    const items = company?.users?.map((p, index) => {
      return (
        <MenuItem key={index} value={p.user_uuid}>
          <Box>
            <Typography
              sx={{
                ...Style.versionsCard.infoRound,
                width: '24px',
                height: '24px',
                fontSize: '14px'
              }}>
              {' '}
              {p.first_name.charAt(0) + '' + p.last_name.charAt(0)}
            </Typography>
          </Box>
          <Typography variant="body2">{p.first_name + ' ' + p.last_name}</Typography>
        </MenuItem>
      );
    });
    return [
      <ListSubheader>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: '600', px: 2, py: 1 }}>
            {' '}
            {company.company_name}
          </Typography>
        </Box>
      </ListSubheader>,
      items
    ];
  };

  const assignUser = async () => {
    try {
      setIsAssignButtonLoading(true);
      let data = {
        owner_company_uuid: assign.company_uuid,
        owner_user_uuid: assign.user_uuid
      };

      if (!assign.company_uuid) {
        const ownerData = option.find((el) => el.user_uuid === assign.user_uuid);
        data.owner_company_uuid = ownerData?.company_uuid || '';
      }

      const res = await RemediationApi.patchAssignee(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        prop?.el.remediation_uuid || '',
        data
      );
      notifySuccess('User Assigned');
      GetRemidiationDetails();
      setEdit(false);
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setIsAssignButtonLoading(false);
      }, 1000);
    }
  };
  const RenderAssetList = (assest: any) => {
    let data = [];
    if (assest?.length > 0) {
      data = AssetList.filter((el: any) => {
        return el?.value && assest?.includes(el.value) && el;
      });
    } else {
      data = [];
    }

    return data;
  };
  const handleCreateJiraTicket = async (re_uuid: any) => {
    setIsLoading(true);
    try {
      const res = await RemediationApi.CreateRemediationJiraTicket(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        re_uuid,
        {}
      );
      GetRemidiationDetails();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const renderstatusColor = (status) => {
    var color = '#F87171';

    if (status == 'in_progress') {
      color = '#FDE68A';
    } else if (status == 'not_started') {
      color = '#FECACA';
    } else if (status == 'reopened') {
      color = '#FDE68A';
    } else {
      color = '#A7F3D0';
    }
    return color;
  };
  const getSource = (source: any) => {
    let sourceLabel = '';
    switch (source) {
      case SourceTypeEnums.gap:
        sourceLabel = 'Compliance';
        break;
      case SourceTypeEnums.vuln:
        sourceLabel = 'Vulnerability';
        break;
      case SourceTypeEnums.pen:
        sourceLabel = 'Penetration Test';
        break;
      case SourceTypeEnums.endpoint:
        sourceLabel = 'Endpoint';
        break;
      case SourceTypeEnums.attack_surface:
        sourceLabel = 'Attack Surface';
        break;
      case SourceTypeEnums.network_protection:
        sourceLabel = 'NDR';
        break;
      case SourceTypeEnums.siem:
        sourceLabel = 'SIEM';
        break;
      case SourceTypeEnums.email:
        sourceLabel = 'Email Protection';
        break;
      case SourceTypeEnums.audit:
        sourceLabel = 'Audit/Assessment';
        break;
    }
    return sourceLabel;
  };
  if (skelton) {
    return <Skeleton height={100} width={520} />;
  }

  const handleConfirmDelete = (deleteFacility) => {
    const updatedFacility = remediationEditData.tags.filter((el, index) => el !== deleteFacility);
    setRemediationEditData({
      ...remediationEditData,
      tags: updatedFacility
    });

    maketags(updatedFacility);
    setOpenDelete(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const maketags = (data: any) => {
    var facilities = [];

    data?.forEach((element) => {
      let facilitytype = tagOptions.filter((q) => q.tag_name == element);
      if (facilitytype?.length > 0) {
        facilities.push({
          tag_name: element,
          tag_uuid: facilitytype[0].tag_uuid
        });
      } else {
        facilities.push({
          tag_name: element
        });
      }
    });

    setFacilities(facilities);
  };
  const handleClickOpenDelete = (option, value) => {
    setDeleteOption(option);
    setRemediationEditData({
      ...remediationEditData,
      tags: value
    });
    setTagErrorMessage({
      ...tagErrorMessage,
      tagLimit: ''
    });

    // formik.setFieldValue('tags', value);
    setOpenDelete(true);
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const GetFullSourceName = (source) => {
    const sourceData = SourceNameFilter.find((el) => el.value === source);
    if (sourceData?.key) {
      return sourceData?.key;
    } else {
      return source;
    }
  };

  const handleToggleIndex = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      {rowData?.status && (
        <Box
          sx={{
            width: { md: 520, xs: 300 },
            height: '100vh' || value == 1 || value == 2 ? '100vh' : 'auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
          }}
          role="presentation">
          <List sx={{ p: 0 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 1,
                justifyContent: 'space-between',
                backgroundColor: '#003260',
                px: 2.5,
                alignItems: 'center',
                py: 1
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',

                  width: 1,
                  alignItems: 'center'
                }}>
                <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', my: 1.25 }}>
                  Cybersecurity Domain: {getSource(rowData?.source)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CopyUrl
                    title="Copy and share this page"
                    handleClose={() => {}}
                    icon={<Shareicon />}
                    mainBtnTitle={''}
                    url={`${window.location.host}${PageRouteConstants.Remediation}?remediation_uuids=${rowData?.remediation_uuid}`}
                    handleConfirm={() => {
                      navigator.clipboard.writeText(
                        `${window.location.host}${PageRouteConstants.Remediation}?remediation_uuids=${rowData?.remediation_uuid}`
                      );
                      // window.location.href

                      notifySuccess('Copied');
                    }}
                  />

                  <Box
                    onClick={(event) => {
                      prop?.closePopup(event);
                    }}>
                    <CrossCancle
                      sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}>
                <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                  Discovered: {moment(rowData?.created_at).format('DD MMM YYYY').toUpperCase()}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: renderstatusColor(rowData?.status),
                    py: '6px',
                    px: '30px',
                    fontSize: '12px',
                    borderRadius: '100px'
                  }}>
                  <Typography variant="caption">
                    {' '}
                    {rowData?.status == 'in_progress' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#FBBF24',
                                    borderRadius: '50%'
                                  }}></Box> */}
                        <Typography variant="caption">In Progress</Typography>
                      </Box>
                    ) : rowData?.status == 'not_started' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#EF4444',
                                    borderRadius: '50%'
                                  }}></Box> */}
                        <Typography variant="caption">Not Started</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#10B981',
                                    borderRadius: '50%'
                                  }}></Box> */}
                        <Typography variant="caption">
                          {rowData?.status.charAt(0).toUpperCase() + '' + rowData?.status.slice(1)}
                        </Typography>
                      </Box>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </List>

          <Box sx={{ flex: 1 }}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    sx={{ '.Mui-selected': { textDecoration: 'underline' }, minHeight: '0px' }}
                    TabIndicatorProps={{ sx: { display: 'none' } }}
                    onChange={handleChange}
                    aria-label="basic tabs example">
                    <Tab
                      sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                      label="Remediation"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Files"
                      sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="History"
                      sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {edit ? (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                          Criticality Level:
                        </Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={formik.values.severity || 'medium'}
                            name="severity"
                            defaultValue={formik.values.severity}
                            onChange={formik.handleChange}
                            displayEmpty
                            sx={{
                              '.MuiSelect-select': {
                                py: 1,
                                display: 'flex',
                                alignItems: 'center'
                              },
                              '&.MuiInputBase-root': {
                                backgroundColor: renderColorBackGround(formik.values.severity)
                              },
                              borderRadius: '100px'
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="critical">
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                              </Box>
                              <Typography variant="body2">Critical</Typography>
                            </MenuItem>
                            <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="high">
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                              </Box>
                              <Typography variant="body2">High</Typography>
                            </MenuItem>
                            <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'medium'}>
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                              </Box>
                              <Typography variant="body2">Medium</Typography>
                            </MenuItem>

                            <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'low'}>
                              {' '}
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                              </Box>
                              <Typography variant="body2">Low</Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ my: 1 }}>
                        <Box>
                          <List sx={{ py: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Cybersecurity Domain
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <Select
                                  size="small"
                                  displayEmpty
                                  fullWidth
                                  disabled={prop?.isGap}
                                  id="type"
                                  sx={{
                                    fontSize: '14px',
                                    color: '#696969',
                                    minWidth: { xxs: 0, md: '188px' },
                                    backgroundColor: '#FFFFFF'
                                  }}
                                  defaultValue=""
                                  name="source"
                                  value={formik.values.source}
                                  onChange={formik.handleChange}
                                  inputProps={{ 'aria-label': 'Without label' }}>
                                  <MenuItem value="" sx={{ display: 'none' }}>
                                    Select Cybersecurity Domain
                                  </MenuItem>
                                  {SOURCE?.map((el: any) => (
                                    <MenuItem sx={{ fontSize: '14px' }} value={el?.value}>
                                      {el?.key}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                            </Box>
                          </List>
                          <List sx={{ py: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Issue Name
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  onChange={formik.handleChange}
                                  fullWidth
                                  name="issue_name"
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  sx={{ fontSize: '14px' }}
                                  value={formik.values?.issue_name}
                                  defaultValue={rowData?.issue_name}
                                />
                              </Box>
                            </Box>
                          </List>
                          <List sx={{ py: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Asset Type
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <FormControl sx={{ width: 1 }}>
                                  <Select
                                    size="small"
                                    displayEmpty
                                    multiple
                                    fullWidth
                                    placeholder="Select Asset"
                                    name="Assets"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={formik.values.Assets}
                                    onChange={(e: any) => handleChangeAsset(e, true)}
                                    input={
                                      <OutlinedInput
                                        sx={{
                                          py: '5.5px'
                                        }}
                                      />
                                    }
                                    renderValue={(selected) => renderValue(selected, AssetList)}
                                    sx={{
                                      py: 0.35,
                                      width: 1,
                                      fontSize: '14px',
                                      color: '#696969',
                                      '.MuiSelect-select': {
                                        py: '5.5px'
                                      },
                                      minWidth: { xxs: 0, md: '150px' },
                                      backgroundColor: '#FFFFFF'
                                    }}
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    {AssetList.map((name, index) => (
                                      <MenuItem
                                        sx={{
                                          fontSize: '14px',
                                          display: !name.value ? 'none' : 'flex',

                                          py: 0,
                                          '.MuiMenuItem-root': {
                                            py: '0px'
                                          }
                                        }}
                                        key={name.key}
                                        autoFocus={index === 1}
                                        value={name.value}>
                                        <Checkbox
                                          checked={formik?.values?.Assets?.indexOf(name.value) > -1}
                                        />
                                        <ListItemText
                                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                                          primary={name.key}
                                        />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          </List>

                          <List sx={{ py: 1.5 }}>
                            {RenderAssetList(formik?.values?.Assets)?.map((el) => {
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',

                                    alignItems: 'flex-start',
                                    gap: 2
                                  }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '14px', fontWeight: 600 }}>
                                    {el?.key}
                                  </Typography>
                                  <Box sx={{ width: 1, pb: 1.5 }}>
                                    <TextField
                                      size="small"
                                      name="asset"
                                      fullWidth
                                      onChange={(e: any) => {
                                        formik.setFieldValue(el?.value, e.target.value);
                                      }}
                                      value={formik.values[el.value]}
                                      inputProps={{ sx: { fontSize: '14px' } }}
                                      variant="outlined"
                                      placeholder={el?.placeholder}
                                      sx={{ fontSize: '14px' }}
                                      defaultValue={assetWithValue?.[el.value]}
                                    />
                                  </Box>
                                </Box>
                              );
                            })}
                          </List>
                          <List sx={{ pt: 1.5, pb: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Remediation Description
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  name="description"
                                  fullWidth
                                  onChange={formik.handleChange}
                                  value={formik.values.description}
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  rows={7}
                                  multiline
                                  sx={{ fontSize: '14px' }}
                                  defaultValue={rowData?.description}
                                />
                              </Box>
                            </Box>
                          </List>

                          <List sx={{ pt: 1.5, pb: 0 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Recommendation
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  name="recommendation"
                                  fullWidth
                                  onChange={formik.handleChange}
                                  value={formik.values.recommendation}
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  rows={7}
                                  multiline
                                  sx={{ fontSize: '14px' }}
                                  defaultValue={formik.values?.recommendation}
                                />
                              </Box>
                            </Box>
                          </List>
                        </Box>
                      </Box>
                      {!resolve && rowData.status != 'resolved' ? (
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                              Remediation Owner
                            </Typography>

                            {!prop?.isEngineer && (
                              <Typography
                                variant="body2"
                                onClick={() =>
                                  setAssign({
                                    first_name: commonDetails.data.first_name,
                                    last_name: commonDetails.data.last_name,
                                    user_uuid: commonDetails.data.user_uuid,

                                    ico: commonDetails.data.ico,
                                    company_uuid: commonDetails.data.company.company_uuid
                                  })
                                }
                                sx={{
                                  fontWeight: 600,
                                  color: '#436AF3',
                                  mb: 2,
                                  mt: 1,
                                  cursor: 'pointer'
                                }}>
                                {commonDetails?.data?.group !=
                                  UserRoles.SERVICE_DELIVERY_COORDINATOR && 'Assign to me'}
                              </Typography>
                            )}
                          </Box>
                          <Box sx={{ display: 'flex', gap: 1.5 }}>
                            <FormControl sx={{ width: '100%' }}>
                              <Autocomplete
                                id="grouped-demo"
                                size="small"
                                value={assign}
                                fullWidth={true}
                                options={option}
                                onChange={(e, value) => handleAssige(e, value)}
                                groupBy={(option: any) => option.firstLetter}
                                getOptionLabel={(option: any) => {
                                  return `${option?.first_name} ${option?.last_name}`;
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ fontSize: '14px' }}
                                    {...params}
                                    placeholder="Assign to"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment:
                                        assign != null ? (
                                          <InputAdornment position="start">
                                            <Box>
                                              {assign.ico ? (
                                                <Box
                                                  sx={{
                                                    ...Style.versionsCard.infoRound,
                                                    width: '24px',
                                                    height: '24px',
                                                    fontSize: '14px'
                                                  }}>
                                                  <img
                                                    width={'100%'}
                                                    height="100%"
                                                    style={{
                                                      objectFit: 'cover',
                                                      borderRadius: '50%'
                                                    }}
                                                    src={GetAssignImage(
                                                      assign.firstLetter,
                                                      assign.ico
                                                    )}
                                                    alt=""
                                                  />
                                                </Box>
                                              ) : (
                                                <Typography
                                                  sx={{
                                                    ...Style.versionsCard.infoRound,
                                                    width: '24px',
                                                    height: '24px',
                                                    fontSize: '14px'
                                                  }}>
                                                  {assign?.first_name?.charAt(0) +
                                                    '' +
                                                    assign?.last_name?.charAt(0)}
                                                </Typography>
                                              )}
                                            </Box>
                                          </InputAdornment>
                                        ) : null
                                    }}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                    key={option.user_uuid}>
                                    {option.ico ? (
                                      <Box
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        <img
                                          width={'100%'}
                                          height="100%"
                                          style={{
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                          }}
                                          src={GetAssignImage(option.firstLetter, option.ico)}
                                          alt=""
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        {option?.first_name?.charAt(0) +
                                          '' +
                                          option?.last_name?.charAt(0)}
                                      </Typography>
                                    )}

                                    <Typography variant="body2">
                                      {' '}
                                      {option.first_name} {option.last_name}
                                    </Typography>
                                  </Box>
                                )}
                                renderGroup={(params) => (
                                  <Box key={params.key}>
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        padding: '10px 10px'
                                      }}>
                                      {params.group}
                                    </Typography>
                                    {params.children}
                                  </Box>
                                )}
                              />
                            </FormControl>
                            <LoadingButton
                              disabled={!assign}
                              loading={isAssignButtonLoading}
                              onClick={() => assignUser()}
                              sx={{
                                border: '1px solid #436AF3',
                                '&.Mui-disabled': {
                                  backgroundColor: '#EBEBEB',
                                  color: isAssignButtonLoading ? '#EBEBEB' : '#9D9D9D',
                                  border: '1px solid #DADADA'
                                }
                              }}>
                              Assign
                            </LoadingButton>
                          </Box>
                          <List sx={{ py: 3 }}>
                            <FormControl variant="outlined" fullWidth>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                                Tag
                              </Typography>
                              <Autocomplete
                                multiple
                                id="tags-filled"
                                freeSolo
                                filterOptions={(options, { inputValue }) => {
                                  const inputValueLower = inputValue.toLowerCase();
                                  return options.filter((option) =>
                                    option.toLowerCase().includes(inputValueLower)
                                  );
                                }}
                                options={tagOptions.map((el) => el.tag_name)}
                                value={remediationEditData.tags}
                                renderTags={(value, getTagProps) => {
                                  return value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                      onDelete={() => handleClickOpenDelete(option, value)}
                                    />
                                  ));
                                }}
                                onChange={(event: any, newValue, reason) => {
                                  if (tagErrorMessage.maxLimit) {
                                    setTagErrorMessage({
                                      ...tagErrorMessage,
                                      maxLimit: ''
                                    });
                                    return;
                                  }
                                  if (newValue.length > 10) {
                                    setTagErrorMessage({
                                      ...tagErrorMessage,
                                      tagLimit: 'You have reached the maximum limit of Tags'
                                    });
                                    return;
                                  } else if (containsWhitespaceString(newValue)) {
                                    setTagErrorMessage({
                                      ...tagErrorMessage,
                                      tagLimit: 'Can not add empty Tag'
                                    });
                                    return;
                                  } else {
                                    setTagErrorMessage({
                                      ...tagErrorMessage,
                                      tagLimit: ''
                                    });
                                  }
                                  if (
                                    reason == 'selectOption' ||
                                    reason == 'removeOption' ||
                                    reason == 'clear'
                                  ) {
                                    handleTagChange(event, newValue);
                                  } else {
                                    const optionToSelect = tagOptions.find((option) => {
                                      if (
                                        option?.tag_name?.toLowerCase() ===
                                        event.target.value.toLowerCase()
                                      ) {
                                        return option;
                                      }
                                    });
                                    if (optionToSelect) {
                                      handleTagChange(event, [
                                        ...remediationEditData.tags,
                                        optionToSelect.tag_name
                                      ]);
                                    } else {
                                      handleTagChange(event, newValue);
                                    }
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    error={
                                      tagErrorMessage.maxLimit || tagErrorMessage.tagLimit
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      tagErrorMessage.maxLimit
                                        ? tagErrorMessage.maxLimit
                                        : tagErrorMessage.tagLimit
                                    }
                                    {...params}
                                    onChange={(e: any) => {
                                      if (e.target.value) {
                                        if (e.target.value.startsWith('_')) {
                                          setTagErrorMessage({
                                            ...tagErrorMessage,
                                            maxLimit: `Please Don't Start the tag with (_)`
                                          });
                                        } else if (!validateString(e.target.value)) {
                                          setTagErrorMessage({
                                            ...tagErrorMessage,
                                            maxLimit: `Please don't add specific characters or numbers just Alphapetical Letters ONLY`
                                          });
                                        } else if (e.target.value.length > 50) {
                                          setTagErrorMessage({
                                            ...tagErrorMessage,
                                            maxLimit:
                                              'You have reached the maximum limit of characters per Tag'
                                          });
                                        } else {
                                          setTagErrorMessage({
                                            ...tagErrorMessage,
                                            maxLimit: ''
                                          });
                                        }
                                      } else {
                                        setTagErrorMessage({
                                          ...tagErrorMessage,
                                          maxLimit: ''
                                        });
                                      }
                                    }}
                                    placeholder={
                                      remediationEditData?.tags?.length > 0 ? '' : 'Add Tags'
                                    }
                                    // InputProps={{
                                    //   startAdornment: (
                                    //     <InputAdornment position="start">
                                    //       <SearchIcon sx={{ color: "grey.500" }} />
                                    //     </InputAdornment>
                                    //   ),
                                    // }}
                                  />
                                )}
                                sx={{
                                  '&.MuiAutocomplete-root': {
                                    '.MuiAutocomplete-input': {
                                      minWidth: '70px'
                                    },
                                    '.MuiOutlinedInput-root': {
                                      p: 0,
                                      pl: 1,
                                      pr: 1,
                                      '.MuiAutocomplete-clearIndicator': {
                                        margin: '0px -6px 0px 0px'
                                      }
                                    },
                                    '.MuiAutocomplete-tag': {
                                      m: '7px',
                                      bgcolor: 'common.lightBlue',
                                      border: 'none'
                                    },
                                    '.MuiAutocomplete-endAdornment': {
                                      right: '5px'
                                    }
                                  }
                                }}
                              />
                              <Dialog
                                fullWidth
                                maxWidth="sm"
                                open={openDelete}
                                onClose={handleCloseDelete}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">
                                <DialogTitle
                                  id="alert-dialog-title"
                                  sx={{
                                    px: { xs: 1, md: 2 },
                                    py: { xs: 0.5, md: 1 },
                                    bgcolor: 'error.red100',
                                    mb: { xs: 2, md: 3 }
                                  }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center'
                                    }}>
                                    <Typography
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'error.main',
                                        fontWeight: '600',
                                        maxWidth: 'calc(100% - 40px)',
                                        p: 1
                                      }}>
                                      <WarningIcon sx={{ mr: 1 }} />
                                      Confirm Deletion
                                    </Typography>
                                    <IconButton
                                      sx={{ color: 'text.primary' }}
                                      aria-label="close"
                                      onClick={handleCloseDelete}>
                                      <CloseIcon />
                                    </IconButton>
                                  </Box>
                                </DialogTitle>
                                <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                                  <DialogContentText
                                    id="alert-dialog-description"
                                    sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
                                    Are you sure do you want to delete this Tag?
                                  </DialogContentText>

                                  <DialogContentText
                                    id="alert-dialog-description"
                                    sx={{ color: 'text.primary' }}>
                                    {deleteOption}
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions
                                  sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                                  <Button
                                    variant="outlined"
                                    sx={{ minWidth: 100 }}
                                    onClick={handleCloseDelete}>
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{ minWidth: 100 }}
                                    color="error"
                                    onClick={() => handleConfirmDelete(deleteOption)}>
                                    {' '}
                                    Confirm
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </FormControl>
                          </List>
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                      <List sx={{ py: 1.5 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 2
                          }}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: '14px', fontWeight: 600 }}>
                            Note
                          </Typography>
                          <Box sx={{ width: 1 }}>
                            <TextField
                              size="small"
                              onChange={formik.handleChange}
                              fullWidth
                              name="note"
                              inputProps={{ sx: { fontSize: '14px' } }}
                              variant="outlined"
                              sx={{ fontSize: '14px' }}
                              value={formik.values?.note}
                              defaultValue={rowData?.note}
                            />
                          </Box>
                        </Box>
                      </List>
                    </Box>
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                          Criticality Level:
                        </Typography>
                        <FormControl sx={{ m: 1 }}>
                          <Box
                            sx={{
                              py: 1,
                              px: 2,

                              backgroundColor: renderColorBackGround(rowData?.severity),

                              borderRadius: '100px'
                            }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FlagIcon
                                sx={{
                                  fontSize: '12px',
                                  mr: 1,
                                  color: renderColor(rowData?.severity)
                                }}
                              />

                              <Typography variant="body2">
                                {' '}
                                {capitalizeFirstLetter(rowData?.severity)}
                              </Typography>
                            </Box>
                          </Box>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: '#F2F9FF',
                          padding: 2.5,
                          borderRadius: '8px',
                          my: 1
                        }}>
                        <Box>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '19px', fontWeight: 600 }}>
                                {rowData?.issue_name}
                              </Typography>
                            </Box>
                          </List>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px', fontWeight: 600 }}>
                                Cybersecurity Domain
                              </Typography>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: ' #696969',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}>
                                  {GetFullSourceName(rowData?.source)}
                                </Typography>
                              </Box>
                            </Box>
                          </List>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px', fontWeight: 600 }}>
                                Asset
                              </Typography>
                              <Box>
                                {RenderAssetList(assetsTagList)?.map((el: any) => (
                                  <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                      {el?.key} :
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: ' #696969',
                                        wordBreak: 'break-word',
                                        wordWrap: 'break-word'
                                      }}>
                                      {assetWithValue[el?.value]}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </List>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px', fontWeight: 600 }}>
                                Description
                              </Typography>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: ' #696969',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}>
                                  {rowData?.description}
                                </Typography>
                              </Box>
                            </Box>
                          </List>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px', fontWeight: 600 }}>
                                Recommendation
                              </Typography>

                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: ' #696969',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}>
                                  {rowData?.recommendation}
                                </Typography>
                              </Box>
                            </Box>
                          </List>
                        </Box>
                      </Box>
                      {(commonDetails?.data?.group.toLowerCase() == UserRoles.SUPER_ADMIN ||
                        commonDetails?.data?.group.toLowerCase() ==
                          UserRoles.CYBER_SECURITY_ENGINEER ||
                        commonDetails?.data?.group.toLowerCase() ==
                          UserRoles.SERVICE_DELIVERY_MANAGER ||
                        commonDetails?.data?.group.toLowerCase() ==
                          UserRoles.SERVICE_DELIVERY_COORDINATOR) && (
                        <Box
                          sx={{
                            pt: 2,
                            pb: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}>
                          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                            <Box>
                              <JiraIcon />
                            </Box>
                            <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                              Jira Ticket
                            </Typography>
                          </Box>
                          <Box>
                            {rowData?.jira_issue ? (
                              <Button
                                variant="text"
                                href={rowData?.jira_issue}
                                target="_blank"
                                sx={{ fontWeight: 600 }}>
                                {
                                  rowData?.jira_issue?.split('/')[
                                    rowData?.jira_issue?.split('/')?.length - 1
                                  ]
                                }
                                <Box
                                  sx={{
                                    ml: 0.5,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                  }}>
                                  <LinkIcon />
                                </Box>
                              </Button>
                            ) : (
                              <LoadingButton
                                loading={isLoading}
                                onClick={() => handleCreateJiraTicket(rowData?.remediation_uuid)}
                                variant="text"
                                sx={{ fontWeight: 600 }}>
                                Create
                              </LoadingButton>
                            )}
                          </Box>
                        </Box>
                      )}

                      {(dataPosted || !resolve) &&
                        rowData?.actions_taken?.map(
                          (el, index) =>
                            index == 0 && (
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                                  Remediation Actions Taken
                                </Typography>
                                <Box
                                  sx={{
                                    backgroundColor: '#F2F9FF',
                                    padding: 2.5,
                                    borderRadius: '8px',
                                    my: 1
                                  }}>
                                  <Typography variant="body2">{el.description}</Typography>
                                </Box>
                              </Box>
                            )
                        )}

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box
                          sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5
                          }}>
                          <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                            Created by
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box>
                                {rowData?.created_by?.ico ? (
                                  <Box
                                    sx={{
                                      ...Style.versionsCard.infoRound,
                                      width: '24px',
                                      height: '24px',
                                      fontSize: '14px'
                                    }}>
                                    <img
                                      width={'100%'}
                                      height="100%"
                                      style={{
                                        objectFit: 'cover',
                                        borderRadius: '50%'
                                      }}
                                      src={GetAssignImage(
                                        rowData?.created_by?.first_name,
                                        rowData?.created_by?.ico
                                      )}
                                      alt=""
                                    />
                                  </Box>
                                ) : (
                                  <Typography
                                    sx={{
                                      ...Style.versionsCard.infoRound,
                                      width: '24px',
                                      height: '24px',
                                      fontSize: '14px'
                                    }}>
                                    {' '}
                                    {rowData?.created_by?.first_name.charAt(0) +
                                      '' +
                                      rowData?.created_by?.last_name.charAt(0)}
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between'
                                }}>
                                <Typography variant="body2" sx={{ fontWeight: 400 }}>
                                  {rowData?.created_by?.first_name} {rowData?.created_by?.last_name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{ color: '#475467', fontSize: '12px' }}>
                                {moment(rowData?.created_at).format('DD MMMM YYYY')}
                                {'        '}
                                At{' '}
                                {moment(rowData?.created_at)
                                  .tz(moment.tz.guess())
                                  .format('hh:mm A z')}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {rowData?.assigned_by?.first_name && (
                          <Box
                            sx={{
                              mt: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1.5
                            }}>
                            <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                              Assigned by
                            </Typography>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                  {rowData?.assigned_by?.ico ? (
                                    <Box
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      <img
                                        width={'100%'}
                                        height="100%"
                                        style={{
                                          objectFit: 'cover',
                                          borderRadius: '50%'
                                        }}
                                        src={GetAssignImage(
                                          rowData?.assigned_by?.first_name,
                                          rowData?.assigned_by?.ico
                                        )}
                                        alt=""
                                      />
                                    </Box>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      {' '}
                                      {rowData?.assigned_by?.first_name.charAt(0) +
                                        '' +
                                        rowData?.assigned_by?.last_name.charAt(0)}
                                    </Typography>
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                  }}>
                                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                                    {rowData?.assigned_by?.first_name}{' '}
                                    {rowData?.assigned_by?.last_name}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#475467', fontSize: '12px' }}>
                                  {rowData?.assigned_at && (
                                    <>
                                      {moment(rowData?.assigned_at).format('DD MMMM YYYY')}
                                      {'        '}
                                      At{' '}
                                      {moment(rowData?.assigned_at)
                                        .tz(moment.tz.guess())
                                        .format('hh:mm A z')}
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                        {rowData?.owner?.first_name && (
                          <Box
                            sx={{
                              mt: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1.5
                            }}>
                            <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                              Assigned to
                            </Typography>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                  {rowData?.owner?.ico ? (
                                    <Box
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      <img
                                        width={'100%'}
                                        height="100%"
                                        style={{
                                          objectFit: 'cover',
                                          borderRadius: '50%'
                                        }}
                                        src={GetAssignImage(
                                          rowData?.owner?.first_name,
                                          rowData?.owner?.ico
                                        )}
                                        alt=""
                                      />
                                    </Box>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      {' '}
                                      {rowData?.owner?.first_name.charAt(0) +
                                        '' +
                                        rowData?.owner?.last_name.charAt(0)}
                                    </Typography>
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                  }}>
                                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                                    {rowData?.owner?.first_name} {rowData?.owner?.last_name}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#475467', fontSize: '12px' }}>
                                  {rowData?.assigned_at && (
                                    <>
                                      {moment(rowData?.assigned_at).format('DD MMMM YYYY')}
                                      {'        '}
                                      At{' '}
                                      {moment(rowData?.assigned_at)
                                        .tz(moment.tz.guess())
                                        .format('hh:mm A z')}
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>

                      {resolve && !dataPosted && (
                        <Box>
                          {' '}
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, color: '#696969', mt: 3, mb: 2 }}>
                            Remediation Actions Taken
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            value={actionTaken}
                            onChange={handleChangeAcctionTaken}
                            inputProps={{ sx: { fontSize: '14px' } }}
                            variant="outlined"
                            rows={6}
                            multiline
                            placeholder="Type here..."
                            sx={{ fontSize: '14px' }}
                          />
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600, color: '#696969', mt: 3, mb: 2 }}>
                              Upload Evidence
                            </Typography>
                            <Box
                              onDragOver={(e) => stopReload(e)}
                              onDrop={(e) => handleDragEvent(e)}
                              sx={{
                                width: '100%',
                                backgroundColor: '#FBFBFF',
                                py: 4,
                                display: 'flex',
                                border: '1px dashed #CCCCCC',
                                borderRadius: '8px',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2.5
                              }}>
                              <UploadIcon />
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 600, color: '#464646' }}>
                                Drag & Drop or{' '}
                                <label style={{ color: '#436AF3', cursor: 'pointer' }}>
                                  <input
                                    multiple
                                    hidden
                                    onChange={(e) => handleBrowseEvent(e)}
                                    style={{
                                      backgroundColor: 'transparent',
                                      color: 'transparent'
                                    }}
                                    type={'file'}
                                  />{' '}
                                  Choose file{' '}
                                </label>
                                to upload
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1.5,
                                  width: '100%',
                                  alignItem: 'center',
                                  m: 'auto'
                                }}>
                                {fileData.length == 0 ? (
                                  <Typography
                                    textAlign={'center'}
                                    variant="body2"
                                    sx={{ color: '#787878' }}>
                                    doc,img, or video
                                  </Typography>
                                ) : (
                                  fileData?.map((el, index) => (
                                    <Box
                                      key={index + 1}
                                      sx={{
                                        display: 'flex',
                                        width: '80%',
                                        m: 'auto',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        p: 1.5,
                                        borderRadius: '8px',
                                        backgroundColor: '#FFFFFF',
                                        border: '1px solid #EBEBEB'
                                      }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {el.type.split('/')[0] == 'image' ? (
                                          <GallaryIcon />
                                        ) : el.type.split('/')[0] == 'video' ? (
                                          <VideoPlay />
                                        ) : (
                                          <FileIcon />
                                        )}

                                        <EllipsisContent
                                          sx={{
                                            fontSize: 14,
                                            color: '#2D2D2D',
                                            width: 'max-content',
                                            maxWidth: {
                                              xxs: 150,
                                              md: 150
                                            }
                                          }}
                                          text={el.name}
                                        />

                                        <Typography variant="body2" sx={{ color: '#787878' }}>
                                          {niceBytes(el.size)}
                                        </Typography>
                                      </Box>
                                      <Box onClick={() => handleRemoveFile(index)}>
                                        <CrossCancle
                                          sx={{
                                            background: 'rgba(120, 120, 120,0.1)',
                                            width: '20px',
                                            height: '20px',
                                            color: '#787878',
                                            p: '6px',
                                            borderRadius: '50%',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  ))
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {rowData?.tags && (
                        <List>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                            Tags
                          </Typography>
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {rowData?.tags.map((el) => (
                              <Chip
                                variant="filled"
                                label={el.tag_name}
                                sx={{
                                  backgroundColor: '#D9EDFF',
                                  marginRight: '8px'
                                }}
                              />
                            ))}
                          </Box>
                        </List>
                      )}
                      {!resolve && rowData.status != 'resolved' && !rowData?.owner?.user_uuid ? (
                        <Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                              Remediation Owner
                            </Typography>

                            {!prop?.isEngineer && (
                              <Typography
                                variant="body2"
                                onClick={() =>
                                  setAssign({
                                    first_name: commonDetails.data.first_name,
                                    last_name: commonDetails.data.last_name,
                                    user_uuid: commonDetails.data.user_uuid,

                                    ico: commonDetails.data.ico,
                                    company_uuid: commonDetails.data.company.company_uuid
                                  })
                                }
                                sx={{
                                  fontWeight: 600,
                                  color: '#436AF3',
                                  my: 2,
                                  cursor: 'pointer'
                                }}>
                                {commonDetails?.data?.group !=
                                  UserRoles.SERVICE_DELIVERY_COORDINATOR && 'Assign to me'}
                              </Typography>
                            )}
                          </Box>
                          <Box sx={{ display: 'flex', gap: 1.5 }}>
                            <FormControl sx={{ width: '100%' }}>
                              <Autocomplete
                                id="grouped-demo"
                                size="small"
                                value={assign}
                                fullWidth={true}
                                options={option}
                                onChange={(e, value) => handleAssige(e, value)}
                                groupBy={(option: any) => option.firstLetter}
                                getOptionLabel={(option: any) => {
                                  return `${option?.first_name} ${option?.last_name}`;
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ fontSize: '14px' }}
                                    {...params}
                                    placeholder="Assign to"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment:
                                        assign != null ? (
                                          <InputAdornment position="start">
                                            <Box>
                                              {assign.ico ? (
                                                <Box
                                                  sx={{
                                                    ...Style.versionsCard.infoRound,
                                                    width: '24px',
                                                    height: '24px',
                                                    fontSize: '14px'
                                                  }}>
                                                  <img
                                                    width={'100%'}
                                                    height="100%"
                                                    style={{
                                                      objectFit: 'cover',
                                                      borderRadius: '50%'
                                                    }}
                                                    src={GetAssignImage(
                                                      assign.firstLetter,
                                                      assign.ico
                                                    )}
                                                    alt=""
                                                  />
                                                </Box>
                                              ) : (
                                                <Typography
                                                  sx={{
                                                    ...Style.versionsCard.infoRound,
                                                    width: '24px',
                                                    height: '24px',
                                                    fontSize: '14px'
                                                  }}>
                                                  {assign?.first_name?.charAt(0) +
                                                    '' +
                                                    assign?.last_name?.charAt(0)}
                                                </Typography>
                                              )}
                                            </Box>
                                          </InputAdornment>
                                        ) : null
                                    }}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                    key={option.user_uuid}>
                                    {option.ico ? (
                                      <Box
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        <img
                                          width={'100%'}
                                          height="100%"
                                          style={{
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                          }}
                                          src={GetAssignImage(option.firstLetter, option.ico)}
                                          alt=""
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        {option?.first_name?.charAt(0) +
                                          '' +
                                          option?.last_name?.charAt(0)}
                                      </Typography>
                                    )}

                                    <Typography variant="body2">
                                      {' '}
                                      {option.first_name} {option.last_name}
                                    </Typography>
                                  </Box>
                                )}
                                renderGroup={(params) => (
                                  <Box key={params.key}>
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        padding: '10px 10px'
                                      }}>
                                      {params.group}
                                    </Typography>
                                    {params.children}
                                  </Box>
                                )}
                              />
                            </FormControl>
                            <LoadingButton
                              disabled={!assign}
                              loading={isAssignButtonLoading}
                              onClick={() => assignUser()}
                              sx={{
                                border: '1px solid #436AF3',
                                '&.Mui-disabled': {
                                  backgroundColor: '#EBEBEB',
                                  color: isAssignButtonLoading ? '#EBEBEB' : '#9D9D9D',
                                  border: '1px solid #DADADA'
                                }
                              }}>
                              Assign
                            </LoadingButton>
                          </Box>
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                      {rowData?.note && (
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                            Note
                          </Typography>
                          <Box
                            sx={{
                              backgroundColor: '#F2F9FF',
                              padding: 2.5,
                              borderRadius: '8px',
                              my: 1
                            }}>
                            <Typography
                              variant="body2"
                              sx={{
                                color: ' #696969',
                                wordBreak: 'break-word',
                                wordWrap: 'break-word'
                              }}>
                              {rowData?.note}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Divider />
                  {rowData?.files.length === 0 && files?.length === 0 ? (
                    <NoSearchDataAvailable title="No files available" />
                  ) : (
                    <Box sx={{ display: 'inline-block' }}>
                      {rowData?.files.map((element, index) => (
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 1,
                            mt: 2,
                            alignItems: 'center',
                            backgroundColor: '#D9EDFF',
                            py: '5px',
                            px: 1.5,
                            borderRadius: '100px',
                            cursor: 'pointer',
                            width: 'fit-content'
                          }}>
                          <a
                            href={element?.file_url}
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            onClick={() => getFile(element)}>
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center'
                              }}>
                              <PinIcon />
                              <EllipsisContent text={element.file_name} maxWidth={377} />
                            </Box>
                          </a>
                          {edit && (
                            <IconButton
                              sx={{ color: 'text.primary', width: '24px', height: '24px' }}
                              aria-label="close"
                              onClick={() =>
                                DeleteActionTakenFile(element, element?.actions_taken_uuid)
                              }>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </Box>
                      ))}
                      {rowData?.actions_taken?.map((el, index) =>
                        // index == rowData?.actions_taken?.length - 1 &&
                        // index == 0 &&
                        el?.files.map((element) => (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              mt: 2,
                              alignItems: 'center',
                              backgroundColor: '#D9EDFF',
                              py: '5px',
                              px: 1.5,
                              borderRadius: '100px',
                              cursor: 'pointer',
                              width: 'fit-content'
                            }}>
                            <a
                              href={el.file_url}
                              style={{ textDecoration: 'none' }}
                              target="_blank"
                              onClick={() => getFile(element)}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  alignItems: 'center'
                                }}>
                                <PinIcon />
                                <EllipsisContent text={element.file_name} maxWidth={377} />
                              </Box>
                            </a>
                            {edit && (
                              <IconButton
                                sx={{ color: 'text.primary', width: '24px', height: '24px' }}
                                aria-label="close"
                                onClick={() =>
                                  DeleteActionTakenFile(element, el?.actions_taken_uuid)
                                }>
                                <CloseIcon />
                              </IconButton>
                            )}
                          </Box>
                        ))
                      )}
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Divider />
                  <Box sx={{ display: 'inline-block', mt: 2 }}>
                    {remediationHistory?.map((element: any, index) => (
                      <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                        <IconButton
                          sx={{
                            width: '32px',
                            height: '32px',
                            backgroundColor: '#D9D9D9',
                            p: 0,
                            mr: '12px',
                            pointerEvents: 'none'
                          }}
                          color="primary"
                          aria-label="profile picture"
                          component="label">
                          {element?.owner?.ico ? (
                            <img
                              width={'100%'}
                              height="100%"
                              style={{ objectFit: 'cover', borderRadius: '50%' }}
                              src={`${process.env.REACT_APP_API_HOST}/${element?.owner?.ico}`}
                              alt=""
                            />
                          ) : (
                            <Typography
                              variant="caption"
                              sx={{ ...Style.UserNameRound, color: '#696969' }}>
                              {element?.owner?.first_name?.charAt(0) +
                                element?.owner?.last_name?.charAt(0)}
                            </Typography>
                          )}
                        </IconButton>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ color: '#696969', fontWeight: 600, mb: '6px' }}>
                            {element?.owner?.first_name} {element?.owner?.last_name}{' '}
                            <span style={{ fontWeight: 500 }}>from</span>{' '}
                            {element?.owner?.company?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: '#696969', fontWeight: 600, mb: '6px' }}>
                            {element?.title}
                          </Typography>
                          <Typography
                            sx={{ fontSize: '12px', color: '#475467', mb: '6px', fontWeight: 400 }}>
                            {expandedIndex === index ? (
                              <>
                                <span
                                  style={{ wordBreak: 'break-word' }}
                                  dangerouslySetInnerHTML={{
                                    __html: element.action
                                  }}></span>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: '#436AF3',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                    mt: '6px'
                                  }}
                                  onClick={() => handleToggleIndex(index)}>
                                  Close
                                </Typography>
                              </>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: '#436AF3',
                                  fontWeight: 600,
                                  cursor: 'pointer'
                                }}
                                onClick={() => handleToggleIndex(index)}>
                                See More
                              </Typography>
                            )}
                          </Typography>
                          <Typography sx={{ fontSize: '12px', color: '#475467', fontWeight: 400 }}>
                            On {moment(element.created_at).format('DD MMM YYYY').toUpperCase()} at{' '}
                            {moment(element.created_at).format('hh:mm A zz').toUpperCase()}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </TabPanel>
              </Box>
              {rowData?.meta && value === 0 && (
                <Box sx={{ mt: edit ? 0 : 2 }}>
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Track Control
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={(event) => {
                        !prop?.isGap
                          ? navigate(
                              `${PageRouteConstants.GapAnalysisV2Questionnaire}?q=${rowData?.meta?.domain_uuid}&controle=${rowData?.meta?.control_uuid}`
                            )
                          : prop?.closePopup(event);
                      }}
                      sx={{
                        color: '#436AF3',
                        cursor: 'pointer',
                        mt: 2,
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center'
                      }}>
                      Control ID: {rowData?.meta?.control_uuid} <LinkIcon />
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          {dataPosted && !prop?.isEngineer ? (
            <Box></Box>
          ) : (
            value === 0 && (
              <Box
                sx={{
                  px: 3,
                  pb: 2,
                  textAlign: 'end',
                  bottom: 0,
                  right: 0,
                  position: value === 1 || value === 2 ? 'absolute' : 'relative'
                }}>
                {(prop?.isEngineer ||
                  commonDetails?.data?.group == UserRoles.SERVICE_DELIVERY_COORDINATOR ||
                  currentAssign.user_uuid == commonDetails?.data?.user_uuid) &&
                  (rowData.status == 'resolved' ? (
                    <SuccessConfirmationUpadated
                      handleConfirm={(e) => {
                        reopenRemediation(e);
                      }}
                      title="Confirm Action"
                      type={DeleteTypesEnum.Button}
                      asking={'Are you sure you want to “Reopen” this Remediation?'}
                      returnData={'Submitted'}
                      mainBtnTitle={'Reopen'}
                      buttonType={'outlined'}
                      mainBtnType={'primary'}
                      cancelButtonText="Cancel"
                      conformButtonText="Confirm"
                      isLoading={isEvidenceButtonLoading}
                    />
                  ) : (
                    <Button
                      onClick={(e: any) => {
                        setRemediationInitialData();
                        if (resolve) {
                          setResolve(false);
                          setEdit(false);
                        } else {
                          if (rowData.status != 'resolved') {
                            setEdit(!edit);
                          } else {
                            setResolve(true);
                          }
                        }
                      }}
                      sx={{
                        position: 'relative',
                        mr: 2,
                        fontWeight: 700,
                        justifySelf: 'flex-end',
                        minWidth: 150,
                        border: '1px solid #436AF3',

                        '&.Mui-disabled': {
                          backgroundColor: '#EBEBEB',
                          color: '#9D9D9D'
                        }
                      }}>
                      {edit || resolve ? 'Cancel' : 'Edit'}
                    </Button>
                  ))}
                {!prop?.isEngineer &&
                  currentAssign.user_uuid != commonDetails?.data?.user_uuid &&
                  resolve && (
                    <Button
                      onClick={() => {
                        setResolve(false);
                        setRemediationInitialData();
                      }}
                      sx={{
                        position: 'relative',
                        mr: 2,
                        fontWeight: 700,
                        justifySelf: 'flex-end',
                        minWidth: 150,
                        border: '1px solid #436AF3',

                        '&.Mui-disabled': {
                          backgroundColor: '#EBEBEB',
                          color: '#9D9D9D'
                        }
                      }}>
                      Cancel
                    </Button>
                  )}
                {prop?.isEngineer ||
                commonDetails?.data?.group == UserRoles.SERVICE_DELIVERY_COORDINATOR ||
                currentAssign?.user_uuid == commonDetails?.data?.user_uuid ? (
                  resolve ? (
                    <SuccessConfirmationUpadated
                      handleConfirm={(e) => {
                        handlePostData(e);
                      }}
                      title="Confirm Action"
                      type={DeleteTypesEnum.Button}
                      asking={'Are you sure you want to “Resolve” this Remediation?'}
                      returnData={'Submitted'}
                      mainBtnTitle={'Submit'}
                      buttonType={'contained'}
                      mainBtnType={'primary'}
                      cancelButtonText="Cancel"
                      conformButtonText="Confirm"
                      isLoading={isEvidenceButtonLoading}
                    />
                  ) : rowData.status != 'resolved' ? (
                    (currentAssign?.user_uuid == commonDetails.data.user_uuid ||
                      commonDetails?.data?.group == UserRoles.SERVICE_DELIVERY_COORDINATOR ||
                      (prop?.isEngineer && edit)) && (
                      <LoadingButton
                        loading={isEditButtonLoading}
                        type="submit"
                        disabled={
                          edit &&
                          (isComplete || tagErrorMessage.maxLimit || tagErrorMessage.tagLimit)
                            ? true
                            : false
                        }
                        onClick={() => {
                          handleSubmitEditData();
                        }}
                        sx={{
                          position: 'relative',
                          fontWeight: 700,
                          justifySelf: 'flex-end',
                          minWidth: 150,
                          backgroundColor: '#436AF3',
                          border: '1px solid #436AF3',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#436AF3'
                          },
                          '&.Mui-disabled': {
                            backgroundColor: '#EBEBEB',
                            border: '1px solid #EBEBEB',
                            color: isEditButtonLoading ? '#EBEBEB' : '#9D9D9D'
                          }
                        }}>
                        {edit ? 'Save' : 'Resolve'}
                      </LoadingButton>
                    )
                  ) : rowData.status == 'resolved' && edit ? (
                    <LoadingButton
                      loading={isEditButtonLoading}
                      type="submit"
                      disabled={
                        edit && (isComplete || tagErrorMessage.maxLimit || tagErrorMessage.tagLimit)
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleSubmitEditData();
                      }}
                      sx={{
                        position: 'relative',
                        fontWeight: 700,
                        justifySelf: 'flex-end',
                        minWidth: 150,
                        backgroundColor: '#436AF3',
                        border: '1px solid #436AF3',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#436AF3'
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#EBEBEB',
                          border: '1px solid #EBEBEB',
                          color: isEditButtonLoading ? '#EBEBEB' : '#9D9D9D'
                        }
                      }}>
                      {edit ? 'Save' : 'Resolve'}
                    </LoadingButton>
                  ) : (
                    <Box></Box>
                  )
                ) : resolve ? (
                  <SuccessConfirmationUpadated
                    handleConfirm={(e) => {
                      handlePostData(e);
                    }}
                    title="Confirm Action"
                    type={DeleteTypesEnum.Button}
                    asking={'Are you sure you want to “Resolve” this Remediation?'}
                    returnData={'Submitted'}
                    mainBtnTitle={'Submit'}
                    mainBtnType={'primary'}
                    buttonType={'contained'}
                    cancelButtonText="Cancel"
                    conformButtonText="Confirm"
                    isLoading={isEvidenceButtonLoading}
                  />
                ) : (
                  rowData.status != 'resolved' &&
                  currentAssign?.user_uuid == commonDetails.data.user_uuid && (
                    <Button
                      type="submit"
                      onClick={() => {
                        setResolve(true);
                      }}
                      sx={{
                        position: 'relative',
                        fontWeight: 700,
                        justifySelf: 'flex-end',
                        minWidth: 150,
                        backgroundColor: '#436AF3',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#436AF3'
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#EBEBEB',
                          color: '#9D9D9D'
                        }
                      }}>
                      Resolve
                    </Button>
                  )
                )}
              </Box>
            )
          )}
        </Box>
      )}
    </>
  );
};

export default RemidiationPopUpList;
