export const PageRouteConstants = {
  Home: '/',
  Login: '/login',
  OnBoarding: '/onboarding',
  Welcome: '/welcome',
  MfaMethod: '/mfa-method',
  MfaSMS: '/mfa-sms',
  MfaAuthenticator: '/mfa-authenticator',
  Mfa: '/two-factor-authentication',
  Signup: '/signup/:id',
  NetwrokDetection: '/network-detection',
  SignupCreate: '/user-signup/:id',
  SIEM: '/events',
  ForgotPassword: '/forgot-password',
  Notification: '/notification',
  PortalActivity: '/portal-activities',
  CompanyProfile: '/company/information/locations',
  ManageUsers: '/company/information/manage-users',
  Integration: '/company/information/integration',
  Meetings: '/company/information/meetings',
  General: '/company/information/general',
  CompanyManagement: '/company-management',
  CompanyDashBoard: '/company/dashboard',
  CompanyManagementAccount: '/company-management/account',
  CompanyManagementContact: '/company-management/contacts',
  CompanyManagementIntegrations: '/company-management/integrations',
  Authorizations: '/company/information/authorizations',
  Licenses: '/company/information/licenses',
  PlanAndBilling: '/company/information/plan-and-billing',
  AuditLogs: '/company/information/audit-logs',
  UpGuard: '/attack-surface-management',
  Applications: '/company/information/applications',
  ExternalInfrastructureAssessment: '/company/information/ip-ranges',
  WirelessAssessment: '/company/information/wireless-network',

  PoliciesAndProcedures: '/policies-procedures',
  PoliciesAndProceduresTemplate: '/policies-procedures/document/',

  SecurityAwarenessRoot: '/security-awareness',
  SecurityAwarenessResult: '/security-awareness/training-campaign',
  SecurityAwarenessParticupant: '/security-awareness/phishing-campaign',
  Vulnerability: '/vulnerability-management',

  Remediation: '/remediation-tracker',
  RiskManagement: '/risk-management',
  AssetManagement: '/asset-management',
  UserManagement: '/user-management',

  Penetration: '/penetration-testing',
  EndpointProtection: '/endpoint-protection',
  Frameworks: '/frameworks/',
  FrameworkControl: '/frameworks/control/',

  // FrameworksCIS: '/frameworks/CIS/',

  GapAnalysis: '/gap-analysis',
  GapAnalysisV2: '/gap-analysis-v2/overview',
  GapAnalysisV2Questionnaire: '/gap-analysis-v2/questionnaire',
  DashBoard: '/dashboard',

  ServicesReview: '/customer-success/service-review',
  EngineeringConsulting: '/customer-success/engineering-consulting',
  EmailProtection: '/email-protection',
  MyAccount: '/myAccount',

  Reports: '/reports',

  Any: '/*'
};
