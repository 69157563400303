import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserPool from '../../utils/UserPool';
import { Auth, Amplify } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
import ReactGA from 'react-ga4';
const axios = require('axios');
const axiosApiInstance = axios.create();

export const notifySuccess = (message: any) =>
  toast.success(message, { position: toast.POSITION.TOP_RIGHT });
export const notifyError = (message: any) =>
  toast.error(message, { position: toast.POSITION.TOP_RIGHT });

Amplify.configure({
  Auth: {
    userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
    userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
  }
});
const commonDetails = () =>
  localStorage.getItem('persist:bitflexmine')
    ? JSON.parse(JSON.parse(localStorage.getItem('persist:bitflexmine'))?.commonDetails)
    : {};

const handleSignout = () => {
  const user = new CognitoUser({
    Username: commonDetails()?.data.email,
    Pool: UserPool
  });
  localStorage.clear();
  user.signOut();
  window.location.href = '/';
};

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config: any) => {
    let idToken = '';
    let accessToken = '';
    try {
      const session = await Auth.currentSession();
      idToken = session.getIdToken().getJwtToken();
      accessToken = session.getAccessToken().getJwtToken();
    } catch {
      idToken = null;
      accessToken = null;
    }

    if (idToken) {
      config.headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${idToken}`,
        Access: `${accessToken}`
      };
    } else {
      config.headers = {
        Accept: 'application/json'
      };
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response: any) => {
    if (
      response.config.method.toLowerCase() == 'post' &&
      response.config.url.includes('settings/users')
    ) {
      notifySuccess('Invite sent successfully');
    }
    if (
      response.config.method.toLowerCase() == 'post' ||
      response.config.method.toLowerCase() == 'put'
    ) {
      if (
        !response.config.url.includes('sf/users') &&
        !response.config.url.includes('settings/users') &&
        !response.config.url.includes('/plan-and-billing') &&
        !response.config.url.includes('/document') &&
        !response.config.url.includes('/bulkdownload') &&
        !response.config.url.includes('evidence-group') &&
        !response.config.url.includes('version') &&
        !response.config.url.includes('/callback') &&
        !response.config.url.includes('/settings/policy') &&
        !response.config.url.includes('/risk/bulk-action') &&
        !response.config.url.includes('/feedbacks')
      )
        notifySuccess('Data saved successfully');
    } else if (response.config.method.toLowerCase() == 'delete') {
      notifySuccess('Data removed successfully');
    }
    if (response?.config?.url?.includes('/risk/bulk-action')) {
      const data = JSON.parse(response?.config?.data);
      if (data?.action?.action_type === 'accept') {
        notifySuccess('You have Accepted this Risk');
      } else if (data?.action?.action_type === 'delete') {
        notifySuccess('Data removed successfully');
      } else if (data?.action?.action_type === 'assign_tag') {
        notifySuccess('Data saved successfully');
      }
    }
    return response;
  },
  async function (error: any) {
    const originalRequest = error.config;

    if (error.response.status === 403) {
      await sendErrorTosterMessage(error);
      //window.location.href = PageRouteConstants.OnBoarding;
    } else if (error.response.status === 401) {
      if (error.response.data.error.message == 'token is invalid') {
        await sendErrorTosterMessage(error);
        localStorage.clear();
        window.location.href = '/login';
      } else {
        await sendErrorTosterMessage(error);
        localStorage.clear();
        window.location.href = '/login';
      }
    } else if (
      error?.response?.data?.error?.code === 500 &&
      error?.response?.data?.message === 'user(s) not found'
    ) {
      handleSignout();
      notifyError('User deactivated, please contact company admin.');
    } else {
      await sendErrorTosterMessage(error);
      ReactGA.event({
        category: 'API Call',
        action: `API Error-${commonDetails()?.commonDetails?.data?.data?.company?.name}-${
          window.location.pathname
        }`,
        label: `API: ${error.config?.url?.split('/')?.slice(7)?.join('/') || 'Unknown API'}`,
        value: error.response?.status || 0 // Capture the HTTP status code
      });
    }

    return Promise.reject(error);
  }
);
function readBlobAsJSON(blob: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const jsonData = JSON.parse(reader.result);
        resolve(jsonData);
      } catch (error) {
        reject(new Error('Failed to parse the response data as JSON.'));
      }
    };
    reader.onerror = () => {
      reject(new Error('Failed to read the response data.'));
    };
    reader.readAsText(blob);
  });
}
const sendErrorTosterMessage = async (error: any) => {
  const isBlob = error.response.data instanceof Blob;

  if (isBlob) {
    const message = await readBlobAsJSON(error.response.data);

    notifyError(message.error.message);
  } else {
    notifyError(error.response.data.error.message);
  }
};
export default axiosApiInstance;
