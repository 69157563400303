import HttpService from '@jibin/common/services/http/HttpService';
import axios from 'axios';

export const CompanySettingsService = {
  GetUserList(userId: string, companyId: string, params: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/users?` + params);
  },

  postUser(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/settings/users`, data);
  },
  // /companies/{company_id}/users/{user_id}/settings/users/invite
  userInvite(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/settings/users/invite`, data);
  },

  putUser(reqUserId: string, companyId: string, userId: string, data: any) {
    return HttpService.put(
      `companies/${companyId}/users/${reqUserId}/settings/users`,
      userId,
      data
    );
  },

  deleteUser(reqUserId: string, companyId: string, userId: string) {
    return HttpService.remove(`companies/${companyId}/users/${reqUserId}/settings/users`, userId);
  },

  // Authorizations
  GetSignatures(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/signatures`);
  },
  // Meetings
  GetCompanyMeetings(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/meetings`);
  },
  GetMeetingsList(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/meetings`);
  },

  //Locations
  GetLocationList(userId: string, companyId: string, params: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/addresses?` + params
    );
  },
  GetLocation(userId: string, companyId: string, locationId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.get(`companies/${companyId}/users/${userId}/settings/address`, locationId);
  },

  postLocation(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/settings/address`, data);
  },

  putLocation(userId: string, companyId: string, locationId: string, data: any) {
    return HttpService.put(
      `companies/${companyId}/users/${userId}/settings/address`,
      locationId,
      data
    );
  },
  PatchLocation(userId: string, companyId: string, locationId: string, data: any) {
    return HttpService.patch(
      `companies/${companyId}/users/${userId}/settings/address`,
      locationId,
      data
    );
  },
  deleteLocation(userId: string, companyId: string, locationId: string) {
    return HttpService.remove(
      `companies/${companyId}/users/${userId}/settings/address`,
      locationId
    );
  },
  updateUserMFAStatus(
    companyId: string,
    reqUserId: string,
    userId: string,
    mfaStatus: string,
    data: any
  ) {
    return HttpService.patch(
      `companies/${companyId}/users/${reqUserId}/settings/users/${userId}/mfa_status`,
      mfaStatus,
      data
    );
  },
  licenseServices(companyId: string, userId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/licenses/services`);
  },
  licenseVendors(companyId: string, userId: string, serviceId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/service/${serviceId}/vendors`
    );
  },
  GetVendors(params: string) {
    return HttpService.getAll(`vendors?company_ids=` + params);
  },
  licenseTools(companyId: string, userId: string, serviceId: string, vendorId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/services/${serviceId}/vendors/${vendorId}/tools`
    );
  },
  createLicense(companyId: string, userId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/licenses`, data);
  },
  getLicense(companyId: string, userId: string, params: any) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/licenses?` + params);
  },
  getAuditLogs(companyId: string, userId: string, params: any) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/audit-logs?` + params);
  },
  exportAuditLogs(companyId: string, userId: string, params: any) {
    return HttpService.getDocument(
      `companies/${companyId}/users/${userId}/audit-logs/export?` + params
    );
  },
  updateLicense(companyId: string, userId: string, licenseId: string, data: any) {
    return HttpService.put(`companies/${companyId}/users/${userId}/licenses`, licenseId, data);
  },
  removeLicense(companyId: string, userId: string, licenseId: string) {
    return HttpService.remove(`companies/${companyId}/users/${userId}/licenses`, licenseId);
  },
  historyLicense(companyId: string, userId: string, licenseId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/licenses/${licenseId}/history`
    );
  },
  GetLicenseFile(companyId: string, userId: string, licenseId: string, fileId: string) {
    return HttpService.get(
      `companies/${companyId}/users/${userId}/licenses/${licenseId}/files`,
      fileId
    );
  },
  removeLicenseFile(companyId: string, userId: string, licenseId: string, fileId: string) {
    return HttpService.remove(
      `companies/${companyId}/users/${userId}/licenses/${licenseId}/files`,
      fileId
    );
  },
  PutCompanyPhoto(companyId: any, userId: string, data: any) {
    return HttpService.putAll(`companies/${companyId}/users/${userId}/settings/profile-pic`, data);
  },
  RemoveCompanyPhoto(companyId: any, userId: string, data: any) {
    return HttpService.remove(`companies/${companyId}/users/${userId}/settings`, 'profile-pic');
  },
  async UploadCompantPhoto(response: any, file: any) {
    const {
      data: {
        data: { upload_url, callback_url, callback_data }
      }
    } = response;

    return axios
      .put(upload_url, file, {
        headers: {
          'Content-Type': file.type
        }
      })
      .then(() => HttpService.post(callback_url, { data: callback_data }));
  }
};
