import { Amplify, Auth } from 'aws-amplify';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import MainContainer from '../../components/Container';
import { CommonFunctions } from '../Common';
import { CompanyUserRoles, dataforUserActivity } from '../constants/ConstantName';
import { PageRouteConstants } from '../constants/PageRouteConstants';
import ProtectedRoute from '../RouteAccessor/ProtectedRoute';
import ReactGA from 'react-ga4';
import { LoginContext } from '@jibin/common/Context/LoginContext';
import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from '@jibin/common/utils/UserPool';

function PrivateRoutes({ children }: { children: React.ReactElement }): React.ReactElement {
  let auth = localStorage.getItem('cognito-token');

  const [waitingToReconnect, setWaitingToReconnect] = useState(false);
  const { clientRef }: any = useContext(LoginContext);
  const [isOpen, setIsOpen] = useState(false);
  const { getTabId } = useIdleTimer({
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    throttle: 500
  });
  const userAgent = navigator.userAgent;

  const tabId = getTabId() === null ? 'loading' : getTabId().toString();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  let location = useLocation();
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const getToken = async () => {
    let idToken = '';
    let accessToken = '';
    try {
      const session = await Auth.currentSession();
      idToken = session.getIdToken().getJwtToken();
      accessToken = session.getAccessToken().getJwtToken();
      return accessToken;
    } catch {
      idToken = null;
    }
  };
  const checkAuditorAllow = () => {
    if (
      commonDetails?.data?.company?.user_role === CompanyUserRoles.AUDITOR &&
      location.pathname !== PageRouteConstants.GapAnalysis &&
      location.pathname !== PageRouteConstants.GapAnalysisV2Questionnaire &&
      location.pathname !== PageRouteConstants.PoliciesAndProcedures &&
      !location.pathname.includes(PageRouteConstants.PoliciesAndProceduresTemplate) &&
      location.pathname !== PageRouteConstants.MyAccount
    ) {
      return true;
    }
  };

  const handleSignout = () => {
    const user = new CognitoUser({
      Username: commonDetails?.data.email,
      Pool: UserPool
    });
    user.signOut();
    localStorage.clear();
    window.location.href =
      '/login?reason=This account has been deactivated. Please contact your Company Admin.';
  };

  useEffect(() => {
    if (commonDetails) {
      ReactGA.event({
        category: 'page-view-by-company',
        action: `${commonDetails.data.company.name}-${location.pathname}-page-visit`,
        label: `${commonDetails.data.company.name}-${location.pathname}`,
        nonInteraction: true // optional, true/false
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (commonDetails) {
      if (waitingToReconnect) {
        return;
      }

      try {
        // Initialize the WebSocket only once
        if (!clientRef.current) {
          const client = new WebSocket(
            `${process.env.REACT_APP_WEBSOCKET_HOST}ws/user/${commonDetails.data.user_uuid}/subscribe`
          );
          clientRef.current = client;
          console.log('WebSocket initialized');
        }

        const page_name = dataforUserActivity.filter((el: any) => el?.route === location.pathname);
        const device_id = localStorage.getItem('device_id') || '';
        const userActivityMessage = {
          type: 'report_user_activity',
          payload: {
            user_id: commonDetails.data.user_uuid,
            company_id: commonDetails.data.company.company_uuid,
            page_name: page_name?.[0]?.name,
            device_id: device_id
          }
        };

        // WebSocket event listeners
        clientRef.current.onerror = (e) => console.error('WebSocket error:', e);

        clientRef.current.onopen = () => {
          console.log('WebSocket connection opened');
          clientRef.current?.send(JSON.stringify(userActivityMessage));
        };

        clientRef.current.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);

            if (data?.data?.type === 'user_deactivated') {
              handleSignout();
            }
          } catch (e) {
            console.error('Error parsing WebSocket message:', e);
          }
        };

        clientRef.current.onclose = () => {
          console.log('WebSocket closed');

          if (clientRef.current) {
            console.log('WebSocket closed by the server. Attempting to reconnect...');
          } else {
            return; // App cleanup triggered, no need to reconnect
          }

          if (waitingToReconnect) {
            return;
          }

          setIsOpen(false);
          setWaitingToReconnect(true);

          // Retry connection after a delay
          setTimeout(() => setWaitingToReconnect(false), 5000);
        };
      } catch (error) {
        console.error('Error setting up WebSocket:', error);
      }

      // Cleanup function to close WebSocket on unmount
      return () => {
        if (clientRef.current) {
          clientRef.current.close();
          clientRef.current = null;
        }
      };
    }
  }, [waitingToReconnect, location.pathname, commonDetails]);
  if (!auth) {
    return <Navigate to={PageRouteConstants.Login} state={{ from: location }} replace />;
  }
  if (commonDetails?.data?.company?.type == 'engineering') {
    return (
      <ProtectedRoute>
        <MainContainer> {children} </MainContainer>
      </ProtectedRoute>
    );
  }
  if (checkAuditorAllow()) {
    return (
      <Navigate
        to={PageRouteConstants.GapAnalysisV2Questionnaire}
        state={{ from: location }}
        replace
      />
    );
  }

  //return children;
  return <MainContainer> {children} </MainContainer>;
}

export default PrivateRoutes;
