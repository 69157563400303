import React from 'react';

const PhoneIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.9165 1.1665H4.08317C3.43884 1.1665 2.9165 1.68884 2.9165 2.33317V11.6665C2.9165 12.3108 3.43884 12.8332 4.08317 12.8332H9.9165C10.5608 12.8332 11.0832 12.3108 11.0832 11.6665V2.33317C11.0832 1.68884 10.5608 1.1665 9.9165 1.1665Z"
        stroke="#2D2D2D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 10.5H7.00583"
        stroke="#2D2D2D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
