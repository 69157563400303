import CloseIcon from '@jibin/common/icons/Close/Close';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import Style from '@jibin/common/style/Styles';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SwipeableDrawer,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RiskManagementApi } from '../RiskManagement.Api';
import { RemediationApi } from 'src/Pages/Remediation/Remediation.Api';
import { GetAssignImage } from '@jibin/common/utils/Common';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import CyberSecurityDomainDropdown from 'src/Pages/Common/CyberSecurityDropdown';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const CreateRisk = ({
  servicesOption,
  GetRisks,
  // GetTagsForList,
  GetRiskSummary,
  engineer,
  text,
  remediationData,
  controle_id,
  triggerId,
  isGap
}: any) => {
  const [value, setValue] = useState(0);
  const [valueTag, setValueTag] = useState([]);
  const [useParams, setUseParam] = useSearchParams();
  const [tagErrorMessage, setTagErrorMessage] = useState({ maxLimit: '', tagLimit: '' });
  const [rowData, setRowData] = useState({} as any);
  const [assign, setAssign] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [assignees, setAssignees] = useState({});
  const [click, setclick] = useState(false);
  const [remediationFilterform, setRemediationFilterform] = useState({
    keyword: '',
    Assets: '',
    status: '',
    source: '',
    tag_uuids: ''
  });

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteOption, setDeleteOption] = useState('');
  const [compalate, setComplate] = useState(true);
  const [tagOptions, setTagOptions] = useState([]);
  const [personName, setPersonName] = useState<string[]>(['All Criticalities']);
  const [isRemediationButtonLoading, setIsRemediationButtonLoading] = useState(false);

  const [state, setState] = useState({
    right: false
  });

  const setFormikValue = (val: any) => {
    if (val.includes('ip')) {
      formik.setFieldValue('ip', formik.values.ip);
      setComplate(true);
    } else {
      formik.setFieldValue('ip', '');
    }

    if (val.includes('mac')) {
      formik.setFieldValue('mac', formik.values.mac);
      setComplate(true);
    } else {
      formik.setFieldValue('mac', '');
    }
    if (val.includes('custom')) {
      formik.setFieldValue('custom', formik.values.custom);
      setComplate(true);
    } else {
      formik.setFieldValue('custom', '');
    }
    if (val.includes('machine')) {
      formik.setFieldValue('machine', formik.values.machine);
      setComplate(true);
    } else {
      formik.setFieldValue('machine', '');
    }
    if (val.includes('hostnames')) {
      formik.setFieldValue('hostnames', formik.values.hostnames);
      setComplate(true);
    } else {
      formik.setFieldValue('hostnames', '');
    }
  };
  const handleChangeAsset = (event: any) => {
    let {
      target: { value, name }
    } = event;
    const filterValue = value.filter((el: any) => el != '');
    setFormikValue(filterValue);
    let string;
    if (filterValue.length == 0) {
      string = '';
      const dataForApi = { ...remediationFilterform };

      dataForApi[name] = string;

      setRemediationFilterform(dataForApi);

      const item = typeof value === 'string' ? filterValue.split(',') : [''];

      formik.setFieldValue('Assets', item);
    } else {
      string = filterValue.join(',');
      const dataForApi = { ...remediationFilterform };

      dataForApi[name] = string;

      setRemediationFilterform(dataForApi);

      const item = typeof value === 'string' ? filterValue.split(',') : filterValue;
      formik.setFieldValue('Assets', item);
    }
    // if (value.length > formik?.values?.Assets?.length) {
    //   setComplate(true);
    // }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleAssige = (event: any, value: any) => {
    setAssign(value);
  };
  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels?.join(', ');
  };

  const GetAssignees = useCallback(async () => {
    try {
      const res = await RiskManagementApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      renderOption(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [commonDetails]);

  const [option, setOption] = useState([]);
  //   const handleSubmitEditData = async () => {
  //     try {
  //       const tags = facilities?.map((el: any) => el.name);
  //       const data = { ...formik.values, tags: tags };
  //       const res = await RiskManagementApi.CreateRemediation(
  //         commonDetails.data.user_uuid,
  //         commonDetails.data.company.company_uuid,

  //         data
  //       );
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       setTimeout(() => {}, 1000);
  //     }
  //   };
  function containsWhitespaceString(arr) {
    for (const str of arr) {
      if (/^\s*$/.test(str)) {
        return true;
      }
    }
    return false;
  }
  const validate = (value: any) => {
    const errors: any = {};
    const isEmpty = value.Assets.every((item) => item.trim() === '');
    const requiredFields = ['hostnames', 'ip', 'mac', 'machine', 'custom'];

    for (const field of requiredFields) {
      if (value.Assets.includes(field) && !value[field]) {
        setComplate(true);
        return;
      }
    }

    if (
      !value.description ||
      !value.discovered_at ||
      !value.name ||
      !value.services_uuid ||
      isEmpty
    ) {
      setComplate(true);
    } else {
      setComplate(false);
    }

    return errors;
  };

  const validateAsset = (val: any) => {
    if (
      formik.values.Assets?.[0] === '' ||
      (formik?.values?.Assets?.includes('ip') && !val.ip) ||
      (formik?.values?.Assets?.includes('hostnames') && !val.hostnames) ||
      (formik?.values?.Assets?.includes('machine') && !val.machine) ||
      (formik?.values?.Assets?.includes('mac') && !val.mac) ||
      (formik?.values?.Assets?.includes('custom') && !val.custom)
    ) {
      return false;
    } else {
      return true;
    }
  };
  const formik = useFormik({
    initialValues: {
      name: remediationData?.name,
      assign: null,
      tags: [],
      description: remediationData?.description,

      criticality: 'Medium',
      Assets: [''],
      services_uuid: '',
      integration_tools_uuid: '',
      discovered_at: new Date(),
      recommendation: remediationData?.recommendation
    },
    validate,
    onSubmit: async () => {
      try {
        setIsRemediationButtonLoading(true);
        //const currentDate = new Date();
        //const formattedDate = currentDate.toISOString();
        let originalDate = new Date(formik.values.discovered_at || '');
        const adjustedISOString =
          originalDate.getFullYear() +
          '-' +
          String(originalDate.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(originalDate.getDate()).padStart(2, '0') +
          'T' +
          String(originalDate.getHours()).padStart(2, '0') +
          ':' +
          String(originalDate.getMinutes()).padStart(2, '0') +
          ':' +
          String(originalDate.getSeconds()).padStart(2, '0') +
          '.' +
          String(originalDate.getMilliseconds()).padStart(3, '0') +
          'Z';

        const data: any = {
          name: formik.values.name,
          description: formik.values.description,
          assets: {
            ip: formik.values.ip,
            mac: formik.values.mac,
            custom: formik.values.custom,
            machine: formik.values.machine,
            hostnames: formik.values.hostnames ? formik.values.hostnames.split(',') : []
          },
          services_uuid: formik.values.services_uuid,
          tags: facilities,
          criticality: formik.values.criticality,
          recommendation: formik.values.recommendation,
          status: 'Not Started',
          discovered_at: adjustedISOString
          // owner_uuid: formik.values?.assign?.user_uuid
        };
        if (formik.values.integration_tools_uuid) {
          data.integration_tools_uuid = formik.values.integration_tools_uuid;
        }

        // if (isGap) {
        //   const res = await RiskManagementApi.CreateRemediationFromGap(
        //     commonDetails.data.user_uuid,
        //     commonDetails.data.company.company_uuid,
        //     controle_id,
        //     data
        //   );
        // } else {
        const res = await RiskManagementApi.CreateRisk(
          commonDetails.data.company.company_uuid,
          commonDetails.data.user_uuid,
          data
        );
        // }
        toggleDrawer('right', false);
      } catch (error) {
      } finally {
        setTimeout(() => {
          setIsRemediationButtonLoading(false);
        }, 1000);
      }
    }
  });
  const renderOption = (assignees) => {
    const options = assignees.data.map((option) => {
      const firstLetter = option.company_name.toUpperCase();
      const company_uuid = option.company_uuid;

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, company_uuid: company_uuid, ...el };
      });
      return data;
    });

    const optionarray = options.concat.apply([], options).flat();
    setOption(optionarray);
  };

  const AssetList = [
    { key: 'Select Asset', value: '', placeholder: '' },

    { key: 'Domain/URL', value: 'hostnames', placeholder: 'Eg: www.redesign-group.com' },
    { key: 'IP Address', value: 'ip', placeholder: 'Eg: 192.158.1.38' },
    { key: 'MAC Address', value: 'mac', placeholder: 'Eg: 00-B0-D0-63-C2-26' },
    {
      key: 'Machine/Server Name',
      value: 'machine',
      placeholder: 'Eg: Teletraan'
    },
    { key: 'Other', value: 'custom', placeholder: 'Type here' }
  ];
  // const areTagsEqual = (tag1, tag2) => tag1.trim().toLowerCase() === tag2.trim().toLowerCase();
  const renderColorBackGround = (criticality: any = 'critical') => {
    var color = '#FFE6E2';
    if (criticality == 'High') {
      color = '#FCD34D';
    } else if (criticality == 'Critical') {
      color = '#FFE6E2';
    } else if (criticality == 'Medium') {
      color = '#FEF3C7';
    } else if (criticality == 'Low') {
      color = '#D0DAFC';
    } else if (criticality == 'Status') {
      color = '#EBEBEB';
    }
    return color;
  };
  const RenderAssetList = (assest: any) => {
    let data = [];
    if (assest?.length > 0) {
      data = AssetList.filter((el: any) => {
        return el?.value && assest?.includes(el.value) && el;
      });
    } else {
      data = [];
    }

    return data;
  };
  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setclick(!click);
    setState({ ...state, [anchor]: open });
    if (!open) {
      formik.setFieldValue('tags', []);
      formik.setFieldValue('Assets', ['']);
      formik.setFieldValue('name', '');
      formik.setFieldValue('assign', null);
      formik.setFieldValue('description', '');
      formik.setFieldValue('criticality', 'Medium');
      formik.setFieldValue('recommendation', '');
      formik.setFieldValue('services_uuid', '');
      formik.setFieldValue('integration_tools_uuid', '');
      formik.setFieldValue('ip', '');
      formik.setFieldValue('mac', '');
      formik.setFieldValue('custom', '');
      formik.setFieldValue('machine', '');
      formik.setFieldValue('hostnames', '');
      formik.setFieldValue('discovered_at', new Date());
    } else {
      GetTags();
    }

    // formik.resetForm({ values: initialValues });

    if (!open) {
      GetRisks();
      GetRiskSummary();
      // GetTagsForList();
    }
  };

  const GetTags = useCallback(async () => {
    const query: any = {
      include_non_associated_tags: true
    };
    try {
      const res = await RiskManagementApi.GetTagsList(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        new URLSearchParams(query).toString()
      );
      if (res?.data?.data) {
        const TagList = res.data.data.map((el) => el);
        setTagOptions(TagList);
      }
    } catch (error) {}
  }, [commonDetails]);

  function validateString(inputString) {
    if (!/^[a-zA-Z_\s]+$/.test(inputString)) {
      return false;
    }
    if (/^\d+$/.test(inputString)) {
      return false;
    }
    return true;
  }

  const maketags = (data: any) => {
    const facility = [];

    data.forEach((element) => {
      let facilitytype = tagOptions.filter((q) => q.tag_name == element);
      if (facilitytype?.length > 0) {
        facility.push({ tag_name: element, tag_uuid: facilitytype[0].tag_uuid });
      } else {
        facility.push({ tag_name: element });
      }
    });
    setFacilities([...facility]);
  };

  const handleClickOpenDelete = (option, value) => {
    setDeleteOption(option);

    formik.setFieldValue('tags', value);
    setOpenDelete(true);
  };

  const handleTagChange = (event, value) => {
    var newArray = value.filter(function (elem, pos) {
      return value.indexOf(elem) == pos;
    });
    if (tagErrorMessage.maxLimit) {
      setTagErrorMessage({
        ...tagErrorMessage,
        maxLimit: ''
      });
      return;
    }
    formik.setFieldValue('tags', newArray);
    maketags(newArray);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = (deleteFacility) => {
    const updatedFacility = formik.values.tags.filter((el, index) => el !== deleteFacility);
    formik.setFieldValue('tags', updatedFacility);

    maketags(updatedFacility);
    setOpenDelete(false);
  };

  useEffect(() => {
    GetAssignees();
    formik.setFieldValue('name', remediationData?.issueName);
    formik.setFieldValue('description', remediationData?.description);
    formik.setFieldValue('recommendation', remediationData?.recommendation);
  }, [GetAssignees, remediationData]);

  return (
    <Box>
      <Box>
        <Button
          onClick={() => toggleDrawer('right', true)}
          disabled={
            (isGap && remediationData?.remediationCreated) ||
            (isGap && remediationData?.control_status == 'Not Applicable')
          }
          sx={{ py: 0.5 }}
          id={triggerId}
          variant="contained">
          {text}
        </Button>
      </Box>
      <SwipeableDrawer
        BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
        sx={{
          '.MuiDrawer-paper': {
            boxShadow: 'none'
          }
        }}
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        onOpen={() => toggleDrawer('right', true)}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              width: { md: 520, xs: 300 },
              position: 'relative',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column'
            }}
            role="presentation">
            <List sx={{ p: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 1,
                  justifyContent: 'space-between',
                  backgroundColor: '#003260',
                  px: 2.5,
                  alignItems: 'center',
                  py: 1
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',

                    width: 1,
                    alignItems: 'center'
                  }}>
                  <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', my: 1.25 }}>
                    {isGap ? 'Gap Analysis' : 'Create a Risk'}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box onClick={() => toggleDrawer('right', false)}>
                      <CrossCancle
                        sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </List>

            <Box sx={{ flex: 1 }}>
              <Box sx={{ p: 3 }}>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderColor: 'divider' }}>
                    {/* <Tabs
                      value={value}
                      sx={{ '.Mui-selected': { textDecoration: 'underline' }, minHeight: '0px' }}
                      TabIndicatorProps={{ sx: { display: 'none' } }}
                      onChange={handleChange}
                      aria-label="basic tabs example">
                      <Tab
                        sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                        label="Remediation"
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Files"
                        sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                        {...a11yProps(1)}
                      />
                      <Tab
                        label="History"
                        sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                        {...a11yProps(2)}
                      />
                    </Tabs> */}
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                          Criticality Level:
                        </Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={formik.values.criticality || 'Medium'}
                            name="criticality"
                            onChange={formik.handleChange}
                            displayEmpty
                            sx={{
                              '.MuiSelect-select': {
                                py: 1,
                                display: 'flex',
                                alignItems: 'center'
                              },
                              '&.MuiInputBase-root': {
                                backgroundColor: renderColorBackGround(formik.values.criticality)
                              },
                              borderRadius: '100px'
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="Critical">
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                              </Box>
                              <Typography variant="body2">Critical</Typography>
                            </MenuItem>
                            <MenuItem sx={{ backgroundColor: '#FCD34D' }} value="High">
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F59E0B' }} />
                              </Box>
                              <Typography variant="body2">High</Typography>
                            </MenuItem>

                            <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'Medium'}>
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                              </Box>
                              <Typography variant="body2">Medium</Typography>
                            </MenuItem>

                            <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'Low'}>
                              {' '}
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                              </Box>
                              <Typography variant="body2">Low</Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ my: 1 }}>
                        <Box>
                          <List sx={{ py: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Risk Name
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  defaultValue={remediationData?.name}
                                  onChange={formik.handleChange}
                                  fullWidth
                                  disabled={isGap && true}
                                  name="name"
                                  placeholder="Type here"
                                  value={isGap ? remediationData?.name : formik.values.name}
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  sx={{ fontSize: '14px' }}
                                />
                              </Box>
                            </Box>
                          </List>

                          <List sx={{ py: 1.5 }}>
                            <CyberSecurityDomainDropdown
                              name=""
                              label={'Cybersecurity Domain'}
                              disabled={isGap}
                              value={formik.values.services_uuid}
                              onChange={(e: any) => {
                                formik.handleChange(e);
                                formik.setFieldValue('integration_tools_uuid', '');
                              }}
                              useUUID={true}
                            />
                          </List>
                          {servicesOption?.find(
                            (service) => service?.value === formik?.values?.services_uuid
                          )?.tools?.length > 0 && (
                            <List sx={{ py: 1.5 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                  gap: 2
                                }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontSize: '14px', fontWeight: 600 }}>
                                  Tool Name
                                </Typography>
                                <Box sx={{ width: 1 }}>
                                  <Select
                                    size="small"
                                    displayEmpty
                                    fullWidth
                                    id="type"
                                    sx={{
                                      fontSize: '14px',
                                      color: '#696969',
                                      minWidth: { xxs: 0, md: '188px' },
                                      backgroundColor: '#FFFFFF'
                                    }}
                                    defaultValue=""
                                    name="integration_tools_uuid"
                                    value={formik.values.integration_tools_uuid}
                                    onChange={formik.handleChange}
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    <MenuItem value="" sx={{ display: 'none' }}>
                                      Select Tool
                                    </MenuItem>
                                    {servicesOption
                                      ?.find(
                                        (service) =>
                                          service?.value === formik?.values?.services_uuid
                                      )
                                      ?.tools?.map((tool: any, index) => (
                                        <MenuItem
                                          sx={{ fontSize: '14px' }}
                                          value={tool.integration_tools_uuid}
                                          key={index}>
                                          <Box sx={{ display: 'flex', gap: 0.5 }}>
                                            <Box>
                                              {tool?.vendor?.name ? `${tool?.vendor?.name} -` : ''}
                                            </Box>
                                            <Box>
                                              <b>{tool?.name}</b>
                                            </Box>
                                          </Box>
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </Box>
                              </Box>
                            </List>
                          )}

                          <List sx={{ pt: 1.5, pb: 0 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Asset Type
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <FormControl sx={{ width: 1 }}>
                                  <Select
                                    size="small"
                                    displayEmpty
                                    multiple
                                    fullWidth
                                    placeholder="Select Asset"
                                    name="Assets"
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={formik.values.Assets}
                                    onChange={handleChangeAsset}
                                    input={
                                      <OutlinedInput
                                        sx={{
                                          py: '5.5px'
                                        }}
                                      />
                                    }
                                    renderValue={(selected) => renderValue(selected, AssetList)}
                                    sx={{
                                      py: 0.35,
                                      width: 1,
                                      fontSize: '14px',
                                      color: '#696969',
                                      '.MuiSelect-select': {
                                        py: '5.5px'
                                      },
                                      minWidth: { xxs: 0, md: '150px' },
                                      backgroundColor: '#FFFFFF'
                                    }}
                                    inputProps={{ 'aria-label': 'Without label' }}>
                                    {AssetList.map((name, index) => (
                                      <MenuItem
                                        sx={{
                                          fontSize: '14px',
                                          display: !name.value ? 'none' : 'flex',

                                          py: 0,
                                          '.MuiMenuItem-root': {
                                            py: '0px'
                                          }
                                        }}
                                        key={name.key}
                                        autoFocus={index === 1}
                                        value={name.value}>
                                        <Checkbox
                                          checked={formik?.values?.Assets?.indexOf(name.value) > -1}
                                        />
                                        <ListItemText
                                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                                          primary={name.key}
                                        />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          </List>

                          <List sx={{ py: 0 }}>
                            {RenderAssetList(formik?.values?.Assets)?.map((el) => {
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    my: 3,
                                    alignItems: 'flex-start',
                                    gap: 2
                                  }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '14px', fontWeight: 600 }}>
                                    {el?.key}
                                  </Typography>
                                  <Box sx={{ width: 1 }}>
                                    <TextField
                                      size="small"
                                      name="assets"
                                      fullWidth
                                      onChange={(e: any) =>
                                        formik.setFieldValue(el?.value, e.target.value)
                                      }
                                      value={formik.values[el.value]}
                                      inputProps={{ sx: { fontSize: '14px' } }}
                                      variant="outlined"
                                      placeholder={el?.placeholder}
                                      sx={{ fontSize: '14px' }}
                                      // defaultValue={rowData?.recommendation}
                                    />
                                  </Box>
                                </Box>
                              );
                            })}
                          </List>

                          <List sx={{ pt: formik?.values?.Assets?.[0] ? 0 : 3, pb: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',

                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Description
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  name="description"
                                  placeholder="Type your answer here..."
                                  fullWidth
                                  defaultValue={remediationData?.description}
                                  onChange={(e) => {
                                    if (e?.target?.value?.length <= 500) {
                                      formik.setFieldValue('description', e.target.value);
                                    }
                                  }}
                                  value={formik.values.description}
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  rows={7}
                                  multiline
                                  sx={{ fontSize: '14px' }}
                                />
                              </Box>
                            </Box>
                          </List>

                          <List sx={{ py: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Recommendation
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  placeholder="Type your answer here..."
                                  name="recommendation"
                                  fullWidth
                                  defaultValue={remediationData?.recommendation}
                                  onChange={formik.handleChange}
                                  value={formik.values.recommendation}
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  rows={7}
                                  multiline
                                  sx={{ fontSize: '14px' }}
                                />
                              </Box>
                            </Box>
                          </List>

                          <List sx={{ py: 2 }}>
                            <FormControl variant="outlined" fullWidth>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                                Tag
                              </Typography>
                              <Autocomplete
                                multiple
                                id="tags-filled"
                                freeSolo
                                options={tagOptions.map((el) => el.tag_name)}
                                value={formik.values.tags}
                                renderTags={(value, getTagProps) => {
                                  return value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                      onDelete={() => handleClickOpenDelete(option, value)}
                                    />
                                  ));
                                }}
                                selectOnFocus
                                onChange={(event: any, newValue, reason) => {
                                  if (
                                    reason == 'selectOption' ||
                                    reason == 'removeOption' ||
                                    reason == 'clear'
                                  ) {
                                    handleTagChange(event, newValue);
                                  } else {
                                    const optionToSelect = tagOptions.find((option) => {
                                      if (
                                        option?.tag_name?.toLowerCase() ===
                                        event.target.value.toLowerCase()
                                      ) {
                                        return option;
                                      }
                                    });
                                    if (optionToSelect) {
                                      handleTagChange(event, [
                                        ...formik.values.tags,
                                        optionToSelect.tag_name
                                      ]);
                                    } else {
                                      handleTagChange(event, newValue);
                                    }
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    error={
                                      tagErrorMessage.maxLimit || tagErrorMessage.tagLimit
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      tagErrorMessage.maxLimit
                                        ? tagErrorMessage.maxLimit
                                        : tagErrorMessage.tagLimit
                                    }
                                    {...params}
                                    onChange={(e: any) => {
                                      if (e.target.value) {
                                        if (e.target.value.startsWith('_')) {
                                          setTagErrorMessage({
                                            ...tagErrorMessage,
                                            maxLimit: `Please Don't Start the tag with (_)`
                                          });
                                        } else if (!validateString(e.target.value)) {
                                          setTagErrorMessage({
                                            ...tagErrorMessage,
                                            maxLimit: `Please don't add specific characters or numbers just Alphapetical Letters ONLY`
                                          });
                                        } else if (e.target.value.length > 50) {
                                          setTagErrorMessage({
                                            ...tagErrorMessage,
                                            maxLimit:
                                              'You have reached the maximum limit of characters per Tag'
                                          });
                                        } else {
                                          setTagErrorMessage({
                                            ...tagErrorMessage,
                                            maxLimit: ''
                                          });
                                        }
                                      } else {
                                        setTagErrorMessage({
                                          ...tagErrorMessage,
                                          maxLimit: ''
                                        });
                                      }
                                    }}
                                    placeholder={formik?.values?.tags.length > 0 ? '' : 'Add Tags'}
                                  />
                                )}
                                sx={{
                                  '&.MuiAutocomplete-root': {
                                    '.MuiAutocomplete-input': {
                                      minWidth: '70px'
                                    },
                                    '.MuiOutlinedInput-root': {
                                      p: 0,
                                      pl: 1,
                                      pr: 1,
                                      '.MuiAutocomplete-clearIndicator': {
                                        margin: '0px -6px 0px 0px'
                                      }
                                    },
                                    '.MuiAutocomplete-tag': {
                                      m: '7px',
                                      bgcolor: 'common.lightBlue',
                                      border: 'none'
                                    },
                                    '.MuiAutocomplete-endAdornment': {
                                      right: '5px'
                                    }
                                  }
                                }}
                              />
                              <Dialog
                                fullWidth
                                maxWidth="sm"
                                open={openDelete}
                                onClose={handleCloseDelete}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">
                                <DialogTitle
                                  id="alert-dialog-title"
                                  sx={{
                                    px: { xs: 1, md: 2 },
                                    py: { xs: 0.5, md: 1 },
                                    bgcolor: 'error.red100',
                                    mb: { xs: 2, md: 3 }
                                  }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center'
                                    }}>
                                    <Typography
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'error.main',
                                        fontWeight: '600',
                                        maxWidth: 'calc(100% - 40px)',
                                        p: 1
                                      }}>
                                      <WarningIcon sx={{ mr: 1 }} />
                                      Confirm Deletion
                                    </Typography>
                                    <IconButton
                                      sx={{ color: 'text.primary' }}
                                      aria-label="close"
                                      onClick={handleCloseDelete}>
                                      <CloseIcon />
                                    </IconButton>
                                  </Box>
                                </DialogTitle>
                                <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
                                  <DialogContentText
                                    id="alert-dialog-description"
                                    sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
                                    Are you sure do you want to delete this Tag?
                                  </DialogContentText>

                                  <DialogContentText
                                    id="alert-dialog-description"
                                    sx={{ color: 'text.primary' }}>
                                    {deleteOption}
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions
                                  sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
                                  <Button
                                    variant="outlined"
                                    sx={{ minWidth: 100 }}
                                    onClick={handleCloseDelete}>
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{ minWidth: 100 }}
                                    color="error"
                                    onClick={() => handleConfirmDelete(deleteOption)}>
                                    {' '}
                                    Confirm
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </FormControl>
                          </List>

                          <List sx={{ py: 1.5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Date Discovered
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <DatePicker
                                  showIcon
                                  maxDate={moment().toDate()}
                                  className="calendar-form"
                                  icon={<CalenderIconFilter />}
                                  dateFormat="dd MMM yyyy"
                                  placeholderText="DD MMM YYYY"
                                  selected={formik.values.discovered_at || new Date()}
                                  onChange={(date: any, event: any) => {
                                    formik.setFieldValue('discovered_at', date);
                                  }}
                                />
                              </Box>
                            </Box>
                          </List>
                        </Box>
                      </Box>
                    </Box>
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <Divider />
                    {/* <Box sx={{ display: 'inline-block' }}>
                  {rowData?.files.map((element, index) => (
                    <Box
                      onClick={() => getFile(element)}
                      sx={{
                        display: 'flex',
                        gap: 1,
                        mt: 2,

                        alignItems: 'center',
                        backgroundColor: '#D9EDFF',
                        py: '5px',
                        px: 1.5,
                        borderRadius: '100px',
                        cursor: 'pointer'
                      }}>
                      <PinIcon />
                      <Typography variant="body1" sx={{ color: '#436AF3' }}>
                        {element.file_name}
                      </Typography>
                    </Box>
                  ))}
                  {rowData?.actions_taken?.map((el, index) =>
                    // index == rowData?.actions_taken?.length - 1 &&
                    // index == 0 &&
                    el?.files.map((element) => (
                      <a
                        href={el.file_url}
                        style={{ textDecoration: 'none' }}
                        target="_blank"
                        onClick={() => getFile(element)}>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 1,
                            mt: 2,

                            alignItems: 'center',
                            backgroundColor: '#D9EDFF',
                            py: '5px',
                            px: 1.5,
                            borderRadius: '100px',
                            cursor: 'pointer'
                          }}>
                          <PinIcon />
                          <Typography variant="body1" sx={{ color: '#436AF3' }}>
                            {element.file_name}
                          </Typography>
                        </Box>
                      </a>
                    ))
                  )}
                </Box> */}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Divider />
                    <Box sx={{ display: 'inline-block' }}>
                      {/* {remediationHistory.map((element: any, index) => (
                    <Typography variant="body2" sx={{ color: '#696969', mt: 2 }}>
                      {`At ${moment(element.created_at)
                        .format('DD MMM YYYY')
                        .toUpperCase()} as ${moment(element.created_at)
                        .format('hh:mm A zz')
                        .toUpperCase()} ${
                        element.owner.first_name + ' ' + element.owner.last_name
                      } ${element.action}`}
                    </Typography>
                  ))} */}
                    </Box>
                  </TabPanel>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                px: 3,
                py: 2,
                textAlign: 'end'
              }}>
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => toggleDrawer('right', false)}
                  sx={{ minWidth: 150 }}
                  variant="outlined">
                  Cancel
                </Button>
                <LoadingButton
                  loading={isRemediationButtonLoading}
                  disabled={
                    compalate || tagErrorMessage.maxLimit || tagErrorMessage.tagLimit ? true : false
                  }
                  variant="contained"
                  sx={{
                    minWidth: 150,
                    border: '1px solid #436AF3',
                    '&.Mui-disabled': {
                      border: '1px solid #E0E0E0'
                    }
                  }}
                  type="submit">
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </form>
      </SwipeableDrawer>
    </Box>
  );
};

export default CreateRisk;
