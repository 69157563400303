import {
  Box,
  Skeleton,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  SwipeableDrawer
} from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import HeadingHeader from '../Common/HeadingHeader';
import SearchIcon from '@jibin/common/icons/Search/Search';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import { NoDataAvailable, NoSearchDataAvailable } from '../Common/NoDataAvailable';
import { RowData } from './RowData';
import TablePagination from '../Common/TablePagination';
import { UserManagementApi } from './UserManagement.Api';
import { shallowEqual, useSelector } from 'react-redux';
import RemediationPopup from './RemediationPopup';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const UserManagement = () => {
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [userList, setUserList] = useState<any[]>([]);
  const [summaryData, setSummaryData] = useState<any[]>([]);
  const [rowData, setRowData] = useState(null);
  const [initialLength, setInitialLength] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const [state, setState] = useState({
    right: false
  });
  const [userFilter, setUserFilter] = useState({
    keyword: '',
    status: '',
    mfa: '',
    role: ''
  });

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100
      }
    }
  };

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const userStatusItems = [
    { key: 'Active', value: 'Active' },
    { key: 'Inactive', value: 'Inactive' },
    { key: 'Unknown', value: 'Unknown' },
    { key: 'All Statuses', value: '' }
  ];

  const mfaStatusItems = [
    { key: 'Enable', value: 'Active' },
    { key: 'Disabled', value: 'Inactive' },
    { key: 'MFA Statuses', value: '' }
  ];

  const rolesItems = [
    { key: 'Admin', value: 'Admin' },
    { key: 'Guest', value: 'Guest' },
    { key: 'Member', value: 'Member' },
    { key: 'All Roles', value: '' }
  ];

  const totalSummaryCount = () => {
    const totalCount = summaryData?.reduce((acc, item) => acc + item.count, 0);
    return totalCount?.toLocaleString();
  };

  const findSummaryCount = (title: string) => {
    const activeItem = summaryData?.find((item) => item.title === title);
    return activeItem?.count?.toLocaleString();
  };

  const accountStatus = (status: string) => {
    let statusColor = '#EF4444';
    status = status.toLowerCase();
    if (status === 'active') {
      statusColor = '#10B981';
    } else if (status === 'pending') {
      statusColor = '#FBBF24';
    }
    return statusColor;
  };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };

  const handleChange = (e: any) => {
    let updatedValue = e.target.value;
    if (Array.isArray(e.target.value)) {
      updatedValue = e.target.value.filter(Boolean).join(',');
    }
    setUserFilter({ ...userFilter, [e.target.name]: updatedValue });
  };

  const handleClearFuntion = () => {
    setUserFilter({
      keyword: '',
      status: '',
      mfa: '',
      role: ''
    });
  };

  const GetSummary = async () => {
    try {
      const res = await UserManagementApi.GetSummary(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid
      );
      setSummaryData(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserList = useCallback(() => {
    UserManagementApi.GetUserList(
      commonDetails.data.company.company_uuid,
      commonDetails.data.user_uuid,
      new URLSearchParams({
        ...userFilter,
        ...pagination
      }).toString()
    ).then(
      (q: any) => {
        setUserList(q?.data?.data?.data);
        setTotalCount(q?.data?.data?.pagination?.total_records);
        if (isSkeleton && q?.data?.data) {
          setInitialLength(true);
        }
        setIsSkeleton(false);
      },
      (err: any) => {
        setIsSkeleton(false);
      }
    );
  }, [commonDetails, userFilter, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    GetUserList();
  }, [GetUserList, userFilter]);

  useEffect(() => {
    GetSummary();
  }, []);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [userFilter]);

  const toggleDrawer = (anchor: Anchor, open: boolean, row: any, isSetParams?: boolean) => {
    if (!open) {
      setRowData(null);
    }
    setRowData(row);
    setState({ ...state, [anchor]: open });
  };

  return (
    <Box>
      <HeadingHeader
        subtitle1={'User Management'}
        subtitle2={'Streamline user management and account oversight'}
      />
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <>
          <Box sx={{ py: 3, px: 4 }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Summary
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={2} md={2} lg={3}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#436AF3',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Total User Accounts'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {totalSummaryCount()}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={2} md={2} lg={3}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#EF4444',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Inactive'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {findSummaryCount('Inactive') || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2} md={2} lg={3}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#10B981',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Active'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {findSummaryCount('Active') || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2} md={2} lg={3}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#FDE68A',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Unknown'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {findSummaryCount('Unknown') || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ px: 4, backgroundColor: '#FBFBFF', width: 1 }}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'flex-start',
                justifyContent: 'space-between'
              }}>
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
                <TextField
                  placeholder="Search by name or e-mail"
                  hiddenLabel
                  size="small"
                  name="keyword"
                  value={userFilter.keyword}
                  onChange={handleChange}
                  sx={{
                    input: { backgroundColor: '#FFFFFF' },
                    '.MuiOutlinedInput-root': {
                      bgcolor: '#FFFFFF'
                    }
                  }}
                  inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />

                <FormControl sx={{ width: 150 }}>
                  <Select
                    size="small"
                    displayEmpty
                    multiple
                    name="status"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={userFilter.status.split(',')}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => renderValue(selected, userStatusItems)}
                    MenuProps={MenuProps}
                    sx={{
                      py: 0.35,
                      fontSize: '14px',
                      '.MuiSelect-select': {
                        py: '5.5px'
                      },
                      color: '#696969',
                      minWidth: { xxs: 0, md: '150px' },
                      backgroundColor: '#FFFFFF'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {userStatusItems?.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          display: !name.value ? 'none' : 'flex',

                          py: 0,
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name.key}
                        value={name.value}
                        autoFocus={index === 0}>
                        <Checkbox checked={userFilter.status.indexOf(name.value) > -1} />
                        <ListItemText
                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                          primary={name.key}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: 150 }}>
                  <Select
                    size="small"
                    displayEmpty
                    multiple
                    name="mfa"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={userFilter.mfa.split(',')}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => renderValue(selected, mfaStatusItems)}
                    MenuProps={MenuProps}
                    sx={{
                      py: 0.35,
                      fontSize: '14px',
                      '.MuiSelect-select': {
                        py: '5.5px'
                      },
                      color: '#696969',
                      minWidth: { xxs: 0, md: '150px' },
                      backgroundColor: '#FFFFFF'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {mfaStatusItems?.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          display: !name.value ? 'none' : 'flex',

                          py: 0,
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name.key}
                        value={name.value}
                        autoFocus={index === 0}>
                        <Checkbox checked={userFilter.mfa.indexOf(name.value) > -1} />
                        <ListItemText
                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                          primary={name.key}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: 150 }}>
                  <Select
                    size="small"
                    displayEmpty
                    multiple
                    name="role"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={userFilter.role.split(',')}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => renderValue(selected, rolesItems)}
                    MenuProps={MenuProps}
                    sx={{
                      py: 0.35,
                      fontSize: '14px',
                      '.MuiSelect-select': {
                        py: '5.5px'
                      },
                      color: '#696969',
                      minWidth: { xxs: 0, md: '150px' },
                      backgroundColor: '#FFFFFF'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {rolesItems?.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          display: !name.value ? 'none' : 'flex',

                          py: 0,
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name.key}
                        value={name.value}
                        autoFocus={index === 0}>
                        <Checkbox checked={userFilter.role.indexOf(name.value) > -1} />
                        <ListItemText
                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                          primary={name.key}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box sx={{ width: 120 }}>
                  <Button
                    onClick={handleClearFuntion}
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center',
                      py: 0.5,
                      whiteSpace: 'nowrap'
                    }}
                    variant="text">
                    {' '}
                    <FilterCancelIcon /> Clear filter
                  </Button>
                </Box>
              </Box>
            </Box>
            {userList?.length == 0 || !userList ? (
              initialLength ? (
                <NoSearchDataAvailable
                  title="No Search Results"
                  message="Try adjusting your search or filter to find what you are looking for"
                />
              ) : (
                <NoDataAvailable
                  title="No Data Available"
                  message="Please provide your company's technical information to enable your security services."
                />
              )
            ) : (
              <>
                <TableContainer
                  component={Paper}
                  sx={{ border: 'none', boxShadow: 'none', bgcolor: 'transparent', overflowX: 'inherit' }}>
                  <Table
                    sx={{
                      minWidth: 650,
                      borderCollapse: 'separate',
                      // backgroundColor: '#FBFBFF',
                      boxShadow: 'none',
                      borderSpacing: '0px 16px',
                      border: 'none'
                    }}
                    className="p10Table"
                    aria-label="site list table">
                    <TableHead
                      sx={{
                        position: 'sticky',
                        top: '86px',
                        zIndex: '1',
                        backgroundColor: '#fbfbff'
                      }}>
                      <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: '500' }}>Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: '500' }}>Account</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: '500' }}>Email</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: '500' }}>Role</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: '500' }}>Account Status</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: '500' }}>Last Login</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: '500' }}>MFA Status</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ fontWeight: '500' }}></Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userList?.length > 0
                        ? userList?.map((row: any, index: any) => {
                          return (
                            <RowData
                              row={row}
                              index={index}
                              toggleDrawer={toggleDrawer}
                              accountStatus={accountStatus}
                            />
                          );
                        })
                        : null}
                    </TableBody>
                  </Table>
                  <TablePagination
                    totalCount={totalCount}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </TableContainer>
              </>
            )}
          </Box>
        </>
      )}

      <SwipeableDrawer
        BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
        sx={{
          '.MuiDrawer-paper': {
            boxShadow: 'none'
          }
        }}
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false, {})}
        onOpen={() => toggleDrawer('right', true, {})}>
        <RemediationPopup
          rowData={rowData}
          onClose={() => toggleDrawer('right', false, {})}
          GetUserList={GetUserList}
        />
      </SwipeableDrawer>
    </Box>
  );
};

export default UserManagement;
