import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '../utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import HeadingHeader from '@jibin/app/src/Pages/Common/HeadingHeader';

// const MenuItems = [
//   'Company Profile', 'Manage Users', 'Meetings', 'Authorizations', 'Plan and Billing'
// ]

// const MenuItems = [
//   { name: 'General', class: '', route: PageRouteConstants.General },
//   { name: 'Manage Users', class: '', route: PageRouteConstants.ManageUsers },
//   { name: 'Integrations', class: '', route: PageRouteConstants.Integration },
//   { name: 'Locations', class: '', route: PageRouteConstants.CompanyProfile },
//   { name: 'Authorizations', class: '', route: PageRouteConstants.Authorizations },
//   { name: 'Licenses', class: '', route: PageRouteConstants.Licenses },
//   { name: 'Billing', class: '', route: PageRouteConstants.PlanAndBilling },
//   { name: 'Audit Logs', class: '', route: PageRouteConstants.AuditLogs }
// ];

export const CompanySettings: FC<{ children: React.ReactNode | React.ReactNode[] }> = (props) => {
  const navigate = useNavigate();
  const [MenuItems, setMenuItems] = useState([
    { name: 'General', class: '', route: PageRouteConstants.General },
    { name: 'Manage Users', class: '', route: PageRouteConstants.ManageUsers },
    { name: 'Integrations', class: '', route: PageRouteConstants.Integration },
    { name: 'Locations', class: '', route: PageRouteConstants.CompanyProfile },
    { name: 'Licenses', class: '', route: PageRouteConstants.Licenses },
    { name: 'Billing', class: '', route: PageRouteConstants.PlanAndBilling },
    { name: 'Audit Logs', class: '', route: PageRouteConstants.AuditLogs }
  ]);
  const menuItemsRefs = MenuItems.map(() => React.createRef<HTMLAnchorElement>());
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const servicesDetials = useSelector(
    ({ servicesDetials }: any) => servicesDetials?.servicesDetials,
    shallowEqual
  );
  useEffect(() => {
    const penetrationTesting = servicesDetials?.find((item) => item?.name === 'Penetration Testing');
    const isAuthorizationAlreadyPresent = MenuItems.some((item) => item?.name === 'Authorizations');

    if (penetrationTesting && Object.keys(penetrationTesting).length > 0 && !isAuthorizationAlreadyPresent) {
      setMenuItems((prevMenuItems) => [
        ...prevMenuItems,
        { name: 'Authorizations', class: '', route: PageRouteConstants.Authorizations }
      ]);
    } else if (!penetrationTesting && isAuthorizationAlreadyPresent) {
      setMenuItems((prevMenuItems) => prevMenuItems.filter(item => item.name !== 'Authorizations'));
    }
  }, [servicesDetials, commonDetails]);

  const handleItemClick = (index) => {
    navigate(MenuItems[index].route);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Tab') {
      event.preventDefault(); // Prevent default tab behavior
      const nextIndex = (index + 1) % MenuItems.length;
      menuItemsRefs[nextIndex]?.current?.focus();
    } else if (event.key === 'Enter') {
      handleItemClick(index);
    }
  };

  return (
    <>
      <HeadingHeader
        subtitle1={
          commonDetails?.data?.company?.type == 'engineering' ? 'Manage Users' : 'Company Account'
        }
      />

      {commonDetails?.data?.company?.type == 'engineering' ? (
        <Box></Box>
      ) : (
        <List sx={Style.CompanySetting.MenuList}>
          {MenuItems.map((currentElement, index) => (
            <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
              {currentElement.name != 'Billing' && (
                <Link
                  ref={menuItemsRefs[index]}
                  onClick={() => handleItemClick(index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  style={{ cursor: 'pointer', fontFamily: 'SF UI Text' }}
                  className={window.location.pathname === currentElement.route ? 'active' : ''}
                  sx={Style.CompanySetting.MenuListItem}
                  tabIndex={0} // Ensure the link is focusable
                >
                  {currentElement.name}
                </Link>
              )}
            </ListItem>
          ))}
        </List>
      )}
      {props.children}
    </>
  );
};

export default CompanySettings;
