import { useState } from 'react';
import { TableRow, TableCell, IconButton, Menu, MenuItem, Typography, Box } from '@mui/material';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import { TickMarkBlue } from '@jibin/common/icons/Tickmark/Tickmark';
import moment from 'moment';
import { CorrectIconRounder } from '@jibin/common/icons/CorrectIcon/CorrectIcon';
import { CrossCancleRounded } from '@jibin/common/icons/CrossCancle/CrossCancle';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import Style from '@jibin/common/style/Styles';

export const RowData = (props) => {
  const { row, index, toggleDrawer, accountStatus } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClickIcon = (event: any, row: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseIcon = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow
        id={row?.ms_entra_users_uuid}
        key={index}
        sx={{
          cursor: 'pointer',

          bgcolor: '#fff',
          '.MuiTableCell-root': {
            px: '20px',

            borderBottom: '1px solid  #CCCCCC',
            borderTopRadius: '4px',
            borderTop: '1px solid  #CCCCCC',
            '&:last-child': {
              borderRight: '1px solid  #CCCCCC',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px'
            },
            '&:first-child': {
              borderLeft: '1px solid  #CCCCCC',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px'
            }
          }
        }}>
        <TableCell sx={{ width: '300px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" sx={{ ...Style.UserNameRound, color: '#696969', flex: '1 0 auto', maxWidth: '32px' }}>
              {row?.displayName?.charAt(0) + row?.displayName?.charAt(1)}
            </Typography>
            {row?.displayName}
          </Box>
        </TableCell>
        <TableCell>{row?.tool?.name}</TableCell>
        <TableCell sx={{ width: '330px', wordBreak: 'break-all' }}>{row?.mail || row?.userPrincipalName}</TableCell>
        <TableCell>{row?.userType}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
              sx={{
                minWidth: '14px',
                height: '14px',
                width: '14px',
                backgroundColor: accountStatus(row?.status),
                borderRadius: '50%'
              }}></Box>
            <Typography sx={{ whiteSpace: 'nowrap', marginTop: '2px' }} variant="caption">
              {row?.status}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {' '}
            {row?.lastSeen && (
              <>
                {moment.utc(row?.lastSeen).format('DD MMM YYYY').toUpperCase()}
                <br />
                {moment.utc(row?.lastSeen).format('hh:mm:ss A').toUpperCase()}
              </>
            )}
          </Typography>
        </TableCell>

        <TableCell>
          {(row.mafMethod === 'Active' || row.mafMethod === 'Inactive') && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {row.mafMethod === 'Active' ? <CorrectIconRounder /> : <CrossCancleRounded />}
              <Typography variant="caption" sx={{ marginLeft: '4px', marginTop: '2px' }}>
                {row.mafMethod === 'Active' ? 'Enabled' : 'Disabled'}
              </Typography>
            </Box>
          )}
        </TableCell>

        <TableCell>
          <IconButton
            onClick={(e) => {
              handleClickIcon(e, row);
            }}
            className="icon-button"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <MoreHorizIcon color="primary" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id={'account-menu'}
            open={open}
            onClose={handleCloseIcon}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            {!!row?.remediation_uuid ? (
              <MenuItem
                onClick={() => {
                  navigate({
                    pathname: PageRouteConstants.Remediation,
                    search: `remediation_uuids=${row.remediation_uuid}`
                  });
                }}>
                <TickMarkBlue />
                <Typography sx={{ ml: 1 }} variant="caption">
                  {' '}
                  Open Remediation
                </Typography>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  toggleDrawer('right', true, row);
                  setAnchorEl(null);
                }}>
                <TickMarkBlue />
                <Typography sx={{ ml: 1 }} variant="caption">
                  {' '}
                  Create Remediation
                </Typography>
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
};
