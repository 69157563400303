import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { CompanyManagementApi } from './CompanyManagement.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { CompnayManageMentContex } from './CompanyManagementTemplate';
import TablePagination from '../Common/TablePagination';
import { NoDataAvailable } from '../Common/NoDataAvailable';
var debounce = require('lodash.debounce');

export const CompanyManagementContactTable = () => {
  const { companyData } = useContext(CompnayManageMentContex);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [companyContactsData, setCompanyContactsData] = useState([]);
  const [useParams, setUseParam] = useSearchParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#27AE60';
        break;

      case 'inactive':
        color = '#EF4444';
        break;

      case 'not active':
        color = '#B7B7B7';
        break;

      case 'deregistered':
      case 'pending':
        color = '#FCD34D';
        break;
    }

    return color;
  };

  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };

  const GetAllCompany = useCallback(async () => {
    try {
      setIsSkeleton(true);
      const res = await CompanyManagementApi.GetCompanyContacts(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        useParams.get('q'),
        new URLSearchParams(pagination).toString()
      );
      setTotalCount(res?.data?.pagination?.total_records);
      setCompanyContactsData(res?.data?.data);
    } catch (error) {
    } finally {
      setIsSkeleton(false);
    }
  }, [commonDetails, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const handleInviteUser = useCallback(
    debounce(
      async (raw: any) => {
        try {
          const data = {
            external_id: raw.external_id,
            fullName: raw.fullName,
            email: raw.email,
            title: raw.title
          };

          CompanyManagementApi.InviteUser(
            commonDetails.data.company.company_uuid,
            commonDetails.data.user_uuid,
            useParams.get('q'),
            data
          ).then(() => {
            handleRefresh();
          });
        } catch (error) {}
      },
      1000,
      true
    ),
    [useParams.get('q'), isRefresh]
  );

  useEffect(() => {
    if (useParams.get('q')) {
      GetAllCompany();
    }
  }, [GetAllCompany, isRefresh]);

  return (
    <Box sx={{ ...Style.PoliciesAndProcedures.PoliciesTableWrapper, overflowX: 'initial' }}>
      {!isSkeleton ? (
        <>
          {companyContactsData?.length ? (
            <>
              <Table sx={{ minWidth: 650, marginBottom: '12px' }} aria-label="site list table">
                <TableHead
                  sx={{ position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' }}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2">Contact Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">Email</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">Job Title</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">Status</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companyContactsData.map((el: any, index) => {
                    return (
                      <>
                        <Box sx={Style.Common.Divider}></Box>
                        <TableRow
                          key={index + 1}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            cursor: 'pointer',

                            borderColor: '#CCCCCC'
                          }}>
                          <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                            <Typography variant="caption">{el.fullName}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption"> {el.email}</Typography>{' '}
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption"> {el.title}</Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: 150 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                  backgroundColor: renderColorBackGround(el.status),
                                  borderRadius: '50%'
                                }}
                              />
                              <Typography variant="caption">{el.status}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              disabled={
                                el.status.toLowerCase() === 'active' ||
                                el.status.toLowerCase() === 'inactive' ||
                                companyData?.status?.toLowerCase() == 'inactive'
                              }
                              onClick={() => {
                                handleInviteUser(el);
                              }}
                              sx={{
                                width: '126px'
                              }}>
                              {el.status.toLowerCase() === 'not active' && 'Invite'}
                              {el.status.toLowerCase() === 'pending' && 'Resend Invite'}
                              {el.status.toLowerCase() === 'active' && 'Invite'}
                              {el.status.toLowerCase() === 'inactive' && 'Invite'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                totalCount={totalCount}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </>
          ) : (
            <NoDataAvailable
              title="No Data Available"
              message="This company has not been onboarded"
            />
          )}
        </>
      ) : (
        <Table
          sx={{ minWidth: 650, borderSpacing: '0px 10px', borderCollapse: 'separate' }}
          aria-label="site list table">
          <TableHead
            sx={{ position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' }}>
            <TableRow>
              <TableCell>Contact Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                {' '}
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </Typography>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default CompanyManagementContactTable;
