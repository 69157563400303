import { useState, FC, useCallback, useEffect } from 'react';
import {
  Box,
  Skeleton,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
  Menu,
  IconButton,
  Button,
  TableContainer,
  Typography,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  Paper,
  Tooltip,
  InputLabel,
  InputAdornment,
  TextField,
  SwipeableDrawer,
  Grid
} from '@mui/material';
import HeadingHeader from '../Common/HeadingHeader';
import { shallowEqual, useSelector } from 'react-redux';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalenderIconFilter from '@jibin/common/icons/CalenderIconFilter/CalenderIconFilter';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import moment from 'moment';
import Event from './Event';
import FilterListIcon from '@mui/icons-material/FilterList';
import { NoDataAvailable, NoSearchDataAvailable } from '../Common/NoDataAvailable';
import TablePagination from '../Common/TablePagination';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import { CustomIcon } from '@jibin/common/icons/AssetsIcons/CustomIcon';
import DomainIcon from '@jibin/common/icons/AssetsIcons/Domain';
import IpIcon from '@jibin/common/icons/AssetsIcons/IpIcon';
import MacAddressIcon from '@jibin/common/icons/AssetsIcons/macAddressIcon';
import MachineIcon from '@jibin/common/icons/AssetsIcons/MachineIcon';
import IconButtonComponent from './IconButton';
import { SIEMApi } from './SIEM.Api';
import DeleteDialog from './DeleteDialog';
import { CompanySettingsService } from '../CompanySettings/CompanySettings.Api';
import FilterDropdown from './FilterDropdown';
import { RiskManagementApi } from '../RiskManagement/RiskManagement.Api';
import { RemediationApi } from '../Remediation/Remediation.Api';
import SIEMSideBarUser from './NetworkDetectionSideBar/SIEMSideBarUser';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { useSearchParams } from 'react-router-dom';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const RowData = ({
  row,
  index,
  rowData,
  setRowData,
  setOpenDelete,
  anchorEl,
  setAnchorEl,
  setIsEdit,
  handleRowClick,
  remidiationDrawer,
  GetEndPoint,
  GetEventSummary,
  setIsCreateRemediation
}) => {
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FCD34D';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };

  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };

  return (
    <>
      <TableRow
        id={row?.event_uuid}
        key={index}
        onClick={(e) => handleRowClick(e, row)}
        sx={{
          cursor: 'pointer',

          bgcolor: '#fff',
          '.MuiTableCell-root': {
            px: '20px',

            borderBottom: '1px solid  #CCCCCC',
            borderTopRadius: '4px',
            borderTop: '1px solid  #CCCCCC',
            '&:last-child': {
              borderRight: '1px solid  #CCCCCC',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px'
            },
            '&:first-child': {
              borderLeft: '1px solid  #CCCCCC',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px'
            }
          }
        }}>
        {/* <TableCell
          key={row?.name}
          // onClick={(event) => {
          //     if (!isDataLoaded) {
          //         handleClickCheck(event, row?.risk_uuid);
          //         stopImmediatePropagation(event);
          //     }
          // }}
          role="checkbox"
          // aria-checked={isItemSelected}
          tabIndex={-1}
          padding="checkbox">
          <Checkbox
            // disabled={isDataLoaded}
            // checked={isItemSelected}
            checkedIcon={<CheckBoxLightBlue />}
            inputProps={
              {
                // 'aria-labelledby': labelId
              }
            }
            icon={
              <CheckBoxOutLine
                sx={{
                  fill: 'none',
                  width: '21px',
                  height: '20px'
                }}
              />
            }
          />
        </TableCell> */}
        <TableCell component="th" scope="row">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  backgroundColor: renderColorBackGround(row?.criticality?.toLowerCase()),
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  width: 100,
                  justifyContent: 'center',
                  borderRadius: '100px',
                  py: 0.5,
                  px: 2
                }}>
                <FlagIcon
                  sx={{
                    backgroundColor: renderColorBackGround(row?.criticality?.toLowerCase()),
                    color: renderColor(row?.criticality?.toLowerCase()),
                    width: '12px'
                  }}
                />
                <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                  {' '}
                  {row?.criticality}
                </Typography>
              </Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ minWidth: '125px' }}>
          <Typography variant="caption">
            {' '}
            {row?.discovered_at && (
              <>
                {moment.utc(row?.discovered_at).format('DD MMM YYYY').toUpperCase()}
                <br />
                {moment.utc(row?.discovered_at).format('hh:mm:ss A').toUpperCase()}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <EllipsisContent
            variant="caption"
            maxWidth={300}
            text={row?.name}
          />
        </TableCell>
        <TableCell align="left">{row?.tool?.name}</TableCell>
        <TableCell sx={{ maxWidth: 250 }} align="left">
          <Box>
            <Box sx={{ display: 'flex' }}>
              {row?.asset?.custom && (
                <Tooltip arrow placement={'top'} title={row?.asset?.custom}>
                  <IconButton>
                    <CustomIcon />
                  </IconButton>
                </Tooltip>
              )}
              {row?.asset?.ip && (
                <Tooltip arrow placement={'top'} title={row?.asset?.ip}>
                  <IconButton>
                    <IpIcon />
                  </IconButton>
                </Tooltip>
              )}
              {row?.asset?.mac && (
                <Tooltip arrow placement={'top'} title={row?.asset?.mac}>
                  <IconButton>
                    <MacAddressIcon />
                  </IconButton>
                </Tooltip>
              )}
              {row?.asset?.machine && (
                <Tooltip arrow placement={'top'} title={row?.asset?.machine}>
                  <IconButton>
                    <MachineIcon />
                  </IconButton>
                </Tooltip>
              )}
              {row?.asset?.hostnames?.length > 0 && (
                <Tooltip arrow placement={'top'} title={row?.asset?.hostnames?.join(',')}>
                  <IconButton>
                    <DomainIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </TableCell>

        <TableCell align="left">
          <Typography variant="caption">
            {' '}
            {row.status == 'In Remediation' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    minWidth: '14px',
                    height: '14px',
                    width: '14px',
                    backgroundColor: '#FBBF24',
                    borderRadius: '50%'
                  }}></Box>
                <Typography sx={{ whiteSpace: 'nowrap' }} variant="caption">
                  In Remediation
                </Typography>
              </Box>
            ) : row.status == 'Not Started' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    minWidth: '14px',
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#EF4444',
                    borderRadius: '50%'
                  }}></Box>
                <Typography sx={{ whiteSpace: 'nowrap' }} variant="caption">
                  Not Started
                </Typography>
              </Box>
            ) : row.status == 'False Positive' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    minWidth: '14px',
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#F9A866',
                    borderRadius: '50%'
                  }}></Box>
                <Typography sx={{ whiteSpace: 'nowrap' }} variant="caption">
                  False Positive
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    minWidth: '14px',
                    height: '14px',
                    width: '14px',
                    backgroundColor: '#10B981',
                    borderRadius: '50%'
                  }}></Box>
                <Typography sx={{ whiteSpace: 'nowrap' }} variant="caption">
                  {row.status?.charAt(0).toUpperCase() + '' + row.status.slice(1)}
                </Typography>
              </Box>
            )}
          </Typography>
        </TableCell>
        <TableCell sx={{ maxWidth: 250 }} align="left">
          <IconButtonComponent
            row={row}
            rowData={rowData}
            setRowData={setRowData}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            setOpenDelete={setOpenDelete}
            setIsEdit={setIsEdit}
            GetEndPoint={GetEndPoint}
            GetEventSummary={GetEventSummary}
            remidiationDrawer={remidiationDrawer}
            setIsCreateRemediation={setIsCreateRemediation}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export const SIEM: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [eventList, setEventList] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [initialLength, setInitialLength] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [servicesOption, setServicesOption] = useState([] as any);
  const [toolsOption, setTools] = useState([] as any);
  const [filterAssets, setFilterAssets] = useState([] as any);
  const [vendorTools, setVendorTools] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [assignees, setAssignees] = useState({} as any);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const [state, setState] = useState({
    right: false
  });

  const [useParams] = useSearchParams();
  const [isCreateRemediation, setIsCreateRemediation] = useState(false);

  const CriticalList = [
    { key: 'Critical', value: 'Critical' },
    { key: 'High', value: 'High' },
    { key: 'Medium', value: 'Medium' },
    { key: 'Low', value: 'Low' },
    { key: 'All Criticalities', value: '' }
  ];

  const statusItems = [
    { key: 'Not Started', value: 'Not Started' },
    { key: 'In Remediation', value: 'In Remediation' },
    { key: 'False Positive', value: 'False Positive' },
    { key: 'Resolved', value: 'Resolved' }
  ];

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180
      }
    }
  };

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [invalidDateRange, setInvalidDateRange] = useState(false);
  const [eventSummary, setEventSummary] = useState([]);
  const [eventSummaryTotalCount, setEventSummaryTotalCount] = useState(null);

  const [filterData, setFilterData] = useState({
    discovered_start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
    discovered_end_date: new Date(),
    criticalities: '',
    statuses: '',
    tool_uuids: '',
    assets: '',
    keyword: ''
  });

  const handleClearFuntion = () => {
    setFilterData({
      discovered_start_date: null,
      discovered_end_date: null,
      criticalities: '',
      statuses: '',
      tool_uuids: '',
      assets: '',
      keyword: ''
    });
  };

  const handleChange = (e: any) => {
    let updatedValue = e.target.value;
    if (Array.isArray(e.target.value)) {
      updatedValue = e.target.value.filter(Boolean).join(',');
    }
    setFilterData({ ...filterData, [e.target.name]: updatedValue });
  };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options?.find((option) => option?.value === value);
      return selectedOption ? selectedOption?.key : '';
    });
    return selectedLabels?.join(', ');
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = eventList?.map((n) => n.risk_uuid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const formattedDate = (d) => {
    const date = new Date(d);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const GetAssignees = useCallback(async () => {
    setIsSkeleton(true);
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      setAssignees(res.data);
    } catch (err) {
      setIsSkeleton(false);
      console.log(err);
    }
  }, [commonDetails]);
  useEffect(() => {
    const params = useParams.get('criticality');
    if (params && (params === 'Critical' || params === 'High' || params === 'Medium')) {
      setFilterData((prev) => ({
        ...prev,
        criticalities: params
      }));
    }
  }, []);
  const GetEndPoint = useCallback(async () => {
    try {
      let keyValuePair = '';
      if (Array.isArray(filterData.assets)) {
        filterData.assets.forEach((item) => {
          keyValuePair += keyValuePair != '' ? ',' + item : item;
        });
      }

      const formattedFilterData = {
        ...filterData,
        assets: keyValuePair,
        discovered_start_date: filterData['discovered_start_date'] ? formattedDate(filterData['discovered_start_date']) : '',
        discovered_end_date: filterData['discovered_end_date'] ? formattedDate(filterData['discovered_end_date']) : ''
      };

      const filterParams = new URLSearchParams();
      for (const key in formattedFilterData) {
        filterParams.append(key, formattedFilterData[key]);
      }

      const queryParams = new URLSearchParams({
        ...Object.fromEntries(filterParams),
        ...pagination
      }).toString();
      const res = await SIEMApi.GetAllEndPoint(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        queryParams
      );
      setTotalCount(res?.data?.pagination?.total_records);
      if (isSkeleton && res?.data?.data) {
        setInitialLength(true);
      }

      setEventList(res?.data?.data);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails, filterData, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    GetEndPoint();
  }, [GetEndPoint, filterData]);

  const GetServiceFilterOption = useCallback(async () => {
    try {
      const res = await RiskManagementApi.GetServicesList();
      const ProviderListForFilter = res?.data?.data?.map((el) => {
        return {
          key: `${el?.code}`,
          value: el?.services_uuid,
          name: el?.name,
          tooltip: el?.name,
          remediation_source: el?.remediation_source,
          tools: el?.tools
        };
      });
      setServicesOption(ProviderListForFilter);
    } catch (error) {}
  }, [commonDetails]);

  useEffect(() => {
    GetServiceFilterOption();
  }, [GetServiceFilterOption]);

  const GetTools = useCallback(async () => {
    try {
      const res = await SIEMApi.GetTools(
        commonDetails?.data?.company.company_uuid,
        commonDetails?.data?.user_uuid
      );
      const ProviderListForFilter = res?.data?.data?.map((el) => {
        return {
          value: el.integration_tools_uuid,
          name: el.name,
          tooltip: el.name
        };
      });
      setTools(ProviderListForFilter);
    } catch (error) {}
  }, [commonDetails]);

  useEffect(() => {
    GetTools();
  }, [GetTools]);

  const GetEventAssets = useCallback(async () => {
    try {
      const res = await SIEMApi.EventAssets(
        commonDetails?.data?.company.company_uuid,
        commonDetails?.data?.user_uuid
      );
      const result = [];
      const uniqueEntries = new Set();
      for (const key in res?.data?.data) {
        for (let i = 0; i < res?.data?.data[key].length; i++) {
          const value = res?.data?.data[key][i];
          if (value.includes(',')) {
            const values = value.split(',');
            values.forEach((val) => {
              const entry = `${key}: ${val.trim()}`;
              if (!uniqueEntries.has(entry)) {
                result.push(entry);
                uniqueEntries.add(entry);
              }
            });
          } else {
            const entry = `${key}: ${value}`;
            if (!uniqueEntries.has(entry)) {
              result.push(entry);
              uniqueEntries.add(entry);
            }
          }
        }
      }
      setFilterAssets(result);
    } catch (error) {}
  }, [commonDetails]);

  useEffect(() => {
    GetEventAssets();
  }, [GetEventAssets]);

  const GetVendorTools = useCallback(async () => {
    try {
      const res = await SIEMApi.VendorTools(
        commonDetails?.data?.company.company_uuid,
        commonDetails?.data?.user_uuid
      );
      const ProviderListForFilter = res?.data?.data?.map((el) => {
        return {
          name: el?.vendor_tool_name,
          tooltip: el?.vendor_tool_name,
          integration_tools_uuid: el?.integration_tools_uuid,
          vendor_uuid: el?.vendor_uuid
        };
      });
      setVendorTools(ProviderListForFilter);
    } catch (error) {}
  }, [commonDetails]);

  useEffect(() => {
    GetVendorTools();
    GetAssignees();
  }, [GetAssignees, GetVendorTools]);

  const GetEventSummary = useCallback(async () => {
    try {
      setIsSkeleton(true);
      const res = await SIEMApi.GetEventSummary(
        commonDetails?.data?.company.company_uuid,
        commonDetails?.data?.user_uuid
      );
      setEventSummary(res?.data?.data);
      const totalCountReduce = res?.data?.data.reduce((acc, issue) => acc + issue.count, 0);
      setEventSummaryTotalCount(totalCountReduce);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails]);

  useEffect(() => {
    GetEventSummary();
  }, [GetEventSummary]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setRowData(null);
    setAnchorEl(null);
  };

  const handleRowClick = (e: any, row: any) => {
    const trElement = (e.target as HTMLElement).closest('tr');
    if (trElement) {
      setRowData(row);
      setIsView(true);
    }
  };

  const remidiationDrawer = (anchor: Anchor, open: boolean, el: any) => {
    // setclick(!click);
    // if (!open) {
    //     useParams.delete('device_id');
    //     setUseParam(useParams);
    // } else {
    //     setParams(el.id);
    // }
    // setRowData(el);
    if (!open) {
      setIsCreateRemediation(false);
    }
    setState({ ...state, [anchor]: open });
  };

  const eventSummaryBgcolor = (type: string) => {
    let color = '#436AF3';
    if (type === 'Not Started') {
      color = '#EF4444';
    } else if (type === 'In Remediation') {
      color = '#FDE68A';
    } else if (type === 'Resolved') {
      color = '#10B981';
    } else if (type === 'False Positive') {
      color = '#F9A866';
    }
    return color;
  };

  return (
    <>
      <Box>
        <HeadingHeader
          subtitle1={'Security Information & Event Management (SIEM)'}
          subtitle2={'Detect, analyze and respond to cyber threats in real time'}
          commonDetails={commonDetails}
          UserRoles={UserRoles}
        />
        {isSkeleton ? (
          <Box sx={{ px: 4, py: 3 }}>
            <Skeleton sx={{ p: 5 }} />
          </Box>
        ) : (
          <>
            <Box sx={{ py: 3, px: 4 }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Summary
                </Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={4} md={3} lg={2.4}>
                    <Box
                      sx={{
                        border: '0.8px solid #CCCCCC',
                        bgcolor: '#ffffff',
                        borderRadius: 2,
                        p: 2
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: eventSummaryBgcolor('Total Events'),
                            borderRadius: '50%'
                          }}></Box>
                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                          {' '}
                          {'Total Events'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ color: '#464646' }} variant="h1">
                          {eventSummaryTotalCount}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  {eventSummary?.map((event) => {
                    return (
                      <Grid item xs={4} md={3} lg={2.4}>
                        <Box
                          sx={{
                            border: '0.8px solid #CCCCCC',
                            bgcolor: '#ffffff',
                            borderRadius: 2,
                            p: 2
                          }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box
                              sx={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: eventSummaryBgcolor(event?.type),
                                borderRadius: '50%'
                              }}></Box>
                            <Typography
                              variant="body2"
                              sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                              {' '}
                              {event?.title}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ color: '#464646' }} variant="h1">
                              {event?.count?.toLocaleString()}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>

            <Box sx={{ px: 4, backgroundColor: '#FBFBFF', width: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ py: 3, display: 'flex', gap: 2 }}>
                  <Box sx={{ width: '132px' }}>
                    <label className={invalidDateRange ? 'invalid-date' : ''}>
                      <DatePicker
                        showIcon
                        className="calendar-filter"
                        icon={<CalenderIconFilter />}
                        selected={filterData['discovered_start_date']}
                        dateFormat="dd MMM yyyy"
                        placeholderText="DD MMM YYYY"
                        onChange={(date: any, event: any) => {
                          setFilterData({
                            ...filterData,
                            discovered_start_date: date
                          });
                        }}
                      />
                    </label>
                  </Box>
                  <Box sx={{ width: '132px' }}>
                    <label className={invalidDateRange ? 'invalid-date' : ''}>
                      <DatePicker
                        showIcon
                        selected={filterData['discovered_end_date']}
                        className="calendar-filter"
                        dateFormat="dd MMM yyyy"
                        placeholderText="DD MMM YYYY"
                        onChange={(date: any, event: any) => {
                          setFilterData({
                            ...filterData,
                            discovered_end_date: date
                          });
                        }}
                        icon={<CalenderIconFilter />}
                      />
                    </label>
                  </Box>
                  <TextField
                    placeholder="Search by Event Name"
                    hiddenLabel
                    size="small"
                    name="keyword"
                    value={filterData.keyword}
                    onChange={handleChange}
                    sx={{
                      input: { backgroundColor: '#FFFFFF' },
                      '.MuiOutlinedInput-root': {
                        bgcolor: '#FFFFFF'
                      }
                    }}
                    inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <FormControl sx={{ width: 150 }}>
                    <Select
                      size="small"
                      displayEmpty
                      multiple
                      name="criticalities"
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={filterData.criticalities.split(',')}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          sx={{
                            py: '5.5px'
                          }}
                        />
                      }
                      renderValue={(selected) => renderValue(selected, CriticalList)}
                      MenuProps={MenuProps}
                      sx={{
                        py: 0.35,
                        fontSize: '14px',
                        color: '#696969',
                        '.MuiSelect-select': {
                          py: '5.5px'
                        },
                        minWidth: { xxs: 0, md: '150px' },
                        backgroundColor: '#FFFFFF'
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      {CriticalList.map((name, index) => (
                        <MenuItem
                          sx={{
                            fontSize: '14px',
                            display: !name.value ? 'none' : 'flex',

                            py: 0,
                            '.MuiMenuItem-root': {
                              py: '0px'
                            }
                          }}
                          key={name.key}
                          value={name.value}
                          autoFocus={index === 0}>
                          <Checkbox
                            checked={filterData.criticalities.split(',').indexOf(name.value) > -1}
                          />
                          <ListItemText
                            sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                            primary={name.key}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FilterDropdown
                    setFilterData={setFilterData}
                    filterData={filterData}
                    statusOption={statusItems}
                    toolsOption={toolsOption}
                    filterAssets={filterAssets}
                  />
                  <Box>
                    <Button
                      onClick={handleClearFuntion}
                      sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
                      variant="text">
                      {' '}
                      <FilterCancelIcon /> Clear filter
                    </Button>
                  </Box>
                </Box>
                <Event
                  text={'Create Event'}
                  isEdit={isEdit}
                  isView={isView}
                  rowData={rowData}
                  setRowData={setRowData}
                  setIsView={setIsView}
                  servicesOption={servicesOption}
                  setIsEdit={setIsEdit}
                  setAnchorEl={setAnchorEl}
                  commonDetails={commonDetails}
                  GetEventSummary={GetEventSummary}
                  GetEndPoint={GetEndPoint}
                />
              </Box>
              <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none', overflowX: 'inherit' }}>
                {eventList?.length == 0 || !eventList ? (
                  initialLength ? (
                    <NoSearchDataAvailable
                      title="No Search Results"
                      message="Try adjusting your search or filter to find what you are looking for"
                    />
                  ) : (
                    <NoDataAvailable
                      title="No Data Available"
                      message="Please provide your company's technical information to enable your security services."
                    />
                  )
                ) : (
                  <>
                    <Table
                      sx={{
                        minWidth: 650,
                        borderCollapse: 'separate',
                        backgroundColor: '#FBFBFF',
                        boxShadow: 'none',
                        borderSpacing: '0px 16px',
                        border: 'none'
                      }}
                      aria-label="site list table">
                      <TableHead sx={{ '.MuiTableCell-root': { border: 'none', position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' } }}>
                        <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                          {/* <TableCell sx={{ px: '20px' }}>
                            <Checkbox
                              // disabled={isDataLoaded}
                              indeterminate={
                                selected?.length > 0 && selected?.length < eventList?.length
                              }
                              indeterminateIcon={
                                selected?.length > 0 &&
                                selected?.length < eventList?.length && (
                                  <CheckBoxOutLine
                                    // disabled={isDataLoaded}
                                    sx={{
                                      fill: 'none',
                                      width: '21px',
                                      height: '20px'
                                    }}
                                  />
                                )
                              }
                              checked={
                                eventList?.length > 0 && selected?.length === eventList?.length
                              }
                              onChange={handleSelectAllClick}
                              checkedIcon={<CheckBoxLightBlue />}
                              icon={
                                <CheckBoxOutLine
                                  sx={{
                                    fill: 'none',
                                    width: '21px',
                                    height: '20px'
                                  }}
                                />
                              }
                            />
                          </TableCell> */}
                          <TableCell>
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>Criticality</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>Date Discovered</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>Event Name</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>Tool</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>Asset</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ fontWeight: '500' }}>Status</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ fontWeight: '500' }}></Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {eventList?.length > 0
                          ? eventList?.map((row: any, index: any) => {
                              return (
                                <RowData
                                  row={row}
                                  index={index}
                                  rowData={rowData}
                                  setRowData={setRowData}
                                  setOpenDelete={setOpenDelete}
                                  anchorEl={anchorEl}
                                  setAnchorEl={setAnchorEl}
                                  setIsEdit={setIsEdit}
                                  handleRowClick={handleRowClick}
                                  remidiationDrawer={remidiationDrawer}
                                  GetEndPoint={GetEndPoint}
                                  GetEventSummary={GetEventSummary}
                                  setIsCreateRemediation={setIsCreateRemediation}
                                />
                              );
                            })
                          : null}
                      </TableBody>
                    </Table>
                    <TablePagination
                      totalCount={totalCount}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                    />
                  </>
                )}
              </TableContainer>
            </Box>
          </>
        )}
      </Box>

      {rowData ? (
        <DeleteDialog
          openDelete={openDelete}
          handleCloseDelete={handleCloseDelete}
          event_uuid={rowData?.event_uuid}
          commonDetails={commonDetails}
          setAnchorEl={setAnchorEl}
          GetEndPoint={GetEndPoint}
          GetEventSummary={GetEventSummary}
        />
      ) : null}
      <SwipeableDrawer
        BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
        sx={{
          '.MuiDrawer-paper': {
            boxShadow: 'none'
          }
        }}
        anchor={'right'}
        open={state['right']}
        onClose={() => remidiationDrawer('right', false, {})}
        onOpen={() => remidiationDrawer('right', true, rowData ? rowData : {})}>
        <SIEMSideBarUser
          assignees={assignees}
          rowData={rowData ? rowData : {}}
          remidiationDrawer={remidiationDrawer}
          isCreateRemediation={isCreateRemediation}
          onClose={() => remidiationDrawer('right', false, {})}
          GetEndPoint={GetEndPoint}
          GetEventSummary={GetEventSummary}
          vendorTools={vendorTools}
          setIsCreateRemediation={setIsCreateRemediation}
        />
      </SwipeableDrawer>
    </>
  );
};
