import HttpService from '@jibin/common/services/http/HttpService';

export const UserManagementApi = {
    GetSummary(companyId: string, userId: string) {
        return HttpService.getAll(`companies/${companyId}/users/${userId}/user-management/summary`);
    },
    GetUserList(companyId: string, userId: string, params: any) {
        return HttpService.getAll(`companies/${companyId}/users/${userId}/user-management/users?` + params);
    },
    CreateRemediation(companyId: string, userId: string, data: any) {
        return HttpService.post(`companies/${companyId}/users/${userId}/user-management/remediation`, data);
    },
}