import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FC, useState, useContext, useEffect, useCallback } from 'react';
import { commonDetailSlice } from '../../store/reducers/commonReducer';
import { useDispatch } from 'react-redux';
import { LoginService } from './index.api';
import UserPool from '@jibin/common/utils/UserPool';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Amplify, Auth } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
import { LoginContext } from '@jibin/common/Context/LoginContext';
import ClockIcon from '@jibin/common/icons/ClockIcon/ClockIcon';
import CloseIcon from '@jibin/common/icons/Close/Close';
import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { count } from 'console';
import serviceDetailSlice from 'src/store/reducers/servicesReducer';
import { SidebarService } from '../Common/Sidebar/Sidebar.api';
import { Service } from '../Common/Sidebar';
import RiskIcon from '@jibin/common/icons/RiskIcon/RiskIcon';
import { CompanyUserRoles } from '@jibin/common/utils/constants/ConstantName';
var debounce = require('lodash.debounce');
const notifyError = (message: any) => toast.error(message, { position: toast.POSITION.TOP_RIGHT });

export const Login: FC = (props: any) => {
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(Object);
  const { setUserData, values, setValues } = useContext(LoginContext);
  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePassword = (event: any) => {
    event.preventDefault();
    const user = cognitoUser;
    user.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: (data: any) => {
          localStorage.setItem('cognito-token', JSON.stringify(data));
          window.location.href = '/';
        },
        onFailure: (err: any) => {}
      }
    );
  };

  // const signIn = (event: any) => {
  //   event.preventDefault();
  //   const user = new CognitoUser({
  //     Username: values.Email,
  //     Pool: UserPool
  //   });

  //   const authDetails = new AuthenticationDetails({
  //     Username: values.Email,
  //     Password: values.password
  //   });

  //   user.authenticateUser(authDetails, {
  //     onSuccess: async (data: any) => {
  //       console.log(data);
  //       localStorage.setItem('cognito-token', JSON.stringify(data));
  //       // await getEntries();
  //     },
  //     onFailure: (err: any) => {
  //
  //     },
  //     newPasswordRequired: (data: any) => {
  //
  //     }
  //   });
  // };

  const signIn = useCallback(
    debounce(
      async () => {
        try {
          const emailRegex = new RegExp(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          );
          if (!values.Email.trim()) {
            notifyError('Please enter email');
            return;
          }
          if (!emailRegex.test(values.Email.trim())) {
            notifyError('Please enter valid email');
            return;
          }
          if (!values.password.trim()) {
            notifyError('Please enter password');
            return;
          }
          setIsLoginButtonLoading(true);
          const user = await Auth.signIn(values.Email.trim(), values.password);

          setUserData(user);

          if (user.challengeName == 'SMS_MFA') {
            navigate(PageRouteConstants.Mfa, { state: { email: values.Email.trim() } });
          } else if (user.challengeName == 'SOFTWARE_TOKEN_MFA') {
            navigate(PageRouteConstants.Mfa, { state: { email: values.Email.trim() } });
          } else {
            localStorage.setItem('cognito-token', JSON.stringify(user.signInUserSession));
            localStorage.setItem('IsSessionActive', 'true');
            await getEntries(user);
          }
        } catch (err) {
          if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
            console.log(err);
          } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            const user = await Auth.signIn(values.Email.trim(), values.password);
            setCognitoUser(user);
            setIsChangePassword(true);
            console.log(err);
          } else if (err.code === 'NotAuthorizedException') {
            notifyError('Username or password incorrect');
            console.log(err);
          } else if (err.code === 'UserNotFoundException') {
            console.log(err);
          } else {
            notifyError(err.message);
            //console.log(err);
          }
        } finally {
          setTimeout(() => {
            setIsLoginButtonLoading(false);
          }, 1000);
        }
      },
      500,
      true
    ),
    [values]
  );

  const dispatch = useDispatch();

  async function getEntries(user: any) {
    await dispatch(commonDetailSlice.actions.removecommonDetails());
    LoginService.GetUserData().then(
      async (t: any) => {
        if (t?.data?.data?.mfa_status === 'enabled' && !t?.data?.data?.mfa?.length) {
          localStorage.setItem('isRedirectAuth', 'true');
          navigate(PageRouteConstants.MfaMethod, { state: { allow: true } });
        } else {
          localStorage.setItem('device_id', uuidv4());
          await dispatch(commonDetailSlice.actions.setcommonDetails(t));
          GetServices(t?.data?.data?.company.company_uuid, t?.data?.data?.user_uuid);
          if (t.data.data.is_first_login) {
            navigate({ pathname: PageRouteConstants.Welcome, search: 'is_login=true' });
          } else {
            if (t?.data?.data?.company?.type == 'engineering') {
              navigate({ pathname: PageRouteConstants.CompanyDashBoard, search: 'is_login=true' });
            } else if (t?.data?.data?.company?.user_role === CompanyUserRoles.AUDITOR) {
              navigate({
                pathname: PageRouteConstants.GapAnalysisV2Questionnaire,
                search: 'is_login=true'
              });
            } else {
              navigate({ pathname: PageRouteConstants.OnBoarding, search: 'is_login=true' });
            }
          }
        }
      },
      async (err: any) => {
        console.log(err);
      }
    );
  }

  const GetServices = async (company_uuid: any, user_uuid: any) => {
    dispatch(serviceDetailSlice.actions.removeserviceDetails());

    try {
      const res = await SidebarService.GetServices(company_uuid, user_uuid);

      const commonObjects = Service.filter((obj1) =>
        res?.data?.data
          ?.filter((el: any) => (el.name == obj1.name && el.enabled) || obj1.isStatic)
          .some((obj2) => (obj2.name === obj1.name && obj2.enabled) || obj1.isStatic)
      );

      dispatch(serviceDetailSlice.actions.setserviceDetails(commonObjects));
    } catch (error) {}
  };

  useEffect(() => {
    let inactivity = searchParams.get('inactivity');
    let reason = searchParams.get('reason');
    toast.error(reason);
    if (inactivity) {
      handleOpen();
    }
    searchParams.delete('inactivity');
    searchParams.delete('reason');
    setSearchParams(searchParams);
  }, []);

  return (
    <Box sx={Style.SignUp.SignUpWrapper}>
      <Container maxWidth="xl">
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
            {!isChangePassword && (
              <Box sx={Style.Auth.AuthLeftPart}>
                <Box sx={Style.Auth.TrustLogo}>
                  <img alt="" src="/images/trust-logo-black.svg" />
                </Box>

                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="Email">E-mail</label>
                  </Box>
                  <OutlinedInput
                    placeholder="Enter your e-mail"
                    id="Email"
                    type="email"
                    value={values.Email}
                    onKeyDown={(e) => e.key == 'Enter' && signIn()}
                    onChange={handleChange('Email')}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'Email'
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="password">Password</label>
                  </Box>

                  <OutlinedInput
                    placeholder="Password"
                    type="password"
                    id="password"
                    onKeyDown={(e) => e.key == 'Enter' && signIn()}
                    value={values.password}
                    onChange={handleChange('password')}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'password'
                    }}
                  />
                </FormControl>
                <LoadingButton
                  onClick={signIn}
                  variant="contained"
                  loading={isLoginButtonLoading}
                  sx={{ mt: { xs: 3, md: 4, xl: 5 } }}
                  fullWidth>
                  Login
                </LoadingButton>
                <Box sx={Style.Login.ActionBox}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Keep me logged in" />
                  </FormGroup>
                  <Link href="/forgot-password">Forgot password?</Link>
                </Box>
              </Box>
            )}
            {isChangePassword && (
              <Box sx={Style.Auth.AuthLeftPart}>
                <Box sx={Style.Auth.TrustLogo}>
                  <img alt="" src="/images/trust-logo-black.svg" />
                </Box>

                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="password">New Password</label>
                  </Box>
                  <OutlinedInput
                    placeholder="Password"
                    id="password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'password'
                    }}
                  />
                </FormControl>
                <Button
                  onClick={handleChangePassword}
                  variant="contained"
                  sx={{ mt: { xs: 3, md: 4, xl: 5 } }}
                  fullWidth>
                  Update Password
                </Button>
                <Box sx={Style.Login.ActionBox}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Keep me logged in" />
                  </FormGroup>
                  <Link href="/forgot-password">Forgot password?</Link>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Box sx={Style.Auth.AuthInfographics}>
              <img alt="" src="/images/elephant-with-men.png" />
            </Box>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              px: { xs: 1, md: 2 },
              py: { xs: 0.5, md: 1 },
              bgcolor: 'error.red100',
              mb: 1
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ClockIcon sx={{ mr: 1 }} />
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#2D2D2D',
                    fontWeight: '600',

                    p: 1
                  }}>
                  You have been signed out
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
              <Box sx={{ textAlign: 'center', width: 1 }}>
                <Typography variant="body1" sx={{ mb: 1.5 }}>
                  You have been logged off for your account's security.
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0, justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ minWidth: 100, color: 'Primary/500' }}>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};
