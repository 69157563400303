import EditIcon from '@jibin/common/icons/Edit/Edit';
import { Box, Skeleton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { ResponsiveContainer } from 'recharts';
import DropdownMenu from 'src/Pages/DashBoard/DropdownMenu';
import { GapAnalysisApi } from '../GapAnalysis.Api';

const GapWidget = ({ isDragable, currentGrid, setAnchorEl, anchorEl, setWidgetSize }) => {
  const [loading, setLoading] = useState(true);
  const [gapStatV2, setGapStatV2] = useState({
    total: 0,
    complated: 0,
    notComplated: 0
  });
  const [completedScore, setCompletedScore] = useState(0);

  const [gapFilterData, setGapFilterData] = useState({
    framework: '',
    control_status: '',
    keyword: ''
  });
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const GetStats = useCallback(async () => {
    setLoading(true);
    await GapAnalysisApi.GetCategories(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams(gapFilterData).toString()
    ).then(
      (q: any) => {
        let total = 0;
        let completed = 0;
        let notComleted = 0;
        const complatedControl = q?.data?.data?.map((el: any) =>
          el?.controls?.map((element: any) => {
            total += 1;
            if (element?.control_status === 'Not Submitted') {
              notComleted += 1;
            } else {
              completed += 1;
            }
          })
        );
        setGapStatV2({
          ...gapStatV2,
          total: total,
          complated: completed,
          notComplated: notComleted
        });

        setCompletedScore(Math.round((completed / total) * 100));
      },
      (err: any) => {}
    );
    setLoading(false);
  }, [commonDetails]);
  useEffect(() => {
    GetStats();
  }, [GetStats]);

  return (
    <Box
      sx={{
        border: '1px solid #CCCCCC',
        width: 1,
        height: 1,
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        cursor: 'default'
      }}>
      <Box
        sx={{
          py: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '100%'
        }}>
        <Box sx={{ mb: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center'
              }}>
              <Typography sx={{ fontWeight: 500, lineHeight: '24px' }}>
                Compliance Progress
              </Typography>
            </Box>
            {isDragable && (
              <>
                <EditIcon className="grid-edit-icon" fontSize="small" />
                {currentGrid?.i === 'dashboard-gap-analysis-v-2-0' ? (
                  <DropdownMenu
                    setAnchorEl={setAnchorEl}
                    anchorEl={anchorEl}
                    setWidgetSize={setWidgetSize}
                  />
                ) : null}
              </>
            )}
          </Box>
        </Box>
        <Box>
          {loading ? (
            <div
              style={{
                width: '200px',
                height: '100px',
                borderRadius: '100% 100% 0 0',
                margin: '0 auto',
                overflow: 'hidden',
                position: 'relative'
              }}>
              <Skeleton
                style={{
                  width: '200px',
                  height: '200px',
                  borderRadius: '100% 100% 0 0',
                  transform: 'none'
                }}
              />
            </div>
          ) : (
            <Box sx={{ mb: 2, width: 1, display: 'flex', justifyContent: 'center' }}>
              <ResponsiveContainer className="container" height={90} width="100%">
                <Box>
                  <div
                    className="semi-donut margin"
                    style={
                      {
                        '--percentage': completedScore,
                        '--fill':
                          completedScore >= 1 && completedScore <= 20
                            ? '#EF4444'
                            : completedScore >= 21 && completedScore * 100 <= 79
                            ? '#F59E0B'
                            : '#27AE60'
                      } as any
                    }>
                    <Typography variant="h2" sx={{ color: '#464646', fontWeight: 600 }}>
                      {completedScore}%
                    </Typography>
                  </div>
                </Box>
              </ResponsiveContainer>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
            <Typography variant="caption" sx={{ textAlign: 'center', fontWeight: 600 }}>
              Control Submitted
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                Not Submitted
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {loading ? <Skeleton width={100} /> : gapStatV2?.notComplated?.toLocaleString()}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                Submitted
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {loading ? <Skeleton width={100} /> : gapStatV2?.complated?.toLocaleString()}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                Total
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {loading ? <Skeleton width={100} /> : gapStatV2?.total?.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GapWidget;
