import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import GoldIcon from '@jibin/common/icons/MedalIcon/GoldIcon.svg';
import SilverIcon from '@jibin/common/icons/MedalIcon/SilverIcon.svg';
import BronzeIcon from '@jibin/common/icons/MedalIcon/BronzeIcon.svg';
import PlatniumIcon from '@jibin/common/icons/MedalIcon/PlatniumIcon.svg';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Skeleton, Switch } from '@mui/material';
import { CompanyManagementApi } from './CompanyManagement.Api';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import { SuccessConfirmationUpadated } from '@jibin/common/components/ConformationModel/SuccessConfirmationUpadated';
import { SidebarService } from '../Common/Sidebar/Sidebar.api';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import TablePagination from '../Common/TablePagination';
import { NoDataAvailable, NoSearchDataAvailable } from '../Common/NoDataAvailable';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
var debounce = require('lodash.debounce');
export const CompanyManagementTable: FC<{
  isRefresh: boolean;

  search: any;
}> = (prop) => {
  const { isRefresh, search } = prop;
  const [companyData, setCompanyData] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initialLength, setInitialLength] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [isSkeleton, setIsSkeleton] = useState(true);

  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const open = Boolean(anchorEl);

  const nevigate = useNavigate();
  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#27AE60';
        break;

      case 'bypass':
      case 'errors':
      case 'inactive':
      case 'pending install':
      case 'quarantine':
      case 'sensor out of date':
        color = '#EF4444';
        break;

      case 'deregistered':
      case 'pending':
        color = '#FCD34D';
        break;
    }

    return color;
  };

  const loadMedal = (type) => {
    switch (type) {
      case 'gold':
      case 'Gold':
        return <img width={'16px'} src={GoldIcon} />;

      case 'silver':
      case 'Silver':
        return <img width={'16px'} src={SilverIcon} />;
      case 'bronze':
      case 'Bronze':
        return <img width={'16px'} src={BronzeIcon} />;
      case 'platinum':
      case 'Platinum':
        return <img width={'16px'} src={PlatniumIcon} />;
    }
  };
  const dispatch = useDispatch();
  const decativeCompany = async (status: any, company_uuid: any) => {
    try {
      const res = await CompanyManagementApi.ChangeStatusOfCompany(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        {
          status: status,
          company_uuid: company_uuid
        }
      );
      GetUserDetails();
      GetAllCompany();
    } catch (error) {}
  };
  const GetUserDetails = () => {
    SidebarService.GetUserData().then(
      async (q: any) => {
        await dispatch(commonDetailSlice.actions.setcommonDetails(q));
      },
      (err: any) => {}
    );
  };
  useEffect(() => {
    setPage(0);
  }, [search]);
  const GetAllCompany = async () => {
    try {
      const res = await CompanyManagementApi.GetCompany(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        new URLSearchParams({ ...search, ...pagination }).toString()
      );
      if (res?.data?.data) {
        setInitialLength(true);
      }
      setTotalCount(res?.data?.pagination?.total_records);
      setCompanyData(res?.data?.data);
      setIsSkeleton(false);
    } catch (error) {}
    setIsSkeleton(false);
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const debouncedSearch = debounce(GetAllCompany, 500);
  const handleRouteChange = (id: any, company_uuid: any) => {
    nevigate(
      PageRouteConstants.CompanyManagementAccount +
        `?q=${id}&company_uuid=${company_uuid ? company_uuid : ''}`
    );
  };
  useEffect(() => {
    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [commonDetails, search, pagination.page_no, pagination.page_size]);
  return (
    <Box sx={Style.PoliciesAndProcedures.PoliciesTableWrapper}>
      {isSkeleton ? (
        <Box sx={{ py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <>
          {companyData?.length == 0 || !companyData ? (
            initialLength ? (
              <NoSearchDataAvailable
                title="No Search Results"
                message="Try adjusting your search or filter to find what you are looking for"
              />
            ) : (
              <NoDataAvailable
                title="No Data Available"
                message="The Company Management page will be populated once when company is
             discovered."
              />
            )
          ) : (
            <>
              <>
                <Table sx={{ minWidth: 650, marginBottom: '12px' }} aria-label="site list table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Website</TableCell>
                      <TableCell>Industry</TableCell>
                      <TableCell>Plan</TableCell>
                      <TableCell>Status</TableCell>
                      {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyData
                      .sort((a: any, b: any) => (a.name < b.name ? -1 : 1))

                      ?.map((el: any, index) => (
                        <>
                          <Box sx={Style.Common.Divider}></Box>
                          <TableRow
                            key={index + 1}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              cursor: 'pointer',
                              borderColor: '#CCCCCC'
                            }}>
                            <TableCell
                              onClick={() => handleRouteChange(el?.id, el?.company_uuid)}
                              component="th"
                              scope="row"
                              sx={{ minWidth: 200 }}>
                              {el?.name}
                            </TableCell>
                            <TableCell onClick={() => handleRouteChange(el?.id, el?.company_uuid)}>
                              {el?.website}
                            </TableCell>
                            <TableCell onClick={() => handleRouteChange(el?.id, el?.company_uuid)}>
                              {el?.industry}
                            </TableCell>
                            <TableCell onClick={() => handleRouteChange(el?.id, el?.company_uuid)}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                {loadMedal(el?.plan.toLowerCase())}
                                {el?.plan}
                              </Box>
                            </TableCell>

                            <TableCell onClick={() => handleRouteChange(el?.id, el?.company_uuid)}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: renderColorBackGround(el?.status),
                                    borderRadius: '50%'
                                  }}></Box>
                                {el?.status}
                              </Box>
                            </TableCell>
                            <TableCell onClick={(e: any) => e.preventDefault()}>
                              <Box onClick={(e: any) => e.preventDefault()}>
                                <SuccessConfirmationUpadated
                                  handleConfirm={(e) => {
                                    decativeCompany(
                                      el?.status.toUpperCase() == 'ACTIVE' ||
                                        el?.status.toUpperCase() == 'PENDING'
                                        ? 'Inactive'
                                        : 'Active',
                                      el?.company_uuid
                                    );
                                  }}
                                  title="Confirm Action"
                                  type={DeleteTypesEnum.Switch}
                                  asking={`Do you want to ${
                                    el?.status.toUpperCase() == 'ACTIVE' ||
                                    el?.status.toUpperCase() == 'PENDING'
                                      ? 'deactivate'
                                      : 'activate'
                                  }   ${el?.name} account?`}
                                  returnData={'Submitted'}
                                  mainBtnTitle={
                                    <Switch
                                      onClick={(e: any) => e.preventDefault()}
                                      checked={
                                        el?.status.toUpperCase() == 'ACTIVE' ||
                                        el?.status.toUpperCase() == 'PENDING'
                                      }
                                      sx={{
                                        ml: 2
                                        // '	.MuiSwitch-switchBase': { color: 'rgba(0, 0, 0, 0.25)' },
                                        // '	.MuiSwitch-track': {
                                        //   backgroundColor: 'transparent',
                                        //   border: '1px solid #ADB5BD',
                                        //   borderRadius: '45px'
                                        // },
                                        // '.css-jsexje-MuiSwitch-thumb': {
                                        //   boxShadow: 'none'
                                        // },
                                        // '	.Mui-disabled': {
                                        //   cursor: 'not-allowed'
                                        // }
                                      }}
                                    />
                                  }
                                  buttonType={'outlined'}
                                  mainBtnType={'primary'}
                                  cancelButtonText="Cancel"
                                  conformButtonText="Confirm"
                                  isLoading={false}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CompanyManagementTable;
