import SearchIcon from '@jibin/common/icons/Search/Search';
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SwipeableDrawer,
  IconButton,
  Menu,
  Grid,
  OutlinedInput,
  FormControl,
  Checkbox,
  Button
} from '@mui/material';

import { Box } from '@mui/system';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { RemediationApi } from '../Remediation/Remediation.Api';
import { EndpointProtectionApi } from './EndpointProtection.Api';
import EndPointProtectionSideBarEngineer from './EndPointsProtectionSideBar/EndPointProtectionSideBarEngineer';
import EndPointProtectionSideBarUser from './EndPointsProtectionSideBar/EndPointProtectionSideBarUser';
import EndpointProtectionRow from './EndpointProtectionRow';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { NoDataAvailable, NoSearchDataAvailable } from './../Common/NoDataAvailable';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import TablePagination from '../Common/TablePagination';
import { DashboardApi } from '../DashBoard/Dashboard.Api';
import HeadingHeader from '../Common/HeadingHeader';
import { LoginContext } from '@jibin/common/Context/LoginContext';
import FilterDropdown from './FilterDropdown';

type Anchor = 'top' | 'left' | 'bottom' | 'right';
var debounce = require('lodash.debounce');
const momentTimezone = require('moment-timezone');
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      fontSize: '14px',
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180
    }
  }
};
const EndpointProtection: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [useParams, setUseParam] = useSearchParams();
  const [endpointStaticData, setEndPointStaticData] = useState([]);
  const [rowData, setRowData] = useState({} as any);
  const toggleDrawer = (anchor: Anchor, open: boolean, el: any) => {
    setclick(!click);
    if (!open) {
      useParams.delete('device_id');
      setUseParam(useParams);
    } else {
      setParams(el.device_id);
    }
    setRowData(el);
    setState({ ...state, [anchor]: open });
  };
  const setParams = (index) => {
    const params = {
      device_id: index
    };
    setUseParam(params);
  };
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [assignees, setAssignees] = useState({} as any);
  const [serach, setSearch] = useState({ keyword: '', status: '', providers: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });
  const [state, setState] = useState({
    right: false
  });
  const menuItems = [
    {
      value: 'ACTIVE',
      lable: 'Active'
    },
    {
      value: 'PENDING',
      lable: 'Pending'
    },
    {
      value: 'INACTIVE',
      lable: 'Inactive'
    },
    {
      value: 'SENSOR_OUTOFDATE',
      lable: 'Sensor out of date'
    },
    {
      value: 'BYPASS',
      lable: 'Bypass'
    },
    {
      value: 'DEREGISTERED',
      lable: 'Deregistered'
    },
    {
      value: 'QUARANTINE',
      lable: 'Quarantine'
    },
    {
      value: 'ERROR',
      lable: 'Error'
    },
    {
      value: 'UNINSTALLED',
      lable: 'Uninstalled'
    },
    {
      value: 'SENSOR_PENDING_UPDATE',
      lable: 'Sensor pending update'
    }
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const { engineer } = useContext(LoginContext);
  const [endpoint, setEndPoints] = useState<any[]>([]);
  const [initialEndpoint, setInitialEndpoint] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [click, setclick] = useState(false);
  const endpointProtectionRef = useRef([]);
  const [isRemediation, setIsRemediation] = useState();
  const [providerOptions, setProviderOption] = useState([] as any);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const [filterData, setFilterData] = useState({
    keyword: '',
    assets: '',
    status: '',
    os: '',

    username: ''
  });
  const [filterAssets, setFilterAssets] = useState([] as any);
  const [userNameList, setUserNameList] = useState([] as any);
  const [ipAddressList, setIpAddressList] = useState([]);
  const [externalIpAddressList, setExternalIpAddressList] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const [osList, setOsList] = useState([]);
  const [osListSearchModel, setOsListSearchModel] = useState('');
  const location = useLocation();
  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };
  const handleClearFuntion = () => {
    setFilterData({
      keyword: '',
      assets: '',
      status: '',
      os: '',

      username: ''
    });
  };
  const GetAssignees = useCallback(async () => {
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      // setIsSkeleton(false);
      setAssignees(res.data);
    } catch (err) {
      // setIsSkeleton(false);
      console.log(err);
    }
  }, [commonDetails]);
  useEffect(() => {
    GetAssignees();
  }, []);
  const handleChangeMultiSelect = useCallback(
    debounce(
      (event: any) => {
        let {
          target: { value, name }
        } = event;
        const filterValue = value.filter((el: any) => el != '');

        let string;
        if (filterValue.length == 0) {
          string = '';
          const dataForApi = { ...serach };

          dataForApi[name] = string;
          const item = { ...filterData };
          setSearch(dataForApi);

          item[name] = typeof value === 'string' ? filterValue.split(',') : [''];

          setFilterData(item);
        } else {
          string = filterValue.join(',');
          const dataForApi = { ...serach };

          dataForApi[name] = string;
          const item = { ...filterData };
          setSearch(dataForApi);

          item[name] = typeof value === 'string' ? filterValue.split(',') : filterValue;

          setFilterData(item);
        }
      },
      100,
      true
    ),
    [serach, setSearch, filterData, setFilterData]
  );
  const findStatuWiseDetails = (status: any) => {
    const data = endpointStaticData?.filter(
      (el: any) => el?.status.toLowerCase() === status.toLowerCase()
    );
    return data?.length;
  };
  const findOtherDevicesDetails = () => {
    const data = endpointStaticData?.filter(
      (el: any) =>
        el?.status.toLowerCase() == 'active' ||
        el?.status.toLowerCase() == 'inactive' ||
        el?.status.toLowerCase() == 'quarantine' ||
        el?.status.toLowerCase() == 'error'
    );

    return endpointStaticData?.length - data?.length;
  };
  useEffect(() => {
    setPage(0);
  }, [serach]);
  const GetEndPoint = useCallback(async () => {
    try {
      const res = await EndpointProtectionApi.GetAllEndPoint(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        new URLSearchParams({ ...filterData, ...pagination }).toString()
      );
      setTotalCount(res?.data?.pagination?.total_records);
      if (searchParams.get('device_id')) {
        const filterdData = res.data.data.filter(
          (el, index) => el.device_id == searchParams.get('device_id')
        );

        setTimeout(() => {
          const element = document.getElementById(filterdData[0].device_id);
          if (element) {
            //element.scrollIntoView({ behavior: 'smooth'});
            var headerOffset = 90;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
            element.classList.add('flash');
            setTimeout(() => {
              element.classList.remove('flash');
            }, 1000);
          }
        }, 1);

        toggleDrawer('right', true, filterdData[0]);
        //setRowData(filterdData[0]);
      }

      if (serach.keyword || serach.status) {
      } else {
        setEndPointStaticData(res.data.data);
      }
      setEndPoints(res.data.data);
      if (res.data.data.length) {
        setInitialEndpoint(true);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails, filterData, location.search, pagination.page_no, pagination.page_size]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const GetFilterOption = useCallback(async () => {
    try {
      const res = await EndpointProtectionApi.GetAllProviderOption(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );

      const uniqueOs = Array.from(new Set(res?.data?.data?.operating_systems || []));
      const uniqueUserName = Array.from(new Set(res?.data?.data?.usernames || []));
      let result = [];
      const uniqueEntries = new Set();
      for (const key in res?.data?.data?.assets) {
        for (let i = 0; i < res?.data?.data?.assets[key].length; i++) {
          const value = res?.data?.data?.assets[key][i];
          if (value.includes(',')) {
            const values = value.split(',');
            values.forEach((val) => {
              const entry = `${key}: ${val.trim()}`;
              if (!uniqueEntries.has(entry)) {
                result.push(entry);
                uniqueEntries.add(entry);
              }
            });
          } else {
            const entry = `${key}: ${value}`;
            if (!uniqueEntries.has(entry)) {
              result.push(entry);
              uniqueEntries.add(entry);
            }
          }
        }
      }
      setFilterAssets(result);
      setUserNameList(uniqueUserName);
      setOsList(uniqueOs);
    } catch (error) {}
  }, [commonDetails, serach, location.search]);
  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#10B981';
        break;
      case 'inactive':
        color = '#B7B7B7';
        break;
      case 'errors':
        color = '#EF4444';
        break;
      case 'bypass':
      case 'pending':
      case 'pending install':
      case 'pending update':
      case 'deregistered':

      case 'sensor out of date':
        color = '#FDE68A';
        break;

      case 'quarantine':
        color = '#F59E0B';
        break;
      case 'all':
        color = '#436AF3';
        break;
      case 'other':
        color = '#FDE68A';
        break;
    }

    return color;
  };
  const renderValueArray = (selected: any, placeholder: string) => {
    if (!selected || selected[0] === '') {
      return placeholder;
    }
    return selected.join(', ');
  };
  const GetEndpointstats = useCallback(() => {
    DashboardApi.endpointProtectionStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        endpointProtectionRef.current = [];

        let othercount = 0;
        let totalCount = 0;
        const totalData = q?.data?.data?.map((pre: any) => (totalCount += pre.count));

        let total = { name: 'Total Devices', value: totalCount || 0, fill: '#436AF3' };

        endpointProtectionRef.current.push(total);
        q.data.data.map((c) => {
          let skip = false;
          let chartArc = { name: '', value: '', fill: '' };
          if (
            c?.type != 'ACTIVE' &&
            c?.type != 'QUARANTINE' &&
            c?.type != 'ERROR' &&
            c?.type != 'INACTIVE'
          ) {
            othercount += c?.count;
          }

          // chartArc.fill = 'rgba(39, 174, 96, 1)';
        });
        let activeData = {
          name: 'Active',
          value: findCount('ACTIVE', q?.data?.data)?.count || 0,
          fill: '#10B981'
        };
        let quarantineData = {
          name: 'Quarantine',
          value: findCount('QUARANTINE', q?.data?.data)?.count || 0,
          fill: '#F59E0B'
        };
        let errorData = {
          name: 'Error',
          value: findCount('ERROR', q?.data?.data)?.count || 0,
          fill: '#EF4444'
        };
        let inactiveData = {
          name: 'Inactive',
          value: findCount('INACTIVE', q?.data?.data)?.count || 0,
          fill: '#B7B7B7'
        };
        let other = { name: 'Other', value: othercount || 0, fill: 'rgb(253, 230, 138)' };

        endpointProtectionRef.current.push(errorData);
        endpointProtectionRef.current.push(quarantineData);
        endpointProtectionRef.current.push(activeData);
        endpointProtectionRef.current.push(other);
        endpointProtectionRef.current.push(inactiveData);
      },
      (err: any) => {}
    );
  }, [commonDetails]);
  const findCount = (status: string, data: any) => {
    const entry = data?.find((el: any) => el?.type == status);
    return entry;
  };
  const handleChange = (e: any) => {
    let updatedValue = e.target.value;
    if (Array.isArray(e.target.value)) {
      updatedValue = e.target.value.filter(Boolean).join(',');
    }
    setFilterData({ ...filterData, [e.target.name]: updatedValue });
  };
  useEffect(() => {
    if (!click) {
      GetEndPoint();

      GetEndpointstats();
    }
  }, [GetEndPoint, GetEndpointstats, click]);
  useEffect(() => {
    GetFilterOption();
  }, [GetFilterOption]);
  const handleSearch = (e) => {
    setSearch({ ...serach, [e.target.name]: e.target.value });
  };
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <Box>
      <HeadingHeader
        subtitle1={'Tool Management'}
        subtitle2={'Efficient oversight of your cybersecurity toolkit'}
        commonDetails={commonDetails}
        UserRoles={UserRoles}
      />
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={{ px: 4, backgroundColor: '#FBFBFF', width: 1 }}>
          <Box sx={{ py: 3 }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Summary
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              <Grid container spacing={2}>
                {endpointProtectionRef?.current?.map((el) => (
                  <Grid item xs={4} md={4} lg={2}>
                    <Box
                      sx={{
                        border: '1px solid #CCCCCC',
                        bgcolor: '#ffffff',

                        borderRadius: 1,

                        p: 2
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: el?.fill,
                            borderRadius: '50%'
                          }}></Box>
                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
                          {' '}
                          {el?.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h1">{el?.value}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box sx={{ py: 2, display: 'flex', gap: 3 }}>
            <TextField
              placeholder="Search"
              hiddenLabel
              size="small"
              onChange={handleChange}
              name="keyword"
              value={filterData.keyword}
              sx={{ backgroundColor: '#FFFFFF', minWidth: 250 }}
              inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <FormControl sx={{ width: 150 }}>
              <Select
                size="small"
                displayEmpty
                multiple
                name="os"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={filterData?.os?.split(',')}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => renderValueArray(selected, 'All OS')}
                MenuProps={MenuProps}
                sx={{
                  py: 0.35,
                  '.MuiSelect-select': {
                    py: '5.5px'
                  },
                  fontSize: '14px',
                  color: '#696969',
                  minWidth: { xxs: 0, md: '150px' },
                  backgroundColor: '#FFFFFF'
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                <Box
                  sx={{
                    fontSize: '14px',
                    py: 0,
                    px: 2,
                    '.MuiMenuItem-root': {
                      py: '0px'
                    }
                  }}>
                  <TextField
                    onClickCapture={stopImmediatePropagation}
                    onKeyDown={(e) => e.stopPropagation()}
                    placeholder="Search"
                    hiddenLabel
                    size="small"
                    name="keyword"
                    value={osListSearchModel}
                    onChange={(e) => setOsListSearchModel(e.target.value)}
                    sx={{ backgroundColor: '#FFFFFF' }}
                    inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>

                {osList
                  ?.filter((el) => {
                    if (osListSearchModel) {
                      return el?.toLowerCase()?.includes(osListSearchModel?.toLowerCase());
                    } else {
                      return el;
                    }
                  })
                  .map((name, index) => (
                    <MenuItem
                      sx={{
                        fontSize: '14px',
                        display: !name ? 'none' : 'flex',

                        py: 0,
                        '.MuiMenuItem-root': {
                          py: '0px'
                        }
                      }}
                      key={name}
                      value={name}>
                      <Checkbox checked={filterData?.os?.split(',')?.indexOf(name) > -1} />
                      <EllipsisContent maxTextWidth={150} text={name} sx={{ fontSize: '14px' }} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: 150 }}>
              <Select
                size="small"
                displayEmpty
                multiple
                name="status"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={filterData?.status?.split(',')}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => renderValueArray(selected, 'All Statuses')}
                MenuProps={MenuProps}
                sx={{
                  py: 0.35,
                  '.MuiSelect-select': {
                    py: '5.5px'
                  },
                  fontSize: '14px',
                  color: '#696969',
                  minWidth: { xxs: 0, md: '150px' },
                  backgroundColor: '#FFFFFF'
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                {menuItems
                  .sort((a: any, b: any) => {
                    return a.value > b.value ? 1 : -1;
                  })
                  .map((name, index) => (
                    <MenuItem
                      sx={{
                        fontSize: '14px',
                        display: !name ? 'none' : 'flex',

                        py: 0,
                        '.MuiMenuItem-root': {
                          py: '0px'
                        }
                      }}
                      key={name.lable}
                      value={name.value}>
                      <Checkbox
                        checked={filterData?.status?.split(',')?.indexOf(name.value) > -1}
                      />
                      <EllipsisContent
                        maxTextWidth={150}
                        text={name.lable}
                        sx={{ fontSize: '14px' }}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FilterDropdown
              setFilterData={setFilterData}
              filterData={filterData}
              filterAssets={filterAssets}
              userNameList={userNameList}
            />
            <Box>
              <Button
                onClick={handleClearFuntion}
                sx={{ display: 'flex', gap: 0.5, alignItems: 'center', py: 0.5 }}
                variant="text">
                {' '}
                <FilterCancelIcon /> Clear filter
              </Button>
            </Box>
          </Box>
          {endpoint.length == 0 || !endpoint ? (
            initialEndpoint ? (
              <NoSearchDataAvailable
                title="No Search Results"
                message="Try adjusting your search or filter to find what you are looking for"
              />
            ) : (
              <NoDataAvailable
                title="No Data Available"
                message="Go to Technical Information and Setup your Environment"
              />
            )
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                border: 'none',
                boxShadow: 'none',
                bgcolor: 'transparent',
                overflowX: 'inherit'
              }}>
              {isSkeleton ? (
                <Box sx={{ px: 4, py: 3 }}>
                  <Skeleton sx={{ p: 5 }} />
                </Box>
              ) : (
                <>
                  <Table
                    sx={{
                      minWidth: 650,
                      borderCollapse: 'separate',
                      backgroundColor: '#FBFBFF',
                      boxShadow: 'none',
                      borderSpacing: '0px 16px',
                      border: 'none',
                      pb: 3
                    }}
                    aria-label="site list table">
                    <TableHead
                      sx={{
                        position: 'sticky',
                        top: '86px',
                        zIndex: '1',
                        backgroundColor: '#fbfbff'
                      }}>
                      <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                        <TableCell>{'Vendor'}</TableCell> <TableCell>Tool Name</TableCell>
                        <TableCell>Version</TableCell>
                        <TableCell>Asset</TableCell>
                        <TableCell>OS</TableCell>
                        <TableCell>Last Check-in</TableCell>
                        <TableCell>User Name</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {endpoint.map((el: any, index: any) => (
                        <EndpointProtectionRow
                          onClick={(e) => {
                            setIsRemediation(e);
                            toggleDrawer('right', true, el);
                          }}
                          engineer={engineer}
                          assignees={assignees}
                          el={el}
                        />
                      ))}
                    </TableBody>
                  </Table>

                  {/* <SwipeableDrawer
                  BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                  sx={{
                    '.MuiDrawer-paper': {
                      boxShadow: 'none'
                    }
                  }}
                  anchor={'right'}
                  open={state['right']}
                  onClose={() => toggleDrawer('right', false, {})}
                  onOpen={() => toggleDrawer('right', true, rowData)}>
                  {engineer ? (
                    <EndPointProtectionSideBarEngineer
                      rowData={rowData}
                      assignees={assignees}
                      isCreateRemediation={isRemediation}
                      onClose={() => toggleDrawer('right', false, {})}
                    />
                  ) : (
                    <EndPointProtectionSideBarUser
                      rowData={rowData}
                      isCreateRemediation={isRemediation}
                      assignees={assignees}
                      onClose={() => toggleDrawer('right', false, {})}
                    />
                  )}
                </SwipeableDrawer> */}
                </>
              )}
              {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                <Box sx={{ mb: 10, textAlign: 'center', width: 1 }}>
                  <Typography variant="body1">
                    Would like to add and manage new tools? Check the{' '}
                    <Typography component={'span'} sx={{ color: '#436AF3', fontWeight: 700 }}>
                      Integration Page{' '}
                    </Typography>{' '}
                    and request new tools
                  </Typography>
                </Box>
              )}
            </TableContainer>
          )}
          <TablePagination
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};

export default EndpointProtection;
