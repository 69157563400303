import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ApiResponse } from './Models/ApiResponse';
import { PoliciesAndProceduresApi } from './Policys.Api';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Checkbox, Skeleton } from '@mui/material';
import PolicyRow from './Template/PolicyRow';
import TablePagination from '../Common/TablePagination'
const momentTimezone = require('moment-timezone');
const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const PoliciesTable: FC<{
  policyList: any;
  isRefresh: boolean;
  setIsRefresh: any;
  handleClickCheck: any;
  isSelected: any;
  isSkeleton: boolean;
  totalCount: number;
  page: number;
  setPage: any;
  rowsPerPage: number;
  setRowsPerPage: any
}> = (prop) => {
  const { policyList, isRefresh, setIsRefresh, handleClickCheck, isSelected, totalCount, page, setPage, rowsPerPage, setRowsPerPage } = prop;
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatedPolicy, setPolicyUpdated] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  useEffect(() => {}, [isRefresh]);

  return (
    <Box sx={{ ...Style.PoliciesAndProcedures.PoliciesTableWrapper, overflowX: 'inherit' }}>
      {!prop.isSkeleton ? (
        <Table
          sx={{ minWidth: 650, borderSpacing: '0px 10px', borderCollapse: 'separate' }}
          aria-label="site list table">
          <TableHead sx={{ position: 'sticky', top: '86px', zIndex: '1', backgroundColor: '#fbfbff' }}>
            <TableRow>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  sx={{ visibility: 'hidden' }}
                  color="primary"
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  // checked={rowCount > 0 && numSelected === rowCount}
                  // onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts'
                  }}
                />
              </TableCell> */}

              <TableCell colSpan={2}>Policy Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Last Draft</TableCell>
              <TableCell>Created By</TableCell>
              {/* <TableCell>Updated By</TableCell> */}
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policyList?.data?.map((row: any, index: any) => (
              // <Box sx={Style.Common.Divider}></Box>
              <PolicyRow
                handleClickCheck={(event, data) => handleClickCheck(event, data)}
                isSelected={(data) => isSelected(data)}
                setIsRefresh={setIsRefresh}
                isRefresh={isRefresh}
                row={row}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table
          sx={{ minWidth: 650, borderSpacing: '0px 10px', borderCollapse: 'separate' }}
          aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell>Policy Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Last Draft</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Last Revised By</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                cursor: 'pointer',
                '.MuiTableCell-root': {
                  backgroundColor: '#FFFFFF ',

                  borderBottom: '1px solid  #CCCCCC',
                  borderTopRadius: '4px',
                  borderTop: '1px solid  #CCCCCC',
                  '&:last-child': {
                    borderRight: '1px solid  #CCCCCC',
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px'
                  },
                  '&:first-child': {
                    borderLeft: '1px solid  #CCCCCC',
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px'
                  }
                }
              }}>
              <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                {' '}
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell align="right" sx={{ minWidth: 200 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      <TablePagination 
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};

export default PoliciesTable;
