import Style from '@jibin/common/style/Styles';
import { Box } from '@mui/material';
import React from 'react';
import AuditLogsTable from './AuditLogsTable';

const AuditLogs = () => {
  return (
    <>
      <Box sx={Style.CompanySettingPage.CompanySettingPageWrapper}>
        <AuditLogsTable />
      </Box>
    </>
  );
};

export default AuditLogs;
