/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  Typography,
  Skeleton,
  Chip,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanyManagementApi } from 'src/Pages/CompanyManagement/CompanyManagement.Api';
import IntegrationCard from './IntegrationCard';
import React from 'react';
import SearchIcon from '@jibin/common/icons/Search/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Integration = () => {
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [cyberSecurityDomains, setCyberSecurityDomains] = useState([]);
  const [selectedCyberSecurityDomains, setSelectedCyberSecurityDomains] = useState([]);
  const [searchByName, setSearchByName] = useState('');
  const [isRefresh, setIsRefresh] = useState(false);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [integrationsData, setIntegrationsData] = useState([] as any);
  const [filteredIntegrationList, setFilteredIntegrationList] = useState([]);
  const [customerList, setCustomerData] = useState([] as any);
  const [orgList, setOrgData] = useState([] as any);

  var settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    padding: '16px',
    nextArrow: (
      <IconButton
        sx={{
          transition: '0.3s all ease',
          zIndex: 11,
          padding: '4px',
          width: '34px',
          height: '34px',
          border: '1px solid #CCCCCC',
          display: 'flex !important',
          background: '#ffffff',
          position: 'absolute',
          right: '-50px',
          left: 'auto !important',
          transform: 'translateY(-50%)',
          top: '50%',
          ':hover': {
            background: '#ffffff'
          },
          '::before': {
            content: '""'
          }
        }}>
        <ChevronRightIcon
          sx={{
            color: '#464646'
          }}
        />
      </IconButton>
    ),
    prevArrow: (
      <IconButton
        sx={{
          transition: '0.3s all ease',
          zIndex: 11,
          padding: '4px',
          width: '34px',
          height: '34px',
          border: '1px solid #CCCCCC',
          display: 'flex !important',
          background: '#ffffff',
          position: 'absolute',
          left: '-50px',
          right: 'auto !important',
          transform: 'translateY(-50%)',
          top: '50%',
          ':hover': {
            background: '#ffffff'
          },
          '::before': {
            content: '""'
          }
        }}>
        <ChevronLeftIcon
          sx={{
            color: '#464646'
          }}
        />
      </IconButton>
    )
  };

  const GetIntegrations = async () => {
    try {
      setIsSkeleton(true);
      const res = await CompanyManagementApi.GetIntegrations(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid,
        commonDetails.data.company.external_id,
        ''
      );
      setIntegrationsData(res?.data?.data);
      populateCyberSecurityDomains(res?.data?.data);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  const GetCustomersList = useCallback(async () => {
    try {
      const res = await CompanyManagementApi.GetCustomersList();
      setCustomerData(res?.data?.data);
    } catch (error) {}
  }, []);

  const GetOrgList = useCallback(async () => {
    try {
      const res = await CompanyManagementApi.GetOrgList();
      setOrgData(res?.data?.data);
    } catch (error) {}
  }, []);

  const populateCyberSecurityDomains = (data: any) => {
    if (!data) {
      return;
    }
    const uniqueDomains = new Set([]);
    data.map((item: any) => {
      if (item.services) {
        item.services.map((el: any) => uniqueDomains.add(el));
      }
    });
    setCyberSecurityDomains(Array.from(uniqueDomains));
  };

  const selectCybersecurityDomain = (domain: string) => {
    if (
      selectedCyberSecurityDomains?.length === 0 ||
      !selectedCyberSecurityDomains.includes(domain)
    ) {
      setSelectedCyberSecurityDomains([...selectedCyberSecurityDomains, domain]);
    } else {
      setSelectedCyberSecurityDomains(
        [...selectedCyberSecurityDomains].filter((el: string) => el !== domain)
      );
    }
  };

  const isCyberSecurityDomainSelected = (domain: string) => {
    return selectedCyberSecurityDomains?.includes(domain);
  };

  useEffect(() => {
    GetIntegrations();
  }, [commonDetails, isRefresh]);

  useEffect(() => {
    GetOrgList();
  }, [GetCustomersList, GetOrgList]);

  useEffect(() => {
    if (selectedCyberSecurityDomains.length === 0 && searchByName.trim().length === 0) {
      setFilteredIntegrationList(integrationsData);
      return;
    }
    const filteredArray = integrationsData.filter((integration) => {
      if (integration.services) {
        if (searchByName.trim().length && selectedCyberSecurityDomains.length) {
          return (
            integration.services.some((domain) => selectedCyberSecurityDomains.includes(domain)) &&
            integration.name?.toLowerCase().includes(searchByName.trim().toLowerCase())
          );
        } else if (searchByName.trim().length && !selectedCyberSecurityDomains.length) {
          return integration.name?.toLowerCase().includes(searchByName.trim().toLowerCase());
        } else if (!searchByName.trim().length && selectedCyberSecurityDomains.length) {
          return integration.services.some((domain) =>
            selectedCyberSecurityDomains.includes(domain)
          );
        }
      }
    });
    setFilteredIntegrationList(filteredArray);
  }, [selectedCyberSecurityDomains, searchByName, integrationsData]);

  return (
    <>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={{ padding: '40px 32px' }}>
          <Box sx={{ marginBottom: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, color: '#696969', fontSize: '19px' }}>
                Connect Tools
              </Typography>
              <TextField
                placeholder="Search by Vendor or Tool"
                hiddenLabel
                size="small"
                name="ipOrAction"
                value={searchByName}
                onChange={(e) => setSearchByName(e.target.value)}
                sx={{
                  input: { backgroundColor: '#FFFFFF' },
                  '.MuiOutlinedInput-root': {
                    bgcolor: '#FFFFFF'
                  },
                  width: 250
                }}
                inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box
              sx={{
                width: 'calc(100% - 68px)',
                margin: '24px auto',
                '& .slick-slide': { paddingRight: '16px' },
                '& .MuiIconButton-root.slick-disabled': {
                  display: 'none !important'
                }
              }}>
              <Slider {...settings}>
                <Chip
                  sx={{
                    padding: '17px 14px',
                    fontSize: '16px',
                    lineHeight: '19px',
                    background: !selectedCyberSecurityDomains.length ? '#D9E1FD' : 'transparent',
                    color: !selectedCyberSecurityDomains.length ? '#436AF3' : '#9D9D9D',
                    border: '1px solid',
                    borderColor: !selectedCyberSecurityDomains.length ? '#8EA6F8' : '#cccccc',
                    display: 'flex !important'
                  }}
                  label={'All Tools'}
                  size="small"
                  onClick={() => setSelectedCyberSecurityDomains([])}
                />
                {cyberSecurityDomains.map((domain) => {
                  return (
                    <Chip
                      sx={{
                        padding: '17px 14px',
                        fontSize: '16px',
                        lineHeight: '19px',
                        background: isCyberSecurityDomainSelected(domain)
                          ? '#D9E1FD'
                          : 'transparent',
                        color: isCyberSecurityDomainSelected(domain) ? '#436AF3' : '#9D9D9D',
                        border: '1px solid',
                        borderColor: isCyberSecurityDomainSelected(domain) ? '#8EA6F8' : '#cccccc',
                        display: 'flex !important'
                      }}
                      label={domain}
                      size="small"
                      onClick={() => selectCybersecurityDomain(domain)}
                    />
                  );
                })}
              </Slider>
            </Box>
          </Box>

          <Box sx={{ mb: 4, mt: 6 }}>
            <Grid container spacing={3}>
              {filteredIntegrationList?.map((el: any, index: any) => {
                return (
                  <Grid key={index} sx={{ flexGrow: 1 }} item xs={12} sm={6} md={4}>
                    <IntegrationCard
                      orgList={orgList}
                      customerList={customerList}
                      el={el}
                      isRefresh={isRefresh}
                      setIsRefresh={setIsRefresh}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Box sx={{ backgroundColor: '#F2F9FF', borderRadius: '16px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                padding: '32px 64px'
              }}>
              <Box>
                <Typography
                  variant="h2"
                  sx={{ fontSize: '28px', fontWeight: 700, color: '#464646', mb: 2 }}>
                  Request new Tools
                </Typography>
                <Typography variant="body1" sx={{ color: '#696969', fontWeight: 600 }}>
                  Request a new tool integration
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={() =>
                    window.open('https://www.redesign-group.com/contact-us', '_blank')
                  }>
                  Feedback
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Integration;
