import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Box, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { UpGuardApi } from 'src/Pages/UpGuard/UpGuard.Api';
const UpGuardChart = ({
  data,
  upGuardStats,
  upGuardStatsLoading,
  isDragable,
  customIcon,
  currentGrid,
  dropdownMenu
}: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const renderColor = (rating: any) => {
    let data = {
      bgcolor: 'rgba(167, 243, 208, 0.2)',
      border: '1px solid #27AE60',
      color: '#27AE60'
    };
    if (rating > 800) {
      data = data;
    } else if (rating > 600 && rating <= 800) {
      data = {
        bgcolor: 'rgba(170, 215, 96, 0.16)',
        border: '1px solid #AAD760',
        color: '#34D399'
      };
    } else if (rating > 400 && rating <= 600) {
      data = {
        bgcolor: 'rgba(243, 197, 52, 0.16)',
        border: '1px solid #F3C534',
        color: '#FCD34D'
      };
    } else if (rating > 200 && rating <= 400) {
      data = {
        bgcolor: 'rgba(255, 166, 102, 0.16)',
        border: '1px solid #FFA666',
        color: '#F59E0B'
      };
    } else if (rating <= 200) {
      data = {
        bgcolor: 'rgba(242, 113, 100, 0.16);',
        border: '1px solid #F27164',
        color: '#F87171'
      };
    }
    return data;
  };
  return (
    <Box
      sx={{
        border: '1px solid #CCCCCC',
        width: 1,
        p: 3,
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        cursor: 'default'
      }}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Attack Surface Risk Rating
            </Typography>
            <Typography variant="caption">Last 12 months</Typography>
          </Box>
          {location.pathname == PageRouteConstants.DashBoard && (
            <>
              {isDragable && (
                <>
                  {customIcon}{' '}
                  {currentGrid?.i === 'dashboard-attack-surface-management' ? dropdownMenu : null}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box>
        <Box sx={{ ...Style.Upguard.RatingBox, width: '100%' }}>
          <Box sx={{ width: 'fit-content' }}>
            <Typography
              variant="body2"
              onClick={() => {
                if (location.pathname == PageRouteConstants.DashBoard) {
                  // navigate(PageRouteConstants.UpGuard);
                }
              }}
              sx={{
                fontWeight: 500,

                color: location.pathname == PageRouteConstants.DashBoard ? '#436AF3' : 'black',
                cursor: location.pathname == PageRouteConstants.DashBoard ? 'pointer' : 'inherit'
              }}>
              My Company Rating:
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {upGuardStatsLoading ? (
              <Skeleton variant="circular" style={{ ...Style.Upguard.Grade, transform: 'none' }} />
            ) : (
              <Box
                sx={{
                  ...Style.Upguard.Grade,
                  background: renderColor(upGuardStats?.score).bgcolor,
                  border: renderColor(upGuardStats?.score).border
                }}>
                <Typography
                  sx={{ ...Style.Upguard.Arating, color: renderColor(upGuardStats?.score).color }}>
                  {upGuardStats?.grade}
                </Typography>
              </Box>
            )}
            <Box
              className="attack-surface-rating"
              sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
                {upGuardStatsLoading ? (
                  <Skeleton width={100} />
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontSize: '40px',
                        color: renderColor(upGuardStats?.score).color,
                        fontWeight: 600,
                        lineHeight: '40px'
                      }}>
                      {upGuardStats?.score}
                    </Typography>

                    <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#9d9d9d' }}>
                      / {upGuardStats?.total_score}
                    </Typography>
                  </>
                )}
              </Box>
              <Box>
                <Legend
                  formatter={(value, entry, index) => (
                    <span style={{ color: '#464646', fontSize: '12px' }}>{value}</span>
                  )}
                  payload={[
                    {
                      id: 'value',
                      value: 'My Company Rating',
                      type: 'circle',
                      color: '#436AF3'
                    }
                  ]}
                />
                {/* <Legend
                formatter={(value, entry, index) => (
                  <span style={{ color: '#464646', fontSize: '12px' }}>{value}</span>
                )}
                payload={[
                  {
                    id: 'value',
                    value: 'My Company Rating',
                    type: 'circle',
                    color: '#94ACFF'
                  }
                ]}
              /> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 2, height: '210.08px', width: 1, ml: '-20px' }}>
        {' '}
        {upGuardStatsLoading ? (
          <Skeleton
            style={{
              height: '100%',
              width: '100%',
              margin: '0 auto 0 20px'
            }}
          />
        ) : (
          <ResponsiveContainer height="100%" width="100%">
            <LineChart
              data={data}
              margin={{
                top: 5,

                bottom: 5
              }}>
              <CartesianGrid color="#EEEEEE" />
              <XAxis fontSize={'10px'} padding={{ right: 30 }} color={'#9E9E9E'} dataKey="month" />
              <YAxis domain={[0, 1000]} fontSize={'10px'} color={'#9E9E9E'} />
              <Tooltip
                labelFormatter={(label) => {
                  const selectedData = data.find((entry) => entry.month == label);
                  return selectedData ? `` : '';
                }}
              />
              <Line
                type="monotone"
                dataKey="value"
                name="My Company Rating"
                stroke="#436AF3"
                strokeDasharray="3 3"
                fontSize={'12px'}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
};
export default UpGuardChart;
