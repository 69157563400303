import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  Grid
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import { RemediationApi } from './Remediation.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { cp } from 'fs/promises';
import RemidiationPopUpList from '@jibin/common/components/RemidiationPopUp/RemidiationPopupList';

import { CustomIcon } from '@jibin/common/icons/AssetsIcons/CustomIcon';
import DomainIcon from '@jibin/common/icons/AssetsIcons/Domain';
import IpIcon from '@jibin/common/icons/AssetsIcons/IpIcon';
import MacAddressIcon from '@jibin/common/icons/AssetsIcons/macAddressIcon';
import MachineIcon from '@jibin/common/icons/AssetsIcons/MachineIcon';
import { SourceNameFilter, SourceTypeEnums } from '@jibin/common/utils/enums/SourceTypeEnums';
import Style from '@jibin/common/style/Styles';
import SearchIcon from '@jibin/common/icons/Search/Search';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import FilterCancelIcon from '@jibin/common/icons/FilterCancelIcon';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import CreateRemediation from './CreateRemediation';
import { UserRoles } from '@jibin/common/utils/constants/ConstantName';
import { NoDataAvailable, NoSearchDataAvailable } from '../Common/NoDataAvailable';
import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import checkUseHasWhichUseHasPermission from '@jibin/common/hooks/checkUseHasWhichUseHasPermission';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import ConformationModel from '@jibin/common/components/ConformationModel';
import SuccessConfirmation from '@jibin/common/components/ConformationModel/SuccessConfirmation';
import { GetAssignImage } from '@jibin/common/utils/Common';
import CheckBoxOutLine from '@jibin/common/icons/CheckBoxOutLine/CheckBoxOutLine';
import CheckBoxLightBlue from '@jibin/common/icons/CheckBoxLightBlue/CheckBoxLightBlue';
import TablePagination from '../Common/TablePagination';
import UnAssigned from '../Common/UnAssigned';
import HeadingHeader from '../Common/HeadingHeader';
import { LoginContext } from '@jibin/common/Context/LoginContext';
import FilterDropdown from './FilterDropDown';
var debounce = require('lodash.debounce');

type Anchor = 'top' | 'left' | 'bottom' | 'right';
export const Remediation: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [selected, setSelected] = useState<any[]>([]);
  const [useParams, setUseParam] = useSearchParams();
  const [rowData, setRowData] = useState({} as any);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [assignees, setAssignees] = useState({});
  const [searchTag, setSearchTag] = useState('');
  const [click, setclick] = useState(false);
  const [initialRemediation, setInitialRemediation] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [actionTakenValue, setActionTakenValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [assigeData, setAssigeData] = useState(null);
  const [remediationSummaryTotalCount, setRemediationSummaryTotalCount] = useState(null);
  const [option, setOption] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page_number: 0,
    page_size: 0,
    total_pages: 0,
    total_records: 0
  });
  const [pagination, setPagination] = useState({
    page_no: '1',
    page_size: '10'
  });

  const [state, setState] = useState({
    right: false
  });
  const [remediationSummary, setRemediationSummary] = useState(null);
  const whichUserHasPermissionForMultiSelect = checkUseHasWhichUseHasPermission([
    UserRoles.CUSTOMER_ADMIN
  ]);
  const source = useParams.get('source');
  const [remediationFilterform, setRemediationFilterform] = useState({
    keyword: '',
    severity: '',
    assignee_uuids: '',
    status: 'not_started,in_progress,reopened',
    source: source || '',
    tag_uuids: ''
  });
  const [filterData, setFilterData] = useState({
    severity: [''],
    assignee_uuids: [''],
    status: ['not_started', 'in_progress', 'reopened'],
    source: [source || ''],
    tag_uuids: ['']
  });
  const [initialFilter, setInitialFilter] = useState({
    statusesForm: 'not_started,in_progress,reopened',
    statusesFilter: ['not_started', 'in_progress', 'reopened']
  });
  const location = useLocation();
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        fontSize: '14px',
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100
      }
    }
  };
  const handleSearchTag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchTag(e.target.value);
  };
  const [anchorElNew, setAnchorElNew] = useState<null | HTMLElement>(null);
  const [assigneeList, setAssigneeList] = useState([] as any);
  const [isInitialFilter, setIsInitialFilter] = useState(true);
  const openNew = Boolean(anchorElNew);
  const handleClickNew = (event: any) => {
    setAnchorElNew(event.currentTarget);
  };
  const handleCloseNew = () => {
    setAnchorElNew(null);
  };
  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    setclick(!click);

    if (!open) {
      useParams.delete('remediation_uuids');
      setUseParam(useParams);
    } else {
      useParams.set('remediation_uuids', row?.remediation_uuid);
      setUseParam(useParams);
    }
    setRowData(row);
    setState({ ...state, [anchor]: open });
  };
  const setParams = (index) => {
    const params = {
      remediation_uuids: index
    };
    setUseParam(params);
  };
  useEffect(() => {
    setPage(0);
  }, [
    remediationFilterform.keyword,
    remediationFilterform.severity,
    remediationFilterform.source,
    remediationFilterform.status,
    remediationFilterform.assignee_uuids,
    remediationFilterform.tag_uuids
  ]);

  const handleParamFilter = () => {
    setRemediationFilterform({
      keyword: useParams.get('keyword') ? useParams.get('keyword') : '',
      severity: useParams.get('severity') ? useParams.get('severity') : '',
      assignee_uuids: useParams.get('assignee_uuids') ? useParams.get('assignee_uuids') : '',
      // status: useParams.get('status')
      //   ? useParams.get('status')
      //   : isInitialFilter && useParams.get('remediation_uuids')
      //   ? 'not_started,in_progress,reopened,resolved'
      //   : isInitialFilter
      //   ? 'not_started,in_progress,reopened'
      //   : remediationFilterform.status,
      status: useParams.get('status')
        ? useParams.get('status')
        : isInitialFilter
        ? initialFilter.statusesForm
        : remediationFilterform?.status,
      source: source || '',
      tag_uuids: useParams.get('tag_uuids') ? useParams.get('tag_uuids') : ''
    });
    setFilterData({
      severity: useParams.get('severity') ? useParams.get('severity').split(',') : [''],
      assignee_uuids: useParams.get('assignee_uuids')
        ? useParams.get('assignee_uuids').split(',')
        : [''],
      // status: useParams.get('status')
      //   ? useParams.get('status').split(',')
      //   : isInitialFilter && useParams.get('remediation_uuids')
      //   ? ['not_started', 'in_progress', 'reopened', 'resolved']
      //   : isInitialFilter
      //   ? ['not_started', 'in_progress', 'reopened']
      //   : [''],
      status: useParams.get('status')
      ? useParams.get('status').split(',')
      : isInitialFilter
      ? initialFilter.statusesFilter
      : filterData?.status,
      source: useParams.get('source') ? useParams.get('source').split(',') : [source || ''],
      tag_uuids: useParams.get('tag_uuids') ? useParams.get('tag_uuids').split(',') : ['']
    });

    setIsInitialFilter(false);
  };

  useEffect(() => {
    handleParamFilter();
  }, [useParams]);

  const [remediations, setRemediations] = useState({} as any);
  const GetRemediations = useCallback(() => {
    setIsDataLoaded(true);
    RemediationApi.Remediations(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams({
        ...remediationFilterform,
        ...pagination,
        remediation_uuids: useParams.get('remediation_uuids') || ''
      }).toString()
    ).then(
      (q: any) => {
        if (useParams.get('remediation_uuids') && !click) {
          const filterdData = q.data.data.filter(
            (el, index) => el.remediation_uuid == useParams.get('remediation_uuids')
          );

          if (filterdData[0]?.remediation_uuid) {
            setTimeout(() => {
              const element = document.getElementById(filterdData[0]?.remediation_uuid);
              if (element) {
                var headerOffset = 90;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth'
                });
                element.classList.add('flash');
                setTimeout(() => {
                  element.classList.remove('flash');
                }, 1000);
              }
            }, 1);

            toggleDrawer('right', true, filterdData[0]);
            setRowData(filterdData[0]);
          }
        }

        if (isSkeleton && q.data.data.length) {
          setInitialRemediation(true);
        }
        setRemediations(q.data?.data);
        setTotalCount(q?.data?.pagination?.total_records);
        // const findDataOfSelected = q?.data?.data
        //   ?.filter((el) => selected?.includes(el?.remediation_uuid))
        //   ?.map((el) => el?.remediation_uuid);
        // setSelected(findDataOfSelected);
        setPaginationData(q?.data?.pagination);
        setIsSkeleton(false);
        setIsDataLoaded(false);
        setUseParam(useParams);
      },
      (err: any) => {
        setIsSkeleton(false);
        setIsDataLoaded(false);
      }
    );
  }, [
    commonDetails,
    remediationFilterform,
    isRefresh,
    location.search,
    pagination.page_no,
    pagination.page_size
  ]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page_no: String(page + 1),
      page_size: String(rowsPerPage)
    }));
  }, [page, rowsPerPage]);

  const GetAssignees = useCallback(async () => {
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      res?.data?.data.push({
        company_name: 'All Assignees',
        users: [{ name: 'All Assignees', user_uuid: '', first_name: 'All', last_name: 'Assignees' }]
      });
      setAssigneeList(res?.data?.data);
      renderOption(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [commonDetails]);

  const GetTags = useCallback(
    async (searchTag = '') => {
      const query: any = {
        include_non_associated_tags: false
      };

      try {
        const res = await RemediationApi.TagsList(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          new URLSearchParams(query).toString()
        );
        const selectedFilterTag = [];
        if (res.data.data) {
          const TagList = res.data.data.map((el) => {
            if (filterData.tag_uuids.includes(el?.tag_uuid)) {
              selectedFilterTag.push(el?.tag_uuid);
            }
            return {
              key: el?.tag_name,
              value: el?.tag_uuid
            };
          });
          TagList.push({
            key: 'All Tags',
            value: ''
          });
          setTagOptions(TagList);
        }
      } catch (error) {}
    },
    [commonDetails]
  );

  useEffect(() => {
    if (!click) GetTags();
  }, [click, GetTags]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!click) {
        GetRemediations();
        GetAssignees();
      }
    }, 700);
    return () => {
      clearTimeout(timer);
    };
  }, [GetRemediations, GetAssignees, remediationFilterform, isRefresh, click]);

  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F59E0B';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleChange = useCallback(
    debounce(
      (event: any) => {
        let {
          target: { value, name }
        } = event;
        const filterValue = value.filter((el: any) => el != '');
        setIsInitialFilter(false);
        let string;
        if (filterValue.length == 0) {
          string = '';
          const dataForApi = { ...remediationFilterform };
          dataForApi[name] = string;
          setMultiPaleUseSeachParams(dataForApi);
        } else {
          string = filterValue.join(',');
          const dataForApi = { ...remediationFilterform };

          dataForApi[name] = string;
          setMultiPaleUseSeachParams(dataForApi);
        }
      },
      100,
      true
    ),
    [remediationFilterform, setRemediationFilterform, filterData, setFilterData]
  );

  const setMultiPaleUseSeachParams = (params: any) => {
    for (const key in params) {
      useParams.set(key, params[key]);
      setUseParam(useParams);
    }
  };

  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FCD34D';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };
  const remediationStatusItems = [
    { key: 'Not Started', value: 'not_started' },
    { key: 'In Progress', value: 'in_progress' },
    { key: 'Resolved', value: 'resolved' },
    { key: 'Reopened', value: 'reopened' },
    { key: 'All Statuses', value: '' }
  ];

  const SeverityList = [
    { key: 'Critical', value: 'critical' },
    { key: 'High', value: 'high' },
    { key: 'Medium', value: 'medium' },
    { key: 'Low', value: 'low' },
    { key: 'All Criticalities', value: '' }
  ];

  const Tag = [
    { value: 'disany2', key: 'Disneyland', tooltip: 'Disneyland' },
    { value: 'disany1', key: 'Disney Channel', tooltip: 'Disney Channel' },
    { value: 'disany3', key: 'Disney World', tooltip: '' },
    { value: '', key: 'All Tags', tooltip: '' }
  ];
  const getSource = (source: any) => {
    let sourceLabel: any = {
      tooltip: '',
      label: ''
    };

    switch (source) {
      case SourceTypeEnums.gap:
        sourceLabel.label = 'CE';
        sourceLabel.tooltip = 'Compliance';
        break;
      case SourceTypeEnums.vuln:
        sourceLabel.label = 'VM';
        sourceLabel.tooltip = 'Vulnerability Management';
        break;
      case SourceTypeEnums.pen:
        sourceLabel.label = 'PT';
        sourceLabel.tooltip = 'Penetration Testing';
        break;
      case SourceTypeEnums.endpoint:
        sourceLabel.label = 'EDR';
        sourceLabel.tooltip = 'Endpoint Detection & Response';
        break;
      case SourceTypeEnums.network_protection:
        sourceLabel.label = 'NDR';
        sourceLabel.tooltip = 'Network Detection & Response';
        break;
      case SourceTypeEnums.attack_surface:
        sourceLabel.label = 'ASM';
        sourceLabel.tooltip = 'Attack Surface Management';
        break;
      case SourceTypeEnums.siem:
        sourceLabel.label = 'SIEM';
        sourceLabel.tooltip = 'Security Information & Event Management';
        break;
      case SourceTypeEnums.audit:
        sourceLabel.label = 'A/A';
        sourceLabel.tooltip = 'Audit/Assessment';
        break;
      case SourceTypeEnums.email:
        sourceLabel.label = 'EP';
        sourceLabel.tooltip = 'Email Protection';
        break;
      case SourceTypeEnums.user:
        sourceLabel.label = 'UM';
        sourceLabel.tooltip = 'User Management';
        break;
    }
    return (
      <Tooltip placement="top" arrow title={sourceLabel?.tooltip}>
        <Typography
          variant="body2"
          sx={{
            fontSize: '12px'
          }}>
          {sourceLabel.label}
        </Typography>
      </Tooltip>
    );
  };
  const handleClearFuntion = () => {
    setSelected([]);
    handleParamFilter();
    deleteMultiPlalParams(remediationFilterform);
    setRemediationFilterform((prev) => ({
      ...prev,
      status: ''
    }))
    setFilterData((prev) => ({
      ...prev,
      status: ['']
    }));
  };
  const deleteMultiPlalParams = (params) => {
    for (const key in params) {
      useParams.delete(key);
    }
    setUseParam(useParams);
  };

  const renderValue = (selected: any, options: any) => {
    const selectedLabels = selected?.map((value) => {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.key : '';
    });

    return selectedLabels.join(', ');
  };
  const renderValueForAssignee = (selected: any, options: any) => {
    const data = findIntegrationsByKeys(options, selected);
    const selectedLabels = []
      .concat(
        ...data.map((value) => {
          return value.users;
        })
      )
      .map((el: any) => el?.first_name + ' ' + el?.last_name);
    //@ts-ignore
    const filterUniq = [...new Set(selectedLabels)];
    return filterUniq.join(', ');
  };
  function findIntegrationsByKeys(data, keyNames) {
    const foundIntegrations = [];

    data.forEach((entry) => {
      const integrations = entry.users.filter((integration) =>
        keyNames.includes(integration.user_uuid)
      );

      if (integrations.length > 0) {
        foundIntegrations.push({
          company_name: entry.company_name,
          users: integrations
        });
      }
    });

    return foundIntegrations;
  }
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = remediations?.map((n) => n.remediation_uuid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const { engineer } = useContext(LoginContext);

  const handleDeleteAll = async () => {
    const data = {
      remediation_uuids: [...selected],

      action: {
        action_type: 'delete'
      }
    };
    try {
      const res = await RemediationApi.DeletAllRemediation(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        data
      );
      if (paginationData.page_number === paginationData.total_pages) {
        setPage(page - 1);
      } else {
        GetRemediations();
      }
    } catch (error) {}
  };
  const handleAssignAll = async () => {
    const data = {
      remediation_uuids: [...selected],

      action: {
        action_type: 'assign',
        action_data: {
          owner_company_uuid: assigeData?.company_uuid,
          owner_user_uuid: assigeData?.user_uuid
        }
      }
    };

    try {
      const res = await RemediationApi.DeletAllRemediation(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        data
      );
    } catch (error) {}
  };
  const handleResolveAll = async () => {
    const data = {
      remediation_uuids: [...selected],

      action: {
        action_type: 'resolve',
        action_data: {
          action_taken: actionTakenValue
        }
      }
    };
    try {
      const res = await RemediationApi.DeletAllRemediation(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        data
      );
    } catch (error) {}
  };
  const handleSearch = (e) => {
    setMultiPaleUseSeachParams({ ...remediationFilterform, [e.target.name]: e.target.value });
    setRemediationFilterform({ ...remediationFilterform, [e.target.name]: e.target.value });
    setIsInitialFilter(false);
  };
  const isSelected = (name: string) => selected?.indexOf(name) !== -1;
  const handleClickCheck = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const renderOption = (assignees) => {
    const options = assignees.data.map((option) => {
      const firstLetter = option.company_name.toUpperCase();

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, ...el, company_uuid: option?.company_uuid };
      });
      return data;
    });

    const optionarray = options.concat.apply([], options).flat();
    setOption(optionarray);
  };
  const generateMenuItems = () => {
    return assigneeList.flatMap((item, index) => [
      item.company_name && (
        <Box sx={{ width: '100px' }}>
          <EllipsisContent
            maxTextWidth={150}
            maxWidth={150}
            text={item.company_name}
            sx={{
              px: 2,

              py: 1,
              display: item.company_name == 'All Assignees' ? 'none' : 'block',
              alignItems: 'center'
            }}
          />
        </Box>
      ),
      item.users
        ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
        ?.map((el, index) => (
          <MenuItem
            key={el?.user_uuid}
            value={el.user_uuid}
            sx={{ py: 0, display: el.name == 'All Assignees' ? 'none' : 'flex' }}>
            <Checkbox checked={filterData.assignee_uuids.indexOf(el.user_uuid) > -1} />

            <EllipsisContent
              maxTextWidth={150}
              text={el.first_name + ' ' + el.last_name}
              sx={{
                fontSize: '14px',
                '>span': {
                  fontSize: '14px'
                }
              }}
            />
          </MenuItem>
        ))
    ]);
  };

  const GetRemediationSummary = useCallback(async () => {
    try {
      setIsSkeleton(true);
      const res = await RemediationApi.GetRemediationSummary(
        commonDetails?.data?.company.company_uuid,
        commonDetails?.data?.user_uuid
      );
      setRemediationSummary(res?.data?.data);
      const totalCountReduce =
        res?.data?.data?.remediation_status_counts?.in_progress +
        res?.data?.data?.remediation_status_counts?.not_started +
        res?.data?.data?.remediation_status_counts?.resolved;
      setRemediationSummaryTotalCount(totalCountReduce);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  }, [commonDetails]);

  useEffect(() => {
    GetRemediationSummary();
  }, [GetRemediationSummary]);

  return (
    <Box>
      <HeadingHeader
        subtitle1={'Remediation Tracker'}
        subtitle2={'Prioritized list of cybersecurity remediations'}
        commonDetails={commonDetails}
        UserRoles={UserRoles}
      />
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <>
          <Box sx={{ py: 3, px: 4 }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Summary
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={2} md={2} lg={3}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#436AF3',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Total Remediations'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {remediationSummaryTotalCount?.toLocaleString() ?? 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={2} md={2} lg={3}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#EF4444',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Not Started'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {remediationSummary?.remediation_status_counts?.not_started?.toLocaleString() ?? 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2} md={2} lg={3}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#FDE68A',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'In Remediation'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {remediationSummary?.remediation_status_counts?.in_progress?.toLocaleString() ?? 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2} md={2} lg={3}>
                  <Box
                    sx={{
                      border: '0.8px solid #CCCCCC',
                      bgcolor: '#ffffff',
                      borderRadius: 2,
                      p: 2
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: '#10B981',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap', fontWeight: 600, color: '#464646' }}>
                        {' '}
                        {'Resolved'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: '#464646' }} variant="h1">
                        {remediationSummary?.remediation_status_counts?.resolved?.toLocaleString() ?? 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ px: 4, py: 3, backgroundColor: '#FBFBFF', width: 1 }}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'flex-start',
                justifyContent: 'space-between'
              }}>
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
                <TextField
                  placeholder="Search"
                  hiddenLabel
                  size="small"
                  name="keyword"
                  value={remediationFilterform.keyword}
                  onChange={handleSearch}
                  sx={{
                    input: { backgroundColor: '#FFFFFF' },
                    '.MuiOutlinedInput-root': {
                      bgcolor: '#FFFFFF'
                    }
                  }}
                  inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ bgcolor: '#FFFFFF' }} position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <FormControl sx={{ width: 150 }}>
                  <Select
                    size="small"
                    displayEmpty
                    multiple
                    name="severity"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterData.severity}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        sx={{
                          py: '5.5px'
                        }}
                      />
                    }
                    renderValue={(selected) => renderValue(selected, SeverityList)}
                    MenuProps={MenuProps}
                    sx={{
                      py: 0.35,
                      fontSize: '14px',
                      color: '#696969',
                      '.MuiSelect-select': {
                        py: '5.5px'
                      },
                      backgroundColor: '#FFFFFF'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {SeverityList.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          display: !name.value ? 'none' : 'flex',

                          py: 0,
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name.key}
                        value={name.value}
                        autoFocus={index === 0}>
                        <Checkbox checked={filterData.severity.indexOf(name.value) > -1} />
                        <ListItemText
                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                          primary={name.key}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 150 }}>
                  <Select
                    size="small"
                    displayEmpty
                    multiple
                    name="source"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterData.source}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => renderValue(selected, SourceNameFilter)}
                    MenuProps={MenuProps}
                    sx={{
                      py: 0.35,
                      fontSize: '14px',
                      '.MuiSelect-select': {
                        py: '5.5px'
                      },
                      color: '#696969',
                      minWidth: { xxs: 0, md: '150px' },
                      backgroundColor: '#FFFFFF'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {SourceNameFilter?.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          py: 0,
                          display: !name.value ? 'none' : 'flex',
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name.key}
                        value={name.value}
                        autoFocus={index === 0}>
                        <Checkbox checked={filterData.source.indexOf(name.value) > -1} />
                        {name?.tooltip ? (
                          <Tooltip placement="top" arrow title={name?.tooltip}>
                            <ListItemText
                              sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                              primary={name.key}
                            />
                          </Tooltip>
                        ) : (
                          <EllipsisContent
                            maxTextWidth={150}
                            text={name.key}
                            sx={{ fontSize: '14px' }}
                          />
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: 150 }}>
                  <Select
                    size="small"
                    displayEmpty
                    multiple
                    name="status"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterData.status}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => renderValue(selected, remediationStatusItems)}
                    MenuProps={MenuProps}
                    sx={{
                      py: 0.35,
                      fontSize: '14px',
                      '.MuiSelect-select': {
                        py: '5.5px'
                      },
                      color: '#696969',
                      minWidth: { xxs: 0, md: '150px' },
                      backgroundColor: '#FFFFFF'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {remediationStatusItems?.map((name, index) => (
                      <MenuItem
                        sx={{
                          fontSize: '14px',
                          display: !name.value ? 'none' : 'flex',

                          py: 0,
                          '.MuiMenuItem-root': {
                            py: '0px'
                          }
                        }}
                        key={name.key}
                        value={name.value}
                        autoFocus={index === 0}>
                        <Checkbox checked={filterData.status.indexOf(name.value) > -1} />
                        <ListItemText
                          sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                          primary={name.key}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FilterDropdown
                  handleChange={handleChange}
                  setFilterData={setFilterData}
                  filterData={filterData}
                  assigneesOption={assigneeList}
                  tagsOption={tagOptions}
                  searchTag={searchTag}
                  handleSearchTag={handleSearchTag}
                />
                <Box sx={{ width: 120 }}>
                  <Button
                    onClick={handleClearFuntion}
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center',
                      py: 0.5,
                      whiteSpace: 'nowrap'
                    }}
                    variant="text">
                    {' '}
                    <FilterCancelIcon /> Clear filter
                  </Button>
                </Box>
              </Box>
              {selected?.length > 0 ? (
                <Box>
                  {' '}
                  <Button
                    id="basic-button"
                    aria-controls={openNew ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
                    variant="contained"
                    aria-expanded={openNew ? 'true' : undefined}
                    onClick={handleClickNew}>
                    Select Action
                    <Box sx={{ display: 'flex' }}>
                      <DropDown />
                    </Box>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElNew}
                    open={openNew}
                    onClose={handleCloseNew}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}>
                    <SuccessConfirmation
                      isLoading={isLoading}
                      handleClose={() => {
                        setIsRefresh(!isRefresh);
                        setActionTakenValue('');
                        handleCloseNew();
                      }}
                      actionTaken={true}
                      title="Resolve Remediations"
                      component={
                        <Box sx={{ mt: 3 }}>
                          <Typography
                            variant="caption"
                            sx={{ color: '#2D2D2D', fontWeight: 400, py: 2 }}>
                            Action Taken
                          </Typography>
                          <TextField
                            autoComplete={'none'}
                            multiline
                            value={actionTakenValue}
                            onChange={(e: any) => setActionTakenValue(e.target.value)}
                            rows={3}
                            sx={{
                              fontSize: '14px',

                              border: 'none',
                              textAlign: 'justify',
                              width: 1,
                              backgroundColor: 'white',
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#CCCCCC'
                              }
                            }}
                            placeholder="Add a description"
                          />
                        </Box>
                      }
                      type="text"
                      description={'Would you like to resolve all selected remediations?'}
                      asking={``}
                      returnData={'Published'}
                      mainBtnTitle={'Archive'}
                      text="Resolve Selected"
                      mainBtnType={'primary'}
                      cancelButtonText="Cancel"
                      conformButtonText="Confirm"
                      handleConfirm={async () => {
                        setIsLoading(true);
                        try {
                          await handleResolveAll();
                          setSelected([]);
                          setIsLoading(false);
                        } catch (error) {
                          setIsLoading(false);
                        }
                      }}
                      checked={false}
                    />
                    <SuccessConfirmation
                      isLoading={isLoading}
                      checked={false}
                      handleClose={() => {
                        setIsRefresh(!isRefresh);
                        setAssigeData(null);
                        handleCloseNew();
                      }}
                      actionTaken={true}
                      title="Assign Selected Remediations"
                      type="text"
                      // description={'Would you like to resolve all selected remediations?'}
                      asking={``}
                      component={
                        <Box sx={{ mt: 3 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                              Assignee
                            </Typography>
                            <Typography
                              variant="body2"
                              onClick={() =>
                                setAssigeData({
                                  first_name: commonDetails.data.first_name,
                                  last_name: commonDetails.data.last_name,
                                  user_uuid: commonDetails.data.user_uuid,

                                  ico: commonDetails.data.ico,
                                  company_uuid: commonDetails.data.company.company_uuid
                                })
                              }
                              sx={{
                                fontWeight: 600,
                                color: '#436AF3',
                                mb: 2,
                                cursor: 'pointer'
                              }}>
                              Assign to me
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', gap: 1.5 }}>
                            <FormControl sx={{ width: '100%' }}>
                              <Autocomplete
                                id="grouped-demo"
                                size="small"
                                fullWidth={true}
                                options={option}
                                value={assigeData}
                                onChange={(e, value) => {
                                  setAssigeData(value);
                                }}
                                groupBy={(option: any) => option.firstLetter}
                                getOptionLabel={(option: any) => {
                                  return `${option?.first_name} ${option?.last_name}`;
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ fontSize: '14px' }}
                                    {...params}
                                    placeholder="Assign to"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: assigeData ? (
                                        <InputAdornment position="start">
                                          <Box>
                                            {assigeData?.ico ? (
                                              <Box
                                                sx={{
                                                  ...Style.versionsCard.infoRound,
                                                  width: '24px',
                                                  height: '24px',
                                                  fontSize: '14px'
                                                }}>
                                                <img
                                                  width={'100%'}
                                                  height="100%"
                                                  style={{
                                                    objectFit: 'cover',
                                                    borderRadius: '50%'
                                                  }}
                                                  src={GetAssignImage(
                                                    assigeData?.firstLetter,
                                                    assigeData?.ico
                                                  )}
                                                  alt=""
                                                />
                                              </Box>
                                            ) : (
                                              <Typography
                                                sx={{
                                                  ...Style.versionsCard.infoRound,
                                                  width: '24px',
                                                  height: '24px',
                                                  fontSize: '14px'
                                                }}>
                                                {assigeData?.first_name?.charAt(0) +
                                                  '' +
                                                  assigeData?.last_name?.charAt(0)}
                                              </Typography>
                                            )}
                                          </Box>
                                        </InputAdornment>
                                      ) : null
                                    }}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                    key={option.user_uuid}>
                                    {option.ico ? (
                                      <Box
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        <img
                                          width={'100%'}
                                          height="100%"
                                          style={{
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                          }}
                                          src={GetAssignImage(option.firstLetter, option.ico)}
                                          alt=""
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        {option?.first_name?.charAt(0) +
                                          '' +
                                          option?.last_name?.charAt(0)}
                                      </Typography>
                                    )}

                                    <Typography variant="body2">
                                      {' '}
                                      {option.first_name} {option.last_name}
                                    </Typography>
                                  </Box>
                                )}
                                renderGroup={(params) => (
                                  <Box key={params.key}>
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        padding: '10px 10px'
                                      }}>
                                      {params.group}
                                    </Typography>
                                    {params.children}
                                  </Box>
                                )}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      }
                      returnData={'Published'}
                      mainBtnTitle={'Archive'}
                      text="Assign Selected"
                      mainBtnType={'primary'}
                      cancelButtonText="Cancel"
                      conformButtonText="Confirm"
                      handleConfirm={async () => {
                        setIsLoading(true);
                        try {
                          await handleAssignAll();
                          setSelected([]);
                          setIsLoading(false);
                        } catch (error) {
                          setIsLoading(false);
                        }
                      }}
                    />
                    {!whichUserHasPermissionForMultiSelect && (
                      <SuccessConfirmation
                        isLoading={isLoading}
                        checked={false}
                        handleClose={() => {
                          setIsRefresh(!isRefresh);
                          handleCloseNew();
                        }}
                        title="Confirm Action"
                        type="text"
                        text="Delete Selected"
                        description={
                          'Are you sure so you want to delete all selected remediations?'
                        }
                        asking={``}
                        returnData={'Published'}
                        mainBtnTitle={'Archive'}
                        mainBtnType={'error'}
                        cancelButtonText="Cancel"
                        conformButtonText="Delete"
                        handleConfirm={async () => {
                          setIsLoading(true);
                          try {
                            await handleDeleteAll();
                            setSelected([]);
                            setIsLoading(false);
                          } catch (error) {
                            setIsLoading(false);
                          }
                        }}
                      />
                    )}
                  </Menu>
                </Box>
              ) : (
                <Box>
                  <CreateRemediation
                    text={'Create'}
                    engineer={engineer}
                    GetRemediations={() => GetRemediations()}
                    GetTagsForList={() => GetTags(searchTag)}
                  />
                </Box>
              )}
            </Box>
            {remediations?.length == 0 || !remediations ? (
              initialRemediation ? (
                <NoSearchDataAvailable
                  title="No Search Results"
                  message="Try adjusting your search or filter to find what you are looking for"
                />
              ) : (
                <NoDataAvailable
                  title="No Data Available"
                  message="The Remediation Tracker page will be populated once the first remediation is
             discovered."
                />
              )
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  border: 'none',
                  boxShadow: 'none',
                  bgcolor: 'transparent',
                  overflowX: 'inherit'
                }}>
                <Table
                  sx={{
                    minWidth: 650,
                    borderCollapse: 'separate',
                    boxShadow: 'none',
                    borderSpacing: '0px 16px',
                    border: 'none'
                  }}
                  className="p10Table"
                  aria-label="site list table">
                  <TableHead
                    sx={{
                      position: 'sticky',
                      top: '86px',
                      zIndex: '9',
                      backgroundColor: '#fbfbff'
                    }}>
                    <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                      <TableCell>
                        <Checkbox
                          size="small"
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 14 },
                            width: '14px',
                            height: '14px'
                          }}
                          disabled={isDataLoaded}
                          indeterminate={
                            selected?.length > 0 && selected?.length < remediations?.length
                          }
                          indeterminateIcon={
                            selected?.length > 0 &&
                            selected?.length < remediations?.length && (
                              <CheckBoxOutLine
                                disabled={isDataLoaded}
                                sx={{
                                  fill: 'none',
                                  width: '14px',
                                  height: '14px'
                                }}
                              />
                            )
                          }
                          checked={
                            remediations?.length > 0 && selected?.length === remediations?.length
                          }
                          onChange={handleSelectAllClick}
                          checkedIcon={<CheckBoxLightBlue />}
                          icon={
                            <CheckBoxOutLine
                              sx={{
                                fill: 'none',
                                width: '14px',
                                height: '14px'
                              }}
                            />
                          }
                        />
                      </TableCell>

                      <TableCell>Criticality </TableCell>
                      <TableCell>Date Discovered</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell>Issue Name</TableCell>
                      <TableCell>Asset</TableCell>
                      <TableCell>Tags</TableCell>
                      <TableCell>Owner</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {remediations?.length > 0 ? (
                      remediations?.map((row, index) => {
                        const isItemSelected = isSelected(row?.remediation_uuid);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            id={row.remediation_uuid}
                            key={index}
                            onClick={() => {
                              toggleDrawer('right', true, row);
                              // GetRemidiationDetails(row);
                              // GetAssignees();
                              // setParams(index);
                            }}
                            sx={{
                              cursor: 'pointer',

                              bgcolor: '#fff',
                              '.MuiTableCell-root': {
                                px: '20px',

                                borderBottom: '1px solid  #CCCCCC',
                                borderTopRadius: '4px',
                                borderTop: '1px solid  #CCCCCC',
                                '&:last-child': {
                                  borderRight: '1px solid  #CCCCCC',
                                  borderTopRightRadius: '4px',
                                  borderBottomRightRadius: '4px'
                                },
                                '&:first-child': {
                                  borderLeft: '1px solid  #CCCCCC',
                                  borderTopLeftRadius: '4px',
                                  borderBottomLeftRadius: '4px'
                                }
                              }
                            }}>
                            <TableCell
                              key={row.name}
                              onClick={(event) => {
                                if (!isDataLoaded) {
                                  handleClickCheck(event, row?.remediation_uuid);
                                  stopImmediatePropagation(event);
                                }
                              }}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              padding="checkbox">
                              <Checkbox
                                disabled={isDataLoaded}
                                checked={isItemSelected}
                                checkedIcon={<CheckBoxLightBlue />}
                                inputProps={{
                                  'aria-labelledby': labelId
                                }}
                                icon={
                                  <CheckBoxOutLine
                                    sx={{
                                      fill: 'none',
                                      width: '21px',
                                      height: '20px'
                                    }}
                                  />
                                }
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Box
                                    sx={{
                                      backgroundColor: renderColorBackGround(row.severity),
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      width: 100,
                                      justifyContent: 'center',
                                      borderRadius: '100px',
                                      py: 0.5,
                                      px: 2
                                    }}>
                                    <FlagIcon
                                      sx={{
                                        backgroundColor: renderColorBackGround(row.severity),
                                        color: renderColor(row.severity),
                                        width: '12px'
                                      }}
                                    />
                                    <Typography
                                      variant="caption"
                                      sx={{ textTransform: 'capitalize' }}>
                                      {' '}
                                      {row.severity}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="caption">
                                {' '}
                                {moment(row.created_at).format('DD MMM YYYY').toUpperCase()}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{getSource(row.source)}</TableCell>
                            <TableCell sx={{ maxWidth: 250 }} align="left">
                              <EllipsisContent
                                variant="caption"
                                maxWidth={200}
                                text={row?.issue_name}
                              />{' '}
                            </TableCell>
                            <TableCell sx={{ maxWidth: 250 }} align="left">
                              <Box>
                                <Box sx={{ display: 'flex' }}>
                                  {row?.asset?.custom && (
                                    <Tooltip arrow placement={'top'} title={row?.asset?.custom}>
                                      <IconButton>
                                        <CustomIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {row?.asset?.ip && (
                                    <Tooltip arrow placement={'top'} title={row?.asset?.ip}>
                                      <IconButton>
                                        <IpIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {row?.asset?.mac && (
                                    <Tooltip arrow placement={'top'} title={row?.asset?.mac}>
                                      <IconButton>
                                        <MacAddressIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {row?.asset?.machine && (
                                    <Tooltip arrow placement={'top'} title={row?.asset?.machine}>
                                      <IconButton>
                                        <MachineIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {row?.asset?.hostname?.length > 0 && (
                                    <Tooltip
                                      arrow
                                      placement={'top'}
                                      title={row?.asset?.hostname?.join(',')}>
                                      <IconButton>
                                        <DomainIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  {row?.tags &&
                                    row.tags.map(
                                      (el: any, index: any) =>
                                        index == 0 && (
                                          <Chip
                                            label={el?.tag_name}
                                            sx={{
                                              mr: 0.5,
                                              bgcolor: 'common.lightBlue',
                                              maxWidth: '117px',
                                              textOverflow: 'ellipsis'
                                            }}
                                          />
                                        )
                                    )}

                                  <Typography
                                    component="span"
                                    sx={{ color: 'primary.main', whiteSpace: 'nowrap' }}>
                                    {row?.tags?.length > 1 && `+ ${row?.tags?.length - 1}`}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                  {row.owner.ico ? (
                                    <Box
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '32px',
                                        height: '32px'
                                      }}>
                                      <img
                                        width={'100%'}
                                        height="100%"
                                        style={{ objectFit: 'cover', borderRadius: '50%' }}
                                        src={`${process.env.REACT_APP_API_HOST}/${row.owner.ico}`}
                                        alt=""
                                      />
                                    </Box>
                                  ) : row.owner.first_name ? (
                                    <Typography
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '32px',
                                        height: '32px'
                                      }}>
                                      {' '}
                                      {row.owner.first_name.charAt(0) +
                                        '' +
                                        row.owner.last_name.charAt(0)}
                                    </Typography>
                                  ) : (
                                    <UnAssigned />
                                  )}
                                </Box>
                                {row.owner.first_name && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between'
                                    }}>
                                    <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                      {row.owner.first_name + ' ' + row.owner.last_name}
                                    </Typography>
                                    <Typography variant="caption">
                                      {row.owner?.company.name}
                                    </Typography>
                                  </Box>
                                )}
                                {row?.is_unassigned && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between'
                                    }}>
                                    <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                      Unassigned
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="caption">
                                {' '}
                                {row.status == 'in_progress' ? (
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        minWidth: '14px',
                                        height: '14px',
                                        width: '14px',
                                        backgroundColor: '#FBBF24',
                                        borderRadius: '50%'
                                      }}></Box>
                                    <Typography variant="caption">In Progress</Typography>
                                  </Box>
                                ) : row.status == 'not_started' ? (
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        minWidth: '14px',
                                        width: '14px',
                                        height: '14px',
                                        backgroundColor: '#EF4444',
                                        borderRadius: '50%'
                                      }}></Box>
                                    <Typography variant="caption">Not Started</Typography>
                                  </Box>
                                ) : row.status == 'reopened' ? (
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        minWidth: '14px',
                                        height: '14px',
                                        width: '14px',
                                        backgroundColor: '#FDE68A',
                                        borderRadius: '50%'
                                      }}></Box>
                                    <Typography variant="caption">Reopened</Typography>
                                  </Box>
                                ) : (
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        minWidth: '14px',
                                        height: '14px',
                                        width: '14px',
                                        backgroundColor: '#10B981',
                                        borderRadius: '50%'
                                      }}></Box>
                                    <Typography variant="caption">
                                      {row.status.charAt(0).toUpperCase() +
                                        '' +
                                        row.status.slice(1)}
                                    </Typography>
                                  </Box>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <>
                        <TableRow
                          sx={{
                            cursor: 'pointer',
                            '.MuiTableCell-root': {
                              backgroundColor: '#FFFFFF ',
                              px: '20px',
                              py: '30px',

                              borderBottom: '1px solid  #CCCCCC',
                              borderTopRadius: '4px',
                              borderTop: '1px solid  #CCCCCC',
                              '&:last-child': {
                                borderRight: '1px solid  #CCCCCC',
                                borderTopRightRadius: '4px',
                                borderBottomRightRadius: '4px'
                              },
                              '&:first-child': {
                                borderLeft: '1px solid  #CCCCCC',
                                borderTopLeftRadius: '4px',
                                borderBottomLeftRadius: '4px'
                              }
                            }
                          }}>
                          <TableCell align="left">
                            <Typography variant="caption">
                              {' '}
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption">
                              {' '}
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption">
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption">
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption">
                              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
                <SwipeableDrawer
                  BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                  sx={{
                    '.MuiDrawer-paper': {
                      boxShadow: 'none'
                    }
                  }}
                  anchor={'right'}
                  open={state['right']}
                  onClose={() => toggleDrawer('right', false, {})}
                  onOpen={() => toggleDrawer('right', true, rowData)}>
                  {!isSkeleton && (
                    <RemidiationPopUpList
                      el={rowData}
                      isGap={false}
                      remediation_uuid={rowData.remediation_uuid}
                      closePopup={() => toggleDrawer('right', false, {})}
                      isEngineer={engineer}
                      setIsRefresh={setIsRefresh}
                      isRefresh={isRefresh}
                    />
                  )}
                </SwipeableDrawer>
              </TableContainer>
            )}
            {!(remediations?.length == 0 || !remediations) && (
              <TablePagination
                totalCount={totalCount}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
