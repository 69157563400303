import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@jibin/common/icons/PhoneIcon';
import Style from '@jibin/common/style/Styles';
import CloseIcon from '@jibin/common/icons/Close/Close';
import PhoneInputWithCountrySelect from 'react-phone-number-input';

const ComapnyAdminDialog = ({ open, handleClose, data, company }) => {
  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottom: '1px solid #EBEBEB'
        }}
        id="alert-dialog-title">
        <Typography
          variant="body1"
          component="div"
          fontSize={'18px'}
          lineHeight={'22px'}
          fontWeight={600}>
          Admins
        </Typography>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          paddingTop: '20px !important'
        }}>
        {data?.length &&
          data.map((admin) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '6px',
                    flex: 1
                  }}>
                  <Box>
                    {admin?.ico ? (
                      <Box
                        sx={{
                          ...Style.versionsCard.infoRound,
                          width: '32px',
                          height: '32px'
                        }}>
                        <img
                          width={'100%'}
                          height="100%"
                          style={{ objectFit: 'cover', borderRadius: '50%' }}
                          src={`${process.env.REACT_APP_API_HOST}/${admin.ico}`}
                          alt=""
                        />
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          ...Style.versionsCard.infoRound,
                          width: '32px',
                          height: '32px'
                        }}>
                        {' '}
                        {admin.first_name
                          ? admin.first_name.charAt(0) + '' + admin.last_name.charAt(0)
                          : '-'}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography fontSize={'16px'} fontWeight={600}>
                      {admin?.name}
                    </Typography>
                    <Typography variant="caption">{company}</Typography>
                  </Box>
                </Box>
                <Box sx={{ flex: 1, width: 'ma' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: '6px'
                    }}>
                    <MailOutlineIcon sx={{ width: '14px', height: '14px' }} />
                    <Typography variant="caption">{admin?.email}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: '6px'
                    }}>
                    <PhoneIcon sx={{ width: '14px', height: '14px' }} />
                    <Typography
                      variant="caption"
                      sx={{
                        color: '#696969',
                        fontSize: '12px',
                        lineHeight: '14px',
                        ' .PhoneInputCountry': {
                          display: 'none'
                        },
                        ' .PhoneInputInput': {
                          padding: 0,
                          color: '#696969',
                          fontSize: '12px',
                          lineHeight: '14px'
                        },
                        ' input:disabled': {
                          bgcolor: 'transparent'
                        }
                      }}>
                      {admin?.phone ? (
                        <PhoneInputWithCountrySelect
                          onChange={() => {}}
                          international
                          disabled
                          autoFormat={false}
                          defaultCountry="US"
                          value={admin?.phone}
                        />
                      ) : (
                        '-'
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </DialogContent>
    </Dialog>
  );
};

export default ComapnyAdminDialog;
