import { Autocomplete, Box, Chip, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RiskManagementApi } from 'src/Pages/RiskManagement/RiskManagement.Api';

const CyberSecurityDomainDropdown = ({
  name,
  label,
  disabled,
  value,
  onChange,
  showChips,
  deleteChip,
  useUUID
}: {
  name: string;
  label: string;
  disabled: boolean;
  value: any;
  onChange: any;
  showChips?: boolean;
  deleteChip?: any;
  useUUID: boolean;
}) => {
  const [servicesOption, setServicesOption] = useState([]);

  const GetServiceFilterOption = async () => {
    try {
      const res = await RiskManagementApi.GetServicesList();
      const ProviderListForFilter = res.data.data.map((el) => {
        return {
          key: `${el.code}`,
          value: !useUUID ? el.remediation_source : el.services_uuid,
          name: el.name,
          tooltip: el.name,
          tools: el.tools
        };
      });
      setServicesOption(ProviderListForFilter);
    } catch (error) {}
  };

  const ChipInput = () => {
    return (
      <Autocomplete
        fullWidth
        multiple
        id="tags-filled"
        options={servicesOption.map((option) => `${option.name} (${option.key})`)}
        freeSolo
        value={value}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              onDelete={deleteChip ? () => deleteChip(option, value) : undefined}
              sx={{ fontSize: '14px' }}
            />
          ))
        }
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            placeholder={value?.length > 0 ? '' : 'Select Cybersecurity Domain'}
            sx={{ fontSize: '14px' }}
          />
        )}
        sx={{
          '&.MuiAutocomplete-root': {
            '.MuiOutlinedInput-root': {
              p: 0,
              pl: 1,
              fontSize: '14px'
            },
            '.MuiAutocomplete-tag': {
              m: 1,
              bgcolor: 'common.lightBlue',
              border: 'none',
              fontSize: '14px'
            }
          },
          '& .MuiInputBase-input': {
            fontSize: '14px'
          },
          '& .MuiChip-root': {
            fontSize: '14px'
          }
        }}
      />
    );
  };

  const SelectInput = () => {
    return (
      <Select
        disabled={disabled}
        size="small"
        displayEmpty
        fullWidth
        id="type"
        sx={{
          fontSize: '14px',
          color: '#696969',
          minWidth: { xxs: 0, md: '188px' },
          backgroundColor: '#FFFFFF'
        }}
        defaultValue=""
        name={name || 'services_uuid'}
        value={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Without label' }}>
        <MenuItem value="" sx={{ display: 'none' }}>
          Select Cybersecurity Domain
        </MenuItem>
        {servicesOption?.map((el: any, index) => (
          <MenuItem sx={{ fontSize: '14px' }} value={el?.value} autoFocus={index === 0} key={index}>
            {el?.name} {`(${el?.key})`}
          </MenuItem>
        ))}
      </Select>
    );
  };

  useEffect(() => {
    GetServiceFilterOption();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2
      }}>
      <Typography variant="subtitle2" sx={{ fontSize: '14px', fontWeight: 600 }}>
        {label}
      </Typography>
      <Box sx={{ width: 1 }}>{showChips ? <ChipInput /> : <SelectInput />}</Box>
    </Box>
  );
};

export default CyberSecurityDomainDropdown;
