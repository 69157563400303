import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { createSlice } from '@reduxjs/toolkit';
import { LicenseIcon } from '@jibin/common/icons/License';
const initialStateObj = {
  servicesDetials: []
};

export const serviceDetailSlice = createSlice({
  name: 'servicesDetials',
  initialState: initialStateObj,
  reducers: {
    setserviceDetails: (state, { payload }) => {
      payload.push({
        name: 'Licenses',
        enabled: true,
        showing_name: 'Licenses',
        tooltip_name: '',
        id: 10,
        route: PageRouteConstants.Licenses,
        supported_route: 'licenses',
        icon: LicenseIcon
      });
      state.servicesDetials = payload;
    },
    removeserviceDetails: (state) => {
      state.servicesDetials = [];
    }
  }
});

//const { setcommonDetails, removecommonDetails } = commonDetailSlice.actions;

//it behave like connector (old redux)

export default serviceDetailSlice;
